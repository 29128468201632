import {
  Button,
  Classes,
  InputGroup,
  Intent,
  Spinner,
  Tab,
  Tabs,
  AnchorButton,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css } from 'emotion';
import gql from 'graphql-tag';
import React from 'react';
import { Query } from 'react-apollo';
import Helmet from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { FAQItem } from '../../gql-types';
import { FAQTable } from '../faq/FAQTable';
import { PageLayout } from '../layouts/PageLayout';

interface Props extends RouteComponentProps<any> {}

interface State {
  currentTab: string;
  titleFilter: string;
}

const FIND_ALL_FAQS = gql`
  query FindAllFAQs {
    faqs: findAllFAQs {
      id
      title
      content
      category
      created
    }
  }
`;

const categoryMap = new Map([
  // ['all', '전체'],
  ['vod-membership', '영상 멤버십'],
  ['consulting-membership', '컨설팅 멤버십'],
  ['workout', '운동정보'],
  ['secret9', '시크릿9'],
  ['account', '회원정보'],
  ['payment', '결제 및 환불'],
]);

const categoryKeys = Array.from(categoryMap.keys());

const koreanLetters = /(ㄱ|ㄴ|ㄷ|ㄹ|ㅁ|ㅂ|ㅅ|ㅇ|ㅈ|ㅊ|ㅋ|ㅌ|ㅍ|ㅎ)/i;

export class FAQPage extends React.Component<Props, State> {
  state = {
    currentTab: 'vod-membership',
    titleFilter: '',
  };

  private handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    const value = target.value;
    this.setState({ titleFilter: value });
  };

  private handleTabChange = (tabId: string) => {
    this.setState({ currentTab: tabId });
  };

  render() {
    const title = (
      <div>
        <h1>자주 하는 질문</h1>
        <label>
          이용 문의 및 기술 지원 ▶
          <AnchorButton href="mailto:tikim@secretdiet.co.kr" minimal>
            tikim@secretdiet.co.kr
          </AnchorButton>
        </label>
      </div>
    );
    return (
      <PageLayout
        title={title}
        className={css({
          maxWidth: 1024,
        })}
      >
        <Helmet title="FAQ" />
        <InputGroup
          style={{
            marginBottom: '1em',
          }}
          value={this.state.titleFilter}
          leftIcon={IconNames.SEARCH}
          onChange={this.handleChange}
        />

        <Query query={FIND_ALL_FAQS}>
          {obj => {
            if (obj.loading) {
              return <Spinner />;
            }

            const items: FAQItem[] = obj.data.faqs;
            const categories = categoryKeys;
            const filtered =
              this.state.titleFilter === ''
                ? items
                : items.filter(item => {
                    const query = this.state.titleFilter.replace(
                      koreanLetters,
                      '',
                    );
                    const reg = new RegExp(query, 'i');
                    return reg.test(item.title);
                  });

            const table = (
              <FAQTable category={this.state.currentTab} items={filtered} />
            );
            return (
              <Tabs
                id="faq-tabs"
                className={css({
                  [`& .${Classes.TAB_LIST}`]: {
                    overflowX: 'scroll',
                    width: '100%',
                  },
                })}
                selectedTabId={this.state.currentTab}
                onChange={this.handleTabChange}
              >
                {categories.map(category => {
                  const title = categoryMap.has(category)
                    ? categoryMap.get(category)
                    : category;
                  return (
                    <Tab
                      key={category}
                      id={category}
                      title={title}
                      panel={table}
                    />
                  );
                })}
              </Tabs>
            );
          }}
        </Query>
      </PageLayout>
    );
  }
}
