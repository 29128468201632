import { Button, Spinner } from '@blueprintjs/core';
import gql from 'graphql-tag';
import React from 'react';
import { Query, QueryResult } from 'react-apollo';
import { IConnection } from '../../gql-model';
import { ReviewContent, User } from '../../gql-types';
import { getEdges, getNodes } from '../../secret-fns';
import { ReviewListTable } from './ReviewListTable';
import { ReviewWriteView } from './ReviewWriteView';

interface Props {
  currentUser?: User | null;
  first: number;
  editEnabled?: boolean;
}

const FETCH_BOARDS_LIST = gql`
  query FetchReviewConnection($after: String, $first: Int) {
    items: reviewConnection(after: $after, first: $first) {
      edges {
        node {
          id
          writerName
          user {
            id
            name
            nickname
          }
          content
          point
          created
          updated
          deleted
        }
        cursor
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export class ReviewList extends React.Component<Props> {
  private handleFetchMore = (
    result: QueryResult<any, any>,
    lastCursor: string | null,
  ) => {
    const { first } = this.props;
    result.fetchMore({
      variables: {
        first,
        after: lastCursor,
      } as any,
      updateQuery(prevResult, { fetchMoreResult }) {
        const newEdges = fetchMoreResult.items.edges;
        const pageInfo = fetchMoreResult.items.pageInfo;
        return newEdges.length
          ? {
              items: {
                __typename: prevResult.items.__typename,
                edges: [...prevResult.items.edges, ...newEdges],
                pageInfo,
              },
            }
          : prevResult;
      },
    });
  };

  render() {
    const { first, editEnabled, currentUser } = this.props;
    return (
      <Query
        query={FETCH_BOARDS_LIST}
        variables={{
          first,
        }}
      >
        {obj => {
          if (obj.loading) {
            return <Spinner />;
          }

          const items: IConnection<ReviewContent> =
            (obj.data || {}).items || [];
          const hasNext = items.pageInfo.hasNextPage;
          const edges = getEdges(items);
          const nodes = getNodes(items);
          const lastCursor =
            edges.length > 1 ? edges[edges.length - 1].cursor : null;
          return (
            <div
              style={{
                display: 'grid',
                gridRowGap: '1em',
              }}
            >
              {editEnabled && <ReviewWriteView />}
              <ReviewListTable currentUser={currentUser} items={nodes} />
              {hasNext && (
                <Button onClick={() => this.handleFetchMore(obj, lastCursor)}>
                  더 보기
                </Button>
              )}
            </div>
          );
        }}
      </Query>
    );
  }
}
