import React from 'react';
import { Spinner } from '@blueprintjs/core';
import { GridByRow, GridRowStyle, GridItem } from './GridHelper';
import styled from 'react-emotion';

export interface GridProps<T> {
  loading?: boolean;
  size: number;
  items: T[];
  itemId: (item: T) => string;
  // cell: React.ComponentType<{ item: T }>;
  cell: React.ComponentType<any>;
}

const SpinnerContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

export function Grid<T>(props: GridProps<T>) {
  const rows = GridByRow<T>(props.size)(props.items);
  const itemId = props.itemId;
  const Cell = props.cell;

  return (
    <div style={{ width: '100%' }}>
      {rows.map((row, index) => {
        return (
          <div className={GridRowStyle} key={index}>
            {row.map(item => {
              return (
                <div className={GridItem} key={itemId(item)}>
                  <Cell item={item} />
                </div>
              );
            })}
          </div>
        );
      })}
      {props.loading && (
        <SpinnerContainer>
          <Spinner size={50} />
        </SpinnerContainer>
      )}
    </div>
  );
}

export const GridItemView = styled('div')(GridItem);
