import React from 'react';
import gql from 'graphql-tag';
import { Spinner, Button } from '@blueprintjs/core';
import { Query, QueryResult } from 'react-apollo';
import { BoardType, BoardContent } from '../../gql-types';
import { BoardListView } from './BoardListView';
import { IConnection } from '../../gql-model';
import { getNodes, getEdges } from '../../secret-fns';
import { BoardWriteView } from './BoardWriteView';

interface Props {
  type: BoardType;
  first: number;
  prefix?: string;
  editEnabled?: boolean;
}

const FETCH_BOARDS_LIST = gql`
  query FetchBoardConnection($type: BoardType!, $after: String, $first: Int) {
    items: boardArticlesConnection(type: $type, after: $after, first: $first) {
      edges {
        node {
          id
          user {
            id
            name
            nickname
          }
          title
          content
          created
        }
        cursor
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

interface State {
  edit: boolean;
}

export class BoardList extends React.Component<Props, State> {
  state = {
    edit: false,
  };

  private stopEdit = () => this.setState({ edit: false });
  private startEdit = () => this.setState({ edit: true });
  private handleFetchMore = (
    result: QueryResult<any, any>,
    lastCursor: string | null,
  ) => {
    const { first } = this.props;
    result.fetchMore({
      variables: {
        first,
        after: lastCursor,
      } as any,
      updateQuery(prevResult, { fetchMoreResult }) {
        const newEdges = fetchMoreResult.items.edges;
        const pageInfo = fetchMoreResult.items.pageInfo;
        return newEdges.length
          ? {
              items: {
                __typename: prevResult.items.__typename,
                edges: [...prevResult.items.edges, ...newEdges],
                pageInfo,
              },
            }
          : prevResult;
      },
    });
  };

  render() {
    const { first, type, editEnabled } = this.props;
    const { edit } = this.state;

    return (
      <Query
        query={FETCH_BOARDS_LIST}
        variables={{
          type: type,
          first,
        }}
      >
        {obj => {
          if (obj.loading) {
            return <Spinner />;
          }

          const items: IConnection<BoardContent> = (obj.data || {}).items || [];
          const hasNext = items.pageInfo.hasNextPage;
          const edges = getEdges(items);
          const nodes = getNodes(items);
          const lastCursor =
            edges.length > 1 ? edges[edges.length - 1].cursor : null;
          return (
            <div>
              {edit &&
                editEnabled && (
                  <BoardWriteView
                    type={this.props.type}
                    onComplete={this.stopEdit}
                    onCancel={this.stopEdit}
                  />
                )}
              {!edit &&
                editEnabled && <Button onClick={this.startEdit}>글쓰기</Button>}
              <BoardListView
                prefix={this.props.prefix}
                type={this.props.type}
                items={nodes}
              />
              {hasNext && (
                <Button
                  fill
                  onClick={() => this.handleFetchMore(obj, lastCursor)}
                >
                  더 보기
                </Button>
              )}
            </div>
          );
        }}
      </Query>
    );
  }
}
