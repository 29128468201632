import React, { useState, useCallback, useMemo } from 'react';
import { css } from 'emotion';
import Helmet from 'react-helmet';
import { Product } from '../../gql-types';
import { ProductGroupQuery, ProductGroupQueryProps } from './ProductGroupQuery';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import { PriceLabel, MarkdownDiv } from '../../components';
import { AddCartItemButton } from '../cart/AddCartItem';
import {
  getTranslatedColors,
  getSizes,
  getColors,
} from './ProductOptionSelectors';
import { ProductOptionSelectSection } from './ProductOptionSelectSection';

const getSelectionName = (product: Product) => {
  const color = getTranslatedColors(product).join(' ');
  if (!color) {
    return product.name;
  }

  return `(${color}) ${product.name}`;
};

export function ProductGroupDetailView(props: ProductGroupQueryProps) {
  const { loading, item } = props;
  if (loading) {
    return <Spinner />;
  }

  if (!item) {
    return <NonIdealState />;
  }

  const products = useMemo(
    () =>
      (item.products || []).filter((product): product is Product =>
        Boolean(product),
      ),
    [item.products],
  );

  const items = useMemo(
    () =>
      products.map(product => ({
        id: product.id,
        label: getSelectionName(product),
      })),
    [products],
  );

  const [selected, setProduct] = useState(products[0]);
  const sizes = getSizes(selected);
  const [size, setSize] = useState(sizes[0]);

  const getImageUrl = () => selected.imageUrl || item.imageUrl || '';
  const getDescription = () => selected.description || item.description || '';
  const getDetails = () => selected.details || item.details || '';
  const getName = () => selected.name || item.name || '';

  const sizeItems = useMemo(
    () =>
      sizes.map(obj => ({
        id: obj,
        label: obj,
      })),
    [sizes],
  );

  const handleProductSelect = useCallback(
    (id: string) => {
      const found = products.find(product => product.id === id);
      if (found) {
        setProduct(found);
        const newSizeList = getSizes(found);
        setSize(newSizeList[0]);
      }
    },
    [selected],
  );

  const handleSizeSelect = useCallback(
    (selectedSize: string) => {
      setSize(selectedSize);
    },
    [size],
  );

  const getOptions = () => {
    const colors = getTranslatedColors(selected);
    const options: any = {};
    if (size && colors) {
      return {
        color: colors.join(' '),
        size,
      };
    }

    if (size && !colors) {
      return {
        size,
      };
    }

    if (colors && !size) {
      return {
        color: colors.join(' '),
      };
    }

    return;
  };

  return (
    <section
      className={css({
        padding: '1em',
        minHeight: 'calc(100vh - 200px)',
      })}
    >
      <Helmet>
        <title>{item.name}</title>
      </Helmet>
      <section
        className={css({
          display: 'flex',
          flexWrap: 'wrap',
          marginBottom: '1em',
        })}
      >
        <section
          className={css({
            flex: '0 0 auto',
            marginRight: '1em',
          })}
        >
          <img
            className={css({ width: 300 })}
            src={getImageUrl()}
            alt={item.name}
          />
        </section>
        <section
          className={css({
            padding: '2em',
          })}
        >
          <h2>{getName()}</h2>
          <PriceLabel
            className={css({
              fontSize: '2em',
              fontWeight: 'bold',
            })}
            amount={selected.price}
            currencyCode={selected.currencyCode}
          />
          {items && items.length >= 2 && (
            <ProductOptionSelectSection
              label="옵션"
              value={selected.id}
              items={items}
              onSelect={handleProductSelect}
            />
          )}
          {sizes.length > 0 && (
            <ProductOptionSelectSection
              label="사이즈"
              value={size}
              items={sizeItems}
              onSelect={handleSizeSelect}
            />
          )}
          <div
            className={css({
              marginTop: '1em',
            })}
          >
            <AddCartItemButton productId={selected.id} options={getOptions()} />
          </div>
        </section>
      </section>
      <section>
        <article>{getDescription()}</article>
        <br />
        <br />
        <MarkdownDiv
          className={css({
            width: '100%',
            img: {
              width: '100%',
            },
          })}
          content={getDetails()}
        />
      </section>
    </section>
  );
}

ProductGroupDetailView.Connected = ProductGroupQuery(ProductGroupDetailView);
