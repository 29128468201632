import React from 'react';
import { RouteComponentProps } from 'react-router';
import { BoardType } from '../../gql-types';
import { BoardList } from '../community/BoardList';
import { CommunityLayout } from '../community/CommunityLayout';

export function CommunityBoardList(type: BoardType) {
  interface Props extends RouteComponentProps<any> {}

  class CommunityPage extends React.Component<Props> {
    static displayName = `CommunityBoardList(${type})`;

    render() {
      return (
        <CommunityLayout type={type} history={this.props.history}>
          <BoardList type={type} first={20} />
        </CommunityLayout>
      );
    }
  }

  return CommunityPage;
}
