import React from 'react';
import gql from 'graphql-tag';
import { Mutation, MutationFn } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import { Button, Intent } from '@blueprintjs/core';
import { Card } from '../../components';
import { PageLayout } from '../layouts/PageLayout';
import { WithCurrentUser, ICurrentUserProps } from '../users/CurrentUserQuery';

interface RouteParams {
  code: string;
}

interface Props extends RouteComponentProps<RouteParams>, ICurrentUserProps {}

interface State {
  completed: boolean;
}

const VERIFY_EMAIL_QUERY = gql`
  mutation VerifyEmail($code: String!) {
    verifyEmail(code: $code)
  }
`;

class EmailVerificationBase extends React.Component<Props, State> {
  state = {
    completed: false,
  };

  private handleLogin = () => {
    const { history } = this.props;
    const isLoggedIn = Boolean(this.props.user);
    const route = isLoggedIn ? '/' : '/login';
    history.push(route);
  };

  private handleUdpate = (update: MutationFn<any, any>) => {
    update();
  };

  private handleCompleted = (data: { verifyEmail: boolean }) => {
    if (data && data.verifyEmail) {
      this.setState({ completed: true });
    }
  };

  render() {
    const { code } = this.props.match.params;
    const { completed } = this.state;
    const isLoggedIn = Boolean(this.props.user);

    return (
      <PageLayout>
        <Card>
          <h2>이메일 확인</h2>
          {!completed && (
            <Mutation
              mutation={VERIFY_EMAIL_QUERY}
              variables={{
                code,
              }}
              onCompleted={this.handleCompleted}
            >
              {(update, obj) => {
                return (
                  <Button
                    id="verifyEmail"
                    loading={obj.loading}
                    disabled={obj.loading}
                    onClick={() => this.handleUdpate(update)}
                    intent={Intent.PRIMARY}
                  >
                    이메일 확인하기
                  </Button>
                );
              }}
            </Mutation>
          )}

          {completed && (
            <>
              <div>이메일 확인이 완료되었습니다</div>
              <Button onClick={this.handleLogin}>
                {isLoggedIn ? '홈페이지로 이동' : '로그인 하기'}
              </Button>
            </>
          )}
        </Card>
      </PageLayout>
    );
  }
}

export const EmailVerificationPage = WithCurrentUser(EmailVerificationBase);
