import React from 'react';
import isEqual from 'lodash/isEqual';
import { User, Gender } from '../../gql-types';
import { Card } from '../../components';
import { BirthdayInput } from './BirthdayInput';
import { UpdateProfileButton } from './UpdateProfileButton';
import {
  FormGroup,
  InputGroup,
  ControlGroup,
  RadioGroup,
  Radio,
  Callout,
  Intent,
} from '@blueprintjs/core';

interface Props {
  user: User;
}

interface State {
  [key: string]: string;
  name: string;
  gender: string;
  birthday: string;
}

function getInitialState(user: User): State {
  return {
    name: user.name || '',
    gender: String(user.gender || Gender.UNKNOWN),
    birthday: user.birthday || '',
  };
}

function isChanged(user: User, state: State): boolean {
  const prevState = getInitialState(user);
  return !isEqual(prevState, state);
}

function isEverythingSet(state: State): boolean {
  return Object.values(state).every(Boolean);
}

export class UserDetailsView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { user } = props;
    this.state = getInitialState(user);
  }

  private handleBirthdayChange = (value: string) => {
    this.setState({ birthday: value });
  };

  private handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    const { name, value } = target;
    this.setState({
      [name]: value,
    });
  };

  private handleComplete = () => {
    const newState = getInitialState(this.props.user);
    this.setState(newState);
  };

  render() {
    const { user } = this.props;
    const { name, gender, birthday } = this.state;
    return (
      <Card
        style={{
          marginBottom: '1em',
        }}
      >
        <h3>상세정보</h3>
        {!isEverythingSet(getInitialState(user)) && (
          <Callout
            title="상담을 위한 상세정보가 필요합니다"
            intent={Intent.WARNING}
          />
        )}
        <FormGroup label="이름">
          <ControlGroup>
            <InputGroup
              style={{ maxWidth: 320 }}
              id="name"
              name="name"
              type="text"
              placeholder="이름을 입력해 주세요"
              value={name}
              onChange={this.handleChange}
            />
          </ControlGroup>
        </FormGroup>
        <FormGroup label="생년월일">
          <BirthdayInput
            value={birthday}
            onChange={this.handleBirthdayChange}
          />
        </FormGroup>
        <RadioGroup
          label="성별"
          name="gender"
          inline
          selectedValue={gender}
          onChange={this.handleChange}
        >
          <Radio large label="여성" value={Gender.FEMALE} />
          <Radio large label="남성" value={Gender.MALE} />
        </RadioGroup>
        {isChanged(user, this.state) && (
          <UpdateProfileButton
            profile={this.state}
            onCompleted={this.handleComplete}
          />
        )}
      </Card>
    );
  }
}
