import React from 'react';
import get from 'lodash/get';
import gql from 'graphql-tag';
import styled from 'react-emotion';
import { Query } from 'react-apollo';
import {
  Spinner,
  InputGroup,
  FormGroup,
  ControlGroup,
  Button,
} from '@blueprintjs/core';
import { JusoRow } from './JusoRow';
import { JusoAddress } from '../../gql-types';
import { IconNames } from '@blueprintjs/icons';

const JusoContainer = styled('div')({
  padding: '1em',
});

const FIND_JUSO_QUERY = gql`
  query FindJuso($keyword: String!, $page: Int) {
    juso: findJuso(keyword: $keyword, page: $page) {
      jusos {
        roadAddr
        roadAddrPart1
        roadAddrPart2
        zipNo
        siNm
      }
      pageInfo {
        total
      }
    }
  }
`;

interface State {
  keyword: string;
  skip: boolean;
}

const isValidKeyword = (keyword: string) => {
  return keyword.length >= 2;
};

interface Props {
  onSelect(address: JusoAddress): void;
}

export class JusoListView extends React.Component<Props, State> {
  state = {
    keyword: '',
    skip: true,
  };

  private handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    this.setState({ keyword: value, skip: false });
  };

  private handleSubmit = (refetch: Function) => {
    const { keyword } = this.state;
    if (!isValidKeyword(keyword)) {
      return;
    }

    this.setState({ skip: false }, () => {
      refetch({
        keyword,
        page: 1,
      });
    });
  };

  private handleSearchKey = (
    e: React.KeyboardEvent<HTMLInputElement>,
    refetch: Function,
  ) => {
    const value = e.currentTarget.value;
    if (e.key === 'Enter' && Boolean(value)) {
      this.handleSubmit(refetch);
    }
  };

  render() {
    const { keyword, skip } = this.state;
    return (
      <Query query={FIND_JUSO_QUERY}>
        {result => {
          const jusos: JusoAddress[] = get(result, 'data.juso.jusos', []);
          return (
            <JusoContainer>
              <FormGroup>
                <ControlGroup>
                  <InputGroup
                    id="addressSearch"
                    value={keyword}
                    onChange={this.handleChange}
                    onKeyDown={e => this.handleSearchKey(e, result.refetch)}
                  />
                  <Button
                    icon={IconNames.SEARCH}
                    onClick={() => this.handleSubmit(result.refetch)}
                  />
                </ControlGroup>
              </FormGroup>
              <table>
                <thead>
                  <tr>
                    <th>주소</th>
                    <th>우편번호</th>
                  </tr>
                </thead>
                <tbody>
                  {!skip && result.loading && (
                    <tr>
                      <td>
                        <Spinner />
                      </td>
                    </tr>
                  )}
                  {jusos.length === 0 && (
                    <tr>
                      <td>검색어를 두글자 이상 입력해 주세요</td>
                    </tr>
                  )}
                  {jusos.map((juso, index) => {
                    return (
                      <JusoRow
                        key={index}
                        address={juso}
                        onSelect={this.props.onSelect}
                      />
                    );
                  })}
                </tbody>
              </table>
            </JusoContainer>
          );
        }}
      </Query>
    );
  }
}
