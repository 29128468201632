import { ShoppingCartDto } from './ShoppingCartDto';

export interface CartItemRecord {
  version: string;
  lastUpdated: Date | number | string;
  items: ShoppingCartDto[];
}

const STORAGE_KEY = 'local-cache';

function equals(item1: ShoppingCartDto, item2: ShoppingCartDto): boolean {
  return item1.productId === item2.productId && item1.options === item2.options;
}

const equalsWith = (item1: ShoppingCartDto) => (item2: ShoppingCartDto) =>
  equals(item1, item2);

export class LocalCart {
  enabled: boolean;

  constructor() {
    if (typeof window === 'undefined') {
      this.enabled = false;
      return;
    }

    if (!window.localStorage) {
      this.enabled = false;
      return;
    }

    this.enabled = true;
  }

  get(): ShoppingCartDto[] {
    if (!this.enabled) {
      return [];
    }

    const value = window.localStorage.getItem(STORAGE_KEY);
    if (!value) {
      return [];
    }

    try {
      const items = JSON.parse(value);
      if (!Array.isArray(items)) {
        // invalid items
        return [];
      }

      return items;
    } catch (ignore) {
      return [];
    }
  }

  set(items: ShoppingCartDto[]) {
    if (!this.enabled) {
      return this;
    }

    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(items));
    return this;
  }

  add(item: ShoppingCartDto): this {
    if (!this.enabled) {
      return this;
    }

    const items = this.get();
    const hasItem = items.some(equalsWith(item));
    if (hasItem) {
      return this.set(
        items.map(prevItem => {
          if (equals(prevItem, item)) {
            return {
              productId: item.productId,
              options: item.options,
              quantity: item.quantity + prevItem.quantity,
            };
          }

          return prevItem;
        }),
      );
    }

    return this.set(items.concat(item));
  }

  update(item: ShoppingCartDto): this {
    if (!this.enabled) {
      return this;
    }

    const items = this.get();
    const newItems = items.map(prevItem => {
      if (equals(prevItem, item)) {
        return {
          productId: item.productId,
          quantity: item.quantity,
          options: item.options,
        };
      }

      return prevItem;
    });
    return this.set(newItems);
  }

  hydrate(): ShoppingCartDto[] {
    if (!this.enabled) {
      return [];
    }

    const items = this.get();
    this.set([]);
    return items;
  }

  remove(item: { productId: string; options?: string | null }): this {
    if (!this.enabled) {
      return this;
    }

    const items = this.get();
    const newItems = items.filter(
      prevItem =>
        !(
          prevItem.productId === item.productId &&
          prevItem.options === item.options
        ),
    );
    return this.set(newItems);
  }

  clear(): this {
    return this.set([]);
  }
}
