import React from 'react';
import { css } from 'emotion';
import { HomeFontStyle } from './HomeFontStyle';
import { OutlineButton } from '../../components/buttons';

export const SplashTitleStyle = css({
  fontSize: '3em',
  lineHeight: 1,
  color: 'black',
  textShadow: '0px 0px 5px white',
});

export const SplashBodyStyle = css({
  fontSize: '0.8em',
  lineHeight: 1.5,
  // fontWeight: 200,
});

interface SplashBody {
  message: string;
  bold?: boolean;
}

interface SplashProps {
  dark?: boolean;
  bottom?: boolean;
  background?: boolean;
  gradient?: boolean;
  messages: SplashBody[];
  onRegister(): void;
}

const containerStyle = (props: SplashProps) =>
  css(
    {
      width: '100%',
      height: '100%',
      paddingTop: '2em',
      paddingRight: '8em',
      paddingLeft: '8em',
      color: props.dark ? '#3c3532' : 'white',
      background: props.gradient
        ? 'linear-gradient(to bottom, white, transparent)'
        : undefined,
      '@media (max-width: 640px)': {
        padding: '2em',
        paddingBottom: '5em',
      },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: props.bottom ? 'flex-end' : undefined,
    },
    HomeFontStyle,
  );

export function SplashMessage(props: SplashProps) {
  if (props.messages.length === 0) {
    return <div className={containerStyle(props)} />;
  }

  return (
    <div className={containerStyle(props)}>
      <div
        className={css(SplashTitleStyle, {
          fontWeight: 'bold',
          marginLeft: '-0.05em',
        })}
      >
        SECRET
      </div>
      <div className={css(SplashTitleStyle, { marginLeft: '-0.1em' })}>
        DIET
      </div>
      <div
        className={css({
          height: 5,
          width: '1em',
          backgroundColor: '#81d5d2',
          display: 'block',
          lineHeight: 2,
          marginLeft: '0.1em',
          marginTop: '0.5em',
          marginBottom: '0.5em',
        })}
      />
      {props.messages.map((body, index) => (
        <div
          key={index}
          className={css(
            SplashBodyStyle,
            !props.dark &&
              props.background && { backgroundColor: 'rgba(0,0,0,0.5)' },
            props.dark && { textShadow: '0px 0px 3px white' },
            !props.dark && { textShadow: '0px 0px 3px black' },
            body.bold && { fontWeight: 700 },
          )}
        >
          {body.message}
        </div>
      ))}
      <OutlineButton
        fill
        className={css({
          marginTop: '1em',
          borderRadius: '1em',
          width: '7em',
        })}
        buttonColor={{ r: 130, g: 207, b: 204 }}
        onClick={props.onRegister}
      >
        가입하기
      </OutlineButton>
    </div>
  );
}
