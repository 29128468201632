import React, { useState, useEffect, useCallback } from 'react';
import { css } from 'emotion';
import { RouteComponentProps } from 'react-router';
import { ICurrentUserProps, WithCurrentUser } from '../users/CurrentUserQuery';
import { Spinner, AnchorButton, Callout, Intent } from '@blueprintjs/core';
import Helmet from 'react-helmet';
import { Card } from '../../components';
import { LoginRedirect } from '../../browser/models/LoginRedirectModel';
import { HambakCheckoutView } from '../events/HambakCheckoutView';
import { NoticeMobilePay } from '../../components'
import { UAParser } from 'ua-parser-js';
import { Link } from 'react-router-dom';

function formatMessage(msg?: string) {
  if (!msg) {
    return '';
  }

  return msg.replace('GraphQL error:', '').trim();
}

interface Props extends RouteComponentProps, ICurrentUserProps {}

export function HambakEventPage(props: Props) {
  if (props.loading) {
    return <Spinner />;
  }

  useEffect(() => {
    if (props.isLoggedIn) {
      return;
    }

    const model = LoginRedirect.forBrowser();
    if (!model) {
      return;
    }

    const url = props.match.url;
    model.set(url);
  }, [props.isLoggedIn]);

  const [error, setError] = useState(null as any | null);
  const handleFail = useCallback(
    (err: any) => {
      setError(err);
    },
    [error, setError],
  );

  const handleSuccess = useCallback(() => {
    props.history.push('/my-fitness');
  }, [props.history]);

  const handleCancel = useCallback(() => {
    props.history.push('/my-fitness');
  }, [props.history]);

  /**
   * #FIXME-START: prevent mobile web pay process.
   * 결제관련 문제가 수정되기 이전까지 모바일 웹으로 접근시 결제가 되지 않도록 수정.
   * 추후 문제가 해결되면 이 부분을 지워주세요.
   */
  const parser = new UAParser();
  const { type: deviceType } = parser.getDevice();
  if (deviceType === 'mobile') {
    return (<NoticeMobilePay />);
  } else {
    // 마지막 중괄호 반드시 지울것!
    // #FIXME-END
    return (
      <article
        className={css({
          position: 'relative',
          maxWidth: 600,
          minWidth: 360,
          width: '100%',
          paddingTop: '5em',
          paddingBottom: '5em',
          paddingLeft: '1em',
          paddingRight: '1em',
        })}
      >
        <Helmet>
          <meta
            key="description"
            content="코로나 이벤트 10주의 기적! 3개월 하면, 밴드도 함께 배송!!!"
          />
        </Helmet>
        <header>
          {error && (
            <Callout intent={Intent.WARNING} title="결제에러">
              {error && formatMessage(error.message)}
            </Callout>
          )}
        </header>
        <Card>
          <h2>코로나 이벤트</h2>
          <section
            className={css({
              marginBottom: '1em',
            })}
          >
            <h3>189,000원</h3>
            <div>10주의 기적!</div>
            <div>3개월 구매 하면, 밴드 배송을 함께!!! </div>
            <div>구매후 바로 시작! </div>
            <div>일시정지 안됨.</div>
          </section>
          <section>
            <div>환불시 정상가격 기준으로 일반 환불규정이 적용 됩니다.</div>
            <div className={css({
              fontWeight: 'bold'
            })}>
              ※ 반드시 결제 후 하단 고객센터 버튼을 클릭 후<br/>&nbsp;&nbsp;&nbsp;카카오톡 플러스친구에서 시크릿다이어트를 친구추가!<br/><br/>
              결제일, 이름, 이메일주소와 '코로나 결제'라고 남기시고 운동 시작!!!<br/>
              (고객센터에 말씀을 해주셔야 밴드가 발송됩니다.)
            </div>
            <div className={css({marginTop: '15px'})}>
              <a href='https://pf.kakao.com/_gxlNkd/chat' className={css({color: 'blue'})}>
                카카오톡 채널 바로가기
              </a>
            </div>
            <div>
              <br/><br/>밤11시 그립 앱의 김지훈트레이너 라방에서 무엇이든 물어보세요!
            </div>
          </section>
          <section>
            <div>필수 운동기구 - 덤벨 한쪽씩2kg, 밴드, 요가매트.</div>
          </section>
        </Card>
        <section
          className={css({
            marginTop: '1em',
          })}
        />
        {!props.loading && props.isLoggedIn && props.user && (
          <HambakCheckoutView.Connected
            shop="hambak-event"
            user={props.user}
            onFail={handleFail}
            onSuccess={handleSuccess}
            onCancel={handleCancel}
          />
        )}
        {!props.loading && !props.isLoggedIn && (
          <div>
            <AnchorButton
              className={css({ marginRight: '1em' })}
              intent={Intent.PRIMARY}
              href="/login"
            >
              로그인
            </AnchorButton>
            <AnchorButton intent={Intent.PRIMARY} href="/signup">
              회원가입
            </AnchorButton>
          </div>
        )}
      </article>
    );
  }
}

HambakEventPage.Connected = WithCurrentUser(HambakEventPage);
