import styled from 'react-emotion';

export const SectionImage = styled('div')(
  {
    backgroundSize: 'cover',
    minHeight: '20em',

    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  (props: { src: string; variableHeight?: boolean }) => {
    return {
      backgroundImage: `url(${props.src})`,
    };
  },
);
