import React from 'react';
import { RouteComponentProps } from 'react-router';
import { css } from 'emotion';
import Helmet from 'react-helmet';

import { ProductGroupGrid } from '../products/ProductGroupGrid';

import UnderConstruction from '../home/UnderConstruction'

export default function SecretMallPage(props: RouteComponentProps<any>) {
  return (
    <section
      className={css({
        padding: '1em',
        minHeight: 'calc(100vh - 200px)',
      })}
    >
      <Helmet>
        <title>시크릿몰</title>
        <meta
          name="description"
          content="시크릿몰 - 다이어트에 도움되는 필수품"
        />
      </Helmet>
      {/* <section
        className={css({
          marginBottom: '2em',
        })}
      >
        <h2>시크릿몰</h2>
        <article className={css({ fontSize: '1.2em' })}>
          김지훈 트레이너가 추천하는 다이어트 필수품
        </article>
      </section>
      <ProductGroupGrid.Connected
        page={1}
        limit={100}
        shop="secret-mall"
        onRouteChange={route => props.history.push(route)}
      /> */}
      <UnderConstruction/>
    </section>
  );
}
