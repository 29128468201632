import { BoardType } from '../gql-types';
import AboutUsPage from './pages/AboutUsPage';
import { BeforeAndAfterItemPage } from './pages/BeforeAndAfterItemPage';
import { BeforeAndAfterPage } from './pages/BeforeAndAfterPage';
import CartPage from './pages/CartPage';
import { CommunityBoardList } from './pages/CommunityBoardList';
import { CommunityContent } from './pages/CommunityContent';
import CommunityPageRedirect from './pages/CommunityPageRedirect';
import { CourseDescriptionPage } from './pages/CourseDescriptionPage';
import { CourseDetailPage } from './pages/CourseDetailPage';
import { EmailVerificationPage } from './pages/EmailVerificationPage';
import { FAQPage } from './pages/FAQPage';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import GymPage from './pages/GymPage';
import { HambakEventPage } from './pages/HambakEventPage';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import MyFitnessPage from './pages/MyFitnesssPage';
import { PaymentFailurePage } from './pages/PaymentFailurePage';
import { PaymentSuccessPage } from './pages/PaymentSuccessPage';
import PlansPage from './pages/PlansPage';
import { ProductGroupItemPage } from './pages/ProductGroupItemPage';
import ProfilePage from './pages/ProfilePage';
import ProgramPage from './pages/ProgramPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import { ReviewListPage } from './pages/ReviewListPage';
import { ReviewsPageRedirect } from './pages/ReviewsPageRedirect';
import { default as SecretMallPage } from './pages/SecretMallPage';
import SignupPage from './pages/SignupPage';
import { SocialSignupPage } from './pages/SocialSignupPage';
import { TlxNewUserPage } from './pages/TlxNewUserPage';
import { TosPage } from './pages/TosPage';
import { flattenViewRoutes } from './ViewRoute';

export default flattenViewRoutes([
  {
    path: '/',
    component: HomePage,
    title: '시크릿다이어트',
  },
  {
    path: '/program',
    component: ProgramPage,
    title: '시크릿한 프로그램',
  },
  {
    path: '/community',
    component: CommunityPageRedirect,
    title: '커뮤니티',
  },
  {
    path: '/reviews',
    component: ReviewsPageRedirect,
    title: '후기',
  },
  {
    path: '/reviews/user-reviews',
    component: ReviewListPage,
    title: '후기',
  },
  {
    path: '/reviews/beforeAndAfter',
    component: BeforeAndAfterPage,
    title: '비포앤애프터',
  },
  {
    path: '/reviews/beforeAndAfter/:id',
    component: BeforeAndAfterItemPage,
    title: '비포앤애프터',
  },
  {
    path: '/community/news',
    component: CommunityBoardList(BoardType.news),
    title: '보도자료',
  },
  {
    path: '/community/news/:id',
    component: CommunityContent(BoardType.news),
    title: '보도자료',
  },
  {
    path: '/community/secretpost',
    component: CommunityBoardList(BoardType.secretpost),
    title: '시크릿포스트',
  },
  {
    path: '/community/secretpost/:id',
    component: CommunityContent(BoardType.secretpost),
    title: '시크릿포스트',
  },
  {
    path: '/community/events',
    component: CommunityBoardList(BoardType.events),
    title: '비포앤애프터',
  },
  {
    path: '/community/events/:id',
    component: CommunityContent(BoardType.events),
    title: '비포앤애프터',
  },
  {
    path: '/community/announcements',
    component: CommunityBoardList(BoardType.announcements),
    title: '비포앤애프터',
  },
  {
    path: '/community/announcements/:id',
    component: CommunityContent(BoardType.announcements),
    title: '비포앤애프터',
  },
  {
    path: '/courses/:slug',
    component: CourseDetailPage,
    title: '프로그램',
  },
  {
    path: '/mall',
    component: SecretMallPage,
  },
  {
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/profile',
    component: ProfilePage,
  },
  {
    path: '/signup',
    component: SignupPage,
  },
  {
    path: '/social-signup',
    component: SocialSignupPage,
  },
  {
    path: '/my-fitness',
    component: MyFitnessPage,
  },
  {
    path: '/gym/lectures/:slug',
    component: GymPage,
  },
  {
    path: '/about-us',
    component: AboutUsPage,
  },
  {
    path: '/plans',
    component: PlansPage,
  },
  {
    path: '/cart',
    component: CartPage,
  },
  {
    path: '/forgot-password',
    component: ForgotPasswordPage,
  },
  {
    path: '/reset-password/:code',
    component: ResetPasswordPage,
  },
  {
    path: '/email-verifications/:code',
    component: EmailVerificationPage,
  },
  {
    path: '/courses/:slug/description',
    component: CourseDescriptionPage,
  },
  {
    path: '/faq',
    title: '자주 하는 질문',
    component: FAQPage,
  },
  {
    path: '/tos',
    title: '이용 약관',
    component: TosPage,
  },
  {
    path: '/payment/success',
    title: '결제 완료',
    component: PaymentSuccessPage,
  },
  {
    path: '/payment/fail',
    title: '결제 실패',
    component: PaymentFailurePage,
  },
  {
    path: '/secret-mall',
    title: '시크릿 몰',
    component: SecretMallPage,
  },
  {
    path: '/product-groups/:slug',
    title: '',
    component: ProductGroupItemPage,
  },
  {
    path: '/cart',
    title: '쇼핑카트',
    component: CartPage,
  },
  {
    path: '/hambak-event',
    title: '코로나 이벤트',
    component: HambakEventPage.Connected,
  },
  {
    path: '/tlx/new-user/:code',
    title: 'TLX + 시크릿다이어트',
    component: TlxNewUserPage,
  },
]);
