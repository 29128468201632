import React from 'react';
import styled from 'react-emotion';
import { JusoAddress } from '../../gql-types';

interface Props {
  index?: number;
  address: JusoAddress;
  onSelect(address: JusoAddress): void;
}

const TableRow = styled('tr')({
  cursor: 'pointer',
  ':hover': {
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
});

export function JusoRow(props: Props) {
  const { address } = props;
  return (
    <TableRow onClick={() => props.onSelect(address)}>
      <td>{address.roadAddr}</td>
      <td>{address.zipNo}</td>
    </TableRow>
  );
}
