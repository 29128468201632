import { Intent } from '@blueprintjs/core';
import { ApolloError } from 'apollo-client';
import gql from 'graphql-tag';
import React from 'react';
import { Mutation, MutationResult } from 'react-apollo';
import styled from 'react-emotion';
import { Redirect, RouteComponentProps } from 'react-router';
import { LoginRedirect } from '../../browser/models/LoginRedirectModel';
import { User } from '../../gql-types';
import AppToaster from '../AppToaster';
import { ICurrentUserProps, WithCurrentUser } from '../users/CurrentUserQuery';
import Signup from '../users/Signup';

const SignupContainer = styled('article')({
  position: 'relative',
  maxWidth: 600,
  minWidth: 360,
  width: '100%',
  paddingTop: '5em',
  paddingBottom: '5em',
  paddingLeft: '1em',
  paddingRight: '1em',
});

interface Props extends ICurrentUserProps, RouteComponentProps<any> {}

const REGISTER_MUTATION = gql`
  mutation RegisterUser($creds: UserInput!) {
    user: register(user: $creds) {
      id
      email
    }
  }
`;

class SignupPage extends React.Component<Props> {
  private handleError = (error: ApolloError) => {
    const errors = error.graphQLErrors;
    errors.forEach(err => {
      if (AppToaster) {
        AppToaster.show({
          intent: Intent.DANGER,
          message: err.message,
        });
      }
    });
  };

  private handleCompleted = () => {
    if (gtag && typeof gtag === 'function') {
      gtag('event', 'sign_up');
    }

    const model = LoginRedirect.forBrowser();
    if (model) {
      const redirect = model.getAndClear();
      if (redirect) {
        this.props.history.push(redirect);
        return;
      }
    }

    const { history } = this.props;
    history.push('/plans');
  };

  render() {
    if (this.props.user) {
      return <Redirect to="/my-fitness" />;
    }

    return (
      <SignupContainer>
        <Mutation
          mutation={REGISTER_MUTATION}
          onError={this.handleError}
          refetchQueries={['GetCurrentLoginStatus']}
          onCompleted={this.handleCompleted}
        >
          {(submit, result) => {
            return (
              <Signup
                loading={result.loading}
                onSubmit={creds =>
                  submit({
                    variables: {
                      creds,
                    },
                  })
                }
              />
            );
          }}
        </Mutation>
      </SignupContainer>
    );
  }
}

export default WithCurrentUser(SignupPage);
