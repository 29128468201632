import React from 'react';
import { ResetPasswordForm } from '../users/ResetPasswordForm';
import {
  ResetPasswordMutation,
  ResetPasswordProps,
} from '../users/ResetPasswordMutation';

interface Props extends ResetPasswordProps {}

export function TlxNewUserForm(props: Props) {
  return (
    <section>
      <ResetPasswordForm
        saving={props.loading}
        called={props.called}
        error={props.error}
        onSubmit={props.onSubmit}
        onLogin={props.onLogin}
      />
    </section>
  );
}

TlxNewUserForm.Connected = ResetPasswordMutation(TlxNewUserForm);
