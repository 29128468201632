import React from 'react';
import { Link } from 'react-router-dom';
import { css } from 'emotion';
import styled from 'react-emotion';

import { SecretDietLogo } from '../../components';
import { LimitedViewport } from '../layouts/LimitedViewport';
import { Instagram, Facebook } from '../../components/icons';
import { inherits } from 'util';

const FooterContainer = styled('footer')({
  width: '100vw',
  backgroundColor: 'rgb(35,35,35)',
  color: '#eee',
});

const CopyrightContainer = styled('section')({
  paddingRight: '2em',
  paddingBottom: '2em',
  fontSize: 14,
  wordBreak: 'keep-all',
  lineHeight: 2,
  '@media (max-width: 1080px)': {
    fontSize: 12,
  },
});

const IconButtonStyle = css({
  transition: 'all .3s',
  fill: 'rgb(255,255,255)',
  cursor: 'pointer',
  ':hover': {
    transform: 'translateY(-1px)',
    fill: 'rgba(255,255,255,0.5)',
  },
  ':active': {
    transform: 'translateY(1px)',
  },
});

const FooterLink = styled(Link)({
  textDecoration: 'none',
  display: 'block',
  color: 'inherit',
  ':hover': {
    textDecoration: 'none',
  },
  ':active': {
    color: 'inherit',
    textDecoration: 'none',
  },
});

class AppFooter extends React.PureComponent<any> {
  handleFacebook = () => {
    if (typeof window === 'object') {
      window.open('https://www.facebook.com/SecretDiet.official');
    }
  };

  handleInstagram = () => {
    if (typeof window === 'object') {
      window.open('https://www.instagram.com/secretdiet.official/');
    }
  };

  render() {
    return (
      <FooterContainer>
        <LimitedViewport>
          <div
            className={css({
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              padding: '1em',
              '@media (max-width: 1080px)': {
                flexDirection: 'column',
              },
            })}
          >
            <section
              className={css({ paddingRight: '1em', paddingBottom: '1em' })}
            >
              <SecretDietLogo plain />
            </section>
            <CopyrightContainer>
              <div>주)에이팀바디사이언스 대표이사: 김지훈</div>
              <div>사업자 등록번호: 106-86-75365 </div>
              <div>통신판매업 신고: 제2018-서울송파-0267호</div>
              <div>
                서울특별시 송파구 잠실동 올림픽로 212, 갤러리아팰리스 A동 406호
              </div>
              <div>개인정보책임자: 김지훈</div>
              <div>©A-TEAM Worldwide ALL RIGHTS RESERVED.</div>
            </CopyrightContainer>
            <CopyrightContainer>
              <div style={{ fontWeight: 'bold' }}>ABOUT US</div>
              <a
                href="http://pf.kakao.com/_gxlNkd"
                className={css({
                  textDecoration: 'none',
                  color: 'inherits',
                  ':active': {
                    textDecoration: 'none',
                  },
                })}
              >
                고객센터
              </a>
              <FooterLink to="/faq">자주 하는 질문</FooterLink>
              <div>마케팅/제휴문의</div>
              <div>010-9147-3077</div>
              <div>zium303@naver.com</div>
            </CopyrightContainer>
            <CopyrightContainer>
              <div style={{ fontWeight: 'bold' }}>POLICY</div>
              <FooterLink to="/tos">개인정보 처리방법</FooterLink>
              <FooterLink to="/tos">이용약관</FooterLink>
            </CopyrightContainer>
            <CopyrightContainer>
              <div style={{ fontWeight: 'bold' }}>FOLLOW US</div>
              <div style={{ display: 'flex' }}>
                <div>
                  <Facebook
                    className={IconButtonStyle}
                    onClick={this.handleFacebook}
                    style={{ width: '2em', height: '2em' }}
                  />
                </div>
                <div>
                  <Instagram
                    className={IconButtonStyle}
                    onClick={this.handleInstagram}
                    style={{ width: '2em', height: '2em' }}
                  />
                </div>
              </div>
            </CopyrightContainer>
          </div>
        </LimitedViewport>
      </FooterContainer>
    );
  }
}

export default AppFooter;
