import gql from 'graphql-tag';
import React from 'react';
import { Query } from 'react-apollo';
import { ProductGroup } from '../../gql-types';

export const GET_PRODUCT_GROUP = gql`
  query GetProductGroup($id: String, $slug: String) {
    item: productGroup(id: $id, slug: $slug) {
      id
      name
      productType
      description
      imageUrl
      videoUrl
      price
      currencyCode
      details
      products {
        id
        name
        productType
        currencyCode
        description
        imageUrl
        videoUrl
        price
        level
        durationInMonth
        options
        details
      }
    }
  }
`;

interface ExternalProps {
  id?: string;
  slug?: string;
}

export interface ProductGroupQueryProps {
  loading?: boolean;
  item: ProductGroup;
}

export function ProductGroupQuery<T extends ProductGroupQueryProps>(
  Component: React.ComponentType<T>,
) {
  function ProductGroupQueryComponent(
    props: ExternalProps &
      Pick<T, Exclude<keyof T, keyof ProductGroupQueryProps>>,
  ) {
    const { id, slug, ...rest } = props;
    return (
      <Query query={GET_PRODUCT_GROUP} variables={{ id, slug }}>
        {result => {
          const childProps = {
            ...rest,
            loading: result.loading,
            item: result.data && result.data.item,
          };
          return <Component {...(childProps as any)} />;
        }}
      </Query>
    );
  }

  return ProductGroupQueryComponent;
}
