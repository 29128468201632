import { css } from 'emotion';

export const TableStyle = css({
  width: '100%',
  minWidth: 320,
  borderCollapse: 'collapse',
  '& thead th': {
    borderBottom: '2px solid #dee2e6',
  },
  '& td, & th': {
    borderTop: '2px solid #dee2e6',
  },
  '& tr > th, tr > td': {
    padding: '0.5em 1em',
  },
  '& tr > th': {
    fontWeight: 400,
    backgroundColor: 'grey',
    textAlign: 'left',
    color: 'white',
  },
  '& tr': {
    padding: '1em',
  },
});
