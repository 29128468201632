import React, { SVGAttributes } from 'react';

export const CreditCard = (props: SVGAttributes<SVGElement>) => (
  <svg viewBox="0 0 72 72" {...props}>
    <path fill="none" d="M-1-1h74v74H-1z" />
    <g>
      <g fillRule="nonzero">
        <path d="M63.984 61.271h-57c-4.134 0-7.5-3.366-7.5-7.5v-33c0-4.134 3.366-7.5 7.5-7.5h57c4.134 0 7.5 3.366 7.5 7.5v33c0 4.134-3.366 7.5-7.5 7.5zm-57-45a4.505 4.505 0 0 0-4.5 4.5v33c0 2.481 2.02 4.5 4.5 4.5h57c2.481 0 4.5-2.019 4.5-4.5v-33c0-2.48-2.019-4.5-4.5-4.5h-57z" />
        <path d="M69.984 34.271h-69a1.5 1.5 0 0 1-1.5-1.5v-9a1.5 1.5 0 0 1 1.5-1.5h69a1.5 1.5 0 0 1 1.5 1.5v9a1.5 1.5 0 0 1-1.5 1.5zm-67.5-3h66v-6h-66v6zM21.984 46.271h-12a1.5 1.5 0 0 1 0-3h12a1.5 1.5 0 0 1 0 3zM30.984 52.271h-21a1.5 1.5 0 0 1 0-3h21a1.5 1.5 0 0 1 0 3z" />
      </g>
    </g>
  </svg>
);
