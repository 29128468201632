import { useState, useEffect, useCallback } from 'react';
import { ApolloClient } from 'apollo-client';
import { ShoppingCartDto } from './ShoppingCartDto';
import { LocalCart } from './LocalCart';
import { removeCartItem } from './RemoveCartItem';
import { addCartItem } from './AddCartItem';
import { updateCartItem } from './UpdateCartItem';
import { GET_SHOPPING_CART } from './CartQuries';
import { ShoppingCartItem, ShoppingCart } from '../../gql-types';
import debounce from 'lodash/debounce';
import { Subject } from 'rxjs';

const sharedCart = new LocalCart();
const cartWatcher = new Subject<ShoppingCartItem[]>();

export function useCartUpdate() {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (!success) {
      return;
    }

    const interval = setTimeout(() => setSuccess(false), 3000);
    return () => clearTimeout(interval);
  }, [success]);

  useEffect(() => {
    if (!error) {
      return;
    }

    const interval = setTimeout(() => setError(false), 3000);
    return () => clearTimeout(interval);
  }, [error]);

  return {
    success,
    error,
    setSuccess,
    setError,
  };
}

export function useAddHook(
  isLoggedIn: boolean,
  client: ApolloClient<any>,
  reset?: any,
) {
  const { success, error, setError, setSuccess } = useCartUpdate();
  const [loading, setLoading] = useState(false);
  const update = addCartItem(client);
  const onRequest = useCallback(
    async (item: ShoppingCartDto) => {
      if (!isLoggedIn) {
        sharedCart.add(item);
        setSuccess(true);
        if (reset) {
          reset(sharedCart.get());
        }
        return;
      }
      setLoading(false);
      try {
        await update(item.productId, item.quantity, item.options as any);
        setSuccess(true);
        setError(false);
      } catch (err) {
        setError(true);
        setSuccess(false);
      } finally {
        setLoading(false);
      }
    },
    [isLoggedIn, client],
  );

  return {
    loading,
    success,
    error,
    onRequest,
  };
}

export function useRemoveHook(
  isLoggedIn: boolean,
  client: ApolloClient<any>,
  reset?: any,
) {
  const { success, error, setError, setSuccess } = useCartUpdate();
  const [loaading, setLoading] = useState(false);
  const update = removeCartItem(client);
  const onRequest = useCallback(
    async (item: { productId: string; options?: string | null }) => {
      try {
        if (!isLoggedIn) {
          sharedCart.remove(item);
          setSuccess(true);
          if (reset) {
            reset(sharedCart.get());
          }
          return;
        }

        setLoading(true);
        await update(item.productId, item.options);
        if (reset) {
          reset();
        }
        setSuccess(true);
        setError(false);
      } catch (err) {
        setError(true);
        setSuccess(false);
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return {
    loaading,
    success,
    error,
    onRequest,
  };
}

export function useUpdateHook(
  isLoggedIn: boolean,
  client: ApolloClient<any>,
  reset?: any,
) {
  const { success, error, setError, setSuccess } = useCartUpdate();
  const updateItem = updateCartItem(client);
  const [loading, setLoading] = useState(false);
  const onRequest = useCallback(async (item: ShoppingCartDto) => {
    try {
      if (!isLoggedIn) {
        sharedCart.update(item);
        setSuccess(true);
        if (reset) {
          reset(sharedCart.get());
        }
        return;
      }
      setLoading(true);
      await updateItem(item.productId, item.quantity, item.options);
      setError(false);
      setSuccess(true);
    } catch (ignore) {
      setError(true);
      setSuccess(false);
    } finally {
      if (reset) {
        reset();
      }
      setLoading(false);
    }
  }, []);

  return {
    loading,
    success,
    error,
    onRequest,
  };
}

export function useCartItemHook(
  isLoggedIn: boolean,
  client: ApolloClient<any>,
) {
  // const { success, error, setError, setSuccess } = useCartUpdate();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([] as ShoppingCartItem[]);
  const onRequest = useCallback(async (localItems?: ShoppingCartDto[]) => {
    try {
      const startLoading = debounce(() => setLoading(true), 200);
      startLoading();
      const result = await client.query({
        query: GET_SHOPPING_CART,
        fetchPolicy: 'network-only',
        variables: {
          items: localItems,
        },
      });
      startLoading.cancel();

      const cart: ShoppingCart | null =
        result && result.data && result.data.cart;
      const cartItems = ((cart && cart.items) || []).filter(
        (item): item is ShoppingCartItem => Boolean(item),
      );
      cartWatcher.next(cartItems);
      if (isLoggedIn) {
        sharedCart.clear();
      }
    } catch (ignore) {
      console.error(ignore);
    } finally {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    onRequest(sharedCart.get());
    const sub = cartWatcher.subscribe({
      next: cartItems => {
        setItems(cartItems);
      },
    });
    return () => {
      sub.unsubscribe();
    };
  }, [client]);

  return {
    loading,
    data: items,
    setItems,
    onRequest,
    // onRequest: (items?: ShoppingCartDto[]) => cartWatcher.next(items),
  };
}

export function useCartHook(isLoggedIn: boolean, client: ApolloClient<any>) {
  const items = useCartItemHook(isLoggedIn, client);
  return {
    items,
    add: useAddHook(isLoggedIn, client, items.onRequest),
    remove: useRemoveHook(isLoggedIn, client, items.onRequest),
    update: useUpdateHook(isLoggedIn, client, items.onRequest),
    resetCart: () => sharedCart.clear(),
  };
}
