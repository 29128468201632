import React from 'react';
import { css } from 'emotion';
import { useCartHook } from './CartHooks';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { WithCurrentUser, ICurrentUserProps } from '../users/CurrentUserQuery';

export function CartIcon(props: ICurrentUserProps) {
  const cartHook = useCartHook(props.isLoggedIn, props.client);
  const size = (cartHook.items.data || []).length;
  const hasItem = size > 0;
  return (
    <div className={css({ position: 'relative' })}>
      <Icon icon={IconNames.SHOPPING_CART} />
      {hasItem && (
        <div
          className={css({
            position: 'absolute',
            top: '-50%',
            left: '100%',
          })}
        >
          <div
            className={css({
              display: 'flex',
              alignItems: 'center',
              background: '#ff0000',
              borderRadius: '50%',
              color: '#fff',
              fontSize: 12,
              paddingRight: 5,
              paddingLeft: 5,
              paddingTop: 2,
              paddingBottom: 2,
            })}
          >
            {size}
          </div>
        </div>
      )}
    </div>
  );
}

export default WithCurrentUser(CartIcon);
