import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Address, AddressInput } from '../../gql-types';
import { Button, Intent } from '@blueprintjs/core';

interface Props {
  address: AddressInput;
  onCompleted(data: { address: Partial<Address> }): void;
}

const UPDATE_ADDRESS_MUTATION = gql`
  mutation UpdateAddress($address: AddressInput!) {
    address: updateAddress(address: $address) {
      id
      address1
      address2
      city
      phone
      mobile
      postalCode
      state
      country
    }
  }
`;

export class UpdateAddressButton extends React.Component<Props> {
  render() {
    const { address } = this.props;
    return (
      <Mutation
        mutation={UPDATE_ADDRESS_MUTATION}
        onCompleted={this.props.onCompleted}
        refetchQueries={['GetCurrentLoginStatus', 'currentUser']}
        variables={{
          address,
        }}
      >
        {update => {
          return (
            <Button
              id="updateAddress"
              intent={Intent.SUCCESS}
              onClick={() => update()}
            >
              저장하기
            </Button>
          );
        }}
      </Mutation>
    );
  }
}
