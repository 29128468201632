import React from 'react';
import styled from 'react-emotion';
import { KakaoButton, CardStyles, KakaotalkLogo } from '../../components';

const SocialStatusContainer = styled('div')(
  {
    backgroundColor: 'rgb(255,232,7)',
    padding: '0.2em',
    fontSize: '1em',
    display: 'flex',
    alignItems: 'center',
    color: '#000',
    width: 200,
  },
  CardStyles.container,
);

function KakaoStatus(props: { large?: boolean; connected: boolean }) {
  const { connected } = props;
  if (!connected) {
    return <KakaoButton large={props.large} fill message="connect" />;
  }

  return (
    <SocialStatusContainer>
      <KakaotalkLogo
        width="1.2em"
        height="1.2em"
        style={{ marginRight: '0.5em' }}
      />
      <span>상태: 연동완료</span>
    </SocialStatusContainer>
  );
}

export default KakaoStatus;
