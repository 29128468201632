import React from 'react';
import Helmet from 'react-helmet';
import styled from 'react-emotion';
import { RouteComponentProps } from 'react-router';
import SplashCarousel from '../home/SplashCarousel';
import { ProgramsMarketing } from '../home/ProgramsMarketing';
import { MemberTestimonials } from '../home/MemberTestimonials';
import { NewYearPromise } from '../home/NewYearPromise';
import { KimjihoonIntro } from '../home/KimjihoonIntro';
import { JoinUsSection } from '../home/JoinUsSection';
import { WithCurrentUser, ICurrentUserProps } from '../users/CurrentUserQuery';
import { LoginRedirect } from '../../browser/models/LoginRedirectModel';
import { EventBanner } from '../home/EventBanner';
import Main from '../home/Main';

// import UnderConstruction from '../home/UnderConstruction'

const HomePageContainer = styled('div')({
  position: 'relative',
  width: '100%',
  minWidth: 360,
  backgroundColor: '#eee',
});

interface Props extends RouteComponentProps<any>, ICurrentUserProps {}

class HomePage extends React.Component<Props> {
  componentDidMount() {
    const model = LoginRedirect.forBrowser();
    if (model) {
      if (!this.props.loading && this.props.isLoggedIn) {
        const redirect = model.getAndClear();
        if (redirect) {
          this.props.history.push(redirect);
        }
      }
    }
  }

  private handleProgramList = () => {
    this.props.history.push('/program');
    if (typeof window === 'object') {
      window.scrollTo(0, 0);
    }
  };

  private handleProgramSelect = (course: string) => {
    this.props.history.push(`/courses/${course}/description`);
    if (typeof window === 'object') {
      window.scrollTo(0, 0);
    }
  };

  private handleAppStore = () => {
    if (typeof window === 'object') {
      window.open(
        'https://itunes.apple.com/kr/app/%EC%8B%9C%ED%81%AC%EB%A6%BF-%EB%8B%A4%EC%9D%B4%EC%96%B4%ED%8A%B8/id1447929450?mt=8',
      );
    }
  };

  private handlePlayStore = () => {
    if (typeof window === 'object') {
      window.open(
        'https://play.google.com/store/apps/details?id=com.secretdiet.android',
      );
    }
  };

  private handleRegister = () => {
    this.props.history.push('/signup');
  };

  private handleHambakEvent = () => {
    const { history } = this.props;
    if (gtag && typeof gtag === 'function') {
      gtag('event', 'hambak-event');
    }

    history.push('/hambak-event');
  };

  render() {
    return (
      <HomePageContainer>
        <Helmet>
          <title>시크릿다이어트 - 홈트레이닝 온라인 PT.</title>
          <meta
            name="description"
            content="시크릿다이어트 - 스타 트레이너 김지훈의 연예인 바디 노하우. 이제 온라인으로 PT받으세요"
          />
        </Helmet>
        <SplashCarousel onRegister={this.handleRegister} />
        <Main history={this.props.history} />
        {/* <UnderConstruction/> */}
        
      </HomePageContainer>
    );
  }
}

export default WithCurrentUser(HomePage);
