import { FormGroup, InputGroup } from '@blueprintjs/core';
import React from 'react';
import { AddressInput, JusoAddress } from '../../gql-types';
import { AddressOverlay } from './AddressOverlay';
import { CountrySelect } from './CountrySelect';

interface Props {
  address: AddressInput;
  onChange(address: AddressInput): void;
}

export class AddressForm extends React.Component<Props> {
  private handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const address = {
      ...this.props.address,
      [name]: value,
    };
    this.props.onChange(address);
  };

  private handleCountryChange = (countryId: string) => {
    const address = {
      ...this.props.address,
      country: countryId,
    };
    this.props.onChange(address);
  };

  private handleJusoSearch = (address: JusoAddress) => {
    const { roadAddrPart1: address1, zipNo: postalCode } = address;
    const newAddress = {
      ...this.props.address,
      address1: address1 || '',
      postalCode: postalCode || '',
      city: address.siNm,
    };
    this.props.onChange(newAddress);
  };

  render() {
    const { address } = this.props;
    return (
      <>
        <FormGroup label="국가">
          <CountrySelect
            value={address.country}
            onSelect={this.handleCountryChange}
          />
        </FormGroup>
        {address.country === 'KOR' && (
          <AddressOverlay onSelect={this.handleJusoSearch} />
        )}
        <FormGroup label="주소1">
          <InputGroup
            id="address1"
            name="address1"
            placeholder="주소1"
            value={address.address1}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup label="주소2">
          <InputGroup
            id="address2"
            name="address2"
            placeholder="주소2"
            value={address.address2 || ''}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup label="도시">
          <InputGroup
            id="city"
            name="city"
            placeholder="도시"
            value={address.city}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup label="핸드폰번호">
          <InputGroup
            id="mobile"
            name="mobile"
            type="tel"
            placeholder="핸드폰번호"
            value={address.mobile}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup label="전화번호">
          <InputGroup
            id="phone"
            name="phone"
            type="tel"
            placeholder="전화번호"
            value={address.phone || ''}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup label="우편번호">
          <InputGroup
            id="postalCode"
            name="postalCode"
            placeholder="우편번호"
            value={address.postalCode}
            onChange={this.handleChange}
          />
        </FormGroup>
      </>
    );
  }
}
