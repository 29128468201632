import React from 'react';
import { History } from 'history';
import { Card } from '../../components';

interface Props {
  history: History;
}

export class Body8WeeksDescription extends React.Component<Props> {
  render() {
    return (
      <div style={{ minWidth: 320 }}>
        <Card>
          <h1>8주 바디 UP</h1>
          <p>
            바쁜 직장생활, 야근과 회식으로 피트니스 센터에 가기 힘들고 PT를 받기
            어려운 직장인을 위한 프로그램입니다.
          </p>
          <p>
            소도구를 이용한 근력운동과 전신 유산소성 근력운동을 주차별 적용하여
            떨어지는 체력, 점점 뭉쳐오는 근육을 케어해 건강하고 아름다운 몸을
            만듭니다.
          </p>
          <div
            style={{
              width: '100%',
              position: 'relative',
              textAlign: 'center',
            }}
          >
            <img
              style={{ width: '70%', marginBottom: '2em' }}
              src="https://storage.googleapis.com/secretdiet-public-images/course-windows/program-bodyup-3.jpeg"
            />
          </div>
          <div
            style={{
              marginBottom: '1em',
            }}
          >
            <div>바쁜 직장생활 야근과 회식으로 피트니스 센터에 가기 힘들고</div>
            <div>PT를 받기 어려운 직장인을 위한 프로그램입니다.</div>
          </div>
          <div
            style={{
              marginBottom: '1em',
            }}
          >
            <div>
              소도구를 이용한 근력운동과 전신 유산소성 근력운동을 주차별
              적용하여
            </div>
            <div>떨어지는 체력, 점점 뭉쳐오는 근육을 케어해</div>
            <div>건강하고 아름다운 몸을 만듭니다.</div>
          </div>

          <img
            style={{ width: '100%' }}
            src="https://storage.googleapis.com/secretdiet-public-images/body-8weeks-curriculum.jpg"
          />
        </Card>
      </div>
    );
  }
}
