import React from 'react';
import styled from 'react-emotion';
import { TextAlignProperty } from 'csstype';

export const PageArticle = styled('article')({
  position: 'relative',
  justifyContent: 'center',
  width: '100%',
  minWidth: 320,
  padding: '2em 1em',
  backgroundColor: '#eee',
});

export const TitleSection = styled('section')(
  {
    width: '100%',
  },
  (props: Pick<PageLayoutProps, 'textAlign'>) => ({
    textAlign: props.textAlign,
  }),
);

export interface PageLayoutProps {
  style?: any;
  title?: string | React.ReactNode;
  className?: string;
  textAlign?: TextAlignProperty;
  description?: string;
  children: any;
}

export function PageLayout(props: PageLayoutProps) {
  return (
    <PageArticle style={props.style} className={props.className}>
      <TitleSection textAlign={props.textAlign}>
        {props.title &&
          typeof props.title === 'string' && <h1>{props.title}</h1>}
        {props.title && typeof props.title !== 'string' && props.title}
        {props.description && <div>{props.description}</div>}
      </TitleSection>
      {props.children}
    </PageArticle>
  );
}
