import React from 'react';
import { FAQItemView } from './FAQItemView';

interface RowItem {
  id: string;
  title: string;
  content: string;
  category: string;
}

interface Props {
  category: string;
  items: RowItem[];
}

export class FAQTable extends React.Component<Props> {
  render() {
    const { items, category } = this.props;
    const rows =
      category === 'all'
        ? items
        : items.filter(item => item.category === category);
    return (
      <div>
        {rows.length === 0 && <div>다시 입력해 주세요</div>}

        {rows.map(row => {
          return (
            <div key={row.id} style={{ marginBottom: '1em' }}>
              <FAQItemView item={row} />
            </div>
          );
        })}
      </div>
    );
  }
}
