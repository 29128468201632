import { Spinner } from '@blueprintjs/core';
import React from 'react';
import { ApolloConsumer } from 'react-apollo';
import { User } from '../../gql-types';
import { CheckoutView } from '../cart/CheckoutView';
import { CheckoutItem } from '../cart/PaymentService';
import {
  ProductGroupListQuery,
  ProductGroupListQueryProps,
} from '../products/ProductGroupListQuery';

interface Props extends ProductGroupListQueryProps {
  user: User;
  onFail(err: any): void;
  onSuccess(): void;
  onCancel?(): void;
}

export function HambakCheckoutView(props: Props) {
  if (props.loading) {
    return <Spinner />;
  }

  const items = props.items;
  const checkoutItems: CheckoutItem[] = items
    .map(item => {
      if (!item.products) {
        return null;
      }

      const product = item.products[0];
      if (!product) {
        return null;
      }

      return {
        product,
        quantity: 1,
      };
    })
    .filter((item): item is CheckoutItem => Boolean(item));

  return (
    <ApolloConsumer>
      {client => {
        return (
          <CheckoutView
            user={props.user}
            quantityLocked={true}
            deleteDisabled={true}
            invoiceName="코로나 이벤트"
            items={checkoutItems}
            client={client}
            onSuccess={props.onSuccess}
            onFail={props.onFail}
            onCancel={props.onCancel}
          />
        );
      }}
    </ApolloConsumer>
  );
}

HambakCheckoutView.Connected = ProductGroupListQuery(HambakCheckoutView);
