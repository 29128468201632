import React from 'react';
import { History } from 'history';
import { Card } from '../../components';

interface Props {
  history: History;
}

export function BikiniDescription(props: Props) {
  return (
    <div style={{ minWidth: 320 }}>
      <Card>
        <img
          style={{
            width: 200,
            height: 120,
          }}
          src="https://s3.ap-northeast-2.amazonaws.com/secret-diet-assets/bikini-logo.jpg"
        />
        <p
          style={{
            marginTop: '1em',
          }}
        >
          아른다운 비키니 라인을 완성하기 위해서는 골반과 힙 라인이 아주
          중요합니다. 또한 탄탄한 복부와 가슴라인 역시 비티니를 입었을 때 시선이
          많이 가는 부위이죠.
        </p>
        <p>
          머스트 비키니는 단기 다이어트를 원하시는 분들을 위한 난이도 상급의
          PT수업입니다. 골반과 힙 가슴과 복부운동 그리고 유산소운동까지 조합된
          고강도 트레이닝으로써 힘들지만 큰 효과를 불 수 있는 프로그램입니다.
        </p>
        <img
          style={{ width: '100%' }}
          src="https://s3.ap-northeast-2.amazonaws.com/secret-diet-assets/bikini-curriculum.jpeg"
        />
      </Card>
    </div>
  );
}
