import React from 'react';
import { css } from 'emotion';
import { Button, Intent, NonIdealState } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { RouteComponentProps } from 'react-router';

interface Props extends RouteComponentProps<any> {}

export function PaymentFailurePage(props: Props) {
  return (
    <div
      className={css({
        width: '100%',
        maxWidth: 1024,
        padding: '1em',
      })}
    >
      <NonIdealState title="결제 실패" icon={IconNames.ISSUE}>
        <div>결제가 실패하였습니다</div>
        <Button
          intent={Intent.PRIMARY}
          onClick={() => props.history.push('/my-fitness')}
        >
          MY FITNESS
        </Button>
      </NonIdealState>
    </div>
  );
}
