import React from 'react';
import { ApolloError } from 'apollo-client';

interface Props {
  error: ApolloError;
}

export function QueryErrorPage(props: Props) {
  return (
    <div>
      무언가가 잘못되었어요
      {props.error.message}
    </div>
  );
}
