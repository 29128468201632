import React from 'react';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import ContentLayout from './ContentLayout';

export class MainLayout extends React.PureComponent<any> {
  state = {
    stickySupported: true,
  };

  componentDidMount() {
    if (typeof CSS !== 'undefined') {
      const result = CSS.supports('position: sticky');
      if (!result) {
        this.setState({ stickySupported: false });
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <AppHeader />
        <ContentLayout offset={this.state.stickySupported ? undefined : 104}>
          {this.props.children}
        </ContentLayout>
        <AppFooter />
      </React.Fragment>
    );
  }
}

export default MainLayout;
