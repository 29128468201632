import { css } from 'emotion';
import React from 'react';
import { BoldStyle, PinkText, SectionContainer } from './AboutStyles';
import { SectionImage } from './SectionImage';

export function TotalSolutionMarketing(props: any) {
  return (
    <SectionContainer>
      <SectionImage src="https://storage.googleapis.com/secretdiet-public-images/about/aboutus-total-solution.jpg" />
      <section
        style={{
          paddingTop: '2em',
          paddingLeft: '2em',
          paddingRight: '2em',
          paddingBottom: '1em',
          fontSize: '0.8em',
        }}
      >
        <img
          style={{
            width: '100%',
          }}
          src="https://storage.googleapis.com/secretdiet-public-images/about/total-solution-gym-03.jpg"
        />
        <h2>
          <span className={PinkText}>SINCE 2009</span>, 시크릿다이어트의 탄생
        </h2>
        <ul
          className={css({
            listStyle: 'none',
            paddingLeft: 0,
            lineHeight: '1.2em',
            wordBreak: 'keep-all',
            '& li > p': {
              marginBottom: '2em',
            },
          })}
        >
          <li>
            <p>
              피트니스센터의 1:1 퍼스널트레이닝이 유행하던 2009년, 에이팀의
              트레이닝도 시작되었습니다.
            </p>
            <p>
              수많은 스타들의 몸매를 만들어내고 관리해주며 강남에서 유명한
              휘트니스 센터로 자리잡고, 소문을 듣고 온 수많은 회원님들도
              만났습니다.
            </p>
          </li>
          <li>
            <p>그러던 어느 날, 비보를 접하게 되었습니다.</p>
            <p>
              한 트레이너의 어머님 건강이 악화되어 일을 그만두고 재활을
              도와드려야 할 것 같다는 소식이었습니다
            </p>
          </li>
          <li>
            <p>
              승승장구하며 앞만 보고 달리던 에이팀 트레이너들에게 깨달음을 얻게
              해준 계기가 되었습니다.
            </p>
          </li>
          <li>
            <p className={BoldStyle}>
              “사람들의 건강관리가 내 직업인데, 정작 가장 소중한 내 가족들의
              건강은 챙기지 못했구나”
            </p>
          </li>
          <li>
            <p>그래서 시크릿다이어트를 시작했습니다.</p>
            <p>
              멀리 있는 부모님, 스케줄이 맞지 않는 내 동생, 운동 알러지가 있는
              누나, 만년 다이어터 삼촌까지…
            </p>
          </li>
          <li>
            <p>
              피트니스 센터에 오지 않아도 체력부터 건강, 그리고 몸매까지 챙겨줄
              수 있도록 영상으로 만들어 보여주고, 매주 식단관리와 체지방 분석을
              통해
            </p>
            <p>오프라인 PT 보다 더 꼼꼼히 관리 해 줄 수 있었습니다. </p>
          </li>
        </ul>
      </section>
      <img
        style={{ width: '100%' }}
        src="https://storage.googleapis.com/secretdiet-public-images/about/about-us-training-20180819.jpg"
      />
      <section
        className={css({
          paddingTop: '2em',
          paddingLeft: '2em',
          paddingRight: '2em',
          paddingBottom: '1em',
          wordBreak: 'keep-all',
          '& > div': {
            fontSize: '0.8em',
            lineHeight: '2em',
            marginBottom: '2em',
          },
          '& > div > p': {
            marginBottom: 0,
          },
        })}
      >
        <h2 className={css({ textAlign: 'center' })}>
          <span className={PinkText}>8년</span>
          간의 준비
        </h2>

        <div>
          <p>
            국내 트랜드에 맞춰 1:1퍼스널트레이닝 센터에 중점을 두며 많은
            고객들을 오프라인에서 만났습니다.
          </p>
          <p className={BoldStyle}>
            ‘김지훈 트레이너와 에이팀 스타트레이닝 그룹에게 PT를 받으면 확실히
            다르다. 단기간에 몸매가 예술이 된다‘
          </p>
          <p>
            이러한 소문에 부끄럽지 않을 만큼, 우리는 고객님들의 건강한 삶과
            성공적인 다이어트를 이끄는 일에 열정적이었습니다.
          </p>
        </div>

        <div>
          <p>
            그러나{' '}
            <span className={BoldStyle}>한정된 장소, 제약된 시간의 한계</span>로
            인해 더 많은 고객들을 만나지 못하는 것이 늘 숙제로 남아있었습니다.
          </p>
          <p>
            그래서 우리는 마음속 깊이 새긴 우리의 꿈을 언제나 준비하고
            있었습니다.
          </p>
          <p>
            더 많은 고객들이 원하는 곳에서 언제든지 에이팀을 만날 수 있는{' '}
            <span className={BoldStyle}>온라인 홈트레이닝 시스템.</span> 이를
            위해 김지훈 트레이너는
          </p>
          <p>
            지금까지 1:1 로 PT 해 온 많은 톱스타들의 몸매 관리 비결을 집약하고,
          </p>
          <p>
            10대에서 60대까지,{' '}
            <span className={BoldStyle}>다양한 여성들의 케이스 연구</span>를
            해왔습니다.
          </p>
        </div>

        <div>
          <p>
            또한 ‘다이어트워’ 를 비롯한 50여 편의 방송, 국내 및 세계 머슬마니아
            대회 심사위원으로 활동하며,
          </p>
          <p>
            언제나{' '}
            <span className={BoldStyle}>
              여성들의 다이어트와 건강한 삶을 만들기 위해 노력
            </span>
            해 왔습니다.
          </p>
          <p>
            결국, 우리는 이렇게 뜻을 모은 많은 전문가들과 함께, 체계적이고
            혁신적인 온라인
          </p>
          <p>
            <span className={BoldStyle}>
              홈트레이닝 ’시크릿다이어트’를 새롭게 완성
            </span>
            하게 됩니다.
          </p>
        </div>
      </section>
      <SectionImage
        src="https://storage.googleapis.com/secretdiet-public-images/about/lectuering-sd-20180820.jpeg"
        className={css({
          color: 'rgba(255,255,255,0.8)',
          paddingLeft: '2em',
          paddingRight: '2em',
          paddingTop: '7em',
          paddingBottom: '3em',
          wordBreak: 'keep-all',
          fontSize: '0.8em',
          '& > div': {
            marginBottom: '2em',
            lineHeight: '2em',
          },
          '& > div > p': {
            marginBottom: 0,
          },
        })}
      >
        <h2>
          <span className={PinkText}>2017년</span> 실패하지 않는 다이어트
          프로그램의 탄생
        </h2>
        <div>
          <p>오프라인 PT와 비교하여 온라인 PT가 생소하던 회원님들에게</p>
          <p>
            꼭 센터에 나오지 않아도 혼자서도 그리고 집에서도 실패하지 않는
            다이어트의 관리를 받을 수 있도록
          </p>
          <p>연구하고 수정하고 보완한지 딱 8년이 되었습니다.</p>
        </div>
        <div>
          <p>그리고 이제는 그 결과물과 노하우를 함께 나누고자 합니다.</p>
        </div>
        <div>
          <p>
            다이어트의 본질과 평생 유지되는 몸매의 비격은 결국 '운동'입니다.
          </p>
          <p>
            우리를 믿고 시작해 보세요. 시크릿다이어트는 반드시 당신의 아름다운
            삶을 선물하게 될 테니까요.
          </p>
        </div>
        <div
          className={css({
            '& > div': {
              fontSize: '1.5em',
              fontWeight: 'bold',
              textDecoration: 'underline',
            },
            '& > p': {
              marginTop: '1.5em',
              fontSize: '1.2em',
            },
          })}
        >
          <div>Take care of your Body</div>
          <div>It's the only place you have to live Jim Rohn</div>
          <p>
            당신의 몸을 늘 관리해라, 그 곳이 바로 당신이 평생을 살아야 할
            집이니까.
          </p>
        </div>
      </SectionImage>
    </SectionContainer>
  );
}
