export interface Theme {
  primaryColor: string;
  accentColor: string;
}

export const darkTextColors = {
  primary: 'rgba(0,0,0,0,1)',
  secondary: 'rgba(0,0,0,0.7)',
  hint: 'rgba(0,0,0,0.38)',
  divider: 'rgba(0,0,0,0.12)',
};

export const lightTextColors = {
  primary: 'rgba(255,255,255,0.87)',
  secondary: 'rgba(255,255,255,0.54)',
  hint: 'rgba(255,255,255,0.38)',
  divider: 'rgba(255,255,255,0.12)',
};

export const theme: Theme = {
  primaryColor: 'rgb(236, 216, 81)',
  accentColor: 'rgb(236, 216, 81)',
};
