import { ApolloCache } from 'apollo-cache';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { ServerError } from 'apollo-link-http-common';
import { createUploadLink } from 'apollo-upload-client';
import { LoginStatusModel } from './models/LoginStatusModel';

export function configureLink(cache: ApolloCache<any>) {
  const authLink = setContext((_, { headers }) => {
    const model = LoginStatusModel.forBrowser();
    const token = model.getAuthToken();
    if (!token) {
      return { headers };
    }

    const isExpired = model.isExpired(token);
    if (isExpired) {
      model.clear();
      return { headers };
    }

    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const errLink = onError(err => {
    const model = LoginStatusModel.forBrowser();
    if (err.networkError) {
      const serverError = err.networkError as ServerError;
      if (serverError.statusCode === 401) {
        // TODO:: notify application state
        // to display session invalidation window.
        model.clear();
      }
    }

    if (err.graphQLErrors) {
      const graphQLErrors = err.graphQLErrors;
      const hasAuthError = graphQLErrors
        .map(graphlQLError => graphlQLError.extensions)
        .map(ext => {
          if (!ext) {
            return false;
          }

          return ext.code === 'UNAUTHENTICATED';
        });

      if (hasAuthError) {
        model.clear();
      }
    }
  });

  const uploadLink = createUploadLink({
    uri: '/graphql',
    credentials: 'same-origin',
  });

  return ApolloLink.from([authLink, errLink, uploadLink]);
}
