export class Currency {
  constructor(readonly code: string, readonly fixed: number = 0) {}

  static USD = new Currency('USD', 2);
  static KRW = new Currency('KRW', 0);

  public of(amount: number): Money {
    return new Money(this, amount);
  }
}

const getExchangeKey = (c1: Currency, c2: Currency) => `${c1.code}:${c2.code}`;

export class ExchangeRate {
  constructor(
    readonly base: Currency,
    readonly counter: Currency,
    readonly rate: number,
  ) {}

  key() {
    return getExchangeKey(this.base, this.counter);
  }

  exchange(amount: number) {
    return new Money(this.counter, amount * this.rate);
  }

  revert() {
    return new ExchangeRate(this.counter, this.base, 1 / this.rate);
  }
}

export const ExchangeRateRegistry = new Map<string, ExchangeRate>();
ExchangeRateRegistry.set(
  'USD:KRW',
  new ExchangeRate(Currency.USD, Currency.KRW, 1100),
);
ExchangeRateRegistry.set(
  'KRW:USD',
  new ExchangeRate(Currency.KRW, Currency.USD, 1 / 1100),
);

export class Money {
  constructor(readonly currency: Currency, readonly amount: number) {}

  public value() {
    return Number(this.amount.toFixed(this.currency.fixed));
  }

  public exchange(currency: Currency) {
    if (currency.code === this.currency.code) {
      return this;
    }

    const rate = ExchangeRateRegistry.get(
      getExchangeKey(this.currency, currency),
    );

    if (!rate) {
      throw new Error('Exchange Rate not found');
    }

    return rate.exchange(this.amount);
  }
}
