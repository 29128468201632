import { NonIdealState, Spinner, Callout, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import gql from 'graphql-tag';
import { History } from 'history';
import React from 'react';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import { Card, MarkdownDiv, StarPoint } from '../../components';
import { Course } from '../../gql-types';
import { getNodes } from '../../secret-fns';
import { CourseLectureListView } from './CourseLectureListView';

const query = gql`
  query GetCourseById($slug: String!) {
    user: currentUser {
      id
    }
    subscriptions: currentSubscription {
      id
      level
      status
      startsAt
      expiresAt
    }
    course(slug: $slug) {
      id
      name
      slug
      imageUrl
      logoUrl
      description
      difficulty
      unlocked
      progress {
        completed
      }
      lectureConnection {
        totalCount
        edges {
          node {
            id
            name
            slug
            effectiveAreas
            description
            courseId
            number
            duration
            unlocked
            imageUrl
            thumbnailUrl
            videoUrl
            progress {
              lectureId
              currentTimeInSec
              completed
            }
          }
        }
      }
    }
  }
`;

interface Props {
  courseSlug: string;
  history: History;
}

export function CourseDetailView(props: Props) {
  const { courseSlug } = props;
  return (
    <Query query={query} variables={{ slug: courseSlug }}>
      {obj => {
        if (obj.loading) {
          return <Spinner />;
        }
        const course = obj.data.course as Course | null;
        if (!course) {
          return (
            <NonIdealState
              title="프로그램을 찾지 못하였습니다"
              icon={IconNames.VIDEO}
            >
              <Link to="/">홈페이지로 돌아가기</Link>
            </NonIdealState>
          );
        }

        const { lectureConnection } = course;
        const { totalCount } = lectureConnection;
        const nodes = getNodes(lectureConnection);
        const isStart = courseSlug === 'start';

        return (
          <section style={{ width: 600 }}>
            <Card
              style={{ marginBottom: '1em', wordBreak: 'keep-all' }}
              media={course.imageUrl}
            >
              <h2>{course.name}</h2>
              <div
                style={{
                  marginTop: '-0.5em',
                  marginBottom: '0.5em',
                }}
              >
                <StarPoint value={course.difficulty} max={6} color="#ed0c6e" />
              </div>
              <div
                style={{
                  marginBottom: '1em',
                }}
              >
                프로그램 수: {totalCount}
              </div>
              <MarkdownDiv content={course.description} />
            </Card>
            {!isStart && (
              <Callout
                style={{ marginBottom: '1em' }}
                intent={Intent.WARNING}
                title="이 프로그램은 상급자를 위한 프로그램입니다."
              >
                <div>
                  시크릿에디션 - 체력 UP / 뱃살 DOWN / 요요 X / 머스트 비키니 /
                  뷰티풀 레그 / 8주 바디업 / 웨딩 3주 전 프로그램은 운동
                  상급자를 위한 고난이도 프로그램입니다.
                </div>
                <div>
                  다이어트바이블 스타트 / 베이직 / 마스터 레벨을 클리어한 후
                  이용해주세요.
                </div>
              </Callout>
            )}
            <CourseLectureListView
              items={nodes}
              onSelect={lecture =>
                props.history.push(`/gym/lectures/${lecture.slug}`)
              }
            />
          </section>
        );
      }}
    </Query>
  );
}
