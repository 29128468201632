import React from 'react';
import {
  InputGroup,
  ControlGroup,
  Button,
  FormGroup,
  Intent,
} from '@blueprintjs/core';
import { ApolloConsumer } from 'react-apollo';
import { CouponApplyButton } from './CouponApplyButton';
import { Coupon } from '../../gql-types';
import { GraphQLError } from 'graphql';

interface Props {
  onCouponReceieved(copon: Coupon): void;
}

interface State {
  couponCode: string;
  err: string;
  received: boolean;
}

export class CouponApplyView extends React.Component<Props, State> {
  state = {
    couponCode: '',
    err: '',
    received: false,
  };

  private handleChagne = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    this.setState({ couponCode: value, received: false, err: '' });
  };

  private handleCouponReceived = (coupon: Coupon) => {
    this.setState({ received: true });
    this.props.onCouponReceieved(coupon);
  };

  private handleError = (err: GraphQLError) => {
    this.setState({ err: err.message });
  };

  render() {
    const hasError = this.state.err !== '';
    const hasCode = this.state.couponCode !== '';
    const helperText = hasError
      ? '사용할 수 없는 쿠폰 입니다'
      : hasCode && this.state.received
      ? '사용 가능한 쿠폰 입니다'
      : undefined;

    return (
      <ApolloConsumer>
        {client => {
          return (
            <FormGroup
              intent={hasError ? Intent.DANGER : undefined}
              helperText={helperText}
            >
              <ControlGroup>
                <InputGroup
                  type="text"
                  value={this.state.couponCode}
                  placeholder="쿠폰 입력"
                  onChange={this.handleChagne}
                />
                <CouponApplyButton
                  client={client}
                  code={this.state.couponCode}
                  onCouponReceieved={this.handleCouponReceived}
                  onError={this.handleError}
                />
              </ControlGroup>
            </FormGroup>
          );
        }}
      </ApolloConsumer>
    );
  }
}
