import { ApolloError } from 'apollo-client';
import gql from 'graphql-tag';
import React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPasswordMutation(
    $code: String!
    $password: String!
    $passwordConfirm: String!
  ) {
    resetPassword(
      code: $code
      password: $password
      passwordConfirm: $passwordConfirm
    )
  }
`;

export interface ResetPasswordInput {
  password: string;
  passwordConfirm: string;
}

export interface ResetPasswordProps {
  loading?: boolean;
  called?: boolean;
  completed?: boolean;
  error?: ApolloError;
  onSubmit(): void;
  onLogin?(): void;
}

export interface ResetPasswordExternalProps {
  code: string;
  onCompleted(): void;
  onCancel(): void;
  onError?(err: ApolloError): void;
}

type Props<T> = ResetPasswordExternalProps &
  Pick<T, Exclude<keyof T, keyof ResetPasswordProps>>;

export function ResetPasswordMutation<T extends ResetPasswordProps>(
  Component: React.ComponentType<T>,
) {
  function ResetPasswordComponent(props: Props<T>) {
    const { code, onCompleted, onError, ...rest } = props;
    return (
      <Mutation
        mutation={RESET_PASSWORD_MUTATION}
        onCompleted={onCompleted}
        onError={onError}
      >
        {(
          update: MutationFn<any, ResetPasswordInput & { code: string }>,
          result,
        ) => {
          return (
            <Component
              loading={result.loading}
              called={result.called}
              completed={result.called && !result.error}
              error={result.error}
              {...rest as any}
              onSubmit={(state: ResetPasswordInput) => {
                update({
                  variables: {
                    code,
                    password: state.password,
                    passwordConfirm: state.passwordConfirm,
                  },
                });
              }}
            />
          );
        }}
      </Mutation>
    );
  }

  return ResetPasswordComponent;
}
