import { Callout, Intent } from '@blueprintjs/core';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { Card } from '../../components';
import { Address, AddressInput, User } from '../../gql-types';
import { AddressForm } from './AddressForm';
import { UpdateAddressButton } from './UpdateAddressButton';

interface Props {
  user: User;
}

interface AddressState {
  address1: string;
  address2?: string | null;
  city: string;
  phone?: string | null;
  mobile: string;
  postalCode: string;
  state: string;
  country: string;
}

function isMissingAddress(user: User) {
  const state = getInitialState(user.address);
  return (
    state.address1 === '' ||
    state.address1 === '' ||
    state.city === '' ||
    state.mobile === '' ||
    state.postalCode === ''
  );
}

function getInitialState(address?: Address | null): AddressState {
  const partialAddress = address || ({} as Partial<Address>);
  return {
    address1: partialAddress.address1 || '',
    address2: partialAddress.address2 || '',
    city: partialAddress.city || '',
    phone: partialAddress.phone || '',
    mobile: partialAddress.mobile || '',
    postalCode: partialAddress.postalCode || '',
    state: partialAddress.state || '',
    country: partialAddress.country || '',
  };
}

function isChanged(user: User, address: AddressState) {
  const state = getInitialState(user.address);
  return !isEqual(state, address);
}

export class UserAddressView extends React.Component<Props, AddressState> {
  state = getInitialState(this.props.user.address);

  private handleChange = (address: AddressInput) => {
    this.setState(address);
  };

  private handleCompleted = (data: { address: Address }) => {
    const newState = getInitialState(data.address);
    this.setState(newState);
  };

  render() {
    const { user } = this.props;
    return (
      <Card
        style={{
          marginBottom: '1em',
        }}
      >
        <h3>주소 정보</h3>
        {isMissingAddress(user) && (
          <Callout intent={Intent.WARNING} title="주소를 입력해 주세요">
            시크릿 몰에서 제공드리는 혜택을 위해서 주소가 필요합니다.
          </Callout>
        )}
        <AddressForm address={this.state} onChange={this.handleChange} />
        {isChanged(user, this.state) && (
          <UpdateAddressButton
            address={this.state}
            onCompleted={this.handleCompleted}
          />
        )}
      </Card>
    );
  }
}
