import React from 'react';
import { css } from 'emotion';

import { StaticGenericSelect } from '../../components/selects/StaticGenericSelect';

interface Item {
  id: string;
  label: string;
}

interface Props {
  value?: string;
  label: string;
  items: Item[];
  onSelect(value: string): void;
}

export function ProductOptionSelectSection(props: Props) {
  return (
    <section
      className={css({
        marginTop: '1em',
        display: 'flex',
        alignItems: 'center',
      })}
    >
      <label
        className={css({
          marginRight: '1em',
        })}
      >
        {props.label}
      </label>
      <StaticGenericSelect
        value={props.value}
        items={props.items}
        onSelect={props.onSelect}
      />
    </section>
  );
}
