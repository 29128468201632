import React from 'react';
import { css } from 'emotion';
import { BoldStyle, PinkText, SectionContainer } from './AboutStyles';
import { SectionImage } from './SectionImage';

const CommonSidePadder = css({
  paddingLeft: '2em',
  paddingRight: '2em',
  wordBreak: 'keep-all',
});

const ListStyle = css({
  listStyle: 'none',
  paddingLeft: 0,
  lineHeight: '1.5em',
  '& > li': {
    marginBottom: '2em',
  },
  '& > li > p': {
    lineHeight: '2em',
    marginBottom: 0,
  },
});

export function AboutTrainers(props: any) {
  return (
    <SectionContainer>
      <SectionImage src="https://storage.googleapis.com/secretdiet-public-images/about/aboutus-trainers.jpg" />
      <section
        className={css(
          {
            paddingTop: '2em',
            paddingBottom: '1em',
            textAlign: 'center',
          },
          CommonSidePadder,
        )}
      >
        <h2>
          대표 트레이너 - <span className={PinkText}>김지훈 트레이너</span>
        </h2>
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <img
            style={{ width: '70%' }}
            src="https://storage.googleapis.com/secretdiet-public-images/about/about-kimjihoon.jpg"
          />
        </div>
      </section>
      <section className={CommonSidePadder}>
        <h3>
          나는 <span className={PinkText}>국가대표 복서</span>
          였다.
        </h3>
        <ul className={ListStyle}>
          <li>
            <p>
              에이팀 김지훈 트레이너는 체육중, 체육고, 체육대학교, 체육대학원을
              졸업한 뼛속까지 체육인이며,
            </p>
            <p>
              한국체육대학교 재학 시절까지 복싱 국가대표로 활동했습니다. 그는{' '}
              <span className={BoldStyle}>
                전국체전 금메달리스트이며, 2000년 국내 대회 3연패
              </span>
              를 기록했습니다.
            </p>
            <p>
              2005 년 어느날, 그는 영화{' '}
              <span className={BoldStyle}>‘주먹이 운다’</span>의 촬영 현장을
              찾아가 이 복싱 영화는 자신의 이야기라며, 무술 감독을 자원했습니다.
            </p>
            <p>
              류승완 감독은 뜬금없이 나타난 그를 무술 감독으로 채용했고, 배우
              최민식을 가르치게 했습니다.
            </p>
          </li>
          <li>
            <p>
              함께 복싱을 하며 친해진 최민식씨는 김지훈 트레이너에게{' '}
              <span className={BoldStyle}>
                ‘운동 가르치는 것에 뛰어난 재능을 가지고 있다며,
              </span>
            </p>
            <p>
              <span className={BoldStyle}>
                일반 대중들에게 운동을 가르쳐 보는 것이 어떻겠냐’
              </span>
              고 추천했습니다.
            </p>
            <p>
              그 말을 귀 기울여 듣던 그는 트레이너로의 삶을 걷기 시작했습니다.
            </p>
          </li>
        </ul>
        <div
          className={css({
            textAlign: 'center',
          })}
        >
          <img
            width="70%"
            src="https://storage.googleapis.com/secretdiet-public-images/about/about-training.jpg"
            alt=""
          />
        </div>
      </section>
      <section className={CommonSidePadder}>
        <h3>
          국내 최고의 <span className={PinkText}>트레이닝 그룹 에이팀</span>의
          시작
        </h3>
        <ul className={ListStyle}>
          <li>
            <p>
              트레이너로서의 삶을 시작한 그는 국가대표 출신 동료들과 함께{' '}
              <span className={PinkText}>
                ‘Anytime Anywhere Make Your Body’
              </span>
              라는 슬로건을 내걸고,
            </p>
            <p>직접 찾아가는 트레이닝 그룹 에이팀을 만들었습니다.</p>
            <p>
              단기간에 다이어트의 큰 효과를 본 고객들의 반응은 뜨거웠지만, 서울
              전체를 돌아다니며 수업을 하다보니{' '}
            </p>
            <p>대부분의 시간을 길 위에서 보내곤 했습니다.</p>
            <p>
              그래서 그는 직접 운영하는{' '}
              <span className={BoldStyle}>에이팀 피트니스 센터</span>를
              만들어야겠다는 생각을 하게 되었습니다.
            </p>
          </li>
        </ul>
        <div className={css({ textAlign: 'center' })}>
          <img
            width="70%"
            src="https://storage.googleapis.com/secretdiet-public-images/about/img_trainers2.jpg"
            alt=""
          />
        </div>
      </section>
      <section className={CommonSidePadder}>
        <h3>
          트레이닝을 넘어 몸매를 만들어내는{' '}
          <span className={PinkText}>바디디자이너 김지훈</span>
        </h3>
        <ul className={ListStyle}>
          <li>
            <p>
              <span className={BoldStyle}>
                ‘맛집은 홍보를 하지 않아도 찾게 된다. 트레이닝 만으로 강남
                최고의 센터가 되겠다’
              </span>{' '}
              라는 의지로
            </p>
            <p>
              테헤란로 LIG 본사에서 간판도 없이 에이팀 피트니스 센터 본점을 오픈
              했습니다.
            </p>
          </li>
          <li>
            <p>
              아디다스, SM Entertainment사와의 인연을 통해 기반을 더욱 다질 수
              있었으며
            </p>
            <p>
              슈퍼주니어 기범을 시작으로 소녀시대, 신세경, 김수현, 최여진 등
              에이팀 트레이닝을 통해 달라진 스타들의 몸매들이
            </p>
            <p>
              <span className={BoldStyle}>에이팀의 실력과 능력을 증명</span>해
              주었습니다.
            </p>
          </li>
          <li>
            <p>
              마침내 에이팀은 테헤란로 본점을 시작으로 강남의 중심인 청담과
              역삼센터,
            </p>
            <p>
              서울의 거점인 용산과 목동센터, 경기 주요 도시인 분당과 용인센터 등{' '}
              <span className={BoldStyle}>8개 지점으로 확대</span>
              되었습니다.
            </p>
          </li>
          <li>
            <p>
              그리고 <span className={BoldStyle}>2017년</span>, 몸매는 타고나는
              것이 아니라 만들어지는 것이다.
            </p>
            <p>
              라는 신념으로 수많은 스타들과 고객들의 몸매를 만든 김지훈
              트레이너와 에이팀이
            </p>
            <p>
              <span className={BoldStyle}>
                홈트레이닝의 패러다임을 바꿀{' '}
                <span className={PinkText}>‘시크릿다이어트’</span>
              </span>
              를 시작합니다.
            </p>
          </li>
        </ul>
      </section>
    </SectionContainer>
  );
}
