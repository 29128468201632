import * as path from 'path';

export interface ViewFlatRoute {
  path: string;
  title?: string;
  strict?: boolean;
  component: React.ComponentType<any>;
}

export interface ViewRoute extends ViewFlatRoute {
  routes?: ViewRoute[];
}

function flatten(viewRoutes: ViewRoute[]): ViewFlatRoute[] {
  const results: ViewFlatRoute[] = [];
  viewRoutes.forEach(r => {
    if (!r.routes) {
      results.push({
        component: r.component,
        title: r.title,
        path: r.path,
        strict: r.strict,
      });
    } else {
      const subRoutes = flatten(r.routes);
      subRoutes.map(s => ({
        path: path.join(r.path, s.path),
        title: r.title,
        strict: r.strict,
        component: s.component,
      }));
      results.push({
        component: r.component,
        title: r.title,
        path: r.path,
        strict: r.strict,
      });
      subRoutes.forEach(sub => results.push(sub));
    }
  });

  return results;
}

export function flattenViewRoutes(routes: ViewRoute[]): ViewFlatRoute[] {
  return flatten(routes);
}
