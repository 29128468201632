import { ApolloError, MutationUpdaterFn } from 'apollo-client';
import gql from 'graphql-tag';
import { User } from '../../gql-types';
import { History } from 'history';
import React from 'react';
import { Mutation } from 'react-apollo';
import {
  GetLoginStatusOperation,
  LoginStatusModel,
} from '../../browser/models/LoginStatusModel';
import AppToaster from '../AppToaster';
import LoginForm from '../../components/forms/LoginForm';
import { Intent } from '@blueprintjs/core';
import { LoginRedirect } from '../../browser/models/LoginRedirectModel';

const LOGIN_MUTATION = gql`
  mutation LoginWithCredentials(
    $email: String!
    $password: String!
    $remember: Boolean
  ) {
    login: loginWithCredentials(
      email: $email
      password: $password
      remember: $remember
    ) {
      authToken
      user {
        id
        email
        name
        firstName
        lastName
        gender
        birthday
      }
    }
  }
`;

const SubmitHandler = (submit: Function) => (
  variables: {
    email: string;
    password: string;
  },
  remember: boolean,
) => {
  submit({
    variables: {
      ...variables,
      remember,
    },
    refetchQueries: [
      GetLoginStatusOperation,
      'GetCurrentUser',
      'GetCurrentLoginStatus',
    ],
  });
};

interface Props {
  className?: string;
  history: History;
  redirectTo?: string;
}

export class LoginMutation extends React.Component<Props> {
  private handleError = (err: ApolloError) => {
    if (AppToaster) {
      AppToaster.show({
        intent: Intent.DANGER,
        message: '유저아이디 혹은 암호가 맞지 않습니다.',
      });
    }
  };

  private handleUpdate: MutationUpdaterFn<any> = (cache, result) => {
    if (result.data) {
      const { login } = result.data;
      const model = LoginStatusModel.forBrowser();
      model.updateAuthToken(login.authToken);
    }
  };

  private handleCompleted = (data: {
    login: { authToken: string; user: User };
  }) => {
    if (gtag && typeof gtag === 'function') {
      gtag('event', 'login');
    }

    if (this.props.redirectTo) {
      this.props.history.push(this.props.redirectTo);
      return;
    }

    const model = LoginRedirect.forBrowser();
    if (model) {
      const redirect = model.getAndClear();
      if (redirect) {
        this.props.history.push(redirect);
        return;
      }
    }

    this.props.history.push('/my-fitness');
  };

  render() {
    const props = this.props;

    return (
      <Mutation
        mutation={LOGIN_MUTATION}
        onError={this.handleError}
        refetchQueries={[
          'GetCurrentLoginStatus',
          'GetCurrentUser',
          'GetLoginStatus',
        ]}
        update={this.handleUpdate}
        onCompleted={this.handleCompleted}
      >
        {(mutate, result) => {
          return (
            <LoginForm
              hasError={Boolean(result.error)}
              className={props.className}
              loading={result.loading}
              onSubmit={SubmitHandler(mutate)}
            />
          );
        }}
      </Mutation>
    );
  }
}
