import React from 'react';
import { css } from 'emotion';
import Helmet from 'react-helmet';

const tos = `이용약관

제 1장 총칙


제 1조 [목적]

이 약관은 “㈜에이팀바디사이언스(이하 “회사”라 한다)”에서 운영하는 “시크릿다이어트 공식웹사이트” (이하 "웹사이트"라 한다)에서 제공하는 인터넷 관련 서비스(이하 "서비스"라 한다)를 이용함에 있어 회사와 이용자의 권리, 의무 및 책임 사항을 규정함을 목적으로 합니다.


제 2조 [용어의 정의]

① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
1. 시크릿다이어트 공식웹사이트 : 회사가 서비스 또는 재화(이하 “서비스 등”이라 함)를 이용자에게 제공하기 위하여 컴퓨터, 모바일 등 정보통신 설비를 이용하여 서비스 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 시크릿다이어트를 운영하는 사업자의 의미로도 사용합니다.
2. 이용자 : “웹사이트”에 접속하여 본 약관에 따라 “회사”가 제공하는 “서비스 등”을 이용하는 “회원” 및 “비회원”을 말합니다.
3. 회원 : “웹사이트”에 접속하여 “아이디(ID)”를 부여받은 자로서 회사가 제공하는 정보와 서비스를 지속적으로 이용할 수 있는 자를 말합니다.
4. 비회원 : 회원에 가입하지 않고 "회사”에서 제공하는 서비스를 이용하는 자를 말합니다.
5. 콘텐츠 : “웹사이트”에서 제공하는 정보로서, 정보통신망이용촉진 및 정보보호 등에 관한 법률 제2조 제1항 제1호의 규정에 의한 정보통신망에서 사용되는 부호ㆍ문자ㆍ음성ㆍ음향ㆍ이미지 또는 영상 등으로 표현된 자료 또는 정보
6. 아이디(ID) : 회원의 식별 및 서비스 이용을 위하여 회원이 정하고 회사가 승인하는 문자 또는 숫자의 조합
7. 비밀번호(PASSWORD) : 서비스 이용 시 “아이디(ID)”와 일치되는 회원임을 확인하고, 회원 개인정보 보호를 위하여 회원 자신이 정한 문자 또는 숫자와의 조합

② 제1항의 용어를 제외한 용어의 정의는 거래 관행 및 관계 법령에 따릅니다.


제 3조 [회사정보 등의 제공]
회사는 회사의 상호, 대표자 성명, 주소, 전화번호(소비자의 불만을 처리하는 곳의 연락처 포함), 모사전송번호, 전자우편주소, 사업자등록번호, 통신판매업 신고번호 및 개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 웹사이트 초기화면에 게시합니다.


제 4조 [약관 효력과 변경]

① 본 약관은 “이용자”가 약관의 내용에 동의하며 회원가입을 신청하고, 회사가 그 신청에 대하여 승낙함으로써 효력이 발생합니다.

② 회사는 관계법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있으며 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여, 개정 전 약관과 함께 적용일 10일 전부터 회사 웹사이트에서 확인할 수 있도록 게시하며, 기존 회원에게는 회원이 공개한 전자우편주소로 이메일 발송, 홈페이지 게시 등의 방법으로 약관 개정사실을 통지할 수 있습니다.

③ 전 항과 같이 약관의 변경에 대하여 통지한 경우 그 때부터 14일이내 “이용자”가 거절의 의사를 표시하지 않으면 변경약관에 동의한 것으로 간주됩니다.


제 5조 [약관 외 준칙]
본 약관에 명시되지 않은 사항과 해석에 관하여는 “약관의 규제에 관한 법률”, “정보통신망 이용촉진 및 정보보호 등에 관한 법률”, “전자상거래 등에서의 소비자보호에 관한 법률” 및 기타 관련법령의 규정과 상관례에 따라 따릅니다.


제 6조 [회원에 대한 통지]
① “회사”는 “회원”에게 알려야 할 사항이 발생하는 경우 회원가입 시 “회원”이 공개한 전자우편주소로 이메일을 보내거나 “웹사이트”에 공지 또는 팝업창을 게시하는 방법 등으로 통지할 수 있습니다.

② 전 항과 같이 통지한 경우 그 때부터 14일 이내에 “회원”이 “회사”가 정하는 방법으로 거부의사를 표시하지 않을 경우 그 “회원”에게는 통지 내용이 도달하였고 통지 내용에 동의하였다고 간주됩니다.

③ “회원”이 개정약관의 적용에 동의하지 않는 경우 이용 중인 유료 “서비스 등”의 이용이 종료될 때까지 기존 약관을 적용하거나 이용계약을 해지 할 수 있습니다.



제 2 장 회원가입


제 7조 [회원가입]

① 회원가입은 “서비스”를 이용하려는 자가 본 약관의 내용에 동의하고 회원가입 신청을 한 후 “회사”가 이러한 신청에 대하여 승낙함으로써 완료됩니다.

② 전 항의 규정에 따라 회원가입을 할 때에는 “회사”가 제공하는 “서비스”의 원활한 이용을 위해서 필요한 “개인정보”를 제공해야 합니다.


제 8조 [회원가입 신청]

① “회원”으로 가입하여 “서비스”를 이용하기를 희망하는 자는 “회사”가 요청하는 소정의 신규회원가입 양식에서 요구하는 사항을 기록하여 신청합니다.

② 온라인 가입신청 양식에 기재하는 사항에 실명이나 실제 정보를 입력하지 않은 자는 법적인 보호를 받을 수 없으며, “서비스” 사용의 제한을 받을 수 있습니다.


제 9조 [회원가입 신청에 대한 승낙]

① 승낙은 "회사”가 회원가입 신청을 한 자에게 “아이디(ID)”, 서비스 이용개시를 통보함으로써 이루어집니다.

② “회사”는 다음 각 호에 해당하는 경우 회원가입 신청에 대하여 승낙하지 아니합니다.
1. 본인의 실명으로 신청하지 않은 경우
2. 다른 사람의 명의를 빌리거나 도용하여 차명으로 신청한 경우
3. 회원가입 신청서의 내용을 허위로 기재했거나 첨부한 경우 또는 기재하지 않은 경우
4. 관계법령에 위배되거나 사회의 안녕질서 혹은 미풍양속을 저해할 수 있는 목적으로 신청한 경우
5. 기타 “회사”가 정한 회원가입 신청 요건이 미비된 경우

③ 회사는 다음 각 호에 해당하는 경우에는 그 사유가 해소될 때까지 이용 승낙을 보류할 수 있으며, 그 사유를 웹사이트에 공지합니다.
1. 서비스 관련 설비의 용량이 부족한 경우
2. 기술상 또는 업무 수행상 지장이 있는 경우
3. 기타 회사가 필요하다고 인정하는 경우



제 3 장 서비스 제공 및 이용


제 10조 [서비스 이용 기술사양]

① “회사”가 제공하는 “서비스 등” 서비스 이용에 필요한 기술 사양은 “웹사이트”에 기재합니다.

② 제 11조 1항보다 고사양이 요구되거나 컴퓨터 이외의 다른 장치로 이용이 가능한 “서비스 등”의 경우 “회사”는 해당 “서비스 등”의 포장 또는 “웹사이트”에 그 내용을 표시할 수 있습니다.


제 11조 [서비스 이용 요금]

① 별도로 표시된 유료 서비스를 제외한 모든 서비스는 회원들에게 무료로 제공됩니다.

② 유료 서비스 이용에 관한 사항은 회사가 별도로 정한 약관 및 정책에 따릅니다.


제 12조 [서비스 이용 시간 및 문의]

① “서비스”는 “회사”의 업무상, 기술상 문제 등 기타 특별한 사정이 없는 한 매일 24시간 운영함을 원칙으로 합니다. 단, “회사”는 일부 “서비스”의 이용 가능 시간을 별도로 정할 수 있으며, 이 경우 “웹사이트”를 통해 공지합니다.

② “이용자”는 “서비스” 이용 중 프로그램 상담, 동영상 재생 오류 및 기술 상담, 통신 오류, 프로그램 환불 및 변경 문의 기타 “서비스” 이용과 관련하여 문의 사항이 있는 경우 “웹사이트” 고객센터를 이용하여 온라인 문의를 하거나 또는 “회사”의 고객센터로 전화 문의를 할 수 있습니다.

③ “회사”는 원활한 “서비스” 제공을 위하여 “이용자”가 전 항에 의하여 문의하는 사항을 저장, 녹음하여 활용할 수 있습니다.

④ 회원의 이용신청을 승인한 때부터 “서비스”를 개시합니다. 다만, 유료서비스를 포함한 일부 서비스의 경우에는 결제가 완료된 이후 또는 “회사”에서 지정한 일자(시점)부터 “서비스”를 개시합니다.


제 13조 [서비스 제공의 중지 및 제한]

① “회사”는 다음 각 호에 해당하는 경우 “서비스” 제공을 중지하거나 제한할 수 있습니다.
1. 서비스용 설비의 최적화를 위한 시스템 점검 또는 설비 보수로 인해 부득이한 경우
2. 분산서비스거부(DDoS) 공격 등에 의해 발생한 서비스 장애 복구를 위해 부득이한 경우
3. 기타 서비스용 설비의 장애 또는 “서비스”이용의 폭주 등으로 “서비스”이용에 지장이 있는 경우
4. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우
5. 국가비상사태, 천재지변에 의해 부득이한 경우
6. 기타 합리적인 이유가 있는 경우로서 “회사”가 필요하다고 인정하는 경우

② “회사”가 전 항에 의하여 “서비스” 제공을 중지하거나 제한하는 경우 “회사”는 이를 “이용자”에게 알려야 합니다. 다만, “회사”의 귀책사유 없이 “이용자”에게 통지할 수 없는 경우에는 예외로 합니다.

③ “회사”의 귀책사유로 “서비스” 제공이 중지되거나 그 이용에 장애가 발생하는 경우에는 “회사”의 규정에 따라 이용이 중지되거나 장애가 발생한 시간만큼 이용 기간을 연장합니다. 다만, 천재지변 또는 이에 준하는 불가항력으로 인하여 “서비스” 제공이 중지되거나 그 이용에 장애가 발생하는 경우에는 예외로 합니다.

④ “회사”의 귀책사유로 “콘텐츠” 자체에 하자가 있는 경우에는 “회사”의 규정에 따라 그 하자가 복구되지 못한 기간만큼 이용 기간을 연장합니다. 다만, 완전한 “콘텐츠”를 다시 제공하는 것이 불가능한 경우에는 “회사”의 규정에 따라 다른 방법으로 그에 대한 보상을 실시할 수 있습니다.


제 14조 [환불]

① “회사”와 “서비스 등”의 구매에 관한 계약을 체결한 회원은 “서비스 등” 구매일로부터 7일 이내에 “서비스 등”의 훼손 없이 “회사” 고객센터나 “웹사이트”를 통해 신청하시면 서비스 이용 수수료 10%를 제외 후 환불이 가능하지만, 그 외의 경우 약관에 따라 진행됩니다.

② 회원은 다음의 경우 위 1항의 환불을 할 수 없습니다.
1. 회원에게 책임 있는 사유 또는 천재지변 사유로 “서비스 등” 이 멸실 또는 훼손된 경우
2. 회원의 사용 또는 일부 소비로 “서비스 등”의 가치가 현저히 감소한 경우
3. 시간의 경과에 의하여 재판매가 곤란할 정도로 “서비스 등”의 가치가 현저히 감소한 경우
4. 복제가 가능한 “서비스 등”의 포장을 훼손한 경우
5. 패키지 회원에게 제공한 “시크릿박스”를 훼손 또는 개봉한 경우

③ 회원이 위 1항에 따라서 환불을 한 경우 회사로부터 공급받은 “서비스 등”을 반환하여야 하며, 해당 비용은 회원이 부담합니다.

④ “서비스 등”에 따라 별도의 환불규정을 둘 수 있으며, 해당 내용은 “서비스 등” 또는 “웹사이트”에 명기합니다.

⑤ 당사의 “서비스 등”을 다른 유통경로로 구매한 경우 별도의 명시가 없는 한 “회원”은 최종 판매처의 환불 규정에 따릅니다.



제 4 장 계약당사자의 권리와 의무

제 15조 [회사의 의무]

① 회사는 지속적, 안정적으로 서비스를 제공하기 위해 최선을 다합니다.

② 회사는 회원 개인의 정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않는 것을 원칙으로 합니다. 단, 아래 사항에 해당하는 경우는 예외로 인정합니다.
1. 전기통신기본법 등 법률의 규정에 의해 국가기관의 요구가 있는 경우
2. 범죄에 대한 수사상의 목적이 있거나 정보 통신 윤리위원회의 요청이 있는 경우
3. 기타 관계법령이 정한 절차에 따른 요청이 있는 경우


제 16조 [개인정보 보호 등]

① “회사”는 “회원”의 “개인정보” 수집 시 필요한 최소한의 정보를 수집합니다.

② “회사”는 “회원”의 “개인정보”를 수집할 때 다음 각 호의 1에 해당하는 경우를 제외하고는 그 “회원”의 동의를 받습니다.
1. 법률에 특별한 규정이 있는 경우
2. 전자거래 계약의 이행을 위하여 필요한 경우
3. “서비스” 제공에 따른 요금 정산을 위하여 필요한 경우

③ “회사”는 “회원”의 “개인정보”를 그 “회원”의 동의 없이 목적 외로 사용할 수 없습니다. 단, 다음 각 호의 1에 해당하는 경우에는 예외로 합니다.
1. 약관 또는 법률에 특별한 규정이 있는 경우
2. “서비스” 제공에 따른 요금 정산을 위해 필요한 경우
3. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공되는 경우
4. 신규 서비스(제품) 개발 및 특화 또는 이벤트 행사 등 마케팅 및 광고를 위해 활용하는 경우

④ “회사”는 “회원”의 “개인정보”를 그 “회원”의 동의 없이 제 3자에게 제공할 수 없습니다. 단, 다음 각 호의 1에 해당하는 경우에는 예외로 합니다.
1. 약관 또는 법률에 특별한 규정이 있는 경우
2. “서비스” 제공에 따른 요금 정산을 위해 필요한 경우
3. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공되는 경우

⑤ “회원”은 “웹사이트”상의 개인정보관리 시스템을 통해 언제든지 본인의 “개인정보”를 열람하고 수정할 수 있습니다. 다만, “아이디(ID)”는 변경할 수 없습니다.

⑥ “회원”은 가입신청 시 기재한 사항이 변경되었을 경우 “웹사이트”에서 회원이 직접 수정해야 하며, 이를 수정하지 아니하여 발생하는 문제의 책임은 “회원”에게 있습니다.

⑦ “개인정보”의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 “개인정보”를 지체 없이 폐기합니다.

⑧ 전 항의 경우라도 “회사”가 사전에 개인정보취급방침에 규정하여 “회원”의 동의를 받거나 관련 법령에 따라 일정기간 “개인정보”를 보관해야 하는 경우 “회사”는 예외적으로 일정기간 “개인정보”를 보관할 수 있습니다.

⑨ “회사”는 합리적 이유가 있는 경우로서 “회사”가 필요하다고 인정하는 경우 독점규제 및 공정거래에 관한 법률 및 동시행령이 규정하는 계열회사로 하여금 “회원”의 “개인정보”를 사용하게 할 수 있습니다.

⑩ “회사”는 “회원”의 동의를 받아 아래와 같이 개인정보 제3자에 제공 및 공유합니다.
1. 제공받는 자
㈜ CJ대한통운, ㈜ 한진, ㈜ 로젠택배, ㈜ KG이니시스, 시크릿TV
2. 제공 목적	
가입 제휴사에 한해 웹사이트 로그인, 기타 제휴사가 제공하는 서비스 및 이벤트 제공 및 광고성 정보 전송
3. 제공하는 항목
이름, 생년월일, 아이디, 휴대전화번호, 이메일주소, 성별, 주소, 전화번호, 구매관련정보
4. 보유 및 이용기간
회사가 제공항목 및 제공목적에 대해 회원에게 동의를 받은 때부터 회원이 당 사가 운영하는 사이트 회원 탈퇴 또는 제휴사에 정보 제공 동의 철회 시까지
5. 제3자 정보제공 동의를 거부하셔도 서비스 이용은 가능하나, 제3자의 정보 제공, 다양한 이벤트 참여, 혜택 안내 및 기타 서비스 제공이 제한될 수 있습니다. (단, 2016.08.16 이전 가입한 회원의 경우 약관 개정 고지에 대한 별도 거부 의사가 없는 한 동의로 간주합니다.)


제 17조 [회원의 "아이디(ID)"와 "비밀번호(PASSWORD)" 관리에 대한 의무]

① “아이디(ID)”와 “비밀번호(PASSWORD)”에 관한 모든 관리책임은 “회원”에게 있습니다.

② “회원”의 “아이디(ID)” 및 “비밀번호(PASSWORD)”의 관리에 관하여 “회사”의 고의 및 중과실이 없는 한, “회원”에게 부여된 “아이디(ID)”와 “비밀번호(PASSWORD)”의 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 “회원”에게 있습니다.

③ 자신의 “아이디(ID)”가 부정하게 사용된 경우, “회원”은 반드시 “회사”에 그 사실을 통보해야 합니다.


제 18조 [회원의 의무]

① “회원”은 “서비스”를 이용할 때 다음 각 호의 행위를 해서는 안 됩니다.
1. 다른 “회원”의 “아이디(ID)”를 부정하게 사용하는 행위
2. 자신의 “아이디(ID)” 및 “서비스”를 타인이 사용하도록 하는 행위
3. “서비스” 이용 시 영상, 사진, 텍스트, 음성 등의 정보를 녹화나 캡쳐 또는 녹음할 수 있는 소프트웨어를 실행시키는 행위
4. “서비스”에서 얻은 정보를 “회사”의 사전 승낙 없이 “회원”의 정당한 이용 이외의 목적으로 복제하거나 이를 출판 및 방송 등에 사용하거나 제 3자에게 제공하는 행위
5. 회원이 회사에서 제공하는 “서비스”를 이용하여 상품 또는 용역을 제공하는 등의 상행위를 하는 경우
6. “회사” 또는 제 3자의 저작권 등 지적재산권 기타 권리를 침해하거나 침해하려는 행위
7. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 등을 타인에게 유포하는 행위
8. 기타 관계법령에 위배되는 행위

② “회원”은 본 약관에 규정하는 사항과 “서비스” 이용안내 또는 주의사항을 준수해야 합니다.

③ “회원”은 사항 별로 “회사”가 “서비스” 공지사항에 게시하거나 별도로 공지한 사항을 준수해야 합니다.

④ “회원”은 “회사”의 명시적인 동의가 없는 한 “서비스”의 이용권한 기타 이용 계약상의 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.

⑤ “회사”는 “회원”이 제1항 내지 제4항을 위반하였을 경우 사전 통지 없이 계약을 해지하거나 또는 “서비스” 이용을 제한할 수 있습니다.

⑥ “회사”가 전 항에 따라 계약을 해지하거나 “서비스” 이용을 제한하는 경우 이를 “회원”에게 알립니다. 다만, “회사”의 귀책사유 없이 “회원”에게 알릴 수 없는 경우에는 예외로 합니다.


제 19조 [게시물]

①“회사”는 “게시물”이 다음 각 호의 1에 해당된다고 판단되는 경우에는 그 “이용자”의 동의 없이 그 “게시물”을 삭제할 수 있습니다.
1. 다른 “이용자” 또는 제 3자를 비방하거나 중상 모략함으로써 타인의 명예를 훼손시킨 경우
2. 공공질서 및 미풍양속에 위반되는 경우
3. 범죄적 행위에 결부된다고 인정되는 경우
4. “회사” 또는 제 3자의 저작권 등 지적재산권 기타 권리를 침해하거나 침해할 우려가 있는 경우
5. 기타 관계 법령이나 “회사”가 정한 규정에 위배되는 경우

② “회사”가 운영하는 “게시판” 등에 게시된 정보로 인하여 법률상 이익이 침해된 자는 권리 침해 사실을 소명하여 “회사”에게 당해 정보의 삭제를 요청할 수 있습니다. 이 경우 “회사”는 신속히 필요한 조치를 취하고 이를 요청자에게 통지합니다.


제 5 장 회원탈퇴 및 자격 상실

제 20조 [회원탈퇴]

① “회원”이 “웹사이트”의 회원 탈퇴를 하고자 하는 경우에는 “회원” 본인 또는 “회원”의 법정대리인이 “웹사이트”에 회원 탈퇴의 신청을 하여야 합니다.

② “회원”의 회원 탈퇴 신청을 확인한 경우 “회사”는 신속히 회원 탈퇴 절차를 처리하며, 관련법 및 “웹사이트”의 개인정보취급 방침에 따라 “회사”가 회원정보를 보유하는 경우를 제외하고 탈퇴 완료 후 지체 없이 “회원”의 개인정보를 파기합니다.


제 21조 [회원자격 상실 및 정지]

① “회사”는 “회원”이 다음 각 호의 1에 해당하는 행위를 하였을 경우 사전 통지 없이 “회원” 자격을 상실시키거나 “회원” 자격을 정지시킬 수 있습니다.
1. 타인의 “개인정보”, “아이디(ID)” 및 “비밀번호(PASSWORD)”를 도용한 경우
2. 가입한 이름이 실명이 아닌 경우
3. 타인의 명예를 손상시키거나 불이익을 주는 행위를 하는 경우
4. “회사” 또는 제 3자의 저작권 등 지적재산권 기타 권리를 침해하거나 침해하려는 행위를 한 경우
5. 공공질서 및 미풍양속에 저해되는 내용을 유포시키는 경우
6. “회원”이 국익 또는 사회적 공익을 저해할 목적으로 “서비스” 이용을 계획 또는 실행하는 경우
7. “서비스” 운영을 고의 또는 과실로 방해하는 경우
8. “서비스”의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나 광고성 정보를 전송하는 경우
9. 정보통신설비의 오작동이나 정보의 파괴를 유발시키는 컴퓨터 바이러스 프로그램 등을 유포하는 경우
10. “회사”의 “서비스”를 이용하여 얻은 정보를 “회사”의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는 경우
11. “웹사이트”에 음란물을 게재하거나 음란 사이트를 링크하는 경우
12. 본 약관 기타 “회사”가 정한 의무 기타 이용 조건을 위반하는 경우
13. “회사”의 명시적인 동의 없이 “회사”가 제공하는 “서비스”를 녹화, 녹음, 캡쳐 하거나 하려는 경우
14. “회사”의 명시적인 동의 없이 “서비스”의 이용권한 기타 이용 계약상의 지위를 타인에게 양도, 증여, 담보 제공하거나 타인과 공유하는 경우
15. 회원이 회사에서 제공하는 “서비스”를 이용하여 상품 또는 용역을 제공하는 등의 상행위를 하는 경우

② “회사”가 전 항에 따라 “회원” 자격을 상실시키거나 제한하는 경우에는 “회사”는 이를 “회원”에게 알립니다. 다만, “회사”의 귀책사유 없이 “회원”에게 알릴 수 없는 경우에는 예외로 합니다.

③ “회사”가 제1항에 따라 “회원” 자격을 상실시키거나 제한하는 경우 해당 “회원”에 대하여는 “회사”가 제공하는 모든 “서비스”의 이용을 중단시킬 수 있고, 해당 “회원”은 “회사”에 대하여 중단 이후의 “서비스”에 대한 환불 등을 요구할 수 없습니다.



제 6 장 기타

제 22조 [저작권]

① “회사”가 자체 제작한 “콘텐츠”와 “회사”가 제3자로부터 저작권을 양수 받은 “콘텐츠”에 대한 저작권은 “회사”에게 있습니다.

② “회사”가 제3자로부터 이용 허락을 받은 “콘텐츠”에 대한 저작권은 제3자에게 있고 “회사”는 그에 대한 사용권을 가집니다.

③ “회원”은 “회사”가 제공하는 “서비스”를 이용함으로써 얻은 정보를 “회사”의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리 또는 비영리의 목적으로 이용하거나 제 3자에게 이용하게 할 수 없습니다.

④ “회사”는 “회사” 또는 제3자의 저작권을 보호하기 위하여 “회원”이 “서비스”를 이용하는 동안 “회원”의 컴퓨터에 제22조 제1항 제3호의 소프트웨어가 실행되는 것을 감지하여 자동으로 차단하는 프로그램을 사용할 수 있습니다.

⑤ “회원”의 “게시물”에 대한 권리 및 책임은 이를 게시한 “회원”에게 있습니다.

⑥ “회사”는 “회원”이 “웹사이트”에 게시한 “게시물”을 사용, 복제, 전송, 수정, 출판, 배포할 수 있습니다.


제 23조 [면책조항]

① “회사”는 천재지변 또는 이에 준하는 불가항력으로 인하여 “서비스”를 제공할 수 없는 경우에는 “서비스” 제공에 관한 책임이 면제됩니다.

② “회사”는 “회원”의 귀책사유로 인한 “서비스” 이용의 장애에 대하여 책임을 지지 않습니다.

③ “회사”는 “이용자”가 “웹사이트”에 게재한 사실의 신뢰도, 정보나 자료의 정확성 등 내용에 관하여는 책임을 지지 않습니다.

④ “회사”는 “서비스” 이용과 관련하여 “이용자”에게 발생한 손해 가운데 “이용자”의 고의, 과실에 의한 손해에 대하여 책임을 지지 않습니다.


제 24조 [분쟁의 해결]

① “회사”는 분쟁이 발생하였을 경우에 “이용자”가 제기하는 정당한 의견이나 불만을 반영하여 적절하고 신속한 조치를 취합니다. 다만, 신속한 처리가 곤란한 경우에 “회사”는 “이용자”에게 그 사유와 처리일정을 통보합니다.

② 전항에도 불구하고, 본 약관 및 서비스이용 및 “이용자”와 “회사” 관련 사항 등은 모두 대한민국 법령의 적용을 받습니다.


[부칙]

본 약관은 2017년 04월 20일부터 적용합니다.










유료서비스 이용약관



제 1장 총칙


제 1조 [목적]

이 약관은 “㈜에이팀바디사이언스(이하 “회사”라 한다)”가 운영하는 “시크릿다이어트 공식사이트”에서 제공하는 유료서비스의 제반사항(환불 규정 및 손해배상책임 등 포함)에 대한 “회사”와 유료서비스 이용자간의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.


제 2조 [용어의 정의]

① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
1. 유료서비스 : “시크릿다이어트 공식사이트”에서 제공하는 온라인 운동 콘텐츠영상 프로그램, 스마트 체지방계, 시크릿다이어리 컨설팅, “회사”에서 판매하는 기타 제품 등 유료로 제공하는 모든 서비스 및 재화를 말합니다.
2. 결제금액 : “이용자”가 입금한 금액 중에서 신용 카드 수수료 등의 이유로 발생되는 비용을 제외하고 회사가 실질적으로 고객으로부터 수납한 금액을 말합니다.
3. 환불금 : “이용자”가 유료서비스 이용계약을 청약철회 또는 해지하여 발생하는 수수료를 제외하고 “이용자”에게 도로 돌려주는 금액을 말합니다.

② 제1항의 용어를 제외한 용어의 정의는 거래 관행 및 관계 법령에 따릅니다.



제 2장 유료서비스의 요금

제 3조 [요금의 산정 및 납입]

① 서비스 이용과 관련하여 이용자가 납입하여야 할 요금 등의 종류는 해당 사이트의 서비스에 따라 정해집니다.

② 요금 납입자는 이용고객으로 합니다. 다만, 미성년자 등 기타 요금을 납입 할 수 없는 자는 회사가 인정하는 경우에 그 법정대리인 또는 제3자를 요금납입자로 지정할 수 있습니다.

③ 제2항의 단서규정에 의한 요금 납입자는 이용고객이 회사에 납입해야 할 요금 등의 모든 채무를 이용자와 연대하여 책임을 지도록 합니다.


제 4조 [서비스 이용요금 납입 방법 및 기일]

① 서비스 이용요금의 납입은 선납제를 원칙으로 합니다.

② 요금납입책임자가 요금 등을 납부하여야 “유료서비스”의 계약이 체결됩니다.


제 5조 [유료서비스의 승인]

① “회사”는 “회사”에서 규정한 결제 방법을 사용하여 “이용자”가 이용 요금을 납입한 사실이 확인되면 지체 없이 그 유료 “서비스” 이용 신청을 승인합니다.

② “회사”는 다음 각 호에 해당하는 경우 전 항의 승인을 제한할 수 있고 그 사유가 해소될 때까지 승인을 유보할 수 있습니다.
1. “유료서비스” 이용 요금을 납입하지 않은 경우
2. “유료서비스” 신청 금액 총액과 납입 금액 총액이 일치하지 않는 경우
3. 기타 합리적인 이유가 있는 경우로서 “회사”가 필요하다고 인정하는 경우

③ 제2항에 의하여 승인을 유보하거나 승인하지 아니하는 경우, 회사는 이를 “이용자”에 알려야 합니다. 다만, 회사의 귀책사유 없이 “이용자”에 통지할 수 없는 경우는 예외로 합니다.


제 6조 [서비스 과오납 요금의 환불]

① “회사”는 “유료서비스” 결제와 관련하여 과오납 요금이 발생한 경우 대금결제와 동일한 방법으로 혹은 결제 취소 후 재결제 등의 방법으로 그 과오납 요금을 “이용자”에게 환불합니다.

② “회사”의 책임 있는 사유로 과오납 요금이 발생한 경우 “회사”는 과오납 요금 전액을 환급합니다. 다만, “이용자”의 책임 있는 사유로 과오납 요금이 발생한 경우, 과오납 요금의 환급에 소요되는 비용은 “이용자”가 부담합니다.



제 3장 유료서비스의 이용계약의 환불 및 변경

제 7조 [유료서비스 이용계약의 환불규정]

① “회사”와 “서비스” 또는 “제품” (이하 “서비스 등”이라 합니다) 의 구매에 관한 계약을 체결한 회원은 “서비스 등” 구매일 또는 “서비스 등” 수령일로부터 7일 이내에 회사 고객센터를 통해 신청해 주시면 서비스 이용 수수료 10%를 제외 후 환불이 가능하지만, 그 외의 경우 약관에 따라 진행됩니다.

② 회원은 다음의 경우 위 1항의 환불을 할 수 없습니다.
1. 회원에게 책임 있는 사유 또는 천재지변 사유로 “서비스 등” 이 멸실 또는 훼손된 경우
2. 회원의 사용 또는 일부 소비로 “서비스 등”의 가치가 현저히 감소한 경우
3. 시간의 경과에 의하여 재판매가 곤란할 정도로 “서비스 등”의 가치가 현저히 감소한 경우
4. 복제가 가능한 “서비스 등”의 포장을 훼손한 경우

③ 회사가 서비스를 제공 할 수 없게 되는 경우 운동프로그램 콘텐츠를 이용을 할 수 없거나  운동프로그램 콘텐츠를 이용을 제공할 수 없게 된 날로부터 이미 낸 이용료를 일할 계산한 금액을 환불한다.

④ 이용자가 본인의 의사로 중도 환불 요청시 결제 방식에 따라 소정의 수수료를 제외 후 환불 가능하며 당사의 사정으로 인한 환불시 수수료 차감은 제외됩니다.
1. 모든 “서비스 등” 공통
 가. 수강 시작일로부터 3일 미만, 1강 미만 수강 시 전액 환불 가능합니다.
 나. 수강 시작일로부터 7일 미만, 3강 미만 수강 시 서비스 이용 수수료 10%를 제외 후 환불 가능합니다.
 다. 시크릿몰 상품 및 시크릿박스는 배송비 및 반송 수수료 제외 후 환불됩니다.
 라. 환불 진행 시 일시정지 기간은 이용 기간으로 합산 적용됩니다.
 마. 이벤트 및 프로모션 상품은 환불이 불가합니다.
 바. 할인 쿠폰을 적용하여 결제시 프로그램 정가는 할인가를 기준으로 적용됩니다.

2. 수강기간 1개월 이하 영상 멤버십의 경우
 가. 수강 시작일로부터 7일 초과 15일 미만, 전체 강좌의 30% 미만 수강 시 잔여 일에 대한 부분 환불이 가능합니다.
 나. 수강 시작일로부터 15일 초과 소요 시 또는 전체 강좌의 30% 초과 수강 시 환불이 불가합니다.

3. 수강기간 1개월 초과 영상 멤버십의 경우
 가. 수강 시작일로부터 7일 초과 30일 미만, 전체 강좌의 30% 미만 수강 시 잔여 일에 대한 부분 환불이 가능합니다.
     예시) 환불금액 = 결제금액 - {결제금액 x (실제 수강일/전체 수강일)}
 나. 수강 시작일로부터 30일 초과 소요 시 또는 전체 강좌의 30% 초과 수강 시 환불이 불가합니다.

4. 담당 트레이너 컨설팅 멤버십의 경우
 가. 최초 컨설팅 진행 완료 후 환불 신청 시 컨설팅료 6만원을 제외한 금액이 환불됩니다.
 나. 식단표 수령 후 환불 신청 시 식단컨설팅료 3만원을 제외한 금액이 환불됩니다.
 다. 담당 트레이너 컨설팅 1개월 이하 멤버십의 경우 시작일로부터 15일 미만 소요 시 잔여 일에 대한 부분 환불이 가능합니다.
      예시) 환불금액 = 결제금액 - 최초 컨설팅료 - 식단 컨설팅료 - {결제금액 x (이용 기간/컨설팅 멤버십 신청 기간)} 
 라. 담당 트레이너 컨설팅 1개월 이하 멤버십의 경우 시작일로부터 15일 이상 소요 시 환불이 불가합니다.
 마. 담당 트레이너 컨설팅 1개월 초과 3개월 이하 멤버십의 경우 시작일로부터 45일 미만 소요 시 잔여 일에 대한 부분 환불이 가능합니다.
      예시) 환불금액 = 결제금액 - 최초 컨설팅료 - 식단 컨설팅료 - {결제금액 x (이용 기간/컨설팅 멤버십 신청 기간)} 
 바. 담당 트레이너 컨설팅 1개월 초과 3개월 이하 멤버십의 경우 시작일로부터 45일 이상 소요 시 환불이 불가합니다.

5. 프리미엄 패키지의 경우
 가. 수강 시작일로부터 7일 초과 30일 미만, 전체 강좌의 30% 미만 수강 시 잔여 일에 대한 부분 환불이 가능합니다.
     예시) 환불금액 = 결제금액 - {결제금액 x (실제 수강일/전체 수강일)}
 나. 배송된 시크릿 박스를 개봉했을 경우 시크릿몰 정가를 기준으로 제외한 금액이 환불됩니다.
예시) 환불금액 = 결제금액 - 제공품 정가 - {결제금액 x (실제 수강일/전체 수강일)} 
 다. 수강 시작일로부터 30일 초과 소요 시 또는 전체 강좌의 30% 초과 수강 시 환불이 불가합니다.

⑤ 스마트 체지방계, 운동용품, 프로틴쉐이크 등을 비롯한 “서비스 등” 의 훼손으로 인해 제품의 가치가 현저히 감소되는 경우 혹은 패키지에 제공된 제반 상품에 개봉 흔적이 있는 경우에는 제품 초기 불량을 제외하고 환불 할 수 없습니다.

⑤ 회원이 위 1항에 따라서 환불을 한 경우 회사로부터 공급받은 “서비스 등”을 반환하여야 하며, 해당 비용은 회원이 부담합니다.

⑥ 당초 제공되기로 공지된 패키지 상품을 제외하고 제공받은 추가적인 “사은품”이 있다면 "회원"은 이를 반환하여야 하며, "회원"이 이를 사용하거나 훼손한 경우에는 동종 상품의 판매가격에서 소비자피해보상규정의 손율 등에 따른 금액을 지급하고 반환해야 합니다.

⑦ 기타 패키지 상품, 이벤트성 강좌, 비정규 강좌, 특별기획 강좌 등에서는 별도의 수강 취소ㆍ변경 및 환불규정이 적용 될 수 있습니다.

⑧ 당사의 “서비스 등” 또는 “제품”,”도서” 등을 다른 유통경로로 구매한 경우 별도의 명시가 없는 한 “회원”은 최종 판매처의 환불 규정에 따릅니다.

6. 시크릿몰에서 구매한 상품의 교환/반품 가능 기간 및 교환/반품비 부담 기준은 다음과 같습니다.
① 구매자의 단순 변심, 착오구매
   교환/반품 가능기간: 상품수령 후 7일 이내
   교환/반품비 부담: 구매자 부담

② 상품의 하자, 오배송
   교환/반품 가능기간: 상품수령 후 30일 이내
   교환/반품비 부담: 무료(판매자 부담)

③ 환불은 물류센터의 반품도착 확인 후 3영업일 이내에 진행됩니다.

④ 반품운송장에 기재된 상품/사은품/수량만을 반품하여야 하며, 수량/구성품이 부족한 경우에는 환불되지 않고 반송 처리될 수 있습니다. 반품운송장에 기재되지 않은 물건을 반품하거나, 반품 신청이 되지 않은 상품을 택배사에 인도한 경우에는 회사가 책임을 지지 않습니다.

⑤ 교환/반품/환불에 따라 고객님이 추가로 부담하여야 할 사항이 있는 경우에는 그 내용과 금액에 대해서 상품 주문 시 또는 교환/반품/환불 접수 및 진행 시 별도 안내하도록 합니다.



제 8조 [유료서비스 이용계약의 변경]

① “이용자”는 “유료서비스” 에 관한 계약을 체결한 이후 회사와의 합의 없이 변경은 불가능하지만 제2장 제3조 1항의 조건으로 계약 취소 후 재계약 할 수 있습니다.

② 이용계약 변경을 위한 청약철회의 경우 제3장 7조 “유료서비스 이용계약의 환불규정”을 따릅니다.


제4장 유료서비스 손해배상 및 면책조항

제 9조 [유료서비스 손해배상]

① “회사”는 다음 각 호에 해당하는 경우 “서비스” 제공을 중지하거나 제한할 수 있습니다.
1. 서비스용 설비의 최적화를 위한 시스템 점검 또는 설비 보수로 인해 부득이한 경우
2. 분산서비스거부(DDoS) 공격 등에 의해 발생한 서비스 장애 복구를 위해 부득이한 경우
3. 기타 서비스용 설비의 장애 또는 “서비스”이용의 폭주 등으로 “서비스”이용에 지장이 있는 경우
4. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우
5. 국가비상사태, 천재지변에 의해 부득이한 경우
6. 기타 합리적인 이유가 있는 경우로서 “회사”가 필요하다고 인정하는 경우

② “회사”가 전 항에 의하여 “서비스” 제공을 중지하거나 제한하는 경우 “회사”는 이를 “이용자”에게 알려야 합니다. 다만, “회사”의 귀책사유 없이 “이용자”에게 통지할 수 없는 경우에는 예외로 합니다.

③ “회사”의 귀책사유로 “서비스” 제공이 중지되거나 그 이용에 장애가 발생하는 경우에는 “회사”의 규정에 따라 이용이 중지되거나 장애가 발생한 시간만큼 이용 기간을 연장합니다. 다만, 천재지변 또는 이에 준하는 불가항력으로 인하여 “서비스” 제공이 중지되거나 그 이용에 장애가 발생하는 경우에는 예외로 합니다.

④ “회사”의 귀책사유로 “콘텐츠” 자체에 하자가 있는 경우에는 “회사”의 규정에 따라 그 하자가 복구되지 못한 기간만큼 이용 기간을 연장합니다. 다만, 완전한 “콘텐츠”를 다시 제공하는 것이 불가능한 경우에는 “회사”의 규정에 따라 다른 방법으로 그에 대한 보상을 실시할 수 있습니다.

⑤ 별도의 저장장치에 “콘텐츠” 를 저장하여 판매한 상품의 “콘텐츠” 수리보증은 결제일로부터 1년 이내 무상처리 되며 이후 소정의 수리비용이 발생합니다. 다만, 비공식 판매처에서 구매로 인해 결제일이 확인 되지 않는 경우 수리보증이 제한 될 수 있습니다.

⑥ 전 항의 저장장치를 구동하기 위한 전자기기 등에 대한 수리보증은 해당 제조사의 기준을 따릅니다.


제 10조 [유료서비스 면책조항]

① “회사”는 천재지변 또는 이에 준하는 불가항력으로 인하여 “서비스”를 제공할 수 없는 경우에는 “서비스” 제공에 관한 책임이 면제됩니다.

② “회사”는 “회원”의 귀책사유로 인한 “서비스” 이용의 장애에 대하여 책임을 지지 않습니다.

③ “회사”는 “이용자”가 “웹사이트”에 게재한 사실의 신뢰도, 정보나 자료의 정확성 등 내용에 관하여는 책임을 지지 않습니다.

④ “회사”는 “서비스” 이용과 관련하여 “이용자”에게 발생한 손해 가운데 “이용자”의 고의, 과실에 의한 손해에 대하여 책임을 지지 않습니다.


제 11조 [운동 영상 서비스 면책조항]

시크릿다이어트 운동 영상에서 제공되는 모든 정보는 의료 목적이 아니며, 엔터테인먼트와 교육적인 목적으로만 제공됩니다. 또한 특정한 개인에게 의료 혹은 전문적인 서비스를 제공하지 않습니다. 그러므로 임산부나 심혈관 및 관절 질환 등을 포함한 기타 개인의 질환이 있는 분들은 이용을 삼가해 주시고, 운동 중 몸에 이상이 있다고 판단될 경우 즉시 운동을 중단하고, 의사의 진단을 받아야합니다. 또한 음주 후 본 영상 이용을 절대 금지하며, 이를 무시하여 발생하는 상황에 대해서는 당사가 책임을 지지 않습니다.


[부칙]
유료서비스 이용약관에 규정된 사항 이외의 사항에 대하여는 “시크릿다이어트 공식사이트” 의 이용약관규정에 따르도록 합니다.`;

const privacy = `개인정보취급방침
 
 
(주)에이팀바디사이언스(이하 ‘회사’라 한다)는 개인정보 보호법 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리지침을 수립·공개합니다.
 
 
제 1조(개인정보의 처리목적)
 
회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
 
1. 홈페이지 회원 가입 및 관리
회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만 14세 미만 아동의 개인정보 처리시 법정대리인의 동의여부 확인, 각종 고지·통지, 고충처리 등을 목적으로 개인정보를 처리합니다.
 
2. 재화 또는 서비스 제공
물품배송, 서비스 제공, 계약서·청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금결제·정산, 채권추심 등을 목적으로 개인정보를 처리합니다.
 
3. 고충처리
민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 등의 목적으로 개인정보를 처리합니다.
 
 
제 2조(개인정보의 처리 및 보유기간)
 
① 회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.
 
② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
 
1. 홈페이지 회원 가입 및 관리 : 사업자/단체 홈페이지 탈퇴시까지
다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료시까지
 
1) 관계 법령 위반에 따른 수사·조사 등이 진행중인 경우에는 해당 수사·조사 종료시까지
 
2) 홈페이지 이용에 따른 채권·채무관계 잔존시에는 해당 채권·채무관계 정산시까지
 
2. 재화 또는 서비스 제공 : 재화·서비스 공급완료 및 요금결제·정산 완료시까지
다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료시까지
 
1) 「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른 표시·광고, 계약내용 및 이행 등 거래에 관한 기록
- 표시·광고에 관한 기록 : 6월
- 계약 또는 청약철회, 대금결제, 재화 등의 공급기록 : 5년
- 소비자 불만 또는 분쟁처리에 관한 기록 : 3년
 
2) 「통신비밀보호법」제41조에 따른 통신사실확인자료 보관
- 가입자 전기통신일시, 개시·종료시간, 상대방 가입자번호, 사용도수, 발신기지국 위치추적자료 : 1년
- 컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료 : 3개월
 
 
제 3조(개인정보의 제3자 제공)
 
① 회사는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
 
 
제 4조(개인정보처리의 위탁)
 
① 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
 
1. A/S 센터 운영
- 위탁받는 자 (수탁자) : 블루케어
- 위탁하는 업무의 내용 : 고객 대상 제품 (시크릿9 스마트체지방계) A/S 제공
 
② 회사는 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고,수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
 
③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
 
 
제 5조(정보주체의 권리·의무 및 행사방법)
 
① 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
 
1. 개인정보 열람요구
2. 오류 등이 있을 경우 정정 요구
3. 삭제요구
4. 처리정지 요구
 
② 제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체없이 조치하겠습니다.
 
③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
 
④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
 
⑤ 정보주체는 개인정보 보호법 등 관계법령을 위반하여 회사가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다.
 
 
제 6조(처리하는 개인정보 항목)
 
회사는 다음의 개인정보 항목을 처리하고 있습니다.
 
1. 홈페이지 회원 가입 및 관리
- 필수항목 : 성명, 생년월일, 아이디, 비밀번호, 주소, 전화번호, 성별, 이메일주소, 아이핀번호
- 선택항목 : 결혼여부, 관심분야
 
2. 재화 또는 서비스 제공
- 필수항목 : 성명, 생년월일, 아이디, 비밀번호, 주소, 전화번호, 이메일주소, 아이핀번호, 신용카드번호, 은행계좌정보 등 결제정보
- 선택항목 : 관심분야, 과거 구매내역
 
3. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.
- IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량 이용기록 등
 
 
제 7조(개인정보의 파기)
 
① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.
 
② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
 
③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
 
1. 파기절차
회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
 
2. 파기방법
회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 로우레밸포멧(Low Level Format) 등의 방법을 이용하여 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
 
 
제 8조(개인정보의 안전성 확보조치)
 
회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
 
1. 관리적 조치 : 내부관리계획 수립·시행, 정기적 직원 교육 등
 
2. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치
 
3. 물리적 조치 : 전산실, 자료보관실 등의 접근통제
 
 
제 9조(개인정보 보호책임자)
 
① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
 
▶ 개인정보 보호책임자
성명 : 테드
직책 : 대표 이사
연락처 : 휴대폰/010-2286-7974, 이메일/osyana@naver.com, 팩스/0505-300-5653
※ 개인정보 보호 담당부서로 연결됩니다.
 
▶ 개인정보 보호 담당부서
부서명 : 스포츠마케팅팀
담당자 : 김태일
연락처 : 휴대폰/010-6417-0477, 이메일/zium303@naver.com, 팩스/0505-300-5653
 
② 정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.
 
 
제 10조(개인정보 열람청구)
 
정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
 
▶ 개인정보 열람청구 접수·처리 부서
부서명 : 스포츠마케팅팀
담당자 : 김태일
연락처 : 휴대폰/010-6417-0477, 이메일/zium303@naver.com, 팩스/0505-300-5653
 
제 11조(권익침해 구제방법) 정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.
 
 
 
< 아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다 >
 
▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)
- 소관업무 : 개인정보 침해사실 신고, 상담 신청
- 홈페이지 : privacy.kisa.or.kr
- 전화 : (국번없이) 118
- 주소 : (138-950) 서울시 송파구 중대로 135 한국인터넷진흥원 개인정보침해신고센터
 
▶ 개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)
- 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
- 홈페이지 : privacy.kisa.or.kr
- 전화 : (국번없이) 118
- 주소 : (138-950) 서울시 송파구 중대로 135 한국인터넷진흥원 개인정보침해신고센터
 
▶ 대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)
 
▶ 경찰청 사이버테러대응센터 : 1566-0112 (www.netan.go.kr)
 
 
제 12조(영상정보처리기기 설치·운영)
 
① <사업자/단체명> 은(는) 아래와 같이 영상정보처리기기를 설치·운영하고 있습니다.
 
1. 영상정보처리기기 설치근거·목적 : <사업자/단체명> 의 시설안전·화재예방
 
2. 설치 대수, 설치 위치, 촬영 범위 : 사옥 로비·전시실 등 주요시설물에 00대 설치, 촬영범위는 주요시설물의 전
공간을 촬영
 
3. 관리책임자, 담당부서 및 영상정보에 대한 접근권한자 : OOO 팀 OOO 과장
 
4. 영상정보 촬영시간, 보관기간, 보관장소, 처리방법
- 촬영시간 : 24시간 촬영
- 보관기간 : 촬영시부터 30일
- 보관장소 및 처리방법 : 스포츠마케팅팀 영상정보처리기기 통제실에 보관·처리
 
5. 영상정보 확인 방법 및 장소 : 관리책임자에 요구 (스포츠마케팅팀)
 
6. 정보주체의 영상정보 열람 등 요구에 대한 조치 : 개인영상정보 열람·존재확인 청구서로 신청하여야 하며, 정보주체 자신이 촬영된 경우 또는 명백히 정보주체의 생명·신체·재산 이익을 위해 필요한 경우에 한해 열람을 허용함
 
7. 영상정보 보호를 위한 기술적·관리적·물리적 조치 : 내부관리계획 수립, 접근통제 및 접근권한 제한, 영상정보의 안전한 저장·전송기술 적용, 처리기록 보관 및 위·변조 방지조치, 보관시설 마련 및 잠금장치 설치 등
 
 
제 13조(개인정보 처리방침 변경)
 
① 이 개인정보 처리방침은 2017. 03. 29부터 적용됩니다.`;

const TextAreaStyle = css({
  resize: 'none',
  width: '100%',
  height: '50%',
});

export function TosPage() {
  return (
    <div
      className={css({
        width: '100%',
        maxWidth: 1024,
        padding: '1em',
      })}
    >
      <Helmet>
        <title>이용약관</title>
      </Helmet>
      <div
        style={{
          marginBottom: '2em',
        }}
      >
        <h2>이용약관</h2>
        <textarea className={TextAreaStyle} readOnly value={tos} />
      </div>
      <div>
        <h2>개인정보취급방침</h2>
        <textarea className={TextAreaStyle} readOnly value={privacy} />
      </div>
    </div>
  );
}
