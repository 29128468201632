import React from 'react';
import { History } from 'history';
import { Card } from '../../components';

interface Props {
  history: History;
}

export class WeddingDescription extends React.Component<Props> {
  render() {
    return (
      <div style={{ minWidth: 320 }}>
        <Card>
          <h1>웨딩 3주 전</h1>
          <p>웨딩 준비, 정말 바쁘죠?</p>
          <p>
            이것저것 준비 하느라 정신없이 바쁜데 정작 결혼식의 주인공인 나를
            위한 준비는 놓치고 있지 않나요?
          </p>
          <p>
            가장 및나는 시간, 아름다운 드레스핏을 위해 하루 20분만 투자하세요.
            당신의 눈부신 웨딩을 만들어 드립니다.
          </p>
          <img
            style={{ width: '100%' }}
            src="https://s3.ap-northeast-2.amazonaws.com/secret-diet-assets/wedding-curriculum.jpeg"
          />
        </Card>
      </div>
    );
  }
}
