import React from 'react';
import { Spinner } from '@blueprintjs/core';
import { Query } from 'react-apollo';
import { CURRENT_USER_QUERY } from '../users/CurrentUserQuery';
import { Redirect } from 'react-router-dom';
import { BasicProfileView } from '../users/BasicProfileView';
import { SocialPluginsView } from '../users/SocialPluginsView';
import { UserDetailsView } from '../users/UserDetailsView';
import { UserAddressView } from '../users/UserAddressView';
import { MembershipStatusView } from '../users/MembershipStatusView';
import { LogoutButton } from '../users/LogoutButton';
import { PageLayout } from '../layouts/PageLayout';

export default () => {
  return (
    <Query query={CURRENT_USER_QUERY} fetchPolicy="network-only">
      {result => {
        if (result.loading) {
          return <Spinner />;
        }

        if (!result.data || !result.data.user) {
          return <Redirect to="/" />;
        }

        const user = result.data.user as any;
        return (
          <PageLayout>
            <BasicProfileView user={user} />
            <SocialPluginsView user={user} />
            <UserDetailsView user={user} />
            <UserAddressView user={user} />
            <MembershipStatusView user={user} />
            <LogoutButton />
          </PageLayout>
        );
      }}
    </Query>
  );
};
