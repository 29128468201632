import React from 'react';
import { css } from 'emotion';
import styled from 'react-emotion';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import {
  AboutSectionContainer,
  MaxWidthSetter,
  EmphasiedText,
} from './AboutStyles';
import { PTDescriptionItem } from './PTDescriptionItem';

const DESCRIPTIONS = [
  {
    title: '피트니스 1:1 PT',
    src:
      'https://storage.googleapis.com/secretdiet-public-images/about/home-consulting.png',
    items: [
      '점진적 과부하의 원리를 적용한 트레이닝',
      '부위별 분할 훈련을 통한 트레이닝',
      '2주~1개월 마다 측정 장비를 통한 바디체크',
    ],
  },
  {
    title: '시크릿다이어트',
    src:
      'https://storage.googleapis.com/secretdiet-public-images/about/hometraining-app.png',
    items: [
      '점진적 과부하의 원리를 적용한 레벨-업 트레이닝',
      '부위별 분할 훈련을 통한 요일 별 스텝 트레이닝',
      '매일 스마트 체중계를 통한 체성분 체크',
      '매 주 진행하는 운동 자세 교정 및 피드백',
    ],
  },
];

const SecondContainer = styled('article')(
  {
    display: 'grid',
    gridTemplateColumns: '30% 1fr',
    paddingTop: '3em',
    paddingBottom: '3em',
    paddingLeft: '1em',
    paddingRight: '1em',
    color: 'white',
  },
  MaxWidthSetter,
);

export function AboutHometraining() {
  return (
    <AboutSectionContainer>
      <SecondContainer>
        <div
          className={css({
            gridColumn: '1 / span 2',
            justifySelf: 'center',
            fontSize: '1.2em',
            marginBottom: '2em',
          })}
        >
          어떻게 <EmphasiedText>PT 시스템</EmphasiedText>을 집으로 옮겼다고 할
          수 있나요?
        </div>
        <PTDescriptionItem
          src={DESCRIPTIONS[0].src}
          title={DESCRIPTIONS[0].title}
          items={DESCRIPTIONS[0].items}
        />
        <div
          className={css({
            gridColumn: '1 / 2 span',
            fontSize: '3em',
            color: 'rgb(124,219,218)',
            alignSelf: 'center',
            justifySelf: 'center',
            transform: 'scale(3, 0.7)',
          })}
        >
          <Icon
            className={css({
              width: '1em',
              height: '1em',
              '& > svg': {
                width: '1em',
                height: '1em',
              },
            })}
            icon={IconNames.ARROW_DOWN}
          />
        </div>
        <PTDescriptionItem
          src={DESCRIPTIONS[1].src}
          title={DESCRIPTIONS[1].title}
          items={DESCRIPTIONS[1].items}
        />
      </SecondContainer>
    </AboutSectionContainer>
  );
}
