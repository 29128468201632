import { createSelector } from 'reselect';
import { CartItemInput } from '../../gql-types';

type Maybe<T> = T | undefined | null;

const ColorMap = {
  black: '블랙',
  blue: '블루',
  navy: '네이비',
  lime: '라임',
  red: '레드',
  white: '화이트',
  skarlet: '스칼렛',
  green: '그린',
  blank: '블랭크',
};

export function getOptions(item: CartItemInput): string {
  return item.options || '{}';
}

export const getParsedOptions: (item: CartItemInput) => any = createSelector(
  getOptions,
  options => {
    try {
      return JSON.parse(options);
    } catch (ignore) {
      return {};
    }
  },
);

export const getColors: (
  item: CartItemInput,
) => Maybe<string[]> = createSelector(
  getParsedOptions,
  options => (!options.color ? undefined : (options.color || []).split(',')),
);

export const mapToTranslatedColor = (color?: string): string => {
  if (!color) {
    return '';
  }

  const result = ColorMap[color];
  if (!result) {
    return color;
  }

  return result;
};

export const getTranslatedColors: (
  item: CartItemInput,
) => Maybe<string[]> = createSelector(
  getParsedOptions,
  colors => (!colors ? undefined : colors.map(mapToTranslatedColor)),
);

export const getSize: (item: CartItemInput) => Maybe<string> = createSelector(
  getParsedOptions,
  options => options.size,
);
