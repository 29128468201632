import { css } from 'emotion';
import memoize from 'lodash/memoize';

export function GridByRow<T>(numberOfRows: number) {
  return memoize((items: T[]): T[][] => {
    const rows: T[][] = [];
    const current = items.slice(0);
    while (current.length > 0) {
      const columns = current.splice(0, numberOfRows);
      if (columns.length) {
        rows.push(columns);
      }
    }
    return rows;
  });
}

export const GridRowStyle = css({
  display: 'flex',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  padding: 8,
  marginLeft: -8,
  width: 'calc(100% + 16px)',
});

export const GridItem = css({
  padding: 8,
});
