import React from 'react';
import gql from 'graphql-tag';
import { Mutation, MutationFn } from 'react-apollo';
import { BoardType } from '../../gql-types';
import { InputGroup, FormGroup, Button, Intent } from '@blueprintjs/core';

interface Props {
  type: BoardType;
  id?: string;
  initialValue?: string;
  onComplete(): void;
  onCancel(): void;
}

interface State {
  title: string;
}

const SAVE_BOARD_ARTICLE = gql`
  mutation CreateBoardArticle($type: BoardType!, $content: BoardContentInput!) {
    createBoardArticle(type: $type, content: $content) {
      id
      user {
        id
        name
        nickname
      }
      title
      content
      created
    }
  }
`;

export class BoardWriteView extends React.Component<Props, State> {
  editorContainer: HTMLDivElement | null = null;
  editor: tui.Editor | null = null;

  state = {
    title: '',
  };

  async componentDidMount() {
    if (!this.editorContainer) {
      return;
    }

    const tuiEditorModule: any = await import('tui-editor');
    const Editor: typeof tui.Editor = tuiEditorModule.default;

    if (Editor) {
      const initialValue = this.props.initialValue || '';
      this.editor = new Editor({
        el: this.editorContainer,
        height: '200',
        // minHeight: 300,
        initialValue,
        initialEditType: 'wysiwyg',
        previewStyle: 'tab',
        usageStatistics: false,
        hideModeSwitch: true,
        toolbarItems: [
          'heading',
          'bold',
          'italic',
          'strike',
          'divider',
          'hr',
          'quote',
          'divider',
          'ul',
          'ol',
          'indent',
          'outdent',
          'link',
        ],
      });
    }
  }

  componentWillUnmount() {
    if (this.editor) {
      this.editor.remove();
    }
  }

  private handleTitleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    this.setState({ title: e.currentTarget.value });
  };

  private handleSubmit = (update: MutationFn<any, any>) => {
    if (!this.editor) {
      return;
    }

    const content = this.editor.getMarkdown();
    const { type } = this.props;
    const { title } = this.state;

    update({
      variables: {
        type,
        content: {
          title,
          content,
        },
      },
    });
  };

  render() {
    return (
      <div>
        <FormGroup labelFor="title">
          <InputGroup
            name="title"
            placeholder="제목을 입력해 주세요"
            value={this.state.title}
            onChange={this.handleTitleChange}
          />
          <div ref={input => (this.editorContainer = input)} />
        </FormGroup>
        <Button intent={Intent.DANGER} onClick={this.props.onCancel}>
          취소하기
        </Button>
        <Mutation
          mutation={SAVE_BOARD_ARTICLE}
          refetchQueries={['FetchBoardConnection']}
          onCompleted={() => this.props.onComplete()}
        >
          {(update, obj) => {
            return (
              <Button
                disabled={obj.loading}
                loading={obj.loading}
                onClick={() => this.handleSubmit(update)}
              >
                저장하기
              </Button>
            );
          }}
        </Mutation>
      </div>
    );
  }
}
