import React, { useState } from 'react';
import styled from 'react-emotion';
import { RouteComponentProps } from 'react-router';
import { LecturePlayer } from '../lectures/LecturePlayer';
import { LoginRequiredDialog } from '../users/LoginRequiredDialog';

const PlayerContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  backgroundColor: '#eee',
});

interface RouteParams {
  slug: string;
}

interface Props extends RouteComponentProps<RouteParams> {}

function navigateToLogin(props: Props) {
  return () => {
    props.history.push('/login');
  };
}

function GymPage(props: Props) {
  const { slug } = props.match.params;
  const [open, setOpen] = useState(false);
  return (
    <PlayerContainer>
      <LoginRequiredDialog
        open={open}
        onRouteToLogin={navigateToLogin(props)}
        onClose={() => setOpen(false)}
      />
      <LecturePlayer slug={slug} onInvalidAccess={() => setOpen(true)} />
    </PlayerContainer>
  );
}

export default GymPage;
