import React from 'react';
import gql from 'graphql-tag';
import { User } from '../../gql-types';
import { Query } from 'react-apollo';
import { ApolloClient } from 'apollo-client';

export const CURRENT_USER_QUERY = gql`
  query GetCurrentLoginStatus {
    user: currentUser {
      id
      email
      emailVerified
      name
      nickname
      firstName
      lastName
      gender
      role
      status
      created
      updated
      birthday
      subscriptions {
        __typename
        id
        level
        courseName
        status
        startsAt
        expiresAt
      }
      address {
        id
        address1
        address2
        city
        phone
        mobile
        postalCode
        state
        country
      }
      facebookAccount {
        type
        accountId
      }
      kakaoAccount {
        type
        accountId
      }
    }
  }
`;

export interface ICurrentUserProps {
  loading: boolean;
  client: ApolloClient<any>;
  isLoggedIn: boolean;
  user?: User | null;
  reloadUser: Function;
}

export function WithCurrentUser<T extends ICurrentUserProps>(
  Component: React.ComponentType<T>,
) {
  const UserQuery: React.SFC<
    Pick<T, Exclude<keyof T, keyof ICurrentUserProps>>
  > = props => {
    return (
      <Query query={CURRENT_USER_QUERY} fetchPolicy="network-only">
        {obj => {
          const data = obj.data;
          const currentUserProps: ICurrentUserProps = {
            client: obj.client,
            loading: obj.loading as boolean,
            isLoggedIn: Boolean(data.user),
            user: data.user,
            reloadUser: obj.refetch,
          };

          const childProps = Object.assign({}, props, currentUserProps);
          return <Component {...(childProps as T)} />;
        }}
      </Query>
    );
  };

  return UserQuery;
}
