import React from 'react';
import { css } from 'emotion';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { BoardType } from '../../gql-types';
import { Spinner } from '@blueprintjs/core';
import { Card, MarkdownDiv } from '../../components';

interface Props {
  type: BoardType;
  id: string;
}

const FETCH_BOARD_CONTENT = gql`
  query FetchBoardContent($type: BoardType!, $id: String!) {
    item: boardArticle(id: $id, type: $type) {
      id
      user {
        id
        name
        nickname
        firstName
        lastName
      }
      title
      content
      created
    }
  }
`;

interface State {
  edit: boolean;
}

export class BoardContentView extends React.Component<Props, State> {
  state = {
    edit: false,
  };

  render() {
    const { type, id } = this.props;
    return (
      <Query
        query={FETCH_BOARD_CONTENT}
        variables={{
          type,
          id,
        }}
      >
        {obj => {
          if (obj.loading) {
            return <Spinner />;
          }

          if (!obj.data || !obj.data.item) {
            return <div>찾을 수 없습니다</div>;
          }

          const item = obj.data.item;
          return (
            <div>
              <h2>{item.title}</h2>
              <Card>
                <MarkdownDiv
                  className={css({
                    '& img': {
                      width: '100%',
                    },
                  })}
                  content={item.content}
                />
              </Card>
            </div>
          );
        }}
      </Query>
    );
  }
}
