import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import ApolloClient from 'apollo-client';
import SecretdietApp from './SecretdietApp';

interface Props<T> {
  client: ApolloClient<T>;
  children: any;
}

export default function BrowserProvider<T>(props: Props<T>) {
  return (
    <ApolloProvider client={props.client}>
      <BrowserRouter>
        <SecretdietApp />
      </BrowserRouter>
    </ApolloProvider>
  );
}
