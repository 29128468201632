import { Button, InputGroup, Intent } from '@blueprintjs/core';
import { css } from 'emotion';
import { IconNames } from '@blueprintjs/icons';
import React, { useState, useCallback } from 'react';
import { CheckoutItem, CheckoutPrice } from './PaymentService';
import { CheckoutItemLayout } from './CheckoutItemLayout';
import * as CartItemSelectors from './CartItemSelectors';
import { Product } from '../../gql-types';
import ApolloClient from 'apollo-client';

interface SimpleCartItem {
  product: Product;
  quantity: number;
  options?: string | null;
}

interface Props {
  isLoggedIn: boolean;
  client: ApolloClient<any>;
  item: CheckoutItem;
  deleteDisabled?: boolean;
  quantityLocked?: boolean;
  onQuantityUpdate?(obj: {
    productId: string;
    quantity: number;
    options?: string;
  }): void;
  onRemove?(obj: { productId: string; options?: string }): void;
}

const hasDiscount = (item: CheckoutItem) => Boolean(item.discount);

function getName(item: SimpleCartItem): string {
  const cartItem = {
    productId: item.product.id,
    quantity: item.quantity,
    options: item.options,
  };

  const colors = CartItemSelectors.getColors(cartItem);
  const size = CartItemSelectors.getSize(cartItem);

  let name = item.product.name;

  if (colors) {
    name += ` (${colors.join(',')})`;
  }

  if (size) {
    name += ` ${size}`;
  }

  return name;
}

function NullishString(str?: string | null | undefined): string | undefined {
  if (str === undefined) {
    return;
  }

  if (str === null) {
    return;
  }

  return str;
}

export function CheckoutItemView(props: Props) {
  const { item, deleteDisabled } = props;
  const { product } = item;
  const [quantity, setQuantity] = useState(item.quantity);
  const changed = quantity !== item.quantity;
  const handleChange = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      setQuantity(Number(e.currentTarget.value));
    },
    [quantity],
  );

  const handleSubmit = useCallback(() => {
    if (!props.onQuantityUpdate) {
      return;
    }
    props.onQuantityUpdate({
      productId: product.id,
      quantity,
      options: NullishString(item.options),
    });
  }, [quantity, product.id, item.options]);
  const handleRemove = useCallback(() => {
    if (!props.onRemove) {
      return;
    }

    props.onRemove({
      productId: product.id,
      options: NullishString(item.options),
    });
  }, [product.id, item.options]);

  return (
    <CheckoutItemLayout
      infoSection={
        <>
          <h2>
            {getName({
              product,
              quantity: item.quantity,
              options: item.options,
            })}
          </h2>
          <div>{product.description}</div>
        </>
      }
      actionSection={
        <>
          <div
            className={css({
              textDecoration: hasDiscount(item) ? 'line-through' : undefined,
            })}
          >
            {product.price.toLocaleString()} 원
          </div>
          {hasDiscount(item) && (
            <div style={{ marginLeft: '1em' }}>
              {CheckoutPrice(item).actual.toLocaleString()} 원
            </div>
          )}
          <div style={{ marginRight: '0.5em', marginLeft: '0.5em' }}>x</div>
          <InputGroup
            style={{ width: '6em', marginRight: '0.5em' }}
            type="number"
            disabled={props.quantityLocked}
            value={String(quantity)}
            onChange={handleChange}
          />
          {changed && (
            <Button intent={Intent.PRIMARY} onClick={handleSubmit}>
              저장
            </Button>
          )}
          {!deleteDisabled && (
            <Button
              intent={Intent.DANGER}
              icon={IconNames.DELETE}
              onClick={handleRemove}
            />
          )}
        </>
      }
    />
  );
}
