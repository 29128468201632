import React from 'react';
import { Button, MenuItem, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Select, ItemRenderer } from '@blueprintjs/select';

interface IGenericItem {
  id: string;
  label: string;
}

const PaymentMethodSelectBase = Select.ofType<IGenericItem>();

interface Props {
  value?: string;
  onSelect(value: string): void;
}

const items: IGenericItem[] = [
  {
    id: 'card',
    label: '신용카드',
  },
  {
    id: 'trans',
    label: '실시계좌이체',
  },
];

const PaymethodItemView: ItemRenderer<IGenericItem> = (item, props) => {
  const { modifiers } = props;
  return (
    <MenuItem
      key={item.id}
      text={item.label}
      active={modifiers.active}
      disabled={modifiers.disabled}
      onClick={props.handleClick}
    />
  );
};

export class PaymentMethodSelect extends React.Component<Props> {
  private handleItemSelect = (item: IGenericItem) => {
    this.props.onSelect(item.id);
  };

  render() {
    const { value } = this.props;
    const currentValue = items.find(item => item.id === value);
    return (
      <PaymentMethodSelectBase
        items={items}
        filterable={false}
        itemRenderer={PaymethodItemView}
        onItemSelect={this.handleItemSelect}
      >
        <Button
          intent={Intent.NONE}
          text={currentValue ? currentValue.label : '결제방법 선택'}
          rightIcon={IconNames.CARET_DOWN}
        />
      </PaymentMethodSelectBase>
    );
  }
}
