import React from 'react';
import { css, cx } from 'react-emotion';
import Slider from 'react-slick';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

const DotContainerStyle = css({
  position: 'absolute',
  padding: 0,
  width: '100%',
  margin: 0,
  listStyle: 'none',
  textAlign: 'center',
  bottom: '1em',

  '& li': {
    position: 'relative',
    display: 'inline-block',
    width: 20,
    height: 20,
    margin: '0 5px',
    padding: 0,
    cursor: 'pointer',
  },

  '& li button': {
    fontSize: 0,
    lineHeight: 0,
    display: 'block',
    width: 20,
    height: 20,
    padding: 5,
    cursor: 'pointer',
    color: 'transparent',
    border: 0,
    outline: 0,
    background: '0 0',
  },

  '& li.slick-active button:before': {
    fontSize: 48,
    opacity: 0.75,
    color: 'white',
  },
  '& li button:before': {
    fontSize: 36,
    lineHeight: '20px',
    position: 'absolute',
    top: 0,
    left: 0,
    width: 20,
    height: 20,
    content: '"•"',
    textAlign: 'center',
    opacity: 0.25,
    color: 'white',
  },
});

const DarkDotContainerStyle = css(DotContainerStyle, {
  '& li.slick-active button:before': {
    opacity: 0.75,
    color: 'black',
  },
  '& li button:before': {
    color: 'black',
  },
});

const SlideStyle = css({
  '.slick-slide': {
    height: 'auto',
  },
});

interface Props {
  autoplay?: boolean;
  userTransform?: boolean;
  arrow?: boolean;
  dark?: boolean;
  autoplaySpeedInMs?: number;
}

function NextArrow(props: any) {
  const { style, className, onClick } = props;
  return (
    <div
      className={cx(
        className,
        css({
          color: 'white',
          display: 'block',
          fontSize: 30,
          zIndex: 3,
          right: 20,
          '&:before': {
            content: 'none',
          },
          '&:focus,&:hover': {
            color: 'white',
          },
        }),
      )}
      onClick={onClick}
      style={style}
    >
      <Icon icon={IconNames.CHEVRON_RIGHT} iconSize={40} />
    </div>
  );
}

function PrevArrow(props: any) {
  const { style, className, onClick } = props;
  return (
    <div
      className={cx(
        className,
        css({
          color: 'white',
          display: 'block',
          zIndex: 3,
          fontSize: 30,
          left: 20,
          '&:before': {
            content: 'none',
          },
          '&:focus,&:hover': {
            color: 'white',
          },
        }),
      )}
      onClick={onClick}
      style={style}
    >
      <Icon icon={IconNames.CHEVRON_LEFT} iconSize={40} />
    </div>
  );
}

export class MarketingSlider extends React.Component<Props> {
  firstClientX: number = 0;
  firstClientY: number = 0;
  clientX: number = 0;
  clientY: number = 0;

  componentDidMount() {
    // window.addEventListener('touchstart', this.touchStart);
    // window.addEventListener('touchmove', this.preventTouch, { passive: false });
  }

  componentWillUnmount() {
    // window.removeEventListener('touchstart', this.touchStart);
    // window.removeEventListener('touchmove', this.preventTouch, {
    //   passive: false,
    // } as any);
  }

  touchStart = (e: any) => {
    this.firstClientX = e.touches[0].clientX;
    this.firstClientY = e.touches[0].clientY;
  };

  preventTouch = (e: any) => {
    const minValue = 5; // threshold

    this.clientX = e.touches[0].clientX - this.firstClientX;
    this.clientY = e.touches[0].clientY - this.firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(this.clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  render() {
    const props = this.props;
    return (
      <Slider
        dots
        infinite
        useTransform={props.userTransform}
        arrows={props.arrow}
        autoplay={props.autoplay}
        autoplaySpeed={props.autoplaySpeedInMs}
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
        className={SlideStyle}
        speed={500}
        touchThreshold={5}
        slidesToShow={1}
        dotsClass={this.props.dark ? DarkDotContainerStyle : DotContainerStyle}
        slidesToScroll={1}
      >
        {props.children}
      </Slider>
    );
  }
}
