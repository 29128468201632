import React from "react";
import { css, injectGlobal } from "emotion";
injectGlobal`html,
          body,
          div,
          span,
          applet,
          object,
          iframe,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p,
          blockquote,
          pre,
          a,
          abbr,
          acronym,
          address,
          big,
          cite,
          code,
          del,
          dfn,
          em,
          img,
          ins,
          kbd,
          q,
          s,
          samp,
          small,
          strike,
          strong,
          sub,
          sup,
          tt,
          var,
          b,
          u,
          i,
          center,
          dl,
          dt,
          dd,
          ol,
          ul,
          li,
          fieldset,
          form,
          label,
          legend,
          table,
          caption,
          tbody,
          tfoot,
          thead,
          tr,
          th,
          td,
          article,
          aside,
          canvas,
          details,
          embed,
          figure,
          figcaption,
          footer,
          header,
          hgroup,
          menu,
          nav,
          output,
          ruby,
          section,
          summary,
          time,
          mark,
          audio,
          video {
            margin: 0;
            padding: 0;
            border: 0;
            font-size: 100%;
            font: inherit;
            vertical-align: top;
          }
          /* HTML5 display-role reset for older browsers */
          article,
          aside,
          details,
          figcaption,
          figure,
          footer,
          header,
          hgroup,
          menu,
          nav,
          section {
            display: block;
          }
          body {
            line-height: 1;
          }
          ol,
          ul {
            list-style: none;
          }
          blockquote,
          q {
            quotes: none;
          }
          blockquote:before,
          blockquote:after,
          q:before,
          q:after {
            content: "";
            content: none;
          }
          table {
            border-collapse: collapse;
            border-spacing: 0;
          }
          a {
            text-decoration: none;
          }
          @import url("https://fonts.googleapis.com/css?family=Noto+Sans+KR:400,500,700&display=swap");`;

const styleWrap = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  overflow: hidden;

  & .Popup_Inner {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 800px;
    height: 600px;
    background: #69dbda;
    transform: translate(-50%, -50%);
  }
  & .Popup_Inner .Text_Box {
    border: 4px solid #fff;
    width: 750px;
    height: 550px;
    margin: 25px 0 0 25px;
    box-sizing: border-box;
    text-align: center;
    color: #fff;
  }
  & .Popup_Inner .Text_Box h2 {
    padding-top: 150px;
    font-size: 60px;
    font-weight: 500;
    font-family: "Noto Sans KR";
  }
  & .Popup_Inner .Text_Box h3 {
    padding-top: 0;
    font-size: 60px;
    font-weight: bold;
    font-family: "Noto Sans KR";
  }
  & .Popup_Inner .Text_Box h4 {
    line-height: 40px;
    padding-top: 60px;
    font-size: 24px;
    font-family: "Noto Sans KR";
  }
  & .Popup_Inner .Text_Box h5 {
    display: none;
  }
  & .Popup_Inner .Text_Box h6 {
    display: none;
  }
  @media (max-width: 1180px) {
    & .Popup_Inner {
      width: 600px;
      height: 400px;
    }
    & .Popup_Inner .Text_Box {
      width: 550px;
      height: 350px;
      margin: 25px 0 0 25px;
    }
    & .Popup_Inner .Text_Box h2 {
      padding-top: 100px;
      font-size: 45px;
    }
    & .Popup_Inner .Text_Box h3 {
      font-size: 45px;
    }
    & .Popup_Inner .Text_Box h4 {
      line-height: 30px;
      padding-top: 45px;
      font-size: 18px;
    }
  }
  @media (max-width: 758px) {
    & .Popup_Inner {
      width: 400px;
      height: 300px;
    }
    & .Popup_Inner .Text_Box {
      width: 350px;
      height: 250px;
      margin: 25px 0 0 25px;
    }
    & .Popup_Inner .Text_Box h2 {
      padding-top: 75px;
      font-size: 30px;
    }
    & .Popup_Inner .Text_Box h3 {
      font-size: 30px;
    }
    & .Popup_Inner .Text_Box h4 {
      line-height: 20px;
      padding-top: 30px;
      font-size: 12px;
    }
  }
  @media (max-width: 400px) {
    & .Popup_Inner {
      width: 280px;
      height: 280px;
    }
    & .Popup_Inner .Text_Box {
      width: 240px;
      height: 240px;
      margin: 20px 0 0 20px;
    }
    & .Popup_Inner .Text_Box h2 {
      padding-top: 40px;
      font-size: 20px;
    }
    & .Popup_Inner .Text_Box h3 {
      font-size: 20px;
    }
    & .Popup_Inner .Text_Box h4 {
      display: none;
    }
    & .Popup_Inner .Text_Box h5 {
      line-height: 20px;
      display: block;
      padding-top: 30px;
      font-size: 12px;
    }
    & .Popup_Inner .Text_Box h6 {
      padding-top: 10px;
      display: block;
      font-size: 12px;
    }
  }
`;

export default function UnderConstruction() {
  return (
    <div className={css({
      width: '100%',
      height: '100%',
      backgroundImage: 'url(https://storage.googleapis.com/secretdiet-public-images/carousel_01.jpg)',
    })}>
      {/* <div
      className={css({
        width: '100%',
        height: '100%',
        backgroundImage: 'url(https://storage.googleapis.com/secretdiet-public-images/carousel_01.jpg)',
      })}
    >        
      </div> */}
      <div className={styleWrap}>
        <div className="Popup_Inner">
          <div className="Text_Box">
            <h2>SECRETMALL 홈페이지</h2>
            <h3>RENEWAL OPEN</h3>
            <h4>
              4월에 여러분을 놀라게 할 플랫폼으로 다시 출시할 것입니다.
              <br />
              커밍 순!
            </h4>
            <h5>
              4월에 여러분을 놀라게 할 플랫폼으로
              <br />
              다시 출시할 것입니다.
            </h5>
            <h6>커밍 순!</h6>
          </div>
        </div>
      </div>
    </div>
  );
}
