import React from 'react';
import { AnchorButton, IButtonProps, Intent } from '@blueprintjs/core';

export function LogoutButton(props: IButtonProps) {
  return (
    <AnchorButton href="/auth/logout" {...props} intent={Intent.DANGER}>
      로그아웃
    </AnchorButton>
  );
}
