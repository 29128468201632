import gql from 'graphql-tag';
import React from 'react';
import { Query } from 'react-apollo';
import { getNodes } from '../../secret-fns';
import { ProductGroup, ProductGroupConnection } from '../../gql-types';

export const GET_PRODUCT_GROUPS = gql`
  query GetProductGroups($page: Int, $limit: Int, $shop: String) {
    items: productGroups(page: $page, limit: $limit, shop: $shop) {
      edges {
        node {
          id
          name
          slug
          productType
          description
          currencyCode
          price
          imageUrl
          videoUrl
          products {
            id
            name
            productType
            price
          }
        }
      }
    }
  }
`;

export interface ExternalProps {
  page?: number;
  limit?: number;
  shop?: string;
}

export interface ProductGroupListQueryProps {
  loading?: boolean;
  items: ProductGroup[];
  onPageChange(page: number, limit: number): void;
}

interface State {
  page?: number;
  limit?: number;
  shop?: string;
}

export function ProductGroupListQuery<T extends ProductGroupListQueryProps>(
  Component: React.ComponentType<T>,
) {
  class ProductGroupListQueryComponent extends React.Component<
    ExternalProps & Pick<T, Exclude<keyof T, keyof ProductGroupListQueryProps>>,
    State
  > {
    state: State = {
      page: this.props.page,
      limit: this.props.limit,
      shop: this.props.shop,
    };

    private handlePageChange = (page?: number, limit?: number) => {
      this.setState({
        page,
        limit,
      });
    };

    render() {
      const { page, limit, shop } = this.state;
      return (
        <Query
          query={GET_PRODUCT_GROUPS}
          variables={{
            page,
            limit,
            shop,
          }}
        >
          {result => {
            const connection: ProductGroupConnection =
              (result.data && result.data.items) || [];
            const items = getNodes(connection);

            const props = {
              ...this.props,
              loading: result.loading,
              items,
              onPageChange: this.handlePageChange,
            };
            return <Component {...(props as any)} />;
          }}
        </Query>
      );
    }
  }

  return ProductGroupListQueryComponent;
}
