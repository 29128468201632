import React, { SVGAttributes } from 'react';

export const OnlineTransfer = (props: SVGAttributes<SVGElement>) => (
  <svg viewBox="0 0 69 69" {...props}>
    <path fill="none" d="M-1-1h71v71H-1z" />
    <g>
      <g fillRule="nonzero">
        <path d="M61.761 67.501H7.239C3.246 67.501 0 64.153 0 60.04V27.346c0-2.426 1.152-4.71 3.081-6.106l6.156-4.453a1.502 1.502 0 0 1 2.094.329c.489.665.339 1.6-.33 2.083L4.845 23.65C3.69 24.49 3 25.87 3 27.346V60.04c0 2.468 1.902 4.477 4.239 4.477h54.522c2.337 0 4.239-2.009 4.239-4.477V27.346c0-1.477-.69-2.856-1.845-3.692l-6.093-4.408a1.482 1.482 0 0 1-.33-2.083 1.503 1.503 0 0 1 2.094-.328l6.093 4.408C67.848 22.633 69 24.917 69 27.346V60.04c0 4.113-3.249 7.461-7.239 7.461zM21.518 10.501c-.47 0-.931-.22-1.228-.638a1.573 1.573 0 0 1 .336-2.167l4.22-3.136C30.339.48 37.712.48 43.204 4.56l4.171 3.1c.68.501.829 1.471.334 2.162a1.5 1.5 0 0 1-2.118.341L41.42 7.064c-4.424-3.285-10.365-3.285-14.788 0l-4.22 3.14c-.27.198-.583.297-.893.297z" />
        <path d="M33.503 46.501a15.3 15.3 0 0 1-8.421-2.524L5.669 31.212a1.466 1.466 0 0 1-.417-2.05 1.51 1.51 0 0 1 2.079-.412l19.413 12.764a12.286 12.286 0 0 0 13.512 0L59.67 28.75a1.508 1.508 0 0 1 2.08.411c.458.681.272 1.599-.418 2.051L41.918 43.977a15.29 15.29 0 0 1-8.415 2.524z" />
        <path d="M58.5 33.501c-.828 0-1.5-.685-1.5-1.53V10.56H12v21.412c0 .844-.672 1.529-1.5 1.529S9 32.816 9 31.971V9.032c0-.845.672-1.53 1.5-1.53h48c.828 0 1.5.685 1.5 1.53v22.94c0 .845-.672 1.53-1.5 1.53z" />
        <path d="M33.497 31.501c-4.035 0-7.182-1.734-7.485-4.122a1.502 1.502 0 0 1 1.302-1.677c.82-.09 1.572.48 1.677 1.302.054.435 1.701 1.497 4.506 1.497 3.057 0 4.503-1.203 4.503-1.5 0-.009-.051-.942-4.38-1.476l-.318-.042C31.106 25.216 26 24.592 26 21.001c0-2.565 3.225-4.5 7.503-4.5 4.035 0 7.182 1.734 7.485 4.122a1.502 1.502 0 0 1-1.302 1.677c-.831.087-1.572-.48-1.677-1.302-.054-.435-1.701-1.497-4.506-1.497-2.907 0-4.503 1.125-4.503 1.5 0 .009.051.942 4.392 1.473l.315.039c2.196.273 7.293.897 7.293 4.488 0 2.565-3.225 4.5-7.503 4.5z" />
        <path d="M34.5 34.501a1.5 1.5 0 0 1-1.5-1.5v-18a1.5 1.5 0 0 1 3 0v18a1.5 1.5 0 0 1-1.5 1.5zM46.474 40.501H20.526c-.842 0-1.526-.672-1.526-1.5s.684-1.5 1.526-1.5h25.948c.842 0 1.526.672 1.526 1.5s-.684 1.5-1.526 1.5zM52.5 16.501h-6a1.5 1.5 0 0 1 0-3h6a1.5 1.5 0 0 1 0 3zM22.5 34.501h-6a1.5 1.5 0 0 1 0-3h6a1.5 1.5 0 0 1 0 3z" />
      </g>
    </g>
  </svg>
);
