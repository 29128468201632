import React from 'react';
import { BoardLayout } from './BoardLayout';
import { History } from 'history';
import { ReviewsMenu } from './ReviewMenu';
import Helmet from 'react-helmet';

interface Props {
  type: 'beforeAndAfter' | 'reviews';
  history: History;
  children: any;
}

function getTitle(type: 'beforeAndAfter' | 'reviews') {
  switch (type) {
    case 'reviews':
      return '시크릿 후기';
    case 'beforeAndAfter':
      return '비포앤애프터';
  }
}

export function ReviewSectionLayout(props: Props) {
  const { type, history } = props;
  const title = getTitle(type);
  return (
    <BoardLayout title={title}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ReviewsMenu type={type} history={history} />
      {props.children}
    </BoardLayout>
  );
}
