import React from 'react';
import { History } from 'history';
import { Card } from '../../components';
import { DietbibleCard } from './DietbibleCard';
import { EpochCard } from './EpochCard';
import { VideoContainer } from './VideoContainer';

interface Props {
  history: History;
}

export function YoyoDescription(props: Props) {
  return (
    <div style={{ minWidth: 320 }}>
      <Card>
        <img
          style={{
            width: 200,
            height: 120,
          }}
          src="https://s3.ap-northeast-2.amazonaws.com/secret-diet-assets/yoyo-x-logo.jpeg"
        />
        <VideoContainer controls>
          <source
            src="https://player.vimeo.com/external/247791012.hd.mp4?s=69e0986f023e734e8d4df786c8dac339303ada49&profile_id=174"
            type="video/mp4"
          />
        </VideoContainer>
        <p
          style={{
            marginTop: '1em',
          }}
        >
          근력운동 위주로 구성된 프로그램입니다.
        </p>
        <p>
          다이어트가 끝난 뒤 유지기와 적응기에 보다 효과적으로 운동하며 요요를
          예방하고 더욱 탄탄한 몸매를 완성할 수 있어요
        </p>
        <p>
          다이어트 후 요요 방지 목적 외에도 마른비만, 근육량이 부족한 분에게
          추천합니다
        </p>
        <img
          style={{ width: '100%' }}
          src="https://s3.ap-northeast-2.amazonaws.com/secret-diet-assets/yoyo-x-curriculum.jpg"
        />
      </Card>
    </div>
  );
}
