import { css } from 'emotion';

export const ButtonResetStyle = css({
  outline: 0,
  ':focus': {
    outline: 0,
  },
});

export const InteractiveStyle = css({
  ':hover': {
    transform: 'translateY(-1px)',
  },
  ':active': {
    transform: 'translateY(1px)',
  },
});
