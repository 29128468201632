import { css } from 'emotion';

export const CardStyles = {
  container: css({
    borderRadius: 4,
    overflow: 'hidden',
  }),
  shadow: css({
    boxShadow: `0px 1px 3px 0px rgba(0, 0, 0, 0.2), 
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 
      0px 2px 1px -1px rgba(0, 0, 0, 0.12)`,
  }),
  mediaContainer: css({
    height: 0,
    paddingTop: '56.25%',
  }),
  media: css({
    display: 'block',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  }),
  content: css({
    paddingTop: '1em',
    paddingBottom: '1em',
    paddingLeft: '1em',
    paddingRight: '1em',
    '@media (min-width: 600px)': {
      paddingLeft: '1.5em',
      paddingRight: '1.5em',
    },
  }),
  action: css({
    display: 'flex',
    padding: '8px 4px',
    alignItems: 'center',
    boxSizing: 'border-box',
    '@media (min-width: 600px)': {
      padding: '8px 12px',
    },
  }),
};
