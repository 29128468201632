import React from 'react';
import styled from 'react-emotion';
import { SectionImage } from './SectionImage';
import { AboutSecretdiet } from './AboutSecretdiet';
import { AboutHometraining } from './AboutHometraining';
import { AboutStartnow } from './AboutStartnow';
import { VariableFontSize } from '../home/HomeStyles';

interface Props {
  onStartNow(): void;
}

const PageContainer = styled('section')(
  {
    position: 'relative',
    width: '100%',
    backgroundColor: '#eee',
    minWidth: 360,
    '& > section:nth-child(even)': {
      backgroundColor: 'white',
    },
    '& > section:nth-child(odd)': {
      backgroundColor: 'rgb(35,35,35)',
    },
  },
  VariableFontSize,
);

export function WhatisSecretdiet(props: Props) {
  return (
    <PageContainer>
      <SectionImage src="https://storage.googleapis.com/secretdiet-public-images/about/bg_visual9.jpg" />
      <AboutSecretdiet />
      <AboutHometraining />
      <AboutStartnow onStartNow={props.onStartNow} />
    </PageContainer>
  );
}
