import styled from 'react-emotion';
import { theme, lightTextColors, darkTextColors } from '../Theme';

interface ExtraProps {
  dark?: boolean;
  size?: 'small' | 'large';
}

const PlainButton = styled('button')(
  {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    outline: 0,
    padding: '4px 16px',
    textDecoration: 'none',
    fontSize: 16,
    transition: 'all .3s',
    ':hover': {
      transform: 'translateY(-1px)',
      color: theme.primaryColor,
    },
    ':active': {
      transform: 'translateY(1px)',
    },
    ':focus': {
      outline: 0,
    },
  },
  (props: ExtraProps) => {
    return {
      color: !props.dark ? lightTextColors.primary : darkTextColors.primary,
      fontSize: props.size === 'large' ? 16 : 12,
    };
  },
);

export default PlainButton;
