import { ApolloClient } from 'apollo-client';
import React from 'react';
import { User } from '../../gql-types';
import { CheckoutView } from '../cart/CheckoutView';
import { CheckoutItem } from '../cart/PaymentService';

interface Props {
  user: User;
  items: CheckoutItem[];
  client: ApolloClient<any>;
  onFail(err: any): void;
  onSuccess(): void;
}

export class PricingCheckoutStage extends React.Component<Props> {
  render() {
    return (
      <CheckoutView
        user={this.props.user}
        quantityLocked
        deleteDisabled
        invoiceName="시크릿다이어트 구독"
        items={this.props.items}
        client={this.props.client}
        onFail={this.props.onFail}
        onSuccess={this.props.onSuccess}
      />
    );
  }
}
