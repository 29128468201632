export type Maybe<T> = T | null;

export interface CourseOrdering {
  sort: CourseSort;

  direction: Direction;
}

export interface LectureOrdering {
  sort: LectureSort;

  direction: Direction;
}

export interface ShoppingCartQueryInput {
  productId: string;

  quantity: number;

  options?: Maybe<string>;
}

export interface ProductOrdering {
  sort: ProductSort;

  direction: Direction;
}

export interface CartItemInput {
  productId: string;

  quantity: number;

  discountId?: Maybe<string>;

  options?: Maybe<string>;
}

export interface AddressInput {
  address1: string;

  address2?: Maybe<string>;

  city: string;

  phone?: Maybe<string>;

  mobile: string;

  postalCode: string;

  state: string;

  country: string;
}

export interface BoardContentOrdering {
  sort: BoardContentSort;

  direction: Direction;
}

export interface UserInput {
  email: string;

  password: string;

  passwordConfirm: string;

  nickname: string;

  name: string;

  referral?: Maybe<string>;
}

export interface SocialSignupInput {
  accessToken: string;

  provider: string;

  email: string;

  referral: string;

  nickname: string;

  name: string;
}

export interface VideoViewEvent {
  lectureId: string;

  currentTimeInSec: number;
  
  updateTime: number;
  
  playDay: string;
  
  logIndex: number;
  
  slug: string;
}

export interface BoardContentInput {
  title?: Maybe<string>;

  content: string;
}

export interface CreateUserDeviceInput {
  userId?: Maybe<string>;

  deviceId: string;

  type: UserDeviceType;
}

export interface ProductInput {
  id?: Maybe<string>;

  currencyCode: CurrencyCode;

  index: number;

  description: string;

  finite: boolean;

  imageUrl?: Maybe<string>;

  name: string;

  brandName?: Maybe<string>;

  price: number;

  productType: ProductType;

  quantity: number;

  status: ProductStatus;

  level?: Maybe<SubscriptionLevel>;

  durationInMonth?: Maybe<number>;

  thumbnailUrl?: Maybe<string>;

  videoUrl?: Maybe<string>;

  options?: Maybe<string>;

  details?: Maybe<string>;
}

export interface SocialUserInput {
  type: string;

  accountId: string;

  email: string;

  nickname: string;

  name: string;
}

export enum UserRole {
  admin = 'admin',
  trainer = 'trainer',
  member = 'member',
  guest = 'guest',
}

export enum UserStatus {
  pending = 'pending',
  active = 'active',
}

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  UNKNOWN = 'UNKNOWN',
}

export enum SubscriptionLevel {
  none = 'none',
  course = 'course',
  basic = 'basic',
  advanced = 'advanced',
}

export enum SubscriptionStatus {
  active = 'active',
  halted = 'halted',
  canceled = 'canceled',
}

export enum BodyArea {
  chest = 'chest',
  arm = 'arm',
  hip = 'hip',
  whole = 'whole',
  leg = 'leg',
  abds = 'abds',
  back = 'back',
  shoulder = 'shoulder',
}

export enum CourseSort {
  created = 'created',
  name = 'name',
}

export enum Direction {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum LectureSort {
  number = 'number',
  created = 'created',
  modified = 'modified',
  duration = 'duration',
}

export enum CurrencyCode {
  KRW = 'KRW',
  USD = 'USD',
  EUR = 'EUR',
  JPY = 'JPY',
}

export enum ProductType {
  physical = 'physical',
  service = 'service',
  subscription = 'subscription',
}

export enum ProductStatus {
  active = 'active',
  inactive = 'inactive',
}

export enum ProductSort {
  updated = 'updated',
  created = 'created',
  name = 'name',
}

export enum DiscountType {
  fixed = 'fixed',
  percent = 'percent',
}

export enum CouponStatus {
  active = 'active',
  inactive = 'inactive',
}

export enum BoardType {
  secretpost = 'secretpost',
  news = 'news',
  beforeAndAfter = 'beforeAndAfter',
  announcements = 'announcements',
  events = 'events',
}

export enum BoardContentSort {
  created = 'created',
}

export enum UserDeviceType {
  browser = 'browser',
  phone = 'phone',
  tablet = 'tablet',
}

export enum DeliveryStatus {
  pending = 'pending',
  shipped = 'shipped',
  delivering = 'delivering',
  complete = 'complete',
}

export enum InvoiceStatus {
  pending = 'pending',
  complete = 'complete',
  canceled = 'canceled',
  failed = 'failed',
}

export enum SubscriptionAction {
  activate = 'activate',
  upgrade = 'upgrade',
  purchase = 'purchase',
  add = 'add',
  halt = 'halt',
  cancel = 'cancel',
}

export enum DurationUnit {
  day = 'day',
  month = 'month',
  hour = 'hour',
  year = 'year',
}

export type Datetime = any;

export type Upload = any;

// ====================================================
// Scalars
// ====================================================

// ====================================================
// Interfaces
// ====================================================

export interface IAddress {
  address1: string;

  address2?: Maybe<string>;

  city?: Maybe<string>;

  phone?: Maybe<string>;

  mobile?: Maybe<string>;

  postalCode?: Maybe<string>;

  state?: Maybe<string>;

  country?: Maybe<string>;

  created?: Maybe<Datetime>;

  updated?: Maybe<Datetime>;

  deleted?: Maybe<boolean>;
}

// ====================================================
// Types
// ====================================================

export interface Query {
  currentUser?: Maybe<User>;

  currentDailyLog?: Maybe<DailyLog>;
  currentSubscription: UserSubscription[];

  benepiaParams?: Maybe<BenepiaParams>;

  isEmailRegistered?: Maybe<boolean>;

  user?: Maybe<User>;
  /** users(after: String, first: Int, before: String, last: Int): UserConnection */
  course?: Maybe<Course>;

  courses?: Maybe<CourseConnection>;
  /** Query a single Lecture */
  lecture?: Maybe<Lecture>;

  lectures?: Maybe<LectureConnection>;

  latestCompletedLectures: LectureCompleteLog[];

  currentProgress: UserProgress;

  shoppingCart?: Maybe<ShoppingCart>;

  latestShoppingCart: ShoppingCartItem[];

  countCartItems: number;

  productGroup?: Maybe<ProductGroup>;

  productGroups?: Maybe<ProductGroupConnection>;
  /** subscriptions(after: String, first: Int, before: String, last: Int): [Product] */
  product?: Maybe<Product>;

  products?: Maybe<ProductConnection>;

  calculateTotalPrice: PriceDetail;

  findJuso: JusoResponse;

  getSubscriptionPrices: SubscriptionPrices;

  productShowCasesByCountry?: Maybe<(Maybe<ProductShowcase>)[]>;

  showCaseDiscountsByCountry?: Maybe<(Maybe<Discount>)[]>;

  findAllFAQs?: Maybe<(Maybe<FAQItem>)[]>;

  coupon?: Maybe<Coupon>;

  boardArticle?: Maybe<BoardContent>;

  findBoardArticles: BoardContent[];

  boardArticlesConnection: BoardContentConnection;

  findReviews: ReviewContent[];

  reviewConnection: ReviewContentConnection;

  findImpotantReviews: ReviewContent[];

  currentSocialRegistration?: Maybe<SocialProfile>;

  userDeviceList: UserDevice[];

  userDevice?: Maybe<UserDevice>;
}

/** Generic User information */
export interface User {
  id: string;

  email?: Maybe<string>;

  emailVerified: boolean;

  nickname?: Maybe<string>;

  name?: Maybe<string>;

  points: number;

  firstName?: Maybe<string>;

  lastName?: Maybe<string>;

  role: UserRole;

  status: UserStatus;

  created?: Maybe<Datetime>;

  updated?: Maybe<Datetime>;

  deleted?: Maybe<boolean>;

  address?: Maybe<Address>;

  gender: Gender;

  subscriptions: UserSubscription[];
  /** Birthday in format of YYYY-MM-DD I can be also in format of YYYY or MM-DD */
  birthday?: Maybe<string>;

  profileImageUrl?: Maybe<string>;

  thumbnailImageUrl?: Maybe<string>;

  facebookAccount?: Maybe<SocialAccount>;

  kakaoAccount?: Maybe<SocialAccount>;
  /** If allowPasswordAuth is not set, the given user is not allowed to be authenticated Using email - password combination */
  allowPasswordAuth: boolean;
}

/** Address registered by a user */
export interface Address extends IAddress {
  id: string;

  address1: string;

  address2?: Maybe<string>;

  city?: Maybe<string>;

  phone?: Maybe<string>;

  mobile?: Maybe<string>;

  postalCode?: Maybe<string>;

  state?: Maybe<string>;

  country?: Maybe<string>;

  created?: Maybe<Datetime>;

  updated?: Maybe<Datetime>;

  deleted?: Maybe<boolean>;

  userId: string;

  user?: Maybe<User>;
}

export interface DailyLog {
  playDay: string;
  logIndex: number;
}

export interface UserSubscription {
  id: string;

  level: SubscriptionLevel;

  courseId?: Maybe<string>;

  courseName?: Maybe<string>;

  startsAt?: Maybe<Datetime>;

  expiresAt?: Maybe<Datetime>;

  status?: Maybe<SubscriptionStatus>;

  created?: Maybe<Datetime>;

  updated?: Maybe<Datetime>;
}

export interface SocialAccount {
  type: string;

  accountId: string;

  userId: string;
}

export interface BenepiaParams {
  sitecode?: Maybe<string>;

  sitename?: Maybe<string>;

  userid?: Maybe<string>;

  benefit_id?: Maybe<string>;
}

/** Workout program course which contains list of video lectures. Course also contains various meta data about overall course materials. */
export interface Course {
  id: string;

  name: string;

  slug: string;

  logoUrl?: Maybe<string>;

  imageUrl: string;

  description: string;

  difficulty: number;

  created?: Maybe<Datetime>;

  updated?: Maybe<Datetime>;

  deleted: boolean;

  unlockedBy?: Maybe<string>;

  unlocked?: Maybe<boolean>;

  progress: CourseProgress;

  lectures?: Maybe<(Maybe<Lecture>)[]>;

  lectureConnection: CourseLectureConnection;
}

export interface CourseProgress {
  courseId: string;

  completed: boolean;
}

export interface Lecture {
  id: string;

  name?: Maybe<string>;

  slug: string;

  description?: Maybe<string>;

  courseId: string;

  course: Course;

  number: number;

  effectiveAreas: (Maybe<BodyArea>)[];

  duration: number;

  imageUrl?: Maybe<string>;

  thumbnailUrl?: Maybe<string>;

  videoUrl?: Maybe<string>;

  unlocked?: Maybe<boolean>;

  created?: Maybe<Datetime>;

  updated?: Maybe<Datetime>;

  deleted: boolean;

  progress: LectureProgress;

  reviews: LectureReview[];
}

export interface LectureProgress {
  lectureId: string;

  currentTimeInSec: number;

  completed: boolean;
}

export interface LectureReview {
  id: string;

  lectureId: string;

  lecture: Lecture;

  userId: string;

  user: string;

  created?: Maybe<Datetime>;

  updated?: Maybe<Datetime>;

  deleted: boolean;
}

export interface CourseLectureConnection {
  edges?: Maybe<(Maybe<CourseLectureEdge>)[]>;

  pageInfo: PageInfo;

  totalCount?: Maybe<number>;

  lectures?: Maybe<(Maybe<Lecture>)[]>;
}

export interface CourseLectureEdge {
  node?: Maybe<Lecture>;

  cursor: string;
}

export interface PageInfo {
  hasNextPage: boolean;

  hasPreviousPage: boolean;
}

export interface CourseConnection {
  edges?: Maybe<(Maybe<CourseEdge>)[]>;

  pageInfo: PageInfo;

  totalCount?: Maybe<number>;

  courses?: Maybe<(Maybe<Course>)[]>;
}

export interface CourseEdge {
  node?: Maybe<Course>;

  cursor: string;
}

export interface LectureConnection {
  pageInfo: PageInfo;

  totalCount?: Maybe<number>;

  edges?: Maybe<(Maybe<LectureEdge>)[]>;
}

export interface LectureEdge {
  node?: Maybe<Lecture>;

  cursor: string;
}

export interface LectureCompleteLog {
  id: string;

  lectureId: string;

  lecture: Lecture;

  userId: string;

  first: boolean;

  created?: Maybe<Datetime>;

  updated?: Maybe<Datetime>;

  deleted: boolean;
}

export interface UserProgress {
  lectureId: string;

  lecture: Lecture;
}

export interface ShoppingCart {
  id: string;

  userId?: Maybe<string>;

  user?: Maybe<User>;

  items?: Maybe<(Maybe<ShoppingCartItem>)[]>;

  created?: Maybe<Datetime>;

  updated?: Maybe<Datetime>;

  deleted?: Maybe<boolean>;
}

export interface ShoppingCartItem {
  id: string;

  userId?: Maybe<string>;

  cartId: string;

  cart: ShoppingCart;

  productId: string;

  product: Product;

  quantity: number;

  created?: Maybe<Datetime>;

  updated?: Maybe<Datetime>;

  deleted: boolean;

  options?: Maybe<string>;
}

export interface Product {
  id: string;

  currencyCode: CurrencyCode;

  description: string;

  finite: boolean;

  imageUrl?: Maybe<string>;

  name: string;

  brandName?: Maybe<string>;

  price: number;

  discountPrice: number;

  productType: ProductType;

  quantity: number;

  status: ProductStatus;

  level: SubscriptionLevel;

  durationInMonth: number;

  thumbnailUrl?: Maybe<string>;

  videoUrl?: Maybe<string>;

  updated?: Maybe<Datetime>;

  created?: Maybe<Datetime>;

  deleted: boolean;

  productGroupId: string;

  productGroup: ProductGroup;

  index: number;

  default?: Maybe<boolean>;

  options: string;

  details?: Maybe<string>;
}

export interface ProductGroup {
  id: string;

  slug: string;

  brandName?: Maybe<string>;

  productType: ProductType;

  price: number;

  currencyCode: CurrencyCode;

  status: ProductStatus;

  name: string;

  description: string;

  imageUrl?: Maybe<string>;

  videoUrl?: Maybe<string>;

  thumbnailUrl?: Maybe<string>;

  updated?: Maybe<Datetime>;

  created?: Maybe<Datetime>;

  deleted: boolean;

  products?: Maybe<(Maybe<Product>)[]>;

  details?: Maybe<string>;
}

export interface ProductGroupConnection {
  edges?: Maybe<(Maybe<ProductGroupEdge>)[]>;

  totalCount?: Maybe<number>;

  pageInfo: PageInfo;
}

export interface ProductGroupEdge {
  node?: Maybe<ProductGroup>;

  cursor?: Maybe<string>;
}

export interface ProductConnection {
  edges?: Maybe<(Maybe<ProductEdge>)[]>;

  totalCount?: Maybe<number>;

  pageInfo: PageInfo;
}

export interface ProductEdge {
  node?: Maybe<Product>;

  cursor?: Maybe<string>;
}

export interface PriceDetail {
  totalPrice: number;

  originalPrice: number;

  discount: number;

  deliveryFee: number;

  points?: Maybe<number>;
}

export interface JusoResponse {
  jusos: JusoAddress[];

  pageInfo: JusoPageInfo;
}

export interface JusoAddress {
  roadAddr: string;

  roadAddrPart1: string;

  roadAddrPart2?: Maybe<string>;

  zipNo: string;

  siNm: string;
}

export interface JusoPageInfo {
  total: number;

  page: number;

  limit: number;
}

export interface SubscriptionPrices {
  basic: PricePackage;

  advanced: PricePackage;
}

export interface PricePackage {
  month1: number;

  month3: number;

  month6: number;
}

export interface ProductShowcase {
  id: string;

  alias: string;

  productId: string;

  product: Product;

  created?: Maybe<Datetime>;

  updated?: Maybe<Datetime>;

  deleted: boolean;
}

export interface Discount {
  id: string;

  amount: number;

  type: DiscountType;

  filter: string;

  filterJson: string;

  startsAt?: Maybe<Datetime>;

  expiresAt?: Maybe<Datetime>;

  created?: Maybe<Datetime>;

  updated?: Maybe<Datetime>;

  deleted?: Maybe<boolean>;
}

export interface FAQItem {
  id: string;

  title: string;

  content: string;

  category: string;

  created?: Maybe<Datetime>;

  updated?: Maybe<Datetime>;

  deleted: boolean;
}

export interface Coupon {
  id: string;

  value: string;

  discountType?: Maybe<DiscountType>;

  discountAmount?: Maybe<number>;

  status: CouponStatus;

  filter?: Maybe<string>;

  expiresAt?: Maybe<Datetime>;

  amount?: Maybe<number>;

  created?: Maybe<Datetime>;

  updated?: Maybe<Datetime>;

  options?: Maybe<string>;
}

export interface BoardContent {
  id: string;

  userId: string;

  user: SimpleUser;

  title?: Maybe<string>;

  content: string;

  created?: Maybe<Datetime>;

  updated?: Maybe<Datetime>;

  deleted: boolean;
}

export interface SimpleUser {
  id: string;

  email?: Maybe<string>;

  name?: Maybe<string>;

  nickname?: Maybe<string>;

  firstName?: Maybe<string>;

  lastName?: Maybe<string>;

  gender: Gender;
}

export interface BoardContentConnection {
  pageInfo: PageInfo;

  totalCount?: Maybe<number>;

  edges?: Maybe<(Maybe<BoardContentEdge>)[]>;
}

export interface BoardContentEdge {
  node?: Maybe<BoardContent>;

  cursor?: Maybe<string>;
}

export interface ReviewContent {
  id: string;

  userId: string;

  writerName?: Maybe<string>;

  user: SimpleUser;

  point: number;

  content: string;

  created?: Maybe<Datetime>;

  announcement?: Maybe<boolean>;

  updated?: Maybe<Datetime>;

  deleted: boolean;
}

export interface ReviewContentConnection {
  totalCount?: Maybe<number>;

  edges?: Maybe<(Maybe<ReviewContentEdge>)[]>;

  pageInfo: PageInfo;
}

export interface ReviewContentEdge {
  node?: Maybe<ReviewContent>;

  cursor?: Maybe<string>;
}

export interface SocialProfile {
  type: string;

  email?: Maybe<string>;

  accountId: string;

  nickname?: Maybe<string>;

  name?: Maybe<string>;

  birthDay?: Maybe<string>;

  gender?: Maybe<string>;
}

export interface UserDevice {
  id: string;

  userId?: Maybe<string>;

  deviceId: string;

  type: UserDeviceType;

  created?: Maybe<Datetime>;

  updated?: Maybe<Datetime>;

  deleted?: Maybe<boolean>;
}

export interface Mutation {
  register?: Maybe<User>;

  registerWithSocial?: Maybe<User>;

  updateAddress?: Maybe<Address>;

  updateNickname?: Maybe<User>;

  updateEmail?: Maybe<User>;

  verifyNickname?: Maybe<boolean>;

  updateProfile: User;
  /** Returns opaque access_token */
  logout: boolean;

  loginWithCredentials: LoginResponse;

  loginWithSocial?: Maybe<LoginResponse>;

  connectWithSocial?: Maybe<User>;

  logVideoUse?: Maybe<ProgressResponse>;

  forgotPassword?: Maybe<boolean>;

  resetPassword?: Maybe<boolean>;

  sendEmailVerification: boolean;

  addCartItem?: Maybe<ShoppingCart>;

  updateCartItem?: Maybe<ShoppingCart>;

  removeCartItem?: Maybe<ShoppingCart>;

  verifyEmail?: Maybe<boolean>;

  tryCheckout: Invoice;

  markInvoiceAsFailed?: Maybe<boolean>;

  markInvoiceAsSuccess?: Maybe<boolean>;

  uploadOneFile: File;

  createLectureReview: LectureReview;

  createBoardArticle: BoardContent;

  updateBoardArticle: BoardContent;

  saveReview?: Maybe<ReviewContent>;

  removeReview?: Maybe<ReviewContent>;

  removeUserDevice?: Maybe<boolean>;

  registerUserDevice?: Maybe<UserDevice>;
}

export interface LoginResponse {
  authToken: string;

  user: User;
}

export interface ProgressResponse {
  lecture: Lecture;

  course: Course;
}

/** A record for each purchase transaction */
export interface Invoice {
  id: string;

  userId: string;

  user: User;

  iamportId?: Maybe<string>;

  point?: Maybe<number>;

  couponId?: Maybe<string>;

  coupon?: Maybe<Coupon>;

  delivery?: Maybe<Delivery>;

  status: InvoiceStatus;

  currencyCode: CurrencyCode;

  totalPrice: number;

  deliveryFee: number;

  items: (Maybe<InvoiceItem>)[];

  created?: Maybe<Datetime>;

  updated?: Maybe<Datetime>;

  deleted: boolean;
}

export interface Delivery {
  id: string;

  invoiceId: string;

  invoice: Invoice;

  address1: string;

  address2?: Maybe<string>;

  city?: Maybe<string>;

  status?: Maybe<DeliveryStatus>;

  phone?: Maybe<string>;

  mobile?: Maybe<string>;

  postalCode?: Maybe<string>;

  state: string;

  country?: Maybe<string>;
}

export interface InvoiceItem {
  id: string;
  /** Number of items sold */
  quantity: number;

  productId: string;

  product: Product;

  currencyCode: CurrencyCode;

  discountId?: Maybe<string>;

  originalPrice: number;

  price: number;

  created?: Maybe<Datetime>;

  updated?: Maybe<Datetime>;

  deleted: boolean;

  options?: Maybe<string>;
}

export interface File {
  filename: string;

  mimetype: string;

  encoding: string;

  url: string;
}

export interface CouponUsageLog {
  id: string;

  couponId: string;

  coupon?: Maybe<Coupon>;

  userId: string;

  user: User;

  expiresAt?: Maybe<Datetime>;

  created?: Maybe<Datetime>;

  updated?: Maybe<Datetime>;

  deleted: boolean;
}

export interface FacebookAccount {
  id: string;

  created?: Maybe<Datetime>;

  updated?: Maybe<Datetime>;

  deleted: boolean;

  userId: string;

  user: User;
}

export interface KakaoAccount {
  id: string;

  created?: Maybe<Datetime>;

  updated?: Maybe<Datetime>;

  deleted: boolean;

  userId: string;

  user: User;
}

/** SubscriptionRecord is a record of subscription purchase. */
export interface SubscriptionLog {
  id: string;

  userId: string;

  doneBy?: Maybe<string>;

  description?: Maybe<string>;

  action: SubscriptionAction;

  level: SubscriptionLevel;

  duration: number;

  durationUnit: DurationUnit;

  created?: Maybe<Datetime>;

  updated?: Maybe<Datetime>;

  deleted?: Maybe<boolean>;
}

export interface UserDailyFitnessLog {
  playDay: string;

  slug: string;

  logIndex: number;

  userId: string;

  playTime?: Maybe<number>;

  isPC?: Maybe<number>;

  createdAt?: Maybe<Datetime>;

  updatedAt?: Maybe<Datetime>;
}

// ====================================================
// Arguments
// ====================================================

export interface isEmailRegisteredQueryArgs {
  email: string;
}
export interface userQueryArgs {
  id?: Maybe<string>;

  email?: Maybe<string>;
}
export interface courseQueryArgs {
  id?: Maybe<string>;

  slug?: Maybe<string>;
}
export interface coursesQueryArgs {
  userId?: Maybe<string>;

  after?: Maybe<string>;

  first?: Maybe<number>;

  before?: Maybe<string>;

  last?: Maybe<number>;

  orderBy?: Maybe<(Maybe<CourseOrdering>)[]>;
}
export interface lectureQueryArgs {
  id?: Maybe<string>;

  slug?: Maybe<string>;
}
export interface lecturesQueryArgs {
  userId?: Maybe<string>;

  after?: Maybe<string>;

  first?: Maybe<number>;

  before?: Maybe<string>;

  last?: Maybe<number>;

  orderBy?: Maybe<(Maybe<LectureOrdering>)[]>;
}
export interface latestCompletedLecturesQueryArgs {
  startOfDay?: Maybe<Datetime>;
}
export interface shoppingCartQueryArgs {
  items?: Maybe<ShoppingCartQueryInput[]>;
}
export interface productGroupQueryArgs {
  id?: Maybe<string>;

  slug?: Maybe<string>;
}
export interface productGroupsQueryArgs {
  page?: Maybe<number>;

  limit?: Maybe<number>;

  after?: Maybe<string>;

  first?: Maybe<number>;

  before?: Maybe<string>;

  last?: Maybe<number>;
  /** Filter in JSON format field */
  filters?: Maybe<string>;

  orderBy?: Maybe<(Maybe<ProductOrdering>)[]>;

  shop?: Maybe<string>;
}
export interface productQueryArgs {
  id: string;
}
export interface productsQueryArgs {
  after?: Maybe<string>;

  first?: Maybe<number>;

  before?: Maybe<string>;

  last?: Maybe<number>;

  productType?: Maybe<ProductType>;
}
export interface calculateTotalPriceQueryArgs {
  items: CartItemInput[];

  coupon?: Maybe<string>;

  address?: Maybe<AddressInput>;
}
export interface findJusoQueryArgs {
  keyword: string;

  page?: number;
}
export interface productShowCasesByCountryQueryArgs {
  country?: string;
}
export interface showCaseDiscountsByCountryQueryArgs {
  country?: string;
}
export interface couponQueryArgs {
  code: string;
}
export interface boardArticleQueryArgs {
  id: string;

  type: BoardType;
}
export interface findBoardArticlesQueryArgs {
  type: BoardType;

  page: number;

  limit: number;
}
export interface boardArticlesConnectionQueryArgs {
  type?: Maybe<BoardType>;

  after?: Maybe<string>;

  first?: Maybe<number>;

  before?: Maybe<string>;

  last?: Maybe<string>;

  orderBy?: Maybe<(Maybe<BoardContentOrdering>)[]>;
}
export interface findReviewsQueryArgs {
  page: number;

  limit: number;
}
export interface reviewConnectionQueryArgs {
  after?: Maybe<string>;

  first?: Maybe<number>;

  before?: Maybe<string>;

  last?: Maybe<string>;

  orderBy?: Maybe<(Maybe<BoardContentOrdering>)[]>;
}
export interface userDeviceQueryArgs {
  userId?: Maybe<string>;

  type?: Maybe<UserDeviceType>;
}
export interface lectureConnectionCourseArgs {
  after?: Maybe<string>;

  first?: Maybe<number>;

  before?: Maybe<string>;

  last?: Maybe<number>;
}
export interface reviewsLectureArgs {
  page: number;

  limit: number;
}
export interface registerMutationArgs {
  user: UserInput;
}
export interface registerWithSocialMutationArgs {
  data: SocialSignupInput;
}
export interface updateAddressMutationArgs {
  address: AddressInput;
}
export interface updateNicknameMutationArgs {
  nickname: string;
}
export interface updateEmailMutationArgs {
  email: string;
}
export interface verifyNicknameMutationArgs {
  nickname?: Maybe<string>;
}
export interface updateProfileMutationArgs {
  name?: Maybe<string>;

  gender?: Maybe<Gender>;

  birthday?: Maybe<string>;
}
export interface loginWithCredentialsMutationArgs {
  email: string;

  password: string;

  remember?: Maybe<boolean>;
}
export interface loginWithSocialMutationArgs {
  accessToken: string;

  provider: string;

  remember?: Maybe<boolean>;
}
export interface connectWithSocialMutationArgs {
  accessToken: string;

  provider: string;
}
export interface logVideoUseMutationArgs {
  event?: Maybe<VideoViewEvent>;
}
export interface forgotPasswordMutationArgs {
  email: string;
}
export interface resetPasswordMutationArgs {
  code: string;

  password: string;

  passwordConfirm: string;
}
export interface addCartItemMutationArgs {
  productId: string;

  quantity: number;

  options?: Maybe<string>;
}
export interface updateCartItemMutationArgs {
  productId: string;

  quantity: number;

  options?: Maybe<string>;
}
export interface removeCartItemMutationArgs {
  productId: string;

  options?: Maybe<string>;
}
export interface verifyEmailMutationArgs {
  code: string;
}
export interface tryCheckoutMutationArgs {
  paymentService?: Maybe<string>;

  items?: Maybe<CartItemInput[]>;

  points?: Maybe<number>;

  coupon?: Maybe<string>;

  address?: Maybe<AddressInput>;

  clearCart?: Maybe<boolean>;
}
export interface markInvoiceAsFailedMutationArgs {
  invoiceId: string;

  pgId: string;
}
export interface markInvoiceAsSuccessMutationArgs {
  invoiceId: string;

  pgId: string;
}
export interface uploadOneFileMutationArgs {
  file: Upload;
}
export interface createLectureReviewMutationArgs {
  lectureId: string;

  content: string;
}
export interface createBoardArticleMutationArgs {
  content: BoardContentInput;

  type: BoardType;
}
export interface updateBoardArticleMutationArgs {
  id: string;

  content: BoardContentInput;

  type: BoardType;
}
export interface saveReviewMutationArgs {
  id?: Maybe<string>;

  point: number;

  content: string;
}
export interface removeReviewMutationArgs {
  id: string;
}
export interface removeUserDeviceMutationArgs {
  userId?: Maybe<string>;

  deviceId: string;

  type: UserDeviceType;
}
export interface registerUserDeviceMutationArgs {
  device?: Maybe<CreateUserDeviceInput>;
}
