import React from 'react';
import get from 'lodash/get';
import { Card } from '../../components';
import {
  Spinner,
  ControlGroup,
  FormGroup,
  InputGroup,
  Button,
  Intent,
} from '@blueprintjs/core';
import { UserInput } from '../../gql-types';

interface ExtraInfo {
  type: string;
  accountId: string;
  email?: string;
  nickname?: string;
  name?: string;
  birthDay?: string;
  gender?: string;
}

interface Props {
  loading: boolean;
  extraInfo?: ExtraInfo;
  onSubmit: (creds: UserInput) => void;
}

interface State {
  type: string;
  creds: {
    [key: string]: string;
  };
}

export class SignupExtraInfo extends React.Component<Props, State> {
  state = {
    type: get(this.props.extraInfo, 'type', ''),
    creds: {
      email: get(this.props.extraInfo, 'email') || '',
      nickname: get(this.props.extraInfo, 'nickname') || '',
      name: get(this.props.extraInfo, 'name') || '',
      password: '',
      passwordConfirm: '',
      referral: '',
    },
  };

  private handleCredsChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    const { creds } = this.state;
    this.setState({
      creds: {
        ...creds,
        [name]: value,
      },
    });
  };

  private handleCompleted = (data?: { extraInfo?: ExtraInfo }) => {
    const extraInfo = data && data.extraInfo;
    if (!extraInfo) {
      return;
    }

    const { creds } = this.state;
    this.setState({
      type: extraInfo.type,
      creds: {
        ...creds,
        email: extraInfo.email || '',
        name: extraInfo.name || '',
        nickname: extraInfo.nickname || '',
      },
    });
  };

  private handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { creds } = this.state;
    this.props.onSubmit(creds);
  };

  render() {
    const { creds } = this.state;

    if (this.props.loading) {
      return (
        <Card>
          <Spinner />
        </Card>
      );
    }

    const type = this.state.type;
    const label = (type: string) => {
      switch (type) {
        case 'kakao':
          return '카카오';
        case 'facebook':
          return '페이스북';
        default:
          return '소셜';
      }
    };

    return (
      <Card>
        <h2>{label(type)} 회원가입 추가정보 입력</h2>
        <form onSubmit={this.handleSubmit}>
          <ControlGroup fill>
            <FormGroup label="이메일" labelFor="email">
              <InputGroup
                style={{ width: '100%' }}
                id="email"
                name="email"
                placeholder="이메일을 입력하세요"
                type="email"
                value={creds.email}
                onChange={this.handleCredsChange}
              />
            </FormGroup>
          </ControlGroup>
          <ControlGroup fill>
            <FormGroup label="암호" labelFor="password">
              <InputGroup
                style={{ width: '100%' }}
                id="password"
                name="password"
                placeholder="암호"
                type="password"
                value={creds.password}
                onChange={this.handleCredsChange}
              />
            </FormGroup>
          </ControlGroup>
          <FormGroup label="암호확인" labelFor="password">
            <InputGroup
              style={{ width: '100%' }}
              id="passwordConfirm"
              name="passwordConfirm"
              placeholder="암호확인"
              type="password"
              value={creds.passwordConfirm}
              onChange={this.handleCredsChange}
            />
          </FormGroup>
          <FormGroup label="닉네임" labelFor="nickname">
            <InputGroup
              style={{ width: '100%' }}
              id="nickname"
              name="nickname"
              placeholder="유저아이디"
              value={creds.nickname}
              onChange={this.handleCredsChange}
            />
          </FormGroup>
          <FormGroup label="이름" labelFor="name">
            <InputGroup
              style={{ width: '100%' }}
              id="name"
              name="name"
              placeholder="이름"
              value={creds.name}
              onChange={this.handleCredsChange}
            />
          </FormGroup>
          <FormGroup label="가입경로" labelFor="referral">
            <InputGroup
              style={{ width: '100%' }}
              id="referral"
              name="referral"
              placeholder="가입경로 (선택입력)"
              value={creds.referral}
              onChange={this.handleCredsChange}
            />
          </FormGroup>
          <Button id="register" type="submit" intent={Intent.PRIMARY} fill>
            회원가입
          </Button>
        </form>
      </Card>
    );
  }
}
