import React from 'react';
import { css } from 'emotion';
import { RouteComponentProps } from 'react-router';
import { CourseDetailView } from '../courses/CourseDetailView';
import { PageLayout } from '../layouts/PageLayout';

interface CoursePageParams {
  slug: string;
}

interface Props extends RouteComponentProps<CoursePageParams> {}

export function CourseDetailPage(props: Props) {
  const { slug } = props.match.params;
  return (
    <PageLayout textAlign="center">
      <div
        className={css({
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        })}
      >
        <CourseDetailView history={props.history} courseSlug={slug} />
      </div>
    </PageLayout>
  );
}
