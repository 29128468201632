import React from 'react';
import { Route as ReactRouterRoute } from 'react-router';
import { Helmet } from 'react-helmet';

interface Props {
  title?: string;
  exact?: boolean;
  path: string;
  strict?: boolean;
  component: any;
}

function Route(props: Props) {
  const title = props.title ? props.title : '시크릿다이어트';
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ReactRouterRoute
        key={props.path}
        component={props.component}
        exact={props.exact}
        path={props.path}
        strict={props.strict}
      />
    </>
  );
}

export default Route;
