import { Collapse } from '@blueprintjs/core';
import React from 'react';
import { Card, MarkdownDiv } from '../../components';
import styled from 'react-emotion';

interface Item {
  id: string;
  title: string;
  content: string;
}

interface Props {
  item: Item;
}

interface State {
  expanded: boolean;
}

const FAQTitleLink = styled('a')({
  display: 'flex',
  justifyContent: 'space-between',
  cursor: 'pointer',
  color: 'black',
  ':hover': {
    textDecoration: 'none',
  },
  ':active': {
    textDecoration: 'none',
  },
});

export class FAQItemView extends React.Component<Props, State> {
  state = {
    expanded: false,
  };

  private handleToggle = () =>
    this.setState(prevState => ({
      expanded: !prevState.expanded,
    }));

  render() {
    const { item } = this.props;
    const markdown = <MarkdownDiv content={item.content} />;
    return (
      <Card>
        <FAQTitleLink
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer',
            color: 'black',
          }}
          onClick={this.handleToggle}
        >
          <h3>{item.title}</h3>
        </FAQTitleLink>
        <Collapse isOpen={this.state.expanded}>{markdown}</Collapse>
      </Card>
    );
  }
}
