import React from 'react';
import { css } from 'emotion';
import { AboutSectionContainer } from './AboutStyles';
import { OvalButton } from '../../components';
import { VariableFontSize } from '../home/HomeStyles';

interface Props {
  onStartNow(): void;
}

const items = [
  {
    src:
      'https://storage.googleapis.com/secretdiet-public-images/about/logo-hometraining-20min.png',
    message: '하루 20분 홈 트레이닝',
  },

  {
    src:
      'https://storage.googleapis.com/secretdiet-public-images/about/logo-hometraining-consulting.png',
    message: '담당 트레이너 맞춤 컨설팅',
  },
  {
    src:
      'https://storage.googleapis.com/secretdiet-public-images/about/logo-hometraining-secret9.png',
    message: '매일 9가지 체성분 검사',
  },
  {
    src:
      'https://storage.googleapis.com/secretdiet-public-images/about/logo-hometraining-shake.png',
    message: '운동 후 건강한 쉐이크',
  },
];

function SecretdietPoint(props: { src: string; message: string }) {
  return (
    <div
      className={css({
        textAlign: 'center',
        fontSize: '0.7em',
      })}
    >
      <img
        className={css({
          width: '60%',
          marginBottom: '1em',
        })}
        src={props.src}
      />
      <div>{props.message}</div>
    </div>
  );
}

export function AboutStartnow(props: Props) {
  return (
    <AboutSectionContainer className={VariableFontSize}>
      <div
        className={css({
          paddingTop: '7em',
          paddingBottom: '3em',
          paddingLeft: '1em',
          paddingRight: '1em',
        })}
      >
        <div
          className={css({
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gridColumnGap: '1em',
            marginBottom: '2em',
          })}
        >
          {items.map((item, index) => {
            return (
              <SecretdietPoint
                key={index}
                src={item.src}
                message={item.message}
              />
            );
          })}
        </div>
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '2em',
          })}
        >
          <OvalButton fontSize="1.5em" onClick={props.onStartNow}>
            지금 시작하세요
          </OvalButton>
        </div>
        <div
          className={css({
            textAlign: 'right',
            fontSize: '0.5em',
            color: 'rgb(237, 15, 111)',
          })}
        >
          * 담당 트레이너 컨설팅 · 스마트 체중계 · 쉐이크는 별도 신청입니다.
        </div>
      </div>
    </AboutSectionContainer>
  );
}
