import React, { SVGAttributes } from 'react';

export const Transfer = (props: SVGAttributes<SVGElement>) => (
  <svg viewBox="0 0 73 73" width="1em" height="1em" {...props}>
    <g fill="#8C8C8C" fillRule="nonzero">
      <path d="M71.53 24c-.012 0-.036.003-.06 0h-69a1.496 1.496 0 0 1-1.446-1.11 1.5 1.5 0 0 1 .69-1.689l36-21a1.509 1.509 0 0 1 1.563.03l32.781 20.862A1.5 1.5 0 0 1 71.53 24zM8.02 21h58.302L38.437 3.255 8.02 21zM71.47 72h-69a1.5 1.5 0 0 1-1.5-1.5v-9a1.5 1.5 0 0 1 1.5-1.5h69a1.5 1.5 0 0 1 1.5 1.5v9c0 .828-.669 1.5-1.5 1.5zm-67.5-3h66v-6h-66v6zM20.47 57h-9a1.5 1.5 0 0 1-1.5-1.5v-27a1.5 1.5 0 0 1 1.5-1.5h9a1.5 1.5 0 0 1 1.5 1.5v27c0 .828-.669 1.5-1.5 1.5zm-7.5-3h6V30h-6v24z" />
      <path d="M11.47 30h-3a1.5 1.5 0 0 1 0-3h3a1.5 1.5 0 0 1 0 3zM11.47 57h-3a1.5 1.5 0 0 1 0-3h3a1.5 1.5 0 0 1 0 3zM23.47 57h-3a1.5 1.5 0 0 1 0-3h3a1.5 1.5 0 0 1 0 3zM23.47 30h-3a1.5 1.5 0 0 1 0-3h3a1.5 1.5 0 0 1 0 3zM41.47 57h-9a1.5 1.5 0 0 1-1.5-1.5v-27a1.5 1.5 0 0 1 1.5-1.5h9a1.5 1.5 0 0 1 1.5 1.5v27c0 .828-.669 1.5-1.5 1.5zm-7.5-3h6V30h-6v24z" />
      <path d="M32.47 30h-3a1.5 1.5 0 0 1 0-3h3a1.5 1.5 0 0 1 0 3zM32.47 57h-3a1.5 1.5 0 0 1 0-3h3a1.5 1.5 0 0 1 0 3zM44.47 57h-3a1.5 1.5 0 0 1 0-3h3a1.5 1.5 0 0 1 0 3zM44.47 30h-3a1.5 1.5 0 0 1 0-3h3a1.5 1.5 0 0 1 0 3zM62.47 57h-9a1.5 1.5 0 0 1-1.5-1.5v-27a1.5 1.5 0 0 1 1.5-1.5h9a1.5 1.5 0 0 1 1.5 1.5v27c0 .828-.669 1.5-1.5 1.5zm-7.5-3h6V30h-6v24z" />
      <path d="M53.47 30h-3a1.5 1.5 0 0 1 0-3h3a1.5 1.5 0 0 1 0 3zM53.47 57h-3a1.5 1.5 0 0 1 0-3h3a1.5 1.5 0 0 1 0 3zM65.47 57h-3a1.5 1.5 0 0 1 0-3h3a1.5 1.5 0 0 1 0 3zM65.47 30h-3a1.5 1.5 0 0 1 0-3h3a1.5 1.5 0 0 1 0 3z" />
    </g>
  </svg>
);
