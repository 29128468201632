import React, { useState } from 'react';
import { css } from 'emotion';
import { useAddHook, useCartHook } from './CartHooks';
import gql from 'graphql-tag';
import { Button, Intent, FormGroup } from '@blueprintjs/core';
import { ApolloConsumer } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { WithCurrentUser, ICurrentUserProps } from '../users/CurrentUserQuery';

type Maybe<T> = string | null | undefined;

function safeParse(options?: { [key: string]: any }): Maybe<string> {
  try {
    if (!options) {
      return;
    }

    return JSON.stringify(options);
  } catch (ignore) {
    return;
  }
}

export const ADD_CART_ITEM_MUTATION = gql`
  mutation AddCartItem($productId: String!, $quantity: Int!, $options: String) {
    cart: addCartItem(
      productId: $productId
      quantity: $quantity
      options: $options
    ) {
      id
      items {
        id
        productId
        quantity
        options
      }
    }
  }
`;

export const addCartItem = (client: ApolloClient<any>) => async (
  productId: string,
  quantity: number,
  options?: string,
) =>
  client.mutate({
    mutation: ADD_CART_ITEM_MUTATION,
    variables: {
      productId,
      quantity,
      options,
    },
  });

interface Props extends ICurrentUserProps {
  disabled?: boolean;
  productId: string;
  quantity?: number;
  options?: { [key: string]: any };
}

export function AddCartItemButtonInternal(
  props: Props & {
    client: ApolloClient<any>;
  },
) {
  const cartHook = useCartHook(props.isLoggedIn, props.client);
  const {
    add: { success, loading, error, onRequest },
  } = cartHook;
  return (
    <FormGroup
      intent={error ? Intent.DANGER : Intent.SUCCESS}
      helperText={
        <span
          className={css({
            transition: 'all 0.2s',
            opacity: success || error ? 100 : 0,
          })}
        >
          {success && '장바구니 저장완료'}
          {error && '로그인 필요'}
        </span>
      }
    >
      <Button
        loading={loading}
        disabled={loading || props.disabled}
        intent={Intent.SUCCESS}
        onClick={() =>
          onRequest({
            productId: props.productId,
            quantity: props.quantity || 1,
            options: safeParse(props.options),
          })
        }
      >
        장바구니 담기
      </Button>
    </FormGroup>
  );
}

export const AddCartItemButton = WithCurrentUser((props: Props) => {
  return (
    <ApolloConsumer>
      {(client: ApolloClient<any>) => {
        return (
          <AddCartItemButtonInternal
            client={client}
            isLoggedIn={props.isLoggedIn}
            disabled={props.disabled}
            productId={props.productId}
            quantity={props.quantity}
            options={props.options}
            user={props.user}
            loading={props.loading}
            reloadUser={props.reloadUser}
          />
        );
      }}
    </ApolloConsumer>
  );
});
