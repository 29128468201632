import React from 'react';
import { History } from 'history';
import { Menu, MenuItem, Classes, MenuDivider } from '@blueprintjs/core';

import { BoardType } from '../../gql-types';

interface Props {
  type: BoardType | 'reviews';
  history: History;
}

export class CommunityMenu extends React.Component<Props> {
  private handleRouteChange = (route: string) => {
    const { history } = this.props;
    history.push(route);
  };

  render() {
    const { type } = this.props;
    return (
      <div>
        <Menu className={Classes.ELEVATION_1}>
          <MenuDivider title="커뮤니티" />
          <MenuItem
            active={type === BoardType.announcements}
            text="공지사항"
            onClick={() =>
              this.handleRouteChange(`/community/${BoardType.announcements}`)
            }
          />
          <MenuItem
            text="이벤트"
            active={type === BoardType.events}
            onClick={() =>
              this.handleRouteChange(`/community/${BoardType.events}`)
            }
          />
          <MenuItem
            active={type === BoardType.secretpost}
            text="시크릿 포스트"
            onClick={() =>
              this.handleRouteChange(`/community/${BoardType.secretpost}`)
            }
          />
          <MenuItem
            text="보도자료"
            active={type === BoardType.news}
            onClick={() =>
              this.handleRouteChange(`/community/${BoardType.news}`)
            }
          />
        </Menu>
      </div>
    );
  }
}
