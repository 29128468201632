import React from 'react';
import styled from 'react-emotion';

const GridLayout = styled('div')(
  {
    display: 'grid',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    gridColumnGap: '0.5em',
    gridRowGap: '0.5em',
    '@media screen and (max-width: 600px)': {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  (props: { size: number }) => {
    return {
      gridTemplateColumns: `repeat(${props.size}, 1fr)`,
    };
  },
);

const GridItem = styled('div')({
  position: 'relative',
  flexShrink: 0,
  height: '100%',
});

interface Props {
  size: number;
  children: React.ReactNode;
}

export function ChooserGrid(props: Props) {
  const children = React.Children.map(props.children, child => {
    return <GridItem>{child}</GridItem>;
  });

  return <GridLayout size={props.size}>{children}</GridLayout>;
}
