import React from 'react';
import { css } from 'emotion';

const NoticeStyle = css`
    .info_box {display:-webkit-box; display:flex; -webkit-box-orient:vertical; -webkit-box-direction:normal; flex-direction:column; -webkit-box-align:center; align-items:center; -webkit-box-pack:center; justify-content:center; margin: 30px auto; width:100%; height:100%; text-align:center; background:#fff; border:1px solid #bbbbbb;}
    .info_box .ico_scd {margin-top:27px; margin-bottom:20px;}
    .info_box .ico_scd img {display:block; width:57px;}
    .info_box h4 {margin-bottom:30px; padding:0 18px; font-size:24px; font-weight:700; color:#232323;}
    .info_box p {padding:0 18px; font-size:20px; font-weight:600; color:#232323; line-height:26px;}
    .info_box .btn_area {display:flex; margin-top:60px; margin-bottom:27px;}
    .info_box .btn_area button:nth-of-type(n+2) {margin-left:10px;}
    .info_box .btn_area .btn_apple {display:block; width:140px; height:46px; background:url(https://storage.googleapis.com/secretdiet-public-images/mobile_notice_apple%402x.png) no-repeat; background-size:140px; border:none;}
    .info_box .btn_area .btn_google {display:block; width:140px; height:46px; background:url(https://storage.googleapis.com/secretdiet-public-images/mobile_notice_google%402x.png) no-repeat; background-size:140px; border:none;}
`;

const goStore = () => {
    window.location.href = 'https://i.secretdietgym.com/vBj1';
};

export function NoticeMobilePay() {
    return (<div className={NoticeStyle}>
        <div className="info_box">
            <div className="ico_scd">
                <img src="https://storage.googleapis.com/secretdiet-public-images/logo%402x.png" alt=""/>
            </div>
            <h4>안내</h4>
            <p>
                스마트폰 웹브라우저에서는<br/>
                결제를 지원하지 않습니다.<br/>
                <br/>
                PC 웹브라우저에서 이용하시거나,<br/>
                스마트폰 앱에서 구매하시면 됩니다.
            </p>
            <div className="btn_area">
                <button className="btn_apple" type="button" onClick={goStore}></button>
                <button className="btn_google" type="button" onClick={goStore}></button>
            </div>
        </div>
    </div>);
}
