import React from 'react';
import MediaQuery from 'react-responsive';
import { MarketingSlider } from './MarketingSlider';
import { SplashMessage } from './SplashMessage';
import { ImageBackground } from '../../components';

interface SplashScene {
  dark?: boolean;
  src: string;
  gradient?: boolean;
  background?: boolean;
  messages: {
    bold?: boolean;
    message: string;
  }[];
}

const images = {
  pc: [
    'https://storage.googleapis.com/secretdiet-public-images/carousel/v2/splash-boxing-1.jpg',
    'https://storage.googleapis.com/secretdiet-public-images/carousel/v2/splash-boxing-2.jpg',
    'https://storage.googleapis.com/secretdiet-public-images/carousel/v2/carousel_03.jpg',
    'https://storage.googleapis.com/secretdiet-public-images/carousel/v2/carousel_04.jpg',
  ],
  mobile: [
    'https://storage.googleapis.com/secretdiet-public-images/carousel/v2/carousel_01_mobile.jpg',
    'https://storage.googleapis.com/secretdiet-public-images/carousel/v2/carousel_02_mobile.jpg',
    'https://storage.googleapis.com/secretdiet-public-images/carousel/v2/carousel_03_mobile.jpg',
    'https://storage.googleapis.com/secretdiet-public-images/carousel/v2/carousel_04_mobile.jpg',
  ],
};

const scenes: SplashScene[] = [
  {
    // dark: true,
    gradient: true,
    src:
      'https://storage.googleapis.com/secretdiet-public-images/carousel/v2/splash-boxing-1.jpg',
    messages: [],
  },
  {
    src:
      'https://storage.googleapis.com/secretdiet-public-images/carousel/v2/splash-boxing-2.jpg',
    background: true,
    messages: [],
  },
  {
    // dark: true,
    src:
      'https://storage.googleapis.com/secretdiet-public-images/carousel/v2/carousel_03.jpg',
    messages: [
      {
        message: '김지훈트레이너가 당신만을 위해 설계한',
      },
      {
        message: '체계적이고 검증된 트레이닝 프로그램',
      },
      {
        message: '지금 무료로 집에서 만나보세요.',
      },
    ],
  },
  {
    src:
      'https://storage.googleapis.com/secretdiet-public-images/carousel/v2/carousel_04.jpg',
    messages: [
      {
        message: '지금 바로 PC와 스마트폰으로 간편하게',
      },
      {
        message: '수백개의 전문 트레이닝 세션에',
      },
      {
        message: '무료로 접속하세요.',
      },
    ],
  },
];

interface Props {
  onRegister(): void;
}

function SplashCarousel(props: Props) {
  return (
    <>
      <MediaQuery query="(min-width:640px)">
        <MarketingSlider arrow autoplay autoplaySpeedInMs={5000}>
          {scenes.map(s => {
            return (
              <ImageBackground key={s.src} src={s.src}>
                <SplashMessage
                  dark={s.dark}
                  messages={s.messages}
                  onRegister={props.onRegister}
                />
              </ImageBackground>
            );
          })}
        </MarketingSlider>
      </MediaQuery>
      <MediaQuery query="(max-width:640px)">
        <MarketingSlider autoplaySpeedInMs={5000}>
          {scenes.map((s, index) => {
            return (
              <ImageBackground
                cover
                height={640}
                key={index}
                src={images.mobile[index]}
              >
                <SplashMessage
                  bottom
                  background={s.background}
                  messages={s.messages}
                  onRegister={props.onRegister}
                />
              </ImageBackground>
            );
          })}
        </MarketingSlider>
      </MediaQuery>
    </>
  );
}

export default SplashCarousel;
