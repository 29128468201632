import React from 'react';
import { css } from 'emotion';
import styled from 'react-emotion';
import { theme } from '../Theme';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  dark?: boolean;
  fontSize?: number | string;
  plain?: boolean;
}

export function SecretDietLogo(props: Props) {
  return (
    <div
      onClick={props.onClick}
      className={css(
        {
          fontFamily: "'Noto Sans KR', sans-serif",
          fontSize: props.fontSize ? props.fontSize : 15,
          fontWeight: 'bold' as 'bold',
          letterSpacing: 4,
          userSelect: 'none',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
        },
        props.className,
      )}
    >
      <span style={{ color: props.dark ? 'black' : 'white' }}>SECRET</span>
      <span style={{ color: props.plain ? 'white' : theme.accentColor }}>
        DIET
      </span>
    </div>
  );
}
