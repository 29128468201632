import React, { useEffect } from 'react';
import styled from 'react-emotion';
import Helmet from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { OvalButton } from '../../components';
import { CourseQuery } from '../courses/CourseQuery';
import { PageLayout } from '../layouts/PageLayout';
import { ICurrentUserProps, WithCurrentUser } from '../users/CurrentUserQuery';
import { MembershipInfoView } from '../users/MembershipInfoView';

const PricingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  backgroundColor: '',
});

interface Props extends RouteComponentProps<any>, ICurrentUserProps {}

const ProgramPage = (props: Props) => {
  const user = props.user;
  useEffect(()=> {
    //alert('시크릿다이어트 영상 종료 안내\n\n안녕하세요. 시크릿 다이어트팀입니다.\nPC버전의 영상 플레이 서비스가 리뉴얼을 위해\n2021년 11월 12일부로 종료될 예정입니다.\n시크릿다이어트는 앱으로도 동일한 서비스를 받으실 수 있으니\n모바일 앱을 이용해 주시기 바랍니다.\n\n감사합니다. 시크릿다이어트팀 일동');
  }, []);
  
  return (
    <PageLayout title="프로그램">
      <Helmet>
        <title>시크릿다이어트 - 프로그램</title>
        <meta
          name="description"
          content="수많은 셀럽의 바디 디자이너 김지훈 트레이너에게 배우는 홈트레이닝"
        />
      </Helmet>
      {!user && (
        <OvalButton onClick={() => props.history.push('/signup')}>
          가입하기
        </OvalButton>
      )}
      {user && (
        <MembershipInfoView
          user={user}
          onExtensionRequest={() => props.history.push('/plans')}
        />
      )}
      <PricingContainer>
        <CourseQuery />
      </PricingContainer>
    </PageLayout>
  );
};

export default WithCurrentUser(ProgramPage);
