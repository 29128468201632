import styled from 'react-emotion';
import { css } from 'emotion';
import { VariableFontSize } from '../home/HomeStyles';

export const AboutSectionContainer = styled('section')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const MaxWidthSetter = css({
  maxWidth: 1024,
});

export const EmphasiedText = styled('span')({
  color: 'rgb(124,219,218)',
});

export const SectionContainer = styled('section')(
  {
    position: 'relative',
    width: '100%',
    backgroundColor: 'white',
    minWidth: 360,
  },
  VariableFontSize,
);

export const PinkText = css({
  color: '#ec0b6d',
});

export const BoldStyle = css({
  fontWeight: 'bold',
});
