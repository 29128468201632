import React from 'react';
import { css } from 'emotion';

interface Props {
  children: any;
}

export function LimitedViewport(props: Props) {
  return (
    <div
      className={css({
        maxWidth: 1080,
        margin: '0 auto',
      })}
    >
      {props.children}
    </div>
  );
}
