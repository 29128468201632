import React from 'react';
import { css } from 'emotion';

const CommunityPageLayout = css({
  display: 'grid',
  gridTemplateColumns: '0.2fr 1fr',
  maxWidth: 1024,
  width: '100%',
  gridColumnGap: '1em',
  padding: '1em',
  marginBottom: '5em',
  '@media screen and (max-width: 600px)': {
    gridTemplateColumns: '1fr',
    gridRowGap: '1em',
  },
});

interface Props {
  title?: string;
  children: any;
}

export function BoardLayout(props: Props) {
  const { title } = props;
  return (
    <div className={CommunityPageLayout}>
      <div />
      <h2>{title}</h2>
      {props.children}
    </div>
  );
}
