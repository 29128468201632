import React from 'react';
import gql from 'graphql-tag';
import { RouteComponentProps } from 'react-router';
import styled from 'react-emotion';
import { Mutation } from 'react-apollo';
import { ResetPasswordForm } from '../users/ResetPasswordForm';

interface RouteParams {
  code: string;
}

interface Props extends RouteComponentProps<RouteParams> {}

const Layout = styled('section')({
  padding: '1em',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: 320,
  maxWidth: 600,
  width: '100%',
});

const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPasswordMutation(
    $code: String!
    $password: String!
    $passwordConfirm: String!
  ) {
    resetPassword(
      code: $code
      password: $password
      passwordConfirm: $passwordConfirm
    )
  }
`;

export function ResetPasswordPage(props: Props) {
  const { code } = props.match.params;
  const { history } = props;
  return (
    <Layout>
      <Mutation mutation={RESET_PASSWORD_MUTATION}>
        {(update, obj) => {
          return (
            <ResetPasswordForm
              code={code}
              saving={obj.loading}
              called={obj.called}
              error={obj.error}
              onLogin={() => history.push('/login')}
              onSubmit={state =>
                update({
                  variables: {
                    ...state,
                    code,
                  },
                })
              }
            />
          );
        }}
      </Mutation>
    </Layout>
  );
}
