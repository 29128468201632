import { IConnection, IEdge } from '../gql-model';

export interface IStrictEdge<T> {
  node: T;
  cursor: string;
}

export function isNotNull<T>(item: T | null | undefined): item is T {
  return item !== null && item !== undefined;
}

export function ensureArray<T>(arr?: (T | null | undefined)[] | null): T[] {
  if (arr === null || arr === undefined) {
    return [];
  }

  return arr.filter(isNotNull);
}

function isStrictEdge<T>(edge: IEdge<T>): edge is IStrictEdge<T> {
  return isNotNull(edge.cursor) && isNotNull(edge.node);
}

export function getEdges<T>(conn: IConnection<T>): IStrictEdge<T>[] {
  const edges = ensureArray(conn.edges);
  return edges.filter(isStrictEdge);
}

export function getNodes<T>(connection?: IConnection<T>): T[] {
  if (!connection) {
    return [];
  }

  const edges = ensureArray(connection.edges);
  return ensureArray(edges.map(edge => edge.node));
}
