import React, { SVGAttributes } from 'react';

export const YogaMat = (props: SVGAttributes<SVGElement>) => (
  <svg viewBox="0 0 48 48" {...props}>
    <g fill="none">
      <path className="st2" d="M1 35V10c0-2.8 2.5-5 5.5-5S12 7.2 12 10v24" />
      <path
        className="st2"
        d="M12 12h35v29H9c-4.7 0-8-2.3-8-6.2 0-1.6 1.2-3.3 2.9-4.2 3.1-1.8 8.1-.2 8.1 3 0 1.1-.9 2.1-2 2.5-.6.2-3.5.9-4-2"
      />
    </g>
  </svg>
);
