import React from 'react';
import { VideoContainer } from './VideoContainer';
import { css } from 'emotion';

import { Card, Header } from '../../components';

interface Props {
  logo: string;
  videoUrl: string;
}

const HeaderContainer = css({
  display: 'flex',
  alignItems: 'center',
});

const LogoContainer = css({
  width: '3em',
  height: '3em',
});

const ColumnsContainer = css({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
});

const Column = css({
  flex: '0 0 auto',
  width: '45%',
  '@media screen and (max-width: 600px)': {
    width: '100%',
  },
});

export function DietbibleCard(props: Props) {
  return (
    <>
      <Card>
        <div className={HeaderContainer}>
          <h1>DIET BIBLE</h1>
          <div
            className={LogoContainer}
            style={{
              background: `url(${props.logo})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          />
        </div>
        <VideoContainer controls>
          <source src={props.videoUrl} type="video/mp4" />
        </VideoContainer>
      </Card>
      <Card style={{ marginTop: '1em' }}>
        <p>
          인터넷에서 쏟아지는 홈트레이닝 영상, 저렴하다 못해 처참한 가격에
          뿌려지는 피트니스 센터 회원권.
        </p>
        <p>
          한번 쯤 겪어본 적이 있을 거에요. 저렴한 가격에 방문한 센터에서 무엇을
          해야할지 모른채 런닝머신만 몇번 타다 경험 인터넷에 돌아다니는 몸짱들의
          운동 영상을 보고 막연히 따라 해보다가 포기한 경험
        </p>

        <h2>이유가 무엇일까요</h2>
        <p>
          아무런 운동 지식이 없는 초보자가 프로그램을 짜고 운동을 한다는 건 정말
          어려운 일에요. 그러나 퍼스털 트레이닝을 선택하기에는 고민이 따르죠.
          퍼스널 트레이닝은 가격이 부담스러우니까요. 그래서 시크릿다이어트가
          준비했습니다.
        </p>
        <p>
          당신을 위한 운동의 모든 것, 다이어트 바이블 운동 세트 구성과 올바른
          동작, 생활 속의 건강관리 팁! 모든 것을 담았습니다. 이제 남은 것은
          당신의 마음먹기, 그리고 시작하기 입니다.
        </p>
        <p>
          우리의 가장 큰 목표와 역할은 당신이 포기하지 않도록 도와 주는 것
          입니다.
        </p>
        <p>용기를 내보세요, 우리가 당신 곁에서 도와 드릴게요.</p>
      </Card>
      <Card style={{ marginTop: '1em' }}>
        <h1>다이어트 바이블은</h1>
        <p>집에서 할 수 있는 모든 강도의 운동을 전부 포함하고 있습니다.</p>
        <p>
          운동을 처음 하는 초보자도 단계별로 운동레벨이 높아지기 때문에 어렵지
          않게 따라할 수 있습니다. 매번 새로운 동작을 배워야 한다면 운동의
          흐름이 끊겨 요율적인 트레이닝을 할 수 없습니다. 그러나 다이어트
          바이블은 1주 마다 학습했던 동작이 조금씩 변형되어 난이도가 높아지기
          때문에 운동 동작을 배우는 데 시간이 걸리지 않습니다.
        </p>
        <p>
          홈 트레이닝 온라인pt에서 놓치기 쉬운 부위들을 6가지로 세분화 하여 주
          6회 분할 운동 프로그램으로 구성했기 때문에 균형 잡힌 트레이닝이
          가능합니다.
        </p>
      </Card>
      <Card style={{ marginTop: '1em' }}>
        <h1>다이어트 바이블의 운동 원리</h1>
        <article className={ColumnsContainer}>
          <div className={Column}>
            <section>
              <h2>진짜 온라인 퍼스널 트레이닝</h2>
              <p>
                영상 속 트레이너가 계속해서 당신의 자세를 체크하고 가이드를
                제공합니다.
              </p>
            </section>
            <section>
              <h2>20분 운동 운동효과 UP</h2>
              <p>
                매일 1시간 이상 운동하기는 정말 어렵죠. 만약 20분만 운동해도
                1시간 이상의 효과가 난다면?
              </p>
            </section>
            <section>
              <h2>시크릿다이어트만의 특허 받은 영상</h2>
              <p>
                기술특허 - 온라인 운동 트레이닝 장치 및 그 방법 디자인특허(15건)
                - 화상디자인이 표시된 디스플레이 패널
              </p>
            </section>
          </div>
          <div className={Column}>
            <section>
              <h2>실시간 정보 제공</h2>
              <section>
                <div>지금 내가 어디에 힘을 들어야 할 지 알려주는</div>
                <div>Key point (2D graphic)</div>
              </section>
              <section>
                <div>연속동작이 가능하도록 다음 스텍을 알려주는</div>
                <div>Advice point (Chroma-key)</div>
              </section>
            </section>
            <section>
              <h2>AMAP</h2>
              <p>
                개인 체력차에 상관없이 최고의 효과를 이끌어내는 시스템, AMAP
              </p>
            </section>
          </div>
        </article>
      </Card>
    </>
  );
}
