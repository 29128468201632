import React from 'react';
import { AnchorButton, IButtonProps } from '@blueprintjs/core';

import { FacebookLogo } from '../logos';
import { css } from 'emotion';

const FacebookAnchorCss = css({
  backgroundImage: 'none !important',
  backgroundColor: '#4267b2 !important',
  borderRadius: '1em !important',
  '& > span': {
    color: 'white',
    display: 'flex',
    alignItems: 'center',
  },
  ':hover': {
    backgroundColor: '4267b2',
  },
});

const messages = {
  register: '회원가입',
  login: '로그인',
  connect: '연결하기',
};

export interface ExtraProps {
  message: 'register' | 'login' | 'connect';
  remember?: boolean;
}

function FacebookButton(props: IButtonProps & ExtraProps) {
  const { message, remember, ...rest } = props;
  const text = messages[message];
  let href = '/auth/facebook';
  href = props.remember ? href + '?remember=true' : href;

  return (
    <AnchorButton {...rest} href={href} className={FacebookAnchorCss}>
      <FacebookLogo
        width="1.2em"
        height="1.2em"
        style={{ marginRight: '0.5em' }}
      />
      {text}
    </AnchorButton>
  );
}

export default FacebookButton;
