import {
  FormGroup,
  ControlGroup,
  InputGroup,
  Icon,
  Colors,
  Intent,
  Callout,
} from '@blueprintjs/core';
import React from 'react';
import trim from 'lodash/trim';
import { EmailUpdateButton } from './EmailUpdateButton';
import { EmailVerifyButton } from './EmailVerifyButton';
import { IconNames } from '@blueprintjs/icons';
import { ApolloError } from 'apollo-client';

interface Props {
  value?: string;
  verified?: boolean;
}

interface State {
  value: string;
  loading: boolean;
  completed: boolean;
  error: ApolloError | null;
  helperText: string;
}

export class UserEmailInput extends React.Component<Props, State> {
  state: State = {
    value: this.props.value || '',
    completed: false,
    loading: false,
    error: null,
    helperText: '',
  };

  componentWillReceiveProps(nextProps: Props) {
    if (
      this.props.value !== nextProps.value &&
      nextProps.value !== this.state.value
    ) {
      this.setState({ value: nextProps.value || '' });
    }
  }

  private handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    this.setState({ value });
  };

  private handleUpdate = () => {
    this.setState({
      loading: true,
      completed: false,
      error: null,
      helperText: '',
    });
  };

  private handleComplete = (message: string) => {
    this.setState({
      loading: false,
      error: null,
      completed: true,
      helperText: message,
    });
  };

  private handleError = (error: ApolloError) => {
    this.setState({
      loading: false,
      value: this.props.value || '',
      error,
    });
  };

  render() {
    const { verified } = this.props;
    const updated = trim(this.props.value) !== trim(this.state.value);
    const { error, completed } = this.state;
    const intent = error
      ? Intent.DANGER
      : completed
        ? Intent.SUCCESS
        : Intent.NONE;
    const helperText = error ? error.message : this.state.helperText;

    return (
      <FormGroup
        label="이메일"
        labelFor="email"
        intent={intent}
        helperText={helperText}
      >
        <ControlGroup fill>
          <InputGroup
            id="email"
            name="email"
            formNoValidate
            type="emali"
            intent={intent}
            value={this.state.value}
            onChange={this.handleChange}
            leftIcon={
              verified ? (
                <Icon icon={IconNames.CONFIRM} color={Colors.GREEN1} />
              ) : (
                undefined
              )
            }
          />
          {updated && (
            <div
              style={{
                flex: '0 1 auto',
              }}
            >
              <EmailUpdateButton
                email={this.state.value}
                onCompleted={this.handleComplete}
                onError={this.handleError}
                onUpdate={this.handleUpdate}
              />
            </div>
          )}
          {!verified && (
            <div
              style={{
                flex: '0 1 auto',
              }}
            >
              <EmailVerifyButton
                onCompleted={this.handleComplete}
                onError={this.handleError}
                onUpdate={this.handleUpdate}
              />
            </div>
          )}
        </ControlGroup>
        {!this.props.value && (
          <Callout intent={Intent.WARNING} title="이메일을 입력해 주세요.">
            <p>더 나은 서비스 제공을 위해서 이메일을 입력해주세요.</p>
          </Callout>
        )}
        {this.props.value &&
          !verified && (
            <Callout intent={Intent.WARNING} title="이메일을 확인해 주세요">
              <p>더 나은 서비스 제공을 위해서 이메일을 확인해주세요.</p>
            </Callout>
          )}
      </FormGroup>
    );
  }
}
