import React, { useCallback } from 'react';
import { Spinner } from '@blueprintjs/core';
import { Redirect, RouteComponentProps } from 'react-router';
import { WithCurrentUser, ICurrentUserProps } from '../users/CurrentUserQuery';
import { ShoppingCartDisplay } from '../cart/ShoppingCartDisplay';

interface Props extends ICurrentUserProps, RouteComponentProps {}

export function ShoppingCartPage(props: Props) {
  const { loading } = props;
  if (loading) {
    return <Spinner />;
  }

  const handleComplete = useCallback(
    () => props.history.push('/secret-mall'),
    [],
  );

  return (
    <ShoppingCartDisplay
      user={props.user}
      client={props.client}
      onCompleted={handleComplete}
      onCancel={handleComplete}
    />
  );
}

export default WithCurrentUser(ShoppingCartPage);
