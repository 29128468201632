import React from 'react';
import { css } from 'emotion';

interface Props {
  src: string;
  height?: number | string;
  cover?: boolean;
  children?: any;
  innerStyle?: any;
  filter?: any;
  backgroundColor?: any;
}

const relativelyWide = css({
  position: 'relative',
  width: '100%',
  display: 'block',
});

const absolutelyWide = css({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
});

export const ImageBackground = (props: Props) => {
  const relativeStyle = css(relativelyWide, {
    height: props.height ? props.height : undefined,
  });
  return (
    <div className={relativeStyle}>
      <img
        src={props.src}
        className={css(relativeStyle, {
          objectFit: props.cover ? 'cover' : undefined,
        })}
        style={{ filter: props.filter, backgroundColor: props.backgroundColor }}
      />
      <div className={absolutelyWide} style={props.innerStyle}>
        {props.children}
      </div>
    </div>
  );
};
