import React from 'react';
import { BoardLayout } from './BoardLayout';
import { History } from 'history';
import { CommunityMenu } from './CommunityMenu';
import { BoardType } from '../../gql-types';

interface Props {
  type: BoardType;
  history: History;
  children: any;
}

function getTitle(type: BoardType) {
  switch (type) {
    case BoardType.secretpost:
      return '시크릿 포스트';
    case BoardType.beforeAndAfter:
      return '비포앤애프터';
    case BoardType.news:
      return '보도자료';
    case BoardType.announcements:
      return '공지사항';
    case BoardType.events:
      return '이벤트';
  }
}

export function CommunityLayout(props: Props) {
  const { type, history } = props;
  const title = getTitle(type);
  return (
    <BoardLayout title={title}>
      <CommunityMenu type={type} history={history} />
      {props.children}
    </BoardLayout>
  );
}
