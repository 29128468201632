import styled from 'react-emotion';

const ContentContainer = styled('section')(
  {
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#eee',
  },
  (props: { offset?: number }) => ({
    paddingTop: props.offset,
  }),
);

export default ContentContainer;
