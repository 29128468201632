import React from 'react';

export const FacebookLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 512 512" {...props}>
    <path
      d="M483.74 0H28.26A28.26 28.26 0 0 0 0 28.26v455.48A28.26 28.26 0 0 0 28.26 512H273.5V314H207v-77.5h66.5v-57c0-66.13 40.39-102.14 99.38-102.14 28.25 0 52.54 2.1 59.62 3v69.11h-40.68c-32.1 0-38.32 15.25-38.32 37.64v49.39h76.74l-10 77.5H353.5v198h130.24A28.26 28.26 0 0 0 512 483.74V28.26A28.26 28.26 0 0 0 483.74 0z"
      fill="transparent"
    />
    <path
      d="M353.5 512V314h66.75l10-77.5H353.5v-49.36c0-22.39 6.22-37.64 38.32-37.64h40.68V80.37c-7.08-.94-31.37-3-59.62-3-59 0-99.38 36-99.38 102.14v57H207V314h66.5v198z"
      fill="#fff"
    />
  </svg>
);
