import React from 'react';
import { css } from 'emotion';
import { RouteComponentProps } from 'react-router';
import { PricingCheckoutView } from '../pricings/PricingCheckoutView';
import { WithCurrentUser, ICurrentUserProps } from '../users/CurrentUserQuery';
import { NoticeMobilePay } from '../../components'
import { UAParser } from 'ua-parser-js';

interface Props extends RouteComponentProps<any>, ICurrentUserProps {}

class PlansPage extends React.Component<Props> {
  render() {
    /**
     * #FIXME-START: prevent mobile web pay process.
     * 결제관련 문제가 수정되기 이전까지 모바일 웹으로 접근시 결제가 되지 않도록 수정.
     * 추후 문제가 해결되면 이 부분을 지워주세요.
     */
    const parser = new UAParser();
    const { type: deviceType } = parser.getDevice();
    if (deviceType === 'mobile') {
      return (<NoticeMobilePay />);
    } else {
      // 마지막 중괄호 반드시 지울것!
      // #FIXME-END
      return (
        <div
          className={css({
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            padding: '2em',
            width: '100%',
          })}
        >
          <PricingCheckoutView
            history={this.props.history}
            user={this.props.user}
          />
        </div>);
    }
  }
}

export default WithCurrentUser(PlansPage);
