import React from 'react';
import styled from 'react-emotion';
import { RouteComponentProps } from 'react-router';
import { StartDescription } from '../courses/StartDescription';
import { BasicDescription } from '../courses/BasicDescription';
import { MasterDescription } from '../courses/MasterDescription';
import { YoyoDescription } from '../courses/YoyoDescription';
import { BikiniDescription } from '../courses/BikiniDescription';
import { LegDescription } from '../courses/LegDescription';
import { WeddingDescription } from '../courses/WeddingDescription';
import { Body8WeeksDescription } from '../courses/Body8WeeksDescription';
import { FitnessUpDescription } from '../courses/FitnessUpDescription';
import { AbdsDownDescription } from '../courses/AbdsDownDescription';
import { BoxingBibleDescription } from '../courses/BoxingBibleDescription';
import { MittBoxingDescription } from '../courses/MittBoxingDescription';

interface RouteParams {
  slug: string;
}

interface Props extends RouteComponentProps<RouteParams> {}

const Container = styled('section')({
  position: 'relative',
  width: '100%',
  backgroundColor: '#eee',
  padding: '2em',
  maxWidth: 800,
});

export function CourseDescriptionPage(props: Props) {
  const { slug } = props.match.params;
  return (
    <Container>
      {slug === 'start' && <StartDescription history={props.history} />}
      {slug === 'basic' && <BasicDescription history={props.history} />}
      {slug === 'master' && <MasterDescription history={props.history} />}
      {slug === 'yoyox' && <YoyoDescription history={props.history} />}
      {slug === 'bikini' && <BikiniDescription history={props.history} />}
      {slug === 'leg' && <LegDescription history={props.history} />}
      {slug === 'wedding' && <WeddingDescription history={props.history} />}
      {slug === 'body-up-8weeks' && (
        <Body8WeeksDescription history={props.history} />
      )}
      {slug === 'fitness-up' && (
        <FitnessUpDescription history={props.history} />
      )}
      {slug === 'abds-down' && <AbdsDownDescription history={props.history} />}
      {slug === 'secret-boxing-bible' && (
        <BoxingBibleDescription history={props.history} />
      )}
      {slug === 'secret-mitt-boxing' && (
        <MittBoxingDescription history={props.history} />
      )}
    </Container>
  );
}
