import React from 'react';
import get from 'lodash/get';
import MediaQuery from 'react-responsive';
import { Button, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Query } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router';
import { CURRENT_USER_QUERY } from '../users/CurrentUserQuery';
import {
  Header,
  NavContentContainer,
  NavItemContainer,
  MenuToggleContainer,
  PlainButton,
  SecretDietLogo,
} from '../../components';
import CartIcon from '../cart/CartIcon';

export interface INavItem {
  id: string;
  label: string | React.ReactElement<any>;
}

const NavItem = (label: string) => ({
  id: label.toLowerCase().replace(' ', '-'),
  label,
});

const NAV_ITEMS: INavItem[] = [
  {
    id: 'about-us',
    label: '서비스소개',
  },
  {
    id: 'program',
    label: '프로그램',
  },
  {
    id: 'reviews',
    label: '후기',
  },
  {
    id: 'community',
    label: '커뮤니티',
  },
  {
    id: 'secret-mall',
    label: '시크릿몰',
  },
];

const NavItemId = (id: string) => `nav-item-${id}`;
const ItemsForLoggedUsers = NAV_ITEMS.concat([NavItem('MY FITNESS')]);

interface Props extends RouteComponentProps<any> {
  isLoggedIn?: boolean;
}

interface State {
  loggedIn: boolean;
  menuVisible: boolean;
}

class AppHeader extends React.Component<Props, State> {
  state = {
    loggedIn: false,
    menuVisible: false,
  };

  private handleMenuClick = (id: string) => {
    this.handleNavigate('/' + id);
  };

  private handleNavigate = (route: string) => {
    this.setState({ menuVisible: false });
    this.navigateTo(route);
  };

  private navigateTo = (route: string) => {
    const { history } = this.props;
    history.push(route);
  };

  private toggle = () =>
    this.setState({ menuVisible: !this.state.menuVisible });

  render() {
    return (
      <Query query={CURRENT_USER_QUERY}>
        {result => {
          const loggedIn: boolean = Boolean(get(result, 'data.user'));
          const loginRoute = loggedIn ? '/profile' : '/login';
          const cartItem: INavItem = {
            id: 'cart',
            label: <CartIcon />,
          };
          const items = !loggedIn ? NAV_ITEMS : ItemsForLoggedUsers;
          const navItems = items
            .concat(cartItem)
            .map(item => {
              return (
                <PlainButton
                  id={NavItemId(item.id)}
                  key={item.id}
                  size="small"
                  onClick={() => this.handleMenuClick(item.id)}
                >
                  {item.label}
                </PlainButton>
              );
            })
            .concat(
              <PlainButton
                id="login"
                key="login"
                size="small"
                onClick={() => this.handleNavigate(loginRoute)}
              >
                {loggedIn ? '내정보' : '로그인'}
              </PlainButton>,
            );

          return (
            <Header fixed>
              <NavContentContainer>
                <div>
                  <SecretDietLogo onClick={() => this.navigateTo('/')} />
                </div>
                <MediaQuery query="(max-width: 640px)">
                  <MenuToggleContainer>
                    <Button
                      icon={
                        <Icon
                          iconSize={20}
                          icon={IconNames.MENU}
                          color="white"
                        />
                      }
                      onClick={this.toggle}
                      minimal
                    />
                  </MenuToggleContainer>
                </MediaQuery>
                <MediaQuery query="(min-width: 640px)">
                  <NavItemContainer visible>{navItems}</NavItemContainer>
                </MediaQuery>
              </NavContentContainer>
              <MediaQuery query="(max-width: 640px)">
                <NavItemContainer visible={this.state.menuVisible}>
                  {navItems}
                </NavItemContainer>
              </MediaQuery>
            </Header>
          );
        }}
      </Query>
    );
  }
}

export default withRouter(AppHeader);
