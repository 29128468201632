import React from 'react';
import { Card } from '../../components';

export function EpochCard() {
  return (
    <Card style={{ marginTop: '1em' }}>
      <h1>정말 20분 운동으로 효과가 있나요?</h1>
      <p>
        운동할 시간이 부족한 당신을 위한 단시간 고강도 트레이닝
        <br />
        운동 후에도 체지방이 계속해서 연소되는 마법같은 EPOC효과
      </p>
      <h1>EPOC Effect는?</h1>
      <video
        controls
        style={{ width: '100%', borderRadius: 20 }}
        src="https://player.vimeo.com/external/247799004.hd.mp4?s=69b77de690dde6e2468611c2086590ee8fbeab67&amp;profile_id=174"
      >
        <source
          src="https://player.vimeo.com/external/247799004.hd.mp4?s=69b77de690dde6e2468611c2086590ee8fbeab67&profile_id=174"
          type="video/mp4"
        />
      </video>
      <h2>
        회복기 초과산소 소모효과 (Excessive Post-exercise Oxygen Consumption)
      </h2>
      <p>고강도 운동시 운동이 끝나도 신체가 에너지를 계속 사용하는 상태</p>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img
          style={{
            width: '50%',
            height: 'auto',
          }}
          src="https://s3.ap-northeast-2.amazonaws.com/secret-diet-assets/metabolic-rate-graph-001.jpeg"
        />
        <img
          style={{
            width: '50%',
            height: 'auto',
          }}
          src="https://s3.ap-northeast-2.amazonaws.com/secret-diet-assets/metabolic-rate-graph-002.jpg"
        />
      </div>
    </Card>
  );
}
