import { Button } from '@blueprintjs/core';
import gql from 'graphql-tag';
import React from 'react';
import { Mutation, MutationFn } from 'react-apollo';
import { Card, StarPoint } from '../../components';

const SAVE_REVIEW_ARTICLE = gql`
  mutation SaveReview($id: String, $point: Int!, $content: String!) {
    saveReview(id: $id, point: $point, content: $content) {
      id
      user {
        id
        name
        nickname
      }
      point
      content
      created
    }
  }
`;

interface Props {
  id?: string;
  initialPoint?: number;
  initialValue?: string;
  onCanceled?: () => void;
  onCompleted?: () => void;
}

interface State {
  point: number;
  content: string;
}

export class ReviewWriteView extends React.Component<Props, State> {
  private editorContainer: HTMLDivElement | null = null;
  private editor: tui.Editor | null = null;

  state = {
    point: this.props.initialPoint || 0,
    content: '',
  };

  async componentDidMount() {
    if (!this.editorContainer) {
      return;
    }

    const tuiEditorModule: any = await import('tui-editor');
    const Editor: typeof tui.Editor = tuiEditorModule.default;

    if (Editor) {
      const initialValue = this.props.initialValue || '';
      this.editor = new Editor({
        el: this.editorContainer,
        height: 'auto',
        minHeight: '100',
        initialValue,
        initialEditType: 'wysiwyg',
        previewStyle: 'tab',
        usageStatistics: false,
        hideModeSwitch: true,
        toolbarItems: [
          'bold',
          'italic',
          'strike',
          'divider',
          'ul',
          'ol',
          'indent',
          'outdent',
        ],
      });
    }
  }

  private handleChange = (point: number) => {
    this.setState({ point });
  };

  private didFinish = () => {
    if (this.editor) {
      this.editor.reset();
    }

    this.setState({
      point: 0,
      content: '',
    });

    this.props.onCompleted && this.props.onCompleted();
  };

  private handleComplete = () => {
    this.didFinish();
  };

  private handleSubmit = (update: MutationFn<any, any>) => {
    const { id } = this.props;
    const { point } = this.state;
    const content = this.editor ? this.editor.getMarkdown() : '';

    update({
      variables: {
        id,
        point,
        content,
      },
    });
  };

  render() {
    return (
      <Card>
        <div
          style={{
            marginBottom: '1em',
          }}
        >
          <StarPoint
            value={this.state.point}
            enableEdit
            max={5}
            onChange={this.handleChange}
          />
        </div>
        <div ref={input => (this.editorContainer = input)} />
        <div>
          <Button
            onClick={() => this.props.onCanceled && this.props.onCanceled()}
          >
            취소
          </Button>
          <Mutation
            mutation={SAVE_REVIEW_ARTICLE}
            onCompleted={this.handleComplete}
            refetchQueries={['FetchReviewConnection']}
          >
            {(update, result) => {
              return (
                <Button
                  disabled={result.loading}
                  loading={result.loading}
                  onClick={() => this.handleSubmit(update)}
                >
                  저장
                </Button>
              );
            }}
          </Mutation>
        </div>
      </Card>
    );
  }
}
