import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Classes, Dialog } from '@blueprintjs/core';
import { ICurrentUserProps, WithCurrentUser } from './CurrentUserQuery';
import { User } from '../../gql-types';
import { Link } from 'react-router-dom';

interface Props extends ICurrentUserProps {}

export function isMissingUserInfo(user: User): boolean {
  return isEmpty(user.nickname) || isEmpty(user.email) || isEmpty(user.name);
}

export class MandatoryInfoConfirm extends React.Component<Props> {
  state = {
    isOpen: false,
  };

  render() {
    // const { isOpen } = this.state;
    const { loading, user } = this.props;
    if (loading || !user) {
      return null;
    }

    const isOpen = isMissingUserInfo(user);

    return (
      <Dialog
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        usePortal
        isCloseButtonShown={false}
        autoFocus
        isOpen={isOpen}
        title="필수 정보 입력"
      >
        <div className={Classes.DIALOG_BODY}>
          <div>시크릿다이어트를 이용하기 위해 아래 정보를 입력해주세요.</div>
          <ul>
            <li>이메일</li>
            <li>이메일 확인</li>
            <li>이름</li>
            <li>유저아이디</li>
          </ul>
          <Link to="/profile">수정하러 가기</Link>
        </div>
      </Dialog>
    );
  }
}

export default WithCurrentUser(MandatoryInfoConfirm);
