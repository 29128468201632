import { css } from 'emotion';
import React from 'react';
import { MobileChat, TV } from '../../components/icons';
import { SubscriptionLevel, UserSubscription } from '../../gql-types';
import { MembershipDuration } from './MembershipDuration';

interface Props {
  subscription: UserSubscription;
}

const SubscriptionLabel = (subscription: UserSubscription): string => {
  switch (subscription.level) {
    case SubscriptionLevel.none:
      return '비회원';
    case SubscriptionLevel.basic:
      return '영상 멤버십';
    case SubscriptionLevel.advanced:
      return '컨설팅 멤버십';
    case SubscriptionLevel.course:
      return '코스 멤버십';
  }
};

const SubscriptionIcon = (subscription: UserSubscription) => {
  switch (subscription.level) {
    case SubscriptionLevel.none:
      return null;
    case SubscriptionLevel.basic:
    case SubscriptionLevel.course:
      return <TV />;
    case SubscriptionLevel.advanced:
      return <MobileChat />;
  }
};

const courseArea = {
  display: 'grid',
  webkitBoxAlign: 'center',
  alignItems: 'center',
  gridTemplateColumns: '2em auto 8em',
  gridColumnGap: '0.5em',
};
const courseAllArea = {
  display: 'grid',
  webkitBoxAlign: 'center',
  alignItems: 'center',
  gridTemplateColumns: 'repeat(2, auto)',
  gridColumnGap: '0.5em',
};

export function MembershipExtensionView(props: Props) {
  const { expiresAt, level, courseName } = props.subscription;
  return (
    <div className={css(level === 'course' ? courseArea : courseAllArea)}>
      <div style={{ width: '2em', height: '2em', }}>
        {SubscriptionIcon(props.subscription)}
      </div>
      {level === 'course' ? <div>{courseName}</div> : ''}
      <div className={css({ marginLeft: '10px', color: 'rgb(51, 185, 184)' })}>
        {MembershipDuration(expiresAt)}
      </div>
    </div>
  );

}
