import React from 'react';
import { ReviewSectionLayout } from '../community/ReviewSectionLayout';
import { BoardType } from '../../gql-types';
import { BoardContentView } from '../community/BoardContentView';
import { RouteComponentProps } from 'react-router';

interface RouteParams {
  type: BoardType;
  id: string;
}

interface Props extends RouteComponentProps<RouteParams> {}

interface State {
  page: number;
  limit: number;
}

export class BeforeAndAfterItemPage extends React.Component<Props, State> {
  state: State = {
    page: 1,
    limit: 50,
  };

  render() {
    const id = this.props.match.params.id;

    return (
      <ReviewSectionLayout type="beforeAndAfter" history={this.props.history}>
        <BoardContentView type={BoardType.beforeAndAfter} id={id} />
      </ReviewSectionLayout>
    );
  }
}
