import gql from 'graphql-tag';
import React from 'react';
import { Mutation, MutationFn } from 'react-apollo';
import styled from 'react-emotion';
import { RouteComponentProps } from 'react-router';
import { ForgotPasswordForm } from '../users/ForgotPasswordForm';

const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

interface Props extends RouteComponentProps<any> {}

const Layout = styled('section')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '10em',
  paddingBottom: '10em',
});

export class ForgotPasswordPage extends React.Component<Props> {
  private handleUpdate = (update: MutationFn<any, { email: string }>) => (
    email: string,
  ) => {
    update({
      variables: {
        email,
      },
    });
  };

  render() {
    return (
      <Layout>
        <Mutation mutation={FORGOT_PASSWORD_MUTATION}>
          {(update, obj) => {
            return (
              <ForgotPasswordForm
                saving={obj.loading}
                called={obj.called}
                onSubmit={this.handleUpdate(update)}
              />
            );
          }}
        </Mutation>
      </Layout>
    );
  }
}
