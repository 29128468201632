import React from 'react';
import styled from 'react-emotion';
import { FacebookButton, FacebookLogo, CardStyles } from '../../components';

const SocialStatusContainer = styled('div')(
  {
    backgroundColor: '#4267b2',
    padding: '0.2em',
    fontSize: '1em',
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    width: 200,
  },
  CardStyles.container,
);

function FacebokStatus(props: { large?: boolean; connected: boolean }) {
  const { connected } = props;
  if (!connected) {
    return <FacebookButton large={props.large} fill message="connect" />;
  }

  return (
    <SocialStatusContainer>
      <FacebookLogo
        width="1.2em"
        height="1.2em"
        style={{ marginRight: '0.5em' }}
      />
      <span>상태: 연동완료</span>
    </SocialStatusContainer>
  );
}

export default FacebokStatus;
