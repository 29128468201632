import { css } from 'emotion';
import styled from 'react-emotion';

export const VariableFontSize = css({
  fontSize: 9.6,
  '@media (min-width: 320px)': {
    fontSize: '3vw',
  },
  '@media (min-width: 1080px)': {
    fontSize: 32,
  },
});

export const TitleFont = css({
  fontSize: '2em',
  margin: 0,
  lineHeight: '1.5em',
  letterSpacing: '0.1em',
});

export const TitleMessage = styled('h1')(TitleFont);

export const SubtitleFont = css({
  fontSize: '1em',
  lineHeight: '2em',
  fontWeight: 100,
  margin: 0,
});

export const SubtitleMessage = styled('h2')(SubtitleFont);

export const HomePageLayout = styled('section')(
  {
    width: '100%',
    height: '100%',
    padding: '2em',
  },
  VariableFontSize,
);
