import React from 'react';
import { css } from 'emotion';
import format from 'date-fns/format';
import { BoardContent, BoardType, SimpleUser } from '../../gql-types';
import { Link } from 'react-router-dom';
import { TableStyle } from './CommunityTable';

interface Props {
  type: BoardType;
  prefix?: string;
  showAuthor?: boolean;
  items: BoardContent[];
}

function fullName(user: SimpleUser): string {
  const { nickname } = user;
  if (!nickname) {
    return '유저';
  }

  return nickname;
}

export class BoardListView extends React.Component<Props> {
  render() {
    const { items, showAuthor } = this.props;
    const prefix = this.props.prefix || 'community';
    return (
      <table className={TableStyle}>
        <thead>
          <tr>
            <th>제목</th>
            {showAuthor && <th>글쓴이</th>}
            <th>등록일</th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => {
            return (
              <tr key={item.id}>
                <td
                  className={css({
                    wordBreak: 'keep-all',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    maxWidth: 0,
                    width: '100%',
                  })}
                >
                  <Link to={`/${prefix}/${this.props.type}/${item.id}`}>
                    {item.title}
                  </Link>
                </td>
                {showAuthor && <td>{fullName(item.user)}</td>}
                <td
                  className={css({
                    minWidth: 130,
                  })}
                >
                  {format(item.created, 'YYYY-MM-DD')}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}
