import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Button, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

interface Props {
  reviewId: string;
}

const REMOVE_REVIEW_BUTTON = gql`
  mutation RemoveReview($id: String!) {
    removeReview(id: $id) {
      id
      deleted
    }
  }
`;

export function RemoveReviewButton(props: Props) {
  const { reviewId } = props;
  return (
    <Mutation
      mutation={REMOVE_REVIEW_BUTTON}
      variables={{
        id: reviewId,
      }}
    >
      {(update, result) => {
        return (
          <Button
            icon={IconNames.REMOVE}
            loading={result.loading}
            disabled={result.loading}
            intent={Intent.DANGER}
            onClick={() => update()}
          />
        );
      }}
    </Mutation>
  );
}
