import React from 'react';
import { css } from 'emotion';

interface Props {
  size: string | number;
  children?: React.ReactNode;
}

const Container = css({
  borderRadius: '1.875em',
  width: '13.5em',
  overflow: 'hidden',
  backgroundColor: 'white',
});

const Header = css({
  height: '2.5em',
});

const Content = css({
  width: '13.5em',
  height: '24em',
  position: 'relative',
});

const Button = css({
  borderRadius: '100%',
  border: '3px solid rgba(0,0,0,0.2)',
  width: '1.875em',
  height: '1.875em',
});

const Footer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '3.75em',
});

export function IPhoneContainer(props: Props) {
  return (
    <div className={Container} style={{ fontSize: props.size }}>
      <div className={Header} />
      <div className={Content}>{props.children}</div>
      <div className={Footer}>
        <div className={Button} />
      </div>
    </div>
  );
}
