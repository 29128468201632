import differenceInSeconds from 'date-fns/difference_in_seconds';
import subHours from 'date-fns/sub_hours';
import addHours from 'date-fns/add_hours';
import differenceInDays from 'date-fns/difference_in_days';
import differenceInHours from 'date-fns/difference_in_hours';
import differenceInMinutes from 'date-fns/difference_in_minutes';



export function MembershipDuration(
  expiresAt: number | string | Date,
  now = new Date(),
) {
  let duration;
  const exp = addHours(expiresAt, 9);
  const daysLeft = differenceInDays(exp, now);
  const secondsLeft = differenceInSeconds(exp, now);

  if (daysLeft > 0) {
    duration = `${daysLeft}일`;
  } else {
    const hoursLeft = differenceInHours(exp, now);
    const minutesLeft = differenceInMinutes(exp, addHours(now, hoursLeft));

    duration = `${hoursLeft}시간 ${minutesLeft}분`;
  }

  const isExpired = secondsLeft < 0;
  return isExpired ? '만료' : `${duration} 남음`;
}
