import React from 'react';
import get from 'lodash/get';
import styled, { css } from 'react-emotion';
import {
  Card,
  InteractiveStyle,
  theme,
  darkTextColors,
} from '../../components';
import {
  Product,
  Discount,
  SubscriptionLevel,
  ProductType,
} from '../../gql-types';
import DurationChooser from './DurationChooser';
import LevelChooser from './LevelChooser';
import {
  FormGroup,
  Switch,
  Overlay,
  Button as BPButton,
  Intent,
} from '@blueprintjs/core';
import '@blueprintjs/icons';
import { CheckoutPrice } from '../cart/PaymentService';

export interface IPricingValue {
  month: number;
  level: SubscriptionLevel;
  gears: boolean;
}

interface Props {
  value: IPricingValue;
  subscriptons: Product[];
  gear?: Product;
  discounts: Discount[];
  onChange(value: IPricingValue): void;
  onNext(): void;
}

const Button = styled('button')(InteractiveStyle, {
  outline: 0,
  border: 'none',
  borderRadius: '0.2em',
  padding: '0.5em 2em',
  color: darkTextColors.primary,
  cursor: 'pointer',
  fontWeight: 'bold',
  fontSize: '2em',
  backgroundColor: theme.primaryColor,
  ':focus': {
    outline: 0,
  },
});

const Container = styled('section')({
  width: '100%',
  maxWidth: 800,
});

const MarginedItem = styled('div')({
  marginBottom: '1em',
});

export class PricingChooser extends React.Component<Props> {
  state = {
    gearModal: false,
  };

  private handleMonthChange = (month: number) => {
    const { value } = this.props;
    this.props.onChange({
      ...value,
      month,
    });
  };

  private handleLevelChange = (level: SubscriptionLevel) => {
    const { value } = this.props;
    this.props.onChange({
      ...value,
      level,
    });
  };

  private handleGearsChange = () => {
    const { value } = this.props;
    const gears = !value.gears;
    const newValue = {
      ...value,
      gears,
    };

    const cb = () => this.props.onChange(newValue);

    if (!gears) {
      // show modal
      this.setState(
        {
          gearModal: true,
        },
        cb,
      );
      return;
    } else {
      cb();
    }
  };

  private handleClose = () => {
    this.setState({ gearModal: false });
  };

  private selectGears = () => {
    this.setState({ gearModal: false }, () => {
      this.props.onChange({
        ...this.props.value,
        gears: true,
      });
    });
  };

  render() {
    const {
      value: { month, level, gears },
      discounts,
      gear,
    } = this.props;

    const discount = discounts.find(d => {
      try {
        const filter: any = JSON.parse(d.filterJson);
        return (
          get(filter, 'duration') === month &&
          get(filter, 'type') === ProductType.physical
        );
      } catch (err) {
        return false;
      }
    });

    const originalPrice = gear ? gear.price : 0;
    const discountPrice = gear
      ? CheckoutPrice({
          product: gear,
          quantity: 1,
          discount: discount,
        }).actual
      : 0;

    return (
      <Container>
        <Overlay
          isOpen={this.state.gearModal}
          hasBackdrop
          usePortal
          onClose={this.handleClose}
        >
          <div
            className={css({
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100vw',
              height: '100%',
              zIndex: 20,
            })}
          >
            <Card
              style={{
                width: '80%',
                maxWidth: 500,
              }}
              onClick={e => e.stopPropagation()}
            >
              <div
                className={css({
                  marginBottom: '1em',
                })}
              >
                <div>
                  운동기구를 활용하면, 시크릿다이어트 운동 프로그램의 효과를
                  100% 확인 하실 수 있습니다.
                </div>
                <div>운동기구를 함께 구매하시겠습니까?</div>
              </div>
              <div
                className={css({
                  display: 'flex,',
                  justifyContent: 'center',
                })}
              >
                <BPButton
                  style={{
                    borderRadius: '0.2em',
                    color: 'black',
                  }}
                  intent={Intent.PRIMARY}
                  onClick={this.selectGears}
                  large
                  fill
                >
                  함께 구입하기
                </BPButton>
              </div>
              <div
                className={css({
                  display: 'flex,',
                  justifyContent: 'center',
                })}
              >
                <BPButton
                  style={{
                    borderRadius: '0.2em',
                  }}
                  large
                  fill
                  onClick={this.handleClose}
                >
                  이미 가지고 있습니다
                </BPButton>
              </div>
            </Card>
          </div>
        </Overlay>
        <MarginedItem>
          <DurationChooser month={month} onChange={this.handleMonthChange} />
        </MarginedItem>
        <MarginedItem>
          <LevelChooser
            level={level}
            month={month}
            subscriptions={this.props.subscriptons}
            discounts={this.props.discounts}
            onChange={this.handleLevelChange}
          />
        </MarginedItem>
        <MarginedItem>
          {/* <Card
            style={{
              backgroundColor: 'rgb(35,35,35)',
              color: 'white',
            }}
          >
            <FormGroup
              labelFor="gears"
              helperText={
                <div
                  style={{
                    color: 'white',
                  }}
                >
                  <div>가격: {originalPrice.toLocaleString()} 원</div>
                  <div>* 구성품: 요가매트, 덤벨, 튜빙밴드</div>
                  <div>* 시크릿다이어트는 운동기구가 필요합니다.</div>
                </div>
              }
            >
              <Switch
                id="gears"
                name="gears"
                large
                style={{
                  color: 'white',
                  marginBottom: 0,
                }}
                label={`운동기구 추가: ${discountPrice.toLocaleString()}원`}
                checked={gears}
                onChange={this.handleGearsChange}
              />
            </FormGroup>
          </Card> */}
        </MarginedItem>
        <MarginedItem
          className={css({
            display: 'flex',
            justifyContent: 'center',
          })}
        >
          <Button onClick={this.props.onNext}>구매하기</Button>
        </MarginedItem>
      </Container>
    );
  }
}

export default PricingChooser;
