import {
  FormGroup,
  ControlGroup,
  InputGroup,
  Intent,
  Callout,
} from '@blueprintjs/core';
import React from 'react';
import { NicknameUpdateButton } from './NicknameUpdateButton';
import { NicknameVerifyButton } from './NicknameVerifyButton';
import { ApolloError } from 'apollo-client';

interface Props {
  value?: string;
}

interface State {
  value: string;
  loading: boolean;
  verified: boolean;
  completed: boolean;
  error: ApolloError | Error | null;
  helperText: string;
}

export class UserNicknameInput extends React.Component<Props, State> {
  state: State = {
    value: this.props.value || '',
    verified: false,
    completed: false,
    loading: false,
    error: null,
    helperText: '',
  };

  componentWillReceiveProps(nextProps: Props) {
    if (
      this.props.value !== nextProps.value &&
      nextProps.value !== this.state.value
    ) {
      this.setState({ value: nextProps.value || '' });
    }
  }

  private handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    this.setState({ value, verified: false });
  };

  private handleUpdate = () => {
    this.setState({
      loading: true,
      completed: false,
      error: null,
      helperText: '',
    });
  };

  private handleComplete = (message: string) => {
    this.setState({
      loading: false,
      verified: true,
      error: null,
      completed: true,
      helperText: message,
    });
  };

  private handleError = (error: ApolloError | Error) => {
    this.setState({
      loading: false,
      value: this.props.value || '',
      error,
    });
  };

  render() {
    const requiresUpdate = !Boolean(this.props.value);
    const { error, completed, verified } = this.state;
    const intent = error
      ? Intent.DANGER
      : completed
        ? Intent.SUCCESS
        : Intent.NONE;
    const helperText = error ? error.message : this.state.helperText;

    return (
      <FormGroup
        label="유저 아이디"
        labelFor="아이디"
        intent={intent}
        helperText={helperText}
      >
        <ControlGroup fill>
          <InputGroup
            id="nickname"
            name="nickname"
            formNoValidate
            readOnly={!requiresUpdate}
            type="nickname"
            intent={intent}
            value={this.state.value}
            onChange={this.handleChange}
          />
          {requiresUpdate &&
            verified && (
              <div
                style={{
                  flex: '0 1 auto',
                }}
              >
                <NicknameUpdateButton
                  nickname={this.state.value}
                  onCompleted={this.handleComplete}
                  onError={this.handleError}
                  onUpdate={this.handleUpdate}
                />
              </div>
            )}
          {requiresUpdate &&
            !verified && (
              <div
                style={{
                  flex: '0 1 auto',
                }}
              >
                <NicknameVerifyButton
                  nickname={this.state.value}
                  onCompleted={this.handleComplete}
                  onError={this.handleError}
                />
              </div>
            )}
        </ControlGroup>
        {!this.props.value && (
          <Callout intent={Intent.WARNING} title="유저아이디를 입력해 주세요">
            <p>더 나은 서비스 제공을 위해서 유저 아이디를 설정해주세요.</p>
            <p>유저아이디는 저장 이후, 변경이 불가능합니다.</p>
          </Callout>
        )}
      </FormGroup>
    );
  }
}
