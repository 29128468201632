import React from 'react';
import { CurrencyCode } from '../gql-types';
import { number } from 'prop-types';

interface Props {
  className?: string;
  amount: number;
  currencyCode: CurrencyCode;
}

function formatNumber(amount: number, currency: CurrencyCode) {
  if (amount.toLocaleString) {
    return amount.toLocaleString('ko-KR', { currency }) + ' 원';
  }

  return `${amount} 원`;
}

export function PriceLabel(props: Props) {
  return (
    <div className={props.className}>
      {formatNumber(props.amount, props.currencyCode)}
    </div>
  );
}
