import {
  InMemoryCache,
  IdGetterObj,
  defaultDataIdFromObject,
} from 'apollo-cache-inmemory';

export function getState(): any {
  const appState = (window as any).__SECRET_APP_STATE__;
  if (!appState) {
    return {};
  }
  return appState;
}

function dataIdFromObject(object: IdGetterObj) {
  switch (object.__typename) {
    case 'UserSubscription': {
      const obj = object as any;
      return obj.id + ':' + obj.level + ':' + obj.courseName;
    }
    case 'SocialAccount': {
      const obj = object as any;
      return obj.type + ':' + obj.accountId;
    }
    default:
      return defaultDataIdFromObject(object);
  }
}

export async function configureCache() {
  const cache = new InMemoryCache({
    dataIdFromObject,
  });
  return cache;
}
