import React from 'react';
import { css } from 'emotion';
import { Card, OvalButton } from '../../components';
import {
  Lecture,
  User,
  UserSubscription,
  SubscriptionLevel,
} from '../../gql-types';
import { MembershipExtensionView } from './MembershipExtensionView';

interface Props {
  user: User;
  onExtensionRequest(): void;
}

const compareMembership = (a: UserSubscription, b: UserSubscription) => {
  if (a.level === SubscriptionLevel.basic) {
    return -1;
  } else if (a.level === SubscriptionLevel.course) {
    return 0;
  }

  return 1;
};

const courseStyle = {
  display: 'grid',
  rowGap: '1em',
  marginTop: '0.83em',
  marginRight: '0.83em',
  padding: '1em',
  // maxWidth: '1000px',
  width: '73%',
  minHeight: '150px',
  fontSize: '0.8em',
  border: '1px solid rgb(51, 185, 184)',
  borderRadius: '1em',
  '@media screen and (max-width: 1000px)': {
    width: '100%',
  }
};

const courseAllStyle = {
  display: 'grid',
  rowGap: '1em',
  marginTop: '0.83em',
  marginRight: '0.83em',
  padding: '1em',
  // maxWidth: '250px',
  width: '22%',
  minHeight: '150px',
  fontSize: '0.8em',
  border: '1px solid rgb(51, 185, 184)',
  borderRadius: '1em',
  '@media screen and (max-width: 1000px)': {
    width: '100%',
  }
};

export function MembershipInfoView(props: Props) {
  const {
    user: { subscriptions },
  } = props;

  const allMembership = subscriptions.filter((sub) => sub.level === SubscriptionLevel.basic || sub.level === SubscriptionLevel.advanced);
  const courseMembership = subscriptions.filter((sub) => sub.level === SubscriptionLevel.course);

  const hasAllMembership = allMembership.length > 0;
  const hasCourseMembership = courseMembership.length > 0;
  return (
    <div className={css({
      display: 'flex',
      flexWrap: 'wrap'
    })}>
      <section className={css(courseAllStyle)}>
        <div className={css({ fontWeight: 'bold' })}>모든 코스 이용권</div>
        {!hasAllMembership && <div>멤버십 없음</div>}
        {allMembership.sort(compareMembership).map(sub => {
          return <MembershipExtensionView key={sub.level} subscription={sub} />;
        })}
        <OvalButton fontSize="1em" onClick={props.onExtensionRequest}>
          {hasAllMembership ? '연장하기' : '신청하기'}
        </OvalButton>
      </section>
      <section className={css(courseStyle)}>
        <div className={css({ fontWeight: 'bold' })}>코스별 이용권</div>
        {!hasCourseMembership && <div>멤버십 없음</div>}
        {courseMembership.sort(compareMembership).map(sub => {
          return <MembershipExtensionView key={sub.level} subscription={sub} />;
        })}
        <div className={css({ fontSize: '0.8em', color: '#aaaaaa' })}>
          * 코스별 이용권 구입은 스마트폰 앱에서만 가능합니다.
        </div>
      </section>
    </div>
  );
}
