import React from 'react';
import { css } from 'emotion';
import { EmphasiedText } from './AboutStyles';

export const PTDescriptionItem = (props: {
  src: string;
  title: string;
  items: string[];
}) => {
  return (
    <>
      <div
        className={css({
          marginRight: '0.5em',
        })}
      >
        <img className={css({ width: '100%' })} src={props.src} />
      </div>
      <div>
        <div
          className={css({
            fontSize: '1em',
          })}
        >
          <EmphasiedText>{props.title}</EmphasiedText>
        </div>
        <ul
          className={css({
            paddingLeft: '1.2em',
            fontSize: '0.65em',
            lineHeight: '1.5em',
          })}
        >
          {props.items.map((item, index) => {
            return <li key={index}>{item}</li>;
          })}
        </ul>
      </div>
    </>
  );
};
