import React from 'react';
import { format, parse } from 'date-fns';
import { DateInput, IDatePickerLocaleUtils } from '@blueprintjs/datetime';

const weekdays = ['일', '월', '화', '수', '목', '금', '토'];
const range = (length: number) => Array.from({ length }, (_, i) => i);

const customLocaleUtils: IDatePickerLocaleUtils = {
  formatDay(day, locale) {
    return format(day, 'ddd ll');
  },
  formatMonthTitle(date, locale) {
    return format(date, 'MMMM YYYY');
  },
  formatWeekdayLong(day, local) {
    return weekdays[day] + '요일';
  },
  formatWeekdayShort(day, local) {
    return weekdays[day];
  },
  getFirstDayOfWeek(locale) {
    return 0;
  },
  getMonths(locale: string) {
    return range(12).map(i => `${i + 1} 월`) as any;
  },
};

const formatBirthday = (d: Date | null) => {
  if (!d) {
    return '';
  }

  return format(d, 'YYYY-MM-DD');
};

const parseBirthday = (str: string) => parse(str);

interface Props {
  value?: string | null;
  onChange(value: string): void;
}

export class BirthdayInput extends React.Component<Props> {
  render() {
    const value =
      this.props.value === '' ? null : parse(this.props.value as any);
    return (
      <DateInput
        value={value}
        canClearSelection={false}
        minDate={parse('1920-01-01')}
        localeUtils={customLocaleUtils}
        placeholder="YYYY-MM-DD"
        formatDate={formatBirthday}
        parseDate={parseBirthday}
        onChange={d => this.props.onChange(formatBirthday(d))}
      />
    );
  }
}
