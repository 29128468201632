import React from 'react';
import { History } from 'history';
import { css } from 'emotion';
import { Card } from '../../components';

interface Props {
  history: History;
}

const styles = {
  section: css({ minWidth: 320 }),
  imageContainer: css({
    width: '100%',
    position: 'relative',
    textAlign: 'center',
  }),
  image: css({
    width: '70%',
    marginBottom: '2em',
  }),
  description: css({
    marginBottom: '1em',
  }),
  otherImage: css({ width: '100%' }),
};

export class BoxingBibleDescription extends React.Component<Props> {
  render() {
    return (
      <div className={styles.section}>
        <Card media="https://storage.googleapis.com/secretdiet-public-images/programs/v2/secret-boxing-bible.jpg">
          <h1>시크릿 복싱 바이블</h1>
        </Card>
      </div>
    );
  }
}
