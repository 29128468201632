import React from 'react';
import gql from 'graphql-tag';
import { ApolloError } from 'apollo-client';
import { Mutation } from 'react-apollo';
import { Button, Intent } from '@blueprintjs/core';
import trim from 'lodash/trim';

interface Props {
  email: string;
  onError(error: ApolloError): void;
  onUpdate(): void;
  onCompleted(message: string): void;
}

export const UPDATE_EMAIL_MUTATION = gql`
  mutation($email: String!) {
    user: updateEmail(email: $email) {
      id
      email
      emailVerified
    }
  }
`;

export function EmailUpdateButton(props: Props) {
  return (
    <Mutation
      mutation={UPDATE_EMAIL_MUTATION}
      onError={props.onError}
      onCompleted={() => props.onCompleted('업데이트 되었습니다')}
      variables={{
        email: trim(props.email),
      }}
    >
      {(update, obj) => {
        return (
          <Button
            id="emailUpdate"
            intent={Intent.NONE}
            disabled={obj.loading}
            loading={obj.loading}
            onClick={() => {
              update();
              props.onUpdate();
            }}
          >
            저장
          </Button>
        );
      }}
    </Mutation>
  );
}
