import decode from 'jwt-decode';

export const GetLoginStatusOperation: string = 'GetLoginStatus';

export class LoginStatusModel {
  static tokenKey = 'authToken';

  readonly storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  getAuthToken(): string | null {
    try {
      return this.storage.getItem(LoginStatusModel.tokenKey);
    } catch (ignore) {
      return null;
    }
  }

  updateAuthToken(authToken: string) {
    try {
      this.storage.setItem(LoginStatusModel.tokenKey, authToken);
    } catch (ignore) {
      return;
    }
  }

  clear() {
    try {
      this.storage.removeItem(LoginStatusModel.tokenKey);
    } catch (ignore) {
      return;
    }
  }

  isExpired(token: string) {
    try {
      const now = Math.floor(Date.now() / 1000);
      const decoded = decode<any>(token, { header: true });
      if (!decoded || typeof decoded === 'string') {
        return false;
      }

      const expiresAt = decoded.exp;
      return now >= expiresAt;
    } catch (err) {
      return true;
    }
  }

  static forBrowser() {
    return new LoginStatusModel(window.localStorage);
  }
}
