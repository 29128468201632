import React from 'react';
import Helmet from 'react-helmet';
import { Switch, Route as ReactRouterRoute } from 'react-router';
import Route from './Route';
import viewRoutes from './routes';
import MainLayout from './layouts/MainLayout';
import { WithCurrentUser, ICurrentUserProps } from './users/CurrentUserQuery';

interface Props extends ICurrentUserProps {}

export class SecretDietAppBase extends React.Component<Props> {
  private reportUserSession = () => {
    if (gtag && typeof gtag === 'function') {
      const { user } = this.props;
      if (user) {
        gtag('set', { user_id: user.id });
      }
    }
  };

  componentDidMount() {
    var browse = navigator.userAgent.toLowerCase(); 
        
    if( (navigator.appName == 'Netscape' && browse.indexOf('trident') != -1) || (browse.indexOf("msie") != -1)) {
      alert("시크릿 다이어트는 IE를 더이상 지원하지 않습니다.\n크롬으로 실행해주세요.");        
    }
    this.reportUserSession();
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.user && this.props.user) {
      this.reportUserSession();
    }
  }

  render() {
    return (
      <MainLayout>
        <Helmet>
          <meta charSet="UTF-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, minimum-scale=1"
          />
          <link
            href="https://unpkg.com/normalize.css@^7.0.0"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
          <link rel="canonical" href="https://www.isecretdiet.com" />
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-109854845-2"
          />
          <script>
            {`window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'UA-109854845-2');`}
          </script>
        </Helmet>
        <Switch>
          {viewRoutes.map(r => (
            <Route
              key={r.path}
              exact
              title={r.title}
              component={r.component}
              path={r.path}
              strict={r.strict}
            />
          ))}
          <ReactRouterRoute component={() => <div>not found</div>} />
        </Switch>
      </MainLayout>
    );
  }
}

export default WithCurrentUser(SecretDietAppBase);
