import { css } from 'emotion';
import get from 'lodash/get';
import React from 'react';
import { Discount, Product, SubscriptionLevel } from '../../gql-types';
import { Card } from '../../components';
import { CheckoutPrice } from '../cart/PaymentService';

interface Props {
  discounts: Discount[];
  level: SubscriptionLevel;
  subscriptions: Product[];
  month: number;
  onChange(level: SubscriptionLevel): void;
}

function productWith(level: string, duration: number) {
  return (p: Discount) => {
    try {
      const filter: any = JSON.parse(p.filterJson);
      return (
        get(filter, 'duration') === duration && get(filter, 'level') === level
      );
    } catch (err) {
      return false;
    }
  };
}

function ItemCard(props: {
  popular?: boolean;
  active?: boolean;
  title: string;
  list: string[];
  original: number;
  price: number;
  totalOriginal: number;
  totalPrice: number;
  onSelect(): void;
}) {
  const hasDiscount = props.original !== props.price;

  return (
    <Card
      className={css({
        backgroundColor: props.active ? '#69dbda' : 'rgb(35,35,35)',
        overflow: 'visible',
        position: 'relative',
        height: '100%',
        margin: '0 auto',
        width: '50%',
      })}
      contentStyle={{
        height: '100%',
      }}
      onClick={props.onSelect}
    >
      {props.popular && (
        <div
          className={css({
            position: 'absolute',
            zIndex: 2,
            width: '10em',
            marginLeft: 'calc(50% - 6em)',
            marginTop: '-2em',
          })}
        >
          <Card
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
            }}
            contentStyle={{
              padding: '4px 2px',
            }}
          >
            인기상품
          </Card>
        </div>
      )}
      <div
        className={css({
          color: props.active ? 'black' : 'white',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        })}
      >
        <div>
          <h2
            style={{
              textAlign: 'center',
            }}
          >
            {props.title}
          </h2>
          <ul
            className={css({
              '@media(max-width: 480px)': {
                display: 'none',
              },
            })}
          >
            {props.list.map((item, index) => {
              return <li key={index}>{item}</li>;
            })}
          </ul>
        </div>
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          })}
        >
          {hasDiscount && (
            <div>
              <div
                className={css({
                  fontSize: '2em',
                  fontWeight: 'bold',
                  textDecoration: 'line-through',
                  textDecorationColor: 'red',
                  marginTop: '5px',
                })}
              >
                {props.totalOriginal.toLocaleString()} 원
              </div>
              <div
              className={css({
                fontSize: '1.0em',
                fontWeight: 'bold',
                textDecoration: 'line-through',
                textDecorationColor: 'red',
              })}
            >
              {props.original.toLocaleString()} 원 / 월
            </div>
          </div>
          )}
          <div
            className={css({
              fontSize: '2em',
              fontWeight: 'bold',
              marginTop: '5px',
            })}
          >
            {props.totalPrice.toLocaleString()} 원
          </div>
          <div
            className={css({
              fontSize: '1.0em',
            })}
          >
            {props.price.toLocaleString()} 원 / 월
          </div>
        </div>
      </div>
    </Card>
  );
}

function LevelChooser(props: Props) {
  const { month, subscriptions, discounts, level } = props;
  const video = subscriptions.find(
    sub =>
      sub.level === SubscriptionLevel.basic && sub.durationInMonth === month,
  );

  const consulting = subscriptions.find(
    sub =>
      sub.level === SubscriptionLevel.advanced && sub.durationInMonth === month,
  );

  const videoPrice = video
    ? CheckoutPrice({
        product: video,
        quantity: 1,
        discount: discounts.find(productWith(SubscriptionLevel.basic, month)),
      })
    : { original: 0, actual: 0 };

  const consultingPrice = consulting
    ? CheckoutPrice({
        product: consulting,
        quantity: 1,
        discount: discounts.find(
          productWith(SubscriptionLevel.advanced, month),
        ),
      })
    : { original: 0, actual: 0 };

  return (
    <div
      className={css({
        display: 'grid'
      })}
    >
      <div
        className={css({
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'center',
          flexDirection: 'row',
          marginBottom: '10px',
        })}
      >
        <img
          style={{
            width: '20%'
          }}
          src="https://storage.googleapis.com/secretdiet-public-images/card-course-img1.png"
        />
        <img
          style={{
            width: '20%'
          }}
          src="https://storage.googleapis.com/secretdiet-public-images/card-course-img2.png"
        />
        <img
          style={{
            width: '20%'
          }}
          src="https://storage.googleapis.com/secretdiet-public-images/card-course-img3.png"
        />
        <img
          style={{
            width: '20%'
          }}
          src="https://storage.googleapis.com/secretdiet-public-images/card-course-img4.png"
        />
        <img
          style={{
            width: '20%'
          }}
          src="https://storage.googleapis.com/secretdiet-public-images/card-course-img5.png"
        />
      </div>
      <ItemCard
        active={level === SubscriptionLevel.basic}
        title="무제한 스트리밍"
        list={[
          '수백개의 전문 트레이닝 세션',
          '쳬계적이고 검증된 트레이닝 프로그램',
          '13가지 컨텐츠를 함께 보는 프리미엄 서비스',
        ]}
        original={videoPrice.original / month}
        price={videoPrice.actual / month}
        totalOriginal = {videoPrice.original}
        totalPrice = {videoPrice.actual}
        onSelect={() => props.onChange(SubscriptionLevel.basic)}
      />
    </div>
  );
}

export default LevelChooser;
