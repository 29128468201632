import React from 'react';
import styled from 'react-emotion';
import { VariableFontSize } from '../home/HomeStyles';
import { WhatisSecretdiet } from '../aboutus/WhatisSecretdiet';
import { RouteComponentProps } from 'react-router';
import { TotalSolutionMarketing } from '../aboutus/TotalSolutionMarketing';
import { AboutTrainers } from '../aboutus/AboutTrainers';
import { WithCurrentUser, ICurrentUserProps } from '../users/CurrentUserQuery';

const PageContainer = styled('section')(
  {
    position: 'relative',
    width: '100%',
    backgroundColor: '#eee',
    minWidth: 360,
    '& > section:nth-child(even)': {
      backgroundColor: 'white',
    },
    '& > section:nth-child(odd)': {
      backgroundColor: 'rgb(35,35,35)',
    },
  },
  VariableFontSize,
);

interface Props extends RouteComponentProps<any>, ICurrentUserProps {}

class AboutUsPage extends React.Component<Props> {
  private handleStartNow = () => {
    const { user } = this.props;
    const hasSubscription = Boolean(
      user && user.subscriptions && user.subscriptions.length > 0,
    );

    const { history } = this.props;
    const route = hasSubscription ? '/my-fitness' : '/plans';
    history.push(route);
  };

  render() {
    return (
      <div style={{ width: '100%', backgroundColor: 'white' }}>
        <WhatisSecretdiet onStartNow={this.handleStartNow} />
        <TotalSolutionMarketing />
        <AboutTrainers />
      </div>
    );
  }
}

export default WithCurrentUser(AboutUsPage);
