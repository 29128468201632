import { ApolloClient } from 'apollo-client';
import gql from 'graphql-tag';
import get from 'lodash/get';

import {
  AddressInput,
  Coupon,
  ProductType,
  Discount,
  DiscountType,
  Product,
  User,
} from '../../gql-types';
import { GraphlErrors } from './GraphlErrors';
import { Currency } from '../../secret-exchange';

const TRY_SUBSCRIPTION_PURCHASE = gql`
  mutation tryCheckout(
    $paymentService: String
    $items: [CartItemInput!]
    $points: Float
    $coupon: String
    $address: AddressInput
  ) {
    invoice: tryCheckout(
      paymentService: $paymentService
      items: $items
      points: $points
      coupon: $coupon
      address: $address
    ) {
      id
      currencyCode
      totalPrice
    }
  }
`;

const COMPLETE_SUBSCRIPTION_PURCHASE = gql`
  mutation CompletePurchase($invoiceId: String!, $iamportId: String!) {
    markInvoiceAsSuccess(invoiceId: $invoiceId, pgId: $iamportId)
  }
`;

const FAIL_SUBSCRIPTION_PURCHASE = gql`
  mutation failSubscriptionPurchase($invoiceId: String!, $iamportId: String!) {
    markInvoiceAsFailed(invoiceId: $invoiceId, pgId: $iamportId)
  }
`;

export interface CheckoutItem {
  product: Product;
  quantity: number;
  discount?: Discount;
  options?: string | null;
}

export const AvailablePaymentMethods = {
  card: 'html5_inicis',
  samsung: 'html5_inicis',
  trans: 'html5_inicis',
  vbank: 'html5_inicis',
  phone: 'html5_inicis',
  point: 'kcp',
  paypal: 'paypal',
};

export function getPgType(method: string) {
  return get(AvailablePaymentMethods, method, 'html5_inicis');
}

export function getPaymentMethod(method: string) {
  if (method === 'paypal') {
    return 'card';
  }

  return method;
}

export function impRequestPay(params: IMPParams) {
  return new Promise<IMPCallbackParam>(resolve => {
    IMP.request_pay(params, resp => {
      resolve(resp);
    });
  });
}

function getUserName(user?: User | null): string {
  const defaultValue = 'Anonymous';
  if (!user) {
    return defaultValue;
  }

  const name = user.name;
  if (!name) {
    return defaultValue;
  }

  return name;
}

function getUserEmail(user?: User | null): string {
  const defaultValue = 'support@secretdiet.co.kr';
  if (!user) {
    return defaultValue;
  }

  const email = user.email;
  if (!email) {
    return defaultValue;
  }

  return email;
}

export async function checkout(options: {
  user?: User | null;
  address?: AddressInput;
  invoiceName: string;
  method: string;
  points?: number;
  items: CheckoutItem[];
  client: ApolloClient<any>;
  coupon?: string;
  pg: string;
}) {
  const { client, user, pg, address, method } = options;
  const items = options.items.map(item => ({
    productId: item.product.id,
    quantity: item.quantity,
    discountId: item.discount && item.discount.id,
    options: item.options,
  }));

  const result = await client.mutate({
    mutation: TRY_SUBSCRIPTION_PURCHASE,
    variables: {
      paymentService: pg,
      items,
      coupon: options.coupon,
      address: options.address,
      points: options.points,
    },
  });

  if (!result.data) {
    // clone errors array.
    if (result.errors) {
      const errors = Array.from(result.errors);
      throw new GraphlErrors(errors);
    }
  }

  const invoiceId = get(result, 'data.invoice.id') as string;
  const totalPrice = get(result, 'data.invoice.totalPrice') as number;
  const hostname = () => `${window.location.protocol}//${window.location.host}`;
  const currency = pg === 'paypal' ? Currency.USD : Currency.KRW;

  const resp = await impRequestPay({
    pg,
    amount: totalPrice,
    pay_method: method,
    company: '시크릿다이어트',
    name: `시크릿다이어트 구독`,
    merchant_uid: `${invoiceId}`,
    buyer_name: getUserName(user),
    buyer_addr: address ? `${address.address1}` : undefined,
    buyer_email: getUserEmail(user),
    buyer_postcode: address ? String(address.postalCode) : undefined,
    // TODO:: use company phone number as contact
    buyer_tel: address ? String(address.mobile) : '02-6416-5653',
    m_redirect_url: `${hostname()}/api/payments`,
    currency: currency.code,
    bypass:
      method === 'point'
        ? {
            complex_pnt_yn: 'Y',
          }
        : undefined,
  });

  if (resp.success) {
    await client.mutate({
      mutation: COMPLETE_SUBSCRIPTION_PURCHASE,
      refetchQueries: ['GetCurrentLoginStatus'],
      variables: {
        invoiceId,
        iamportId: resp.imp_uid,
      },
    });

    if (gtag && typeof gtag === 'function') {
      gtag('event', 'purchase', {
        transaction_id: invoiceId,
        value: totalPrice,
        currency: currency.code,
      });
    }

    return invoiceId;
  } else {
    await client.mutate({
      mutation: FAIL_SUBSCRIPTION_PURCHASE,
      variables: {
        invoiceId,
        iamportId: resp.imp_uid,
      },
    });
    throw new Error(resp.error_msg);
  }
}

export function CouponPrice(price: number, coupon?: Coupon | null) {
  if (!coupon) {
    return price;
  }

  const { discountType, discountAmount } = coupon;
  if (!discountAmount) {
    return price;
  }

  switch (discountType) {
    case DiscountType.fixed:
      return price - discountAmount;
    case DiscountType.percent:
      return price * (1.0 - discountAmount);
    default:
      return price;
  }
}

function shouldSkip(product: Product, coupon?: Coupon | null): boolean {
  return true;
}

export function CouponProductPrice(product: Product, coupon?: Coupon | null) {
  if (shouldSkip(product, coupon)) {
    return product.price;
  }

  return CouponPrice(product.price, coupon);
}

const DiscountPrice = (item: {
  product: Product;
  quantity: number;
  discount?: Discount;
}) => {
  const { product, discount, quantity } = item;
  if (!discount) {
    return product.price * quantity;
  }

  const type = discount.type;
  switch (type) {
    case DiscountType.fixed:
      return Math.floor(product.price * quantity - discount.amount);
    case DiscountType.percent:
      return Math.floor(product.price * quantity * (1 - discount.amount));
    default:
      return product.price * quantity;
  }
};

export function calculateInvoicePrice(
  items: CheckoutItem[],
  coupon?: Coupon | null,
  point?: number,
) {
  const pointPrice = point ? point : 0;
  return (
    items.reduce((total, item) => {
      const price = DiscountPrice(item);
      return total + price;
    }, 0) - pointPrice
  );
}

export function hasAnyGood(products: Product[]): boolean {
  return products.some(product => product.productType === ProductType.physical);
}

export function calculateDeliveryFee(
  totalPrice: number,
  hasGoods: boolean,
  address?: AddressInput,
): number {
  if (!hasGoods) {
    return 0;
  }
  if (address && address.country !== 'KOR') {
    return 0;
  }

  if (totalPrice >= 25000) {
    return 0;
  }

  if (totalPrice < 25000) {
    return 5000;
  }

  return 0;
}

export function CheckoutPrice(item: CheckoutItem) {
  const { product, discount } = item;
  if (!discount) {
    return {
      original: product.price,
      actual: product.price,
    };
  }

  const type = discount.type;
  switch (type) {
    case DiscountType.fixed:
      return {
        original: product.price,
        actual: Math.floor(product.price - discount.amount),
      };
    case DiscountType.percent:
      return {
        original: product.price,
        actual: Math.floor(product.price * (1 - discount.amount)),
      };
    default:
      return {
        original: product.price,
        actual: product.price,
      };
  }
}
