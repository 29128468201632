import { Button, FormGroup, Intent } from '@blueprintjs/core';
import { ApolloClient } from 'apollo-client';
import React from 'react';
import { Card } from '../../components';
import { AddressInput, Coupon, ProductType, User } from '../../gql-types';
import AppToaster from '../AppToaster';
import { AddressView } from './AddressView';
import { CheckoutItemView } from './CheckoutItemView';
import { CouponApplyView } from './CouponApplyView';
import { PaymentMethodChooser } from './PaymentMethodChooser';
import {
  calculateDeliveryFee,
  calculateInvoicePrice,
  checkout,
  CheckoutItem,
  getPaymentMethod,
  getPgType,
  hasAnyGood,
} from './PaymentService';
import { EmptyCartView } from './EmptyCartView';

interface Props {
  user?: User | null;
  quantityLocked?: boolean;
  invoiceName: string;
  deleteDisabled?: boolean;
  items: CheckoutItem[];
  client: ApolloClient<any>;
  onSuccess(): void;
  onFail(err: any): void;
  onCancel?(): void;
  onItemUpdate?(obj: {
    productId: string;
    quantity: number;
    options?: string;
  }): void;
  onItemRemove?(obj: { productId: string; options?: string }): void;
}

interface State {
  method: string;
  coupon?: Coupon | null;
  address: AddressInput;
}

const defaultAddress = {
  address1: '',
  address2: '',
  mobile: '',
  phone: '',
  city: '',
  state: '',
  postalCode: '',
  country: 'KOR',
};

function PriceLabel(price: number): string {
  return Math.floor(price).toLocaleString();
}

function getAddressFromUser(user?: User | null): AddressInput {
  if (!user) {
    return defaultAddress;
  }
  const { address } = user;
  if (!address) {
    return defaultAddress;
  }

  return {
    address1: address.address1,
    address2: address.address2,
    mobile: address.mobile || '',
    phone: address.phone,
    city: address.city || '',
    state: address.state || '',
    postalCode: address.postalCode || '',
    country: address.country || '',
  };
}

export class CheckoutView extends React.Component<Props, State> {
  state: State = {
    method: '',
    coupon: null,
    address: getAddressFromUser(this.props.user),
  };

  private handlePaymethodSelect = (value: string) => {
    this.setState({ method: value });
  };

  private handleAddressChange = (address: AddressInput) => {
    this.setState({ address });
  };

  private checkout = async () => {
    try {
      const { client, user, invoiceName, items } = this.props;
      const { coupon, address } = this.state;
      const pg = getPgType(this.state.method);
      const method = getPaymentMethod(this.state.method);
      await checkout({
        pg,
        user,
        items,
        client,
        coupon: coupon ? coupon.value : undefined,
        method,
        invoiceName,
        address,
      });

      if (AppToaster) {
        AppToaster.show({
          intent: Intent.PRIMARY,
          message: '결제가 완료 되었습니다.',
        });
      }

      this.props.onSuccess();
    } catch (err) {
      this.props.onFail(err);
    }
  };

  private handleCouponReceieved = (coupon: Coupon) => {
    this.setState({ coupon });
  };

  render() {
    const disabled = this.state.method === '';
    const { items } = this.props;
    const originalPrice = calculateInvoicePrice(items);
    const totalPrice = calculateInvoicePrice(items, this.state.coupon);
    const hasGood = hasAnyGood(items.map(i => i.product));
    const deliveryFee = calculateDeliveryFee(
      originalPrice,
      hasGood,
      this.state.address,
    );

    const noItem = items.length === 0;

    return (
      <>
        {!noItem && Boolean(this.props.user) && (
          <AddressView
            user={this.props.user}
            address={this.state.address}
            onChange={this.handleAddressChange}
          />
        )}
        <Card style={{ width: '100%', marginTop: '1em' }}>
          <div>
            <h2>전체 가격: {PriceLabel(originalPrice)}원</h2>
            {originalPrice !== totalPrice && (
              <h2>할인 가격: {PriceLabel(totalPrice)}원</h2>
            )}
            {hasGood && deliveryFee > 0 && <h2>배송비: {deliveryFee}원</h2>}
          </div>
          {noItem && <EmptyCartView />}
          <div>
            {items.map(item => {
              return (
                <CheckoutItemView
                  isLoggedIn={Boolean(this.props.user)}
                  client={this.props.client}
                  key={item.product.id + ':' + item.options}
                  deleteDisabled={this.props.deleteDisabled}
                  onRemove={this.props.onItemRemove}
                  onQuantityUpdate={this.props.onItemUpdate}
                  quantityLocked={
                    this.props.quantityLocked ||
                    item.product.productType === ProductType.subscription
                  }
                  item={item}
                />
              );
            })}
          </div>
          {!noItem && (
            <section>
              <CouponApplyView onCouponReceieved={this.handleCouponReceieved} />
              <FormGroup>
                <PaymentMethodChooser
                  method={this.state.method}
                  onChange={this.handlePaymethodSelect}
                />
              </FormGroup>
              <FormGroup>
                <Button
                  fill
                  large
                  style={{ marginRight: '1em' }}
                  intent={Intent.SUCCESS}
                  disabled={disabled}
                  onClick={this.checkout}
                >
                  결제
                </Button>
              </FormGroup>
              <FormGroup>
                <Button
                  fill
                  style={{ marginRight: '1em' }}
                  intent={Intent.DANGER}
                  onClick={this.props.onCancel}
                >
                  취소
                </Button>
              </FormGroup>
            </section>
          )}
        </Card>
      </>
    );
  }
}
