import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Button, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import ApolloClient from 'apollo-client';

export const REMOVE_CART_ITEM_MUTATION = gql`
  mutation RemoveCartItem($productId: String!, $options: String) {
    removeCartItem(productId: $productId, options: $options) {
      id
      items {
        id
        productId
        quantity
        options
      }
    }
  }
`;

export const removeCartItem = (client: ApolloClient<any>) => (
  productId: string,
  options?: string | null,
) =>
  client.mutate({
    mutation: REMOVE_CART_ITEM_MUTATION,
    refetchQueries: ['GetShoppingCart', 'CountCartItems'],
    variables: {
      productId,
      options,
    },
  });

interface Props {
  productId: string;
  options?: string | null;
}

export function RemoveCartItemButton(props: Props) {
  return (
    <Mutation mutation={REMOVE_CART_ITEM_MUTATION}>
      {update => {
        return (
          <Button
            intent={Intent.DANGER}
            icon={IconNames.DELETE}
            onClick={() =>
              update({
                variables: {
                  productId: props.productId,
                  options: props.options,
                },
                refetchQueries: ['GetShoppingCart', 'CountCartItems'],
              })
            }
          />
        );
      }}
    </Mutation>
  );
}
