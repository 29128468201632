import React, { SVGAttributes } from 'react';

export const MobileCheckout = (props: SVGAttributes<SVGElement>) => (
  <svg viewBox="0 0 79 79" {...props}>
    <path fill="none" d="M-1-1h74v74H-1z" />
    <g>
      <path
        d="M41.271 6h-.03c-.828 0-1.485.672-1.485 1.5S40.443 9 41.271 9a1.5 1.5 0 0 0 0-3zm8.94 45a1.5 1.5 0 0 0-1.5 1.5V54h-39V15h39v4.5a1.5 1.5 0 0 0 3 0v-9c0-5.79-4.71-10.5-10.5-10.5h-24c-5.79 0-10.5 4.71-10.5 10.5v51c0 5.79 4.71 10.5 10.5 10.5h24c5.79 0 10.5-4.71 10.5-10.5v-9a1.5 1.5 0 0 0-1.5-1.5zm-40.5-40.5c0-4.134 3.366-7.5 7.5-7.5h24c4.134 0 7.5 3.366 7.5 7.5V12h-39v-1.5zm39 51c0 4.134-3.366 7.5-7.5 7.5h-24c-4.134 0-7.5-3.366-7.5-7.5V57h39v4.5zM32.211 6h-6a1.5 1.5 0 0 0 0 3h6a1.5 1.5 0 0 0 0-3zm22.5 36c.558 0 1.053-.195 1.5-.459.447.264.942.459 1.5.459a3.001 3.001 0 0 0 0-6c-.558 0-1.053.195-1.5.459-.447-.264-.942-.459-1.5-.459a3.001 3.001 0 0 0 0 6zm7.5-18h-24a4.505 4.505 0 0 0-4.5 4.5v15c0 2.481 2.019 4.5 4.5 4.5h24c2.481 0 4.5-2.019 4.5-4.5v-15c0-2.481-2.019-4.5-4.5-4.5zm1.5 19.5a1.5 1.5 0 0 1-1.5 1.5h-24a1.5 1.5 0 0 1-1.5-1.5V33h27v10.5zm0-13.5h-27v-1.5a1.5 1.5 0 0 1 1.5-1.5h24a1.5 1.5 0 0 1 1.5 1.5V30zm-34.5 36a3.001 3.001 0 0 0 0-6 3.001 3.001 0 0 0 0 6z"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
