import React from 'react';
import { css } from 'emotion';
import random from 'lodash/random';
import { interval, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { VideoComponentLayout } from './VideoComponentLayout';

interface Props {
  playing: boolean;
  userId?: string;
  userNickname?: string | null;
}

interface State {
  top: number;
  left: number;
}

export class SecurityText extends React.Component<Props, State> {
  text: HTMLDivElement | null = null;
  subs: Subscription | null = null;

  state = {
    top: 0,
    left: 0,
  };

  private startDisplay = () => {
    this.subs = interval(5000)
      .pipe(filter(() => this.props.playing))
      .subscribe(() => {
        const top = random(0, 20);
        const bottom = random(60, 80);
        const left = random(0, 20);
        const right = random(70, 90);

        this.setState({
          top: random(0, 1, true) > 0.5 ? top : bottom,
          left: random(0, 1, true) > 0.5 ? left : right,
        });
      });
  };

  componentDidMount() {
    this.startDisplay();
  }

  componentWillUnmount() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  render() {
    if (!this.props.userId || !this.props.userNickname) {
      return null;
    }

    return (
      <VideoComponentLayout style={{ pointerEvents: 'none' }}>
        <div
          className={css({
            position: 'absolute',
            fontSize: '1.5vw',
            zIndex: 2147483647,
          })}
          style={{
            top: `${this.state.top}%`,
            left: `${this.state.left}%`,
            visibility: this.props.playing ? 'visible' : 'hidden',
            textShadow: '0px 0px 0.5em white',
          }}
          ref={div => (this.text = div)}
        >
          <div>{this.props.userNickname}</div>
          <div
            style={{
              fontSize: '0.5em',
            }}
          >
            {this.props.userId}
          </div>
        </div>
      </VideoComponentLayout>
    );
  }
}
