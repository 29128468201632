import React from 'react';
import { Dialog, Classes, AnchorButton, Intent } from '@blueprintjs/core';

interface Props {
  className?: string;
  open?: boolean;
  onRouteToLogin(): void;
  onClose(): void;
}

export function LoginRequiredDialog(props: Props) {
  return (
    <Dialog
      className={props.className}
      isOpen={props.open}
      title="로그인 만료"
      onClose={props.onClose}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      isCloseButtonShown={false}
    >
      <div className={Classes.DIALOG_BODY}>
        <p>세션이 만료되었습니다</p>
        <AnchorButton href="/auth/logout" intent={Intent.PRIMARY}>
          로그인
        </AnchorButton>
      </div>
    </Dialog>
  );
}
