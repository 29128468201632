import { GraphQLError } from 'graphql';

export class GraphlErrors extends Error {
  readonly errors: GraphQLError[];

  constructor(errors: GraphQLError[] = []) {
    super('Graphql Error');
    this.errors = errors;
  }
}
