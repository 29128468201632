import React from 'react';
import { css } from 'emotion';

interface RGB {
  r: number;
  g: number;
  b: number;
}

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonColor?: RGB;
  fill?: boolean;
}

const defaultColor = {
  r: 99,
  g: 200,
  b: 196,
};

const rgb = (color: { r: number; g: number; b: number }) =>
  `rgb(${color.r},${color.g},${color.b})`;

const rgba = (color: { r: number; g: number; b: number }, alpha: number) =>
  `rgba(${color.r},${color.g},${color.b}, ${alpha})`;

export function OutlineButton(props: Props) {
  const { buttonColor, fill, ...rest } = props;
  const color = buttonColor || defaultColor;
  return (
    <button
      {...rest}
      className={css(
        {
          border: `2px solid ${rgba(color, 0.8)}`,
          color: fill ? 'white' : rgb(color),
          backgroundColor: fill ? rgb(color) : 'transparent',
          cursor: 'pointer',
          outline: 0,
          textDecoration: 'none',
          paddingTop: '0.3em',
          paddingBottom: '0.3em',
          paddingRight: '0.7em',
          paddingLeft: '0.7em',
          transition: 'all .3s',
          ':hover': {
            border: `2px solid ${rgb(color)}`,
            backgroundColor: `${rgba(color, fill ? 1 : 0.08)}`,
            transform: 'translateY(-1px)',
          },
          ':active': {
            transform: 'translateY(1px)',
          },
        },
        props.className,
      )}
    />
  );
}
