import gql from 'graphql-tag';
import React from 'react';
import { Query } from 'react-apollo';
import { Grid } from '../../components';
import { CourseConnection } from '../../gql-types';
import { getNodes } from '../../secret-fns';
import CourseCard from './CourseCard';

const COURSES_QUERY = gql`
  query GetAllCourses {
    courses {
      edges {
        cursor
        node {
          id
          name
          slug
          logoUrl
          imageUrl
          description
          unlocked
          difficulty
          progress {
            completed
          }
          created
          updated
        }
      }
    }
  }
`;

export function CourseQuery() {
  return (
    <Query query={COURSES_QUERY}>
      {obj => {
        const connection = obj.data.courses as CourseConnection;
        return (
          <Grid
            loading={obj.loading}
            itemId={item => item.id}
            size={3}
            items={getNodes(connection)}
            cell={CourseCard}
          />
        );
      }}
    </Query>
  );
}
