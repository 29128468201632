import React from 'react';
import { Lecture } from '../../gql-types';
import { CourseLectureItemView } from './CourseLectureItemView';

interface ListProps {
  items: Lecture[];
  onSelect(lecture: Lecture): void;
}

export class CourseLectureListView extends React.Component<ListProps> {
  render() {
    const { items } = this.props;
    return (
      <section>
        {items.map(item => {
          return (
            <CourseLectureItemView
              disabled={!item.unlocked}
              key={item.slug}
              item={item}
              onSelect={this.props.onSelect}
            />
          );
        })}
      </section>
    );
  }
}
