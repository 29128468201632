import React from 'react';
import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import { Button } from '@blueprintjs/core';
import { GraphlErrors } from './GraphlErrors';
import { Coupon } from '../../gql-types';

const VERIFY_COUPON = gql`
  query GetCoupon($code: String!) {
    coupon(code: $code) {
      id
      value
      discountType
      discountAmount
      status
      expiresAt
      amount
    }
  }
`;

interface Props {
  code: string;
  client: ApolloClient<any>;
  onCouponReceieved(coupon: Coupon): void;
  onError(err: any): void;
}

export class CouponApplyButton extends React.Component<Props> {
  private handleRequest = async () => {
    try {
      const { code, client } = this.props;
      const result = await client.query({
        query: VERIFY_COUPON,
        variables: {
          code,
        },
      });

      if (result.errors) {
        this.props.onError(new GraphlErrors(Array.from(result.errors)));
      }

      const coupon: Coupon | null = result.data
        ? (result.data as any).coupon
        : undefined;

      // TODO do verifications
      if (coupon) {
        this.props.onCouponReceieved(coupon);
      }
    } catch (err) {
      this.props.onError(err);
    }
  };
  render() {
    return <Button onClick={this.handleRequest}>적용하기</Button>;
  }
}
