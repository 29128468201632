/**
 * LoginRedirect class is
 */
export class LoginRedirect {
  static tokenKey = 'loginRedirect';

  constructor(readonly storage: Storage) {}

  getAndClear(): string | null {
    try {
      const redirect = this.storage.getItem(LoginRedirect.tokenKey);
      if (redirect) {
        this.clear();
      }
      return redirect;
    } catch (ignore) {
      return null;
    }
  }

  set(value: string) {
    try {
      this.storage.setItem(LoginRedirect.tokenKey, value);
    } catch (ignore) {}
  }

  clear() {
    try {
      this.storage.removeItem(LoginRedirect.tokenKey);
    } catch (ignore) {
      return;
    }
  }

  static forBrowser() {
    if (typeof window === 'undefined') {
      return;
    }

    return new LoginRedirect(window.localStorage);
  }
}
