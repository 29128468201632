import React from 'react';
import { css } from 'emotion';
import { Link } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

export function EmptyCartView() {
  return (
    <div
      className={css({
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      })}
    >
      <Icon icon={IconNames.SHOPPING_CART} iconSize={200} />
      <h2>장바구니가 비어있습니다.</h2>
      <Link to="/mall">시크릿몰 구경하기</Link>
    </div>
  );
}
