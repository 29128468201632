import gql from 'graphql-tag';

export const GET_SHOPPING_CART = gql`
  query GetShoppingCart($items: [ShoppingCartQueryInput!]) {
    cart: shoppingCart(items: $items) {
      id
      items {
        id
        quantity
        productId
        options
        product {
          id
          name
          imageUrl
          thumbnailUrl
          price
          currencyCode
        }
      }
    }
  }
`;
