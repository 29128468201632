import React from 'react';

export default ({history}) => {
  const handleHambakEvent = (e) => {
    history.push('/hambak-event');
  };
  const handleProgram = (e) => {
    history.push('/program');
  };
  return (
    <>
    <div className="mainWrap">
        {/* <!-- Trainer introduction --> */}
        <div className="trainerIntroductionWrap">
          <p className="title"><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret02.jpg" alt="" /></p>
          <ul className="list">
            <li><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret03_01.jpg" alt="" /></li>
            <li><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret03_02.jpg" alt="" /></li>
            <li><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret03_03.jpg" alt="" /></li>
          </ul>
        </div>
        {/* <!-- Trainer introduction --> */}

        {/* <!-- 이벤트신청 --> */}
        <div className="eventApplication">
          범죄도시4 개봉이벤트!! 지금 3개월 구매를 하면 밴드도 배송!
          <a href="#" className="link" onClick={handleHambakEvent}>이벤트 신청</a>
        </div>
        {/* <!-- //이벤트신청 --> */}

        {/* <!-- 셀러사진 --> */}
        <div className="influencerWrap">
          <ul className="list">
            <li><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret04_01.jpg" alt="" /></li>
            <li><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret04_02.jpg" alt="" /></li>
          </ul>
        </div>
        {/* <!-- //셀러사진 --> */}


        {/* <!-- 생생한후기 --> */}
        <div className="beforeAfterWrap">
          <p className="title"><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret05.jpg" alt="" /></p>
          <ul className="list">
            <li><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret06_01.jpg" alt="" /></li>
            <li><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret06_02.jpg" alt="" /></li>
            <li><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret06_03.jpg" alt="" /></li>
            <li><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret06_04.jpg" alt="" /></li>
            <li><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret06_05.jpg" alt="" /></li>
            <li><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret06_06.jpg" alt="" /></li>
            <li><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret06_07.jpg" alt="" /></li>
          </ul>
        </div>
        {/* <!-- //생생한후기 --> */}

        {/* <!-- SECRET PROGRAM --> */}
        <div className="secretProgramWrap">
          <p className="title"><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret09.jpg" alt="" /></p>
          <ul className="list">
            <li><a href="/courses/start"><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret10_01.jpg" alt="" /></a></li>
            <li><a href="/courses/basic"><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret10_02.jpg" alt="" /></a></li>
            <li><a href="/courses/master"><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret10_03.jpg" alt="" /></a></li>
            <li><a href="/courses/abds-down"><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret10_04.jpg" alt="" /></a></li>
            <li><a href="/courses/body-up-8weeks"><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret10_05.jpg" alt="" /></a></li>
            <li><a href="/courses/leg"><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret10_06.jpg" alt="" /></a></li>
            <li><a href="/courses/fitness-up"><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret10_07.jpg" alt="" /></a></li>
            <li><a href="/courses/bikini"><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret10_08.jpg" alt="" /></a></li>
            <li><a href="/courses/secret-boxing-bible"><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret10_09.jpg" alt="" /></a></li>
          </ul>
        </div>
        {/* <!-- //SECRET PROGRAM --> */}
        {/* <!-- 전체프로그램보기 --> */}
        <div className="eventApplication">
          새해 새로운 다짐 2021년 그동안 미뤄왔던 목표! 시크릿다이어트와 함께 해낼 수 있습니다
          <a href="#" className="link" onClick={handleProgram}>전체 프로그램 보기</a>
        </div>
        {/* <!-- //전체프로그램보기 --> */}
      
        <div className="joinusWrap">
          <p className="title"><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret14.jpg" alt="" /></p>
          <div className="joinusInfo">
            <div className="imgBox"><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret15_01.jpg" alt="" /></div>
            <div className="info">
              <div className="text">
              지금 바로 시크릿다이어트에 참여하세요<br />
                요가매트 한 장 만큼의 공간이면<br />
                어디서나 할 수 있습니다<br />
                체계적인 운동 프로그램 및 개인 맞춤형 식단,<br />
                전담 트레이너의 컨설팅<br />
                여러분의 다이어트 목표를 달성할 수 있습니다<br /><br />

                다이어트 매번 실패하는 이유는 무엇일까<br />
                다이어트는 체계적인 프로그램과 식단이 중요합니다.<br />
                혼자 하다 보면 잘못된 자세로 운동을 하거나 나쁜식단으로 몸에 무리를 주게 되죠
                더 이상 혼자하는 홈트레이닝으로 시간을 낭비하지 마세요.<br />
                뻔한 일상을 바꾸는 시크릿다이어트<br />
                지금 바로 시작하세요
              </div>
              <div className="appdown">
                <a href="https://apps.apple.com/kr/app/%EC%8B%9C%ED%81%AC%EB%A6%BF-%EB%8B%A4%EC%9D%B4%EC%96%B4%ED%8A%B8/id1447929450" target="_blank"><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret16_01.jpg" alt="" /></a>
                <a href="https://play.google.com/store/apps/details?id=com.secretdiet.android" target="_blank"><img src="https://storage.googleapis.com/secretdiet-public-images/2021/img_secret16_02.jpg" alt="" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
