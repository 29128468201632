import React from 'react';
import { ReviewContent, User } from '../../gql-types';
import { ReviewItemView } from './ReviewItemView';

interface Props {
  currentUser?: User | null;
  items: ReviewContent[];
}

export function ReviewListTable(props: Props) {
  const { currentUser, items } = props;
  return (
    <>
      {items.filter(item => !item.deleted).map(item => {
        return (
          <ReviewItemView key={item.id} currentUser={currentUser} item={item} />
        );
      })}
    </>
  );
}
