import React from 'react';
import clamp from 'lodash/clamp';
import { IconNames } from '@blueprintjs/icons';
import { Icon } from '@blueprintjs/core';

interface Props {
  value: number;
  max: number;
  enableEdit?: boolean;
  color?: string;
  onChange?: (point: number) => void;
}

interface State {
  hovering: boolean;
  hoverValue: number;
}

const range = (length: number) => Array.from({ length }, (_, i) => i + 1);

export class StarPoint extends React.Component<Props, State> {
  state = {
    hovering: false,
    hoverValue: 0,
  };

  private handleChange = (point: number) => {
    if (!this.props.enableEdit) {
      return;
    }

    this.props.onChange && this.props.onChange(point);
  };

  private handleMountEnter = (pt: number) => {
    this.setState({
      hovering: true,
      hoverValue: pt,
    });
  };

  private handleMouseLeave = () => this.setState({ hovering: false });

  private getCurrentPoint() {
    const { value, max, enableEdit } = this.props;
    const point = clamp(value, max);
    if (!enableEdit) {
      return point;
    }

    const { hovering, hoverValue } = this.state;
    return hovering ? hoverValue : point;
  }

  render() {
    const { max } = this.props;
    const color = this.props.color || '#3498db';
    const point = this.getCurrentPoint();

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'nowrap',
        }}
        onMouseLeave={this.handleMouseLeave}
      >
        {range(max).map(number => {
          const icon = number <= point ? IconNames.STAR : IconNames.STAR_EMPTY;
          return (
            <Icon
              key={number}
              icon={icon}
              color={color}
              onMouseEnter={() => this.handleMountEnter(number)}
              onClick={() => this.handleChange(number)}
            />
          );
        })}
      </div>
    );
  }
}
