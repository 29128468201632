import React from 'react';
import { StandardShorthandProperties } from 'csstype';

interface BadgeProps {
  className?: string;
  style?: StandardShorthandProperties;
  badgeContent: React.ReactNode;
  color?: string;
  children?: React.ReactNode;
}

export function Badge(props: BadgeProps) {
  return (
    <span
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'inherit',
        justifyContent: 'inherit',
      }}
    >
      <span
        style={{
          display: 'inline-flex',
          position: 'relative',
          verticalAlign: 'middle',
        }}
      >
        {props.children}
        {props.badgeContent && (
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'red',
              color: 'white',
              borderRadius: '50%',
              position: 'absolute',
              width: 22,
              height: 22,
              top: -11,
              right: -11,
              fontSize: '0.75em',
            }}
          >
            {props.badgeContent}
          </span>
        )}
      </span>
    </span>
  );
}
