import gql from 'graphql-tag';
import ApolloClient from 'apollo-client';

export const UPDATE_CART_ITEM_MUTATION = gql`
  mutation UpdateCartItem(
    $productId: String!
    $quantity: Int!
    $options: String
  ) {
    updateCartItem(
      productId: $productId
      quantity: $quantity
      options: $options
    ) {
      id
      items {
        id
        productId
        quantity
        options
      }
    }
  }
`;

export const updateCartItem = (client: ApolloClient<any>) => async (
  productId: string,
  quantity: number,
  options?: string | null,
) =>
  client.mutate({
    mutation: UPDATE_CART_ITEM_MUTATION,
    refetchQueries: ['GetShoppingCart', 'CountCartItems'],
    variables: {
      productId,
      quantity,
      options,
    },
  });
