import React, { CSSProperties } from 'react';
import { css } from 'emotion';
import { CardStyles } from './CardStyle';

interface CardProps {
  style?: CSSProperties;
  mediaSize?: string;
  contentStyle?: CSSProperties;
  className?: string;
  media?: string | null;
  children?: any;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const ContainerStyle = css(CardStyles.container, CardStyles.shadow);
const MediaContainerStyel = css(CardStyles.media, CardStyles.mediaContainer);
const WhiteBackground = css({ backgroundColor: 'white' });

export function Card(props: CardProps) {
  const mediaStyle = props.mediaSize
    ? css(MediaContainerStyel, { paddingTop: props.mediaSize })
    : MediaContainerStyel;
  const mediaEl = props.media && (
    <div
      className={mediaStyle}
      style={{ backgroundImage: `url(${props.media})` }}
    />
  );

  return (
    <div
      style={props.style}
      className={css(ContainerStyle, WhiteBackground, props.className)}
      onClick={props.onClick}
    >
      {mediaEl}
      <div style={props.contentStyle} className={CardStyles.content}>
        {props.children}
      </div>
    </div>
  );
}
