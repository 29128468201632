import React from 'react';
import { Card } from '../../components';
import { User } from '../../gql-types';
import { UserEmailInput } from './UserEmailInput';
import { UserNicknameInput } from './UserNicknameInput';
import { LogoutButton } from './LogoutButton';

interface Props {
  user: User;
}

export class BasicProfileView extends React.Component<Props> {
  render() {
    const { user } = this.props;
    return (
      <Card
        style={{
          marginBottom: '1em',
        }}
      >
        <h3>기본 정보</h3>
        <UserNicknameInput value={user.nickname || ''} />
        <UserEmailInput
          verified={user.emailVerified}
          value={user.email || ''}
        />
        <LogoutButton />
      </Card>
    );
  }
}
