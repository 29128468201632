import React from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import { Lecture, BodyArea } from '../../gql-types';
import { Card } from '../../components';
import { Icon, Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

interface ItemProps {
  disabled?: boolean;
  item: Lecture;
  onSelect(item: Lecture): void;
}

const ContentStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

const CardStyle = css({
  marginBottom: '1em',
  ':last-of-type': {
    marginBottom: 0,
  },
});

const LectureInfoContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

function EffectiveAreaLabel(area?: BodyArea | null): string | void {
  switch (area) {
    case BodyArea.abds:
      return '복근';
    case BodyArea.arm:
      return '팔';
    case BodyArea.back:
      return '등';
    case BodyArea.chest:
      return '가슴';
    case BodyArea.hip:
      return '엉덩이';
    case BodyArea.leg:
      return '다리';
    case BodyArea.shoulder:
      return '어깨';
    case BodyArea.whole:
      return '전신';
  }
}

export class CourseLectureItemView extends React.Component<ItemProps> {
  private handleClick = () => {
    const { item } = this.props;
    this.props.onSelect(item);
  };

  render() {
    const { item } = this.props;
    const completed = item.progress.completed;
    const hasEffectiveAreas =
      item.effectiveAreas && item.effectiveAreas.length > 0;
    return (
      <Card className={CardStyle}>
        <div className={ContentStyle}>
          <LectureInfoContainer>
            {completed && (
              <div style={{ marginRight: '1em' }}>
                <Icon icon={IconNames.ENDORSED} color="green" iconSize={16} />
              </div>
            )}
            <div>
              <div style={{ fontWeight: 800 }}>{item.name}</div>
              <div>{Math.floor(item.duration / 60)} 분</div>
              {hasEffectiveAreas && (
                <div>
                  운동부위:{' '}
                  {item.effectiveAreas
                    .map(EffectiveAreaLabel)
                    .filter(Boolean)
                    .join(',')}
                </div>
              )}
            </div>
          </LectureInfoContainer>
          <Button
            id={`lecture-${item.slug}`}
            disabled={this.props.disabled}
            icon={IconNames.VIDEO}
            minimal
            onClick={this.handleClick}
          >
            시작
          </Button>
        </div>
      </Card>
    );
  }
}
