import styled from 'react-emotion';
import { InteractiveStyle, ButtonResetStyle } from './ButtonStyles';
import { lightTextColors } from '../Theme';

const OvalButton = styled('button')(
  {
    backgroundColor: 'rgb(236, 216, 81)',
    border: 'none',
    color: lightTextColors.primary,
    cursor: 'pointer',
    display: 'block',
    padding: '0.25em 1em',
    textDecoration: 'none',
    fontSize: 16,
    transition: 'all .3s',
  },
  ButtonResetStyle,
  InteractiveStyle,
  (props: {
    backgroundColor?: string;
    color?: string;
    fontSize?: number | string;
  }) => {
    const fontSize = props.fontSize ? props.fontSize : 16;
    const borderRadius =
      typeof fontSize === 'string' && /em/.test(fontSize) ? '1em' : 24;
    return {
      fontSize: props.fontSize ? props.fontSize : 16,
      borderRadius,
      color: props.color,
      backgroundColor: props.backgroundColor,
    };
  },
);

export default OvalButton;
