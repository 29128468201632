import React from 'react';
import { css } from 'emotion';
import { Card } from '../../components';
import { Button, Intent, InputGroup, ControlGroup } from '@blueprintjs/core';

interface Props {
  saving: boolean;
  called?: boolean;
  onSubmit(email: string): void;
}

interface State {
  email: string;
}

const cardStyle = css({
  maxWidth: 600,
  minWidth: 320,
});

export class ForgotPasswordForm extends React.Component<Props, State> {
  state = {
    email: '',
  };

  private handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    this.setState({ email: value });
  };

  private handleSubmit = () => {
    this.props.onSubmit(this.state.email);
  };

  render() {
    const { called, saving } = this.props;
    return (
      <Card className={cardStyle}>
        <h1>비밀번호 찾기</h1>
        <ControlGroup fill>
          <InputGroup
            id="email"
            name="email'"
            type="email"
            placeholder="이메일을 입력하세요"
            value={this.state.email}
            onChange={this.handleChange}
          />
          <Button
            id="sendForgotPassword"
            disabled={saving}
            loading={saving}
            intent={Intent.PRIMARY}
            onClick={this.handleSubmit}
          >
            이메일 보내기
          </Button>
        </ControlGroup>
        {called && <div>{this.state.email}로 이메일을 보냈습니다.</div>}
      </Card>
    );
  }
}
