import React, { CSSProperties } from 'react';
import Path from 'path';
import marked from 'marked';

class CustomRenderer extends marked.Renderer {
  link(href: string, title: string, text: string) {
    if (/(vimeo)/.test(href)) {
      const videoId = Path.basename(href);
      const uri = `https://player.vimeo.com/video/${videoId}`;
      return `<div style="padding:56.25% 0 0 0;position:relative;">
  <iframe 
    style="position:absolute;top:0;left:0;width:100%;height:100%;"
    src="${uri}?portrait=0" 
    frameborder="0" 
    webkitallowfullscreen 
    mozallowfullscreen 
    allowfullscreen>
  </iframe>
</div>
<script src="https://player.vimeo.com/api/player.js"></script>`;
    }

    return super.link(href, title, text);
  }
}

const renderer = new CustomRenderer();

interface Props {
  style?: CSSProperties;
  className?: string;
  content: string;
}

export class MarkdownDiv extends React.PureComponent<Props> {
  render() {
    return (
      <div
        style={this.props.style}
        className={this.props.className}
        dangerouslySetInnerHTML={{
          __html: marked(this.props.content, { renderer }),
        }}
      />
    );
  }
}
