import React from 'react';
import { Button, MenuItem, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Select, ItemRenderer } from '@blueprintjs/select';

interface IGenericItem {
  id: string;
  label: string;
}

const CountrySelectBase = Select.ofType<IGenericItem>();

interface Props {
  value?: string;
  onSelect(value: string): void;
}

const items: IGenericItem[] = [
  {
    id: 'KOR',
    label: '대한민국',
  },
  {
    id: 'USA',
    label: '미국',
  },
  {
    id: 'JPN',
    label: '일본',
  },
  {
    id: 'AUS',
    label: '오스트레일리아',
  },
];

const CountryItemView: ItemRenderer<IGenericItem> = (item, props) => {
  const { modifiers } = props;
  return (
    <MenuItem
      key={item.id}
      text={item.label}
      active={modifiers.active}
      disabled={modifiers.disabled}
      onClick={props.handleClick}
    />
  );
};

export class CountrySelect extends React.Component<Props> {
  private handleItemSelect = (item: IGenericItem) => {
    this.props.onSelect(item.id);
  };

  render() {
    const { value } = this.props;
    const currentValue = items.find(item => item.id === value);
    return (
      <CountrySelectBase
        items={items}
        filterable={false}
        activeItem={currentValue}
        itemRenderer={CountryItemView}
        onItemSelect={this.handleItemSelect}
      >
        <Button
          intent={Intent.NONE}
          text={currentValue ? currentValue.label : '국가를 선택해 주세요'}
          rightIcon={IconNames.CARET_DOWN}
        />
      </CountrySelectBase>
    );
  }
}
