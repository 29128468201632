import React from 'react';
import gql from 'graphql-tag';
import { ApolloError } from 'apollo-client';
import { Mutation } from 'react-apollo';
import { Button, Intent } from '@blueprintjs/core';

export const EMAIL_VERIFY_MUTATION = gql`
  mutation SendVerifyEmail {
    sendEmailVerification
  }
`;

interface Props {
  onError(error: ApolloError): void;
  onUpdate(): void;
  onCompleted(message: string): void;
}

export function EmailVerifyButton(props: Props) {
  return (
    <Mutation
      mutation={EMAIL_VERIFY_MUTATION}
      onError={props.onError}
      onCompleted={() =>
        props.onCompleted('발송 되었습니다. 이메일을 확인해 주세요')
      }
    >
      {(update, obj) => {
        return (
          <Button
            id="emailVerify"
            intent={Intent.SUCCESS}
            disabled={obj.called || obj.loading}
            loading={obj.loading}
            onClick={() => {
              update();
              props.onUpdate();
            }}
          >
            확인메일 발송
          </Button>
        );
      }}
    </Mutation>
  );
}
