import React from 'react';
import { IconNames } from '@blueprintjs/icons';
import { Select, ItemRenderer } from '@blueprintjs/select';
import { Button, MenuItem, Intent } from '@blueprintjs/core';

export interface IGenericItem {
  id: string;
  label: string;
}

export const GenericSelectBase = Select.ofType<IGenericItem>();

export interface SelectProps {
  value?: string;
  disabled?: boolean;
  onSelect(value: string): void;
}

export const GenericItemView: ItemRenderer<IGenericItem> = (item, props) => {
  const { modifiers } = props;
  return (
    <MenuItem
      key={item.id}
      text={item.label}
      active={modifiers.active}
      disabled={modifiers.disabled}
      onClick={props.handleClick}
    />
  );
};

export function createSelect(options: {
  items: IGenericItem[];
  placeholder?: string;
  displayName?: string;
}) {
  const { items, placeholder } = options;

  class GenericSelect extends React.Component<SelectProps> {
    static displayName = options.displayName;

    private handleItemSelect = (item: IGenericItem) => {
      this.props.onSelect(item.id);
    };

    render() {
      const { value } = this.props;
      const currentValue = items.find(item => item.id === value);
      return (
        <GenericSelectBase
          items={items}
          filterable={false}
          disabled={this.props.disabled}
          activeItem={currentValue}
          itemRenderer={GenericItemView}
          onItemSelect={this.handleItemSelect}
        >
          <Button
            disabled={this.props.disabled}
            intent={Intent.NONE}
            text={currentValue ? currentValue.label : placeholder}
            rightIcon={IconNames.CARET_DOWN}
          />
        </GenericSelectBase>
      );
    }
  }

  return GenericSelect;
}
