import React from 'react';
import { User } from '../../gql-types';
import { Card } from '../../components';
import FacebokStatus from './FacebookStatus';
import KakaoStatus from './KakaoStatus';

interface Props {
  user: User;
}

export class SocialPluginsView extends React.Component<Props> {
  render() {
    const { user } = this.props;
    return (
      <Card
        style={{
          marginBottom: '1em',
        }}
      >
        <h3>소셜 로그인</h3>
        <div style={{ marginBottom: '1em' }}>
          <FacebokStatus connected={Boolean(user.facebookAccount)} />
        </div>
        <div>
          <KakaoStatus connected={Boolean(user.kakaoAccount)} />
        </div>
      </Card>
    );
  }
}
