import React from 'react';
import { Dialog, Button } from '@blueprintjs/core';
import { JusoListView } from './JusoListView';
import { JusoAddress } from '../../gql-types';

interface Props {
  onSelect(address: JusoAddress): void;
}

interface State {
  opened: boolean;
  page: number;
}

export class AddressOverlay extends React.Component<Props> {
  state = {
    opened: false,
    page: 1,
  };

  show = () => this.setState({ opened: true });
  close = () => this.setState({ opened: false });

  private handleSelect = (address: JusoAddress) => {
    this.setState(
      {
        opened: false,
      },
      () => this.props.onSelect(address),
    );
  };

  render() {
    return (
      <div>
        <Dialog
          title="우편번호 검색"
          canOutsideClickClose
          isOpen={this.state.opened}
          onClose={this.close}
          usePortal
        >
          <JusoListView onSelect={this.handleSelect} />
        </Dialog>
        <Button onClick={this.show}>우편번호 검색</Button>
      </div>
    );
  }
}
