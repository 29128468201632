import styled from 'react-emotion';
import { InteractiveStyle } from './buttons/ButtonStyles';

export const ChooserButton = styled('button')(
  {
    cursor: 'pointer',
    display: 'block',
    border: '1px solid rgb(217,217,217)',
    outline: 0,
    textAlign: 'center',
    padding: '1em',
    width: '100%',
    height: '100%',
    fontSize: '1em',
    margin: '0 auto',
    borderRadius: '0.25em',
    wordBreak: 'keep-all',
    backgroundColor: 'rgb(246,246,246)',
    ':focus': {
      outline: 0,
    },
  },
  InteractiveStyle,
  (props: { active?: boolean }) => {
    if (props.active) {
      return {
        color: 'white',
        fill: 'white',
        border: '2px solid rgb(47,183,183)',
        backgroundColor: 'rgb(99,220,220)',
      };
    }
  },
);
