import React from 'react';
import { css } from 'emotion';
import styled from 'react-emotion';

interface Props {
  fixed?: boolean;
  children?: React.ReactNode;
}

interface State {
  stickySupported: boolean;
}

export const NavContainer = styled('nav')(
  {
    boxSizing: 'border-box',
    backgroundColor: 'rgb(35,35,35)',
    width: '100%',
    color: 'white',
    zIndex: 3,
  },
  (p: { height: number; fixed: boolean; sticky: boolean }) => ({
    top: p.fixed ? 0 : undefined,
    height: p.height,
    position:
      p.fixed && p.sticky ? ('sticky' as any) : p.fixed ? 'fixed' : 'relative',
  }),
);

const MaxWidth = 640;

export const MenuToggleContainer = styled('div')({
  display: 'none',
  [`@media screen and (max-width: ${MaxWidth}px)`]: {
    display: 'block',
    alignSelf: 'center',
  },
});

export const NavContentContainer = styled('div')({
  height: '100%',
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 30,
  paddingRight: 30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [`@media screen and (max-width: ${MaxWidth}px)`]: {
    flexWrap: 'wrap',
  },
});

export const NavItemContainer = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgb(35,35,35)',
    [`@media screen and (max-width: ${MaxWidth}px)`]: {
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      overflow: 'hidden',
      width: '100%',
    },
  },
  (props: { visible: boolean }) => ({
    height: props.visible ? 'auto' : 0,
    padding: !props.visible ? 0 : undefined,
  }),
);

export class Header extends React.Component<Props, State> {
  state = {
    stickySupported: true,
  };

  componentDidMount() {
    if (typeof CSS !== 'undefined') {
      const result = CSS.supports('position: sticky');
      if (!result) {
        this.setState({ stickySupported: false });
      }
    }
  }

  render() {
    const { fixed } = this.props;

    return (
      <NavContainer
        height={104}
        sticky={this.state.stickySupported}
        fixed={Boolean(fixed)}
      >
        <div
          className={css({
            maxWidth: 1080,
            margin: '0 auto',
          })}
        >
          {this.props.children}
        </div>
      </NavContainer>
    );
  }
}
