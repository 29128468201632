import React from 'react';
import { History } from 'history';
import { Card } from '../../components';

interface Props {
  history: History;
}

export class FitnessUpDescription extends React.Component<Props> {
  render() {
    return (
      <div style={{ minWidth: 320 }}>
        <Card>
          <h1>체력 UP</h1>
          <p>
            전신 근지구력 운동 위주로 체력 향상을 위해 구성된 프로그램입니다.
          </p>
          <p>
            취미로 즐기고 있는 운동을 좀 더 잘 하고 싶거나, 체력저하가 걱정되는
            중년들, 군입대, 공무원 시험 등 체력 샹상이 필요한 이벤트나 좀 더
            건강해지고 싶은 분들께 추천하는 프로그램입니다.
          </p>
          <img
            style={{ width: '100%' }}
            src="https://s3.ap-northeast-2.amazonaws.com/secret-diet-assets/fitness-up-curriculum.jpg"
          />
        </Card>
      </div>
    );
  }
}
