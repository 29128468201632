import React from 'react';
import { css } from 'emotion';
import clamp from 'lodash/clamp';
import format from 'date-fns/format';
import styled from 'react-emotion';
import { Button, Intent } from '@blueprintjs/core';
import { Card, MarkdownDiv, StarPoint } from '../../components';
import { ReviewContent, SimpleUser, User } from '../../gql-types';
import { ReviewWriteView } from './ReviewWriteView';
import { RemoveReviewButton } from './RemoveReviewButton';

interface Props {
  currentUser?: User | null;
  item: ReviewContent;
}

interface State {
  open: boolean;
  editMode: boolean;
}

const range = (length: number) => Array.from({ length }, (_, i) => i);

const ReviewContainer = styled('article')({});

const HeaderContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '1em',
  flexBasis: 320,
  justifyContent: 'space-between',
});

const SummaryContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const MarkdownContainer = styled('div')(
  {
    width: '100%',
    overflow: 'hidden',
    wordBreak: 'keep-all',
  },
  (props: { open: boolean }) => {
    const { open } = props;
    return {
      height: open ? 'auto' : 'auto',
    };
  },
);

function PartialHidenString(str: string, limit: number) {
  const first = str.slice(0, limit);
  const rest = str.slice(limit);
  return (
    first +
    range(clamp(rest.length, 8))
      .map(() => '*')
      .join('')
  );
}

function getUserDisplay(user: SimpleUser, writerName?: string | null): string {
  if (writerName) {
    return PartialHidenString(writerName, 2);
  }

  const { name } = user;
  if (!name) {
    return '익명';
  }

  return PartialHidenString(name, 2);
}

export class ReviewItemView extends React.Component<Props, State> {
  state = {
    open: false,
    editMode: false,
  };

  private handleToggle = (requiredCollapse: boolean) => {
    if (requiredCollapse) {
      this.setState(prevState => ({
        open: !prevState.open,
      }));
    }
  };

  render() {
    const { currentUser, item } = this.props;
    const { open, editMode } = this.state;
    const { content } = item;
    const lines = content.split('\n').map(line => line.trim());
    const requireCollapse = lines.length > 3;
    const first3Lines = lines.splice(0, 3).join('\n');
    const canEdit = currentUser && currentUser.id === item.user.id;

    if (editMode) {
      return (
        <ReviewWriteView
          id={item.id}
          initialPoint={item.point}
          initialValue={item.content}
          onCanceled={() => this.setState({ editMode: false })}
          onCompleted={() => this.setState({ editMode: false })}
        />
      );
    }

    return (
      <Card
        key={item.id}
        onClick={() => this.handleToggle(requireCollapse)}
        style={{
          cursor: requireCollapse ? 'pointer' : undefined,
        }}
      >
        <ReviewContainer>
          <HeaderContainer>
            <SummaryContainer>
              <div
                style={{
                  marginRight: '1em',
                }}
              >
                <StarPoint value={item.point} max={5} />
              </div>
              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: '0.8em',
                }}
              >
                ({getUserDisplay(item.user, item.writerName)})
              </div>
            </SummaryContainer>
            <div
              style={{
                fontSize: '0.9em',
                color: 'grey',
              }}
            >
              {format(item.created, 'YYYY/MM/DD')}
            </div>
          </HeaderContainer>
          <MarkdownContainer open={open}>
            <MarkdownDiv
              content={!open ? first3Lines : content}
              className={css({
                '& img': {
                  maxWidth: '100%',
                },
              })}
            />
          </MarkdownContainer>
          <div
            className={css({
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
            })}
          >
            <div
              className={css({
                gridColumn: '1',
              })}
            >
              {!open && requireCollapse && <Button minimal>펼쳐보기</Button>}
            </div>
            {canEdit && (
              <div
                className={css({
                  gridColumn: '2',
                  justifySelf: 'end',
                })}
              >
                <Button
                  style={{ marginRight: '1em' }}
                  minimal
                  onClick={() => this.setState({ editMode: true })}
                >
                  수정하기
                </Button>
                <RemoveReviewButton reviewId={item.id} />
              </div>
            )}
          </div>
        </ReviewContainer>
      </Card>
    );
  }
}
