import React, { useCallback } from 'react';
import { RouteComponentProps } from 'react-router';
import { TlxNewUserForm } from '../tlx/TlxNewUserForm';

interface RouteParams {
  code: string;
}

export function TlxNewUserPage(props: RouteComponentProps<RouteParams>) {
  const { history, match } = props;
  const { code } = match.params;

  const handleCompleted = useCallback(() => history.push('/login'), [history]);
  const handleCancel = useCallback(() => history.push('/login'), [history]);

  return (
    <section>
      <TlxNewUserForm.Connected
        code={code}
        onCompleted={handleCompleted}
        onCancel={handleCancel}
      />
    </section>
  );
}
