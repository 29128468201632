import React from 'react';
import get from 'lodash/get';
import { ApolloError } from 'apollo-client';
import styled from 'react-emotion';
import gql from 'graphql-tag';
import AppToaster from '../AppToaster';
import { Mutation, Query } from 'react-apollo';
import { SignupExtraInfo } from '../users/SignupExtraInfo';
import { Intent } from '@blueprintjs/core';
import { RouteComponentProps } from 'react-router';

const REGISTER_MUTATION = gql`
  mutation RegisterUser($creds: UserInput!) {
    user: register(user: $creds) {
      id
      email
    }
  }
`;

const FetchExtraInfo = gql`
  query FetchExtraInfo {
    extraInfo: currentSocialRegistration {
      type
      accountId
      email
      birthDay
      name
      nickname
    }
  }
`;

const SignupContainer = styled('article')({
  position: 'relative',
  maxWidth: 600,
  minWidth: 360,
  width: '100%',
  paddingTop: '5em',
  paddingBottom: '5em',
  paddingLeft: '1em',
  paddingRight: '1em',
});

interface Props extends RouteComponentProps<any> {}

export class SocialSignupPage extends React.Component<Props> {
  private handleError = (error: ApolloError) => {
    const errors = error.graphQLErrors;
    errors.forEach(err => {
      if (AppToaster) {
        AppToaster.show({
          intent: Intent.DANGER,
          message: err.message,
        });
      }
    });
  };

  private handleCompleted = () => {
    if (gtag && typeof gtag === 'function') {
      gtag('event', 'social_sign_up', {
        event_category: 'engagement',
      });
    }

    const { history } = this.props;
    history.push('/plans');
  };

  render() {
    return (
      <SignupContainer>
        <Query query={FetchExtraInfo} onCompleted={this.handleCompleted}>
          {queryResult => (
            <Mutation
              mutation={REGISTER_MUTATION}
              onError={this.handleError}
              onCompleted={this.handleCompleted}
            >
              {(update, result) => {
                return (
                  <SignupExtraInfo
                    loading={result.loading || queryResult.loading}
                    extraInfo={get(queryResult, 'data.extraInfo')}
                    onSubmit={creds => {
                      update({
                        variables: {
                          creds,
                        },
                      });
                    }}
                  />
                );
              }}
            </Mutation>
          )}
        </Query>
      </SignupContainer>
    );
  }
}
