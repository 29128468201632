import { Spinner } from '@blueprintjs/core';
import { css } from 'emotion';
import React from 'react';
import Helmet from 'react-helmet';
import { Redirect, RouteComponentProps } from 'react-router';
import { ICurrentUserProps, WithCurrentUser } from '../users/CurrentUserQuery';
import { LoginMutation } from '../users/LoginMutation';
import qs from 'querystring';

interface Props extends RouteComponentProps<any>, ICurrentUserProps {}

class LoginPage extends React.Component<Props> {
  render() {
    if (this.props.loading) {
      return <Spinner />;
    }

    if (this.props.isLoggedIn) {
      return <Redirect to="/my-fitness" />;
    }

    const query = new URLSearchParams(this.props.location.search || '');
    console.log('search', this.props.location.search);
    console.log('query', query);
    const redirect: string | null = query.get('redirect');

    return (
      <article
        className={css({
          position: 'relative',
          maxWidth: 600,
          minWidth: 360,
          width: '100%',
          paddingTop: '5em',
          paddingBottom: '5em',
          paddingLeft: '1em',
          paddingRight: '1em',
        })}
      >
        <Helmet>
          <title>시크릿 다이어트 - 로그인</title>
        </Helmet>
        <LoginMutation
          history={this.props.history}
          redirectTo={redirect || undefined}
        />
      </article>
    );
  }
}

export default WithCurrentUser(LoginPage);
