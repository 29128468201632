import React from 'react';
import styled from 'react-emotion';

const ItemContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  borderTop: '1px solid grey',
  padding: '0.5em 0.2em',
});

const ProductDescription = styled('div')({
  flex: '1 1 auto',
  minWidth: 200,
});

const ActionContainer = styled('div')({
  flex: '0 0 auto',
  minWidth: 200,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
});

interface Props {
  infoSection: React.ReactNode;
  actionSection: React.ReactNode;
}

export function CheckoutItemLayout(props: Props) {
  return (
    <ItemContainer>
      <ProductDescription>{props.infoSection}</ProductDescription>
      <ActionContainer>{props.actionSection}</ActionContainer>
    </ItemContainer>
  );
}
