import React from 'react';
import gql from 'graphql-tag';
import { ApolloError } from 'apollo-client';
import { Mutation } from 'react-apollo';
import { Button, Intent } from '@blueprintjs/core';
import trim from 'lodash/trim';

interface Props {
  nickname: string;
  onError(error: ApolloError): void;
  onUpdate(): void;
  onCompleted(message: string): void;
}

export const UPDATE_EMAIL_MUTATION = gql`
  mutation($nickname: String!) {
    user: updateNickname(nickname: $nickname) {
      id
      nickname
    }
  }
`;

export function NicknameUpdateButton(props: Props) {
  return (
    <Mutation
      mutation={UPDATE_EMAIL_MUTATION}
      onError={props.onError}
      onCompleted={() => props.onCompleted('업데이트 되었습니다')}
      variables={{
        nickname: trim(props.nickname),
      }}
    >
      {(update, obj) => {
        return (
          <Button
            id="nicknameUpdate"
            intent={Intent.NONE}
            disabled={obj.loading}
            loading={obj.loading}
            onClick={() => {
              update();
              props.onUpdate();
            }}
          >
            저장
          </Button>
        );
      }}
    </Mutation>
  );
}
