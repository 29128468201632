import React from 'react';
import { css } from 'react-emotion';
import { Card } from '../../components';
import { Button, Intent } from '@blueprintjs/core';

interface Props {
  month: number;
  onChange(month: number): void;
}

const items = [
  {
    label: '1 개월',
    value: 1,
  },
  {
    label: '3 개월',
    value: 3,
  },
  {
    label: '6 개월',
    value: 6,
  },
];

function DurationChooser(props: Props) {
  return (
    <Card
      style={{
        backgroundColor: 'rgb(35,35,35)',
      }}
    >
      <div
        className={css({
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridColumnGap: '1em',
        })}
      >
        {items.map(item => {
          const active = item.value === props.month;
          return (
            <Button
              style={{
                color: active ? 'black' : 'white',
                borderRadius: '0.3em',
              }}
              minimal={!active}
              key={item.value}
              intent={Intent.PRIMARY}
              onClick={() => props.onChange(item.value)}
            >
              {item.label}
            </Button>
          );
        })}
      </div>
    </Card>
  );
}

export default DurationChooser;
