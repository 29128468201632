import React from 'react';
import gql from 'graphql-tag';
import { ApolloError } from 'apollo-client';
import { Mutation } from 'react-apollo';
import { Button, Intent } from '@blueprintjs/core';

export const EMAIL_VERIFY_MUTATION = gql`
  mutation VerifyNickname($nickname: String!) {
    verifyNickname(nickname: $nickname)
  }
`;

interface Props {
  nickname: string;
  verified?: boolean;
  onError(error: ApolloError | Error): void;
  onCompleted(message: string): void;
}

export function NicknameVerifyButton(props: Props) {
  return (
    <Mutation
      mutation={EMAIL_VERIFY_MUTATION}
      onError={props.onError}
      onCompleted={(data: any) => {
        if (data && data.verifyNickname) {
          props.onCompleted('사용할 수 있는 아이디 입니다');
        } else {
          props.onError(new Error('중복된 아이디 입니다'));
        }
      }}
    >
      {(update, obj) => {
        return (
          <Button
            id="nicknameVerify"
            intent={Intent.SUCCESS}
            disabled={obj.loading || props.verified}
            loading={obj.loading}
            onClick={() => {
              update({
                variables: {
                  nickname: props.nickname,
                },
              });
            }}
          >
            중복 아이디 확인
          </Button>
        );
      }}
    </Mutation>
  );
}
