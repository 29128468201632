import { AnchorButton, IButtonProps } from '@blueprintjs/core';
import { css } from 'emotion';
import React from 'react';
import { KakaotalkLogo } from '../logos/Kakaotalk';

const KakaoAnchorCss = css({
  backgroundImage: 'none !important',
  backgroundColor: 'rgb(255,232,7) !important',
  borderRadius: '1em !important',
  '& > span': {
    display: 'flex',
    alignItems: 'center',
  },
  ':hover': {
    backgroundColor: 'rgb(255,232,7)',
  },
});

const messages = {
  register: '회원가입',
  login: '로그인',
  connect: '연결하기',
};

export interface ExtraProps {
  message: 'register' | 'login' | 'connect';
  remember?: boolean;
}

function KakaoButton(props: IButtonProps & ExtraProps) {
  const { message, remember, ...rest } = props;
  const text = messages[message];
  let href = '/auth/kakao';
  href = props.remember ? href + '?remember=true' : href;
  return (
    <AnchorButton {...rest} href={href} className={KakaoAnchorCss}>
      <KakaotalkLogo
        width="1.2em"
        height="1.2em"
        style={{ marginRight: '0.5em' }}
      />
      {text}
    </AnchorButton>
  );
}

export default KakaoButton;
