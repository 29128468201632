import React from 'react';
import { RouteComponentProps } from 'react-router';
import { BoardType } from '../../gql-types';
import { BoardContentView } from '../community/BoardContentView';
import { CommunityLayout } from '../community/CommunityLayout';

export function CommunityContent(type: BoardType) {
  interface Props extends RouteComponentProps<any> {}

  class CommunityContentPage extends React.Component<Props> {
    static displayName = `CommunityContent(${type})`;

    render() {
      const id = this.props.match.params.id;
      return (
        <CommunityLayout type={type} history={this.props.history}>
          <BoardContentView type={type} id={id} />
        </CommunityLayout>
      );
    }
  }

  return CommunityContentPage;
}
