import React from 'react';
import { RouteComponentProps } from 'react-router';
import { ProductGroupDetailView } from '../products/ProductGroupDetailView';

interface Params {
  slug: string;
}

interface Props extends RouteComponentProps<Params> {}

export function ProductGroupItemPage(props: Props) {
  const { slug } = props.match.params;
  return <ProductGroupDetailView.Connected slug={slug} />;
}
