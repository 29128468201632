import React from 'react';
import { RouteComponentProps } from 'react-router';
import { ReviewSectionLayout } from '../community/ReviewSectionLayout';
import { ReviewList } from '../community/ReviewList';
import { WithCurrentUser, ICurrentUserProps } from '../users/CurrentUserQuery';

interface Props extends RouteComponentProps<any>, ICurrentUserProps {}

export class ReviewListPageBase extends React.Component<Props> {
  render() {
    const { user } = this.props;
    const editEnabled = Boolean(user);
    return (
      <ReviewSectionLayout type="reviews" history={this.props.history}>
        <ReviewList currentUser={user} first={20} editEnabled={editEnabled} />
      </ReviewSectionLayout>
    );
  }
}

export const ReviewListPage = WithCurrentUser(ReviewListPageBase);
