import { Button, Intent } from '@blueprintjs/core';
import { css } from 'emotion';
import React from 'react';
import { Card } from '../../components';
import { Lecture, User } from '../../gql-types';
import { getNodes } from '../../secret-fns';
import { MembershipInfoView } from '../users/MembershipInfoView';
import { LectureCompleteBar } from './LectureCompleteBar';

interface Props {
  user: User;
  lecture: Lecture;
  onLectureView(lectureSlug: string): void;
  onCourseDetail(courseSlug: string): void;
  onExtensionRequest(): void;
}

export class CurrentLectureScreen extends React.Component<Props> {
  private view = () => {
    const { lecture } = this.props;
    return this.props.onLectureView(lecture.slug);
  };

  private courseDetail = () => {
    const { lecture } = this.props;
    return this.props.onCourseDetail(lecture.course.slug);
  };

  render() {
    const { lecture } = this.props;
    const course = lecture.course;
    const allLectures = getNodes(course.lectureConnection);
    const completedLectures = allLectures.filter(
      lecture => lecture.progress.completed,
    );
    return (
      <Card media={course.imageUrl}>
        <div
          className={css({
            display: 'grid',
            // gridTemplateColumns: '2fr auto',
            gridColumnGap: '1em',
            wordBreak: 'keep-all',
          })}
        >
          <section>
            <h2>현재 진행중</h2>
            <h3>{lecture.course.name}</h3>
            <LectureCompleteBar
              total={allLectures.length}
              completed={completedLectures.length}
            />
          </section>
          <MembershipInfoView
            user={this.props.user}
            onExtensionRequest={this.props.onExtensionRequest}
          />
        </div>
        <div
          className={css({
            marginTop: '2em',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          })}
        >
          <Button id="view-now" intent={Intent.PRIMARY} onClick={this.view}>
            운동 시작하기 {lecture.name}
          </Button>
          <Button
            id="course-detail"
            intent={Intent.SUCCESS}
            onClick={this.courseDetail}
          >
            프로그램 정보
          </Button>
        </div>
      </Card>
    );
  }
}
