import { NonIdealState, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import gql from 'graphql-tag';
import React from 'react';
import { Mutation, Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import { HlsVideoPlayer } from '../../components';
import { DailyLog, Lecture, User } from '../../gql-types';

export const LogViewUse = gql`
  mutation LogVideoUse($event: VideoViewEvent) {
    logVideoUse(event: $event) {
      course {
        id
        unlocked
        progress {
          completed
        }
      }
      lecture {
        id
        progress {
          completed
        }
      }
    }
  }
`;

export const LectureById = gql`
  query GetLectureById($slug: String!) {
    user: currentUser {
      id
      nickname
    }
    currentDailyLog(slug: $slug) {
      playDay
      logIndex
    }
    subscriptions: currentSubscription {
      id
      level
      status
      startsAt
      expiresAt
    }
    lecture(slug: $slug) {
      id
      slug
      name
      effectiveAreas
      description
      courseId
      number
      duration
      imageUrl
      thumbnailUrl
      videoUrl
      progress {
        currentTimeInSec
        completed
      }
      course {
        id
        name
        logoUrl
        imageUrl
        progress {
          completed
        }
      }
    }
  }
`;

interface Props {
  userId?: string;
  slug: string;
  onInvalidAccess?(): void;
}
let updateTime = 10;

export function LecturePlayer(props: Props) {
  const { slug } = props;

  return (
    <Mutation mutation={LogViewUse}>
      {update => {
        return (
          <Query
            query={LectureById}
            variables={{ slug }}
            fetchPolicy="network-only"
          >
            {obj => {
              if (obj.loading) {
                return <Spinner />;
              }

              const user = obj.data && (obj.data.user as User | null);
              const lecture = obj.data && (obj.data.lecture as Lecture | null);
              const dailyLog = obj.data && (obj.data.currentDailyLog as DailyLog);
              const {logIndex, playDay} = dailyLog;

              if (!user) {
                return (
                  <NonIdealState
                    title="로그인 해 주세요"
                    icon={IconNames.VIDEO}
                  >
                    <Link to="/">홈페이지로 돌아가기</Link>
                  </NonIdealState>
                );
              }

              if (!lecture) {
                return (
                  <NonIdealState
                    title="프로그램을 찾지 못하였습니다"
                    icon={IconNames.VIDEO}
                  >
                    <Link to="/">홈페이지로 돌아가기</Link>
                  </NonIdealState>
                );
              }

              if (!user.nickname) {
                return (
                  <NonIdealState
                    title="유저아이디를 설정해 주세요"
                    icon={IconNames.VIDEO}
                  >
                    <div>시크릿다이어트 프로그램 이용을 위해서는</div>
                    <div>유저아이디를 설정해 주셔야 합니다.</div>
                    <Link to="/profile">유저 아이디 설정하기</Link>
                  </NonIdealState>
                );
              }

              const { videoUrl } = lecture;
              return (
                <HlsVideoPlayer
                  userId={user.id}
                  userNickname={user.nickname}
                  lecture={lecture}
                  src={videoUrl || ''}
                  type="application/x-mpegURL"
                  timeToStart={lecture.progress.currentTimeInSec}
                  onInvalidAccess={props.onInvalidAccess}
                  onTimeUpdate={time => {
                    console.log("logIndex", logIndex);
                    console.log("playDay", playDay);
                    console.log("time", time);
                    
                    update({
                      variables: {
                        event: {
                          lectureId: lecture.id,
                          currentTimeInSec: time,
                          updateTime: updateTime,
                          playDay: playDay,
                          logIndex: logIndex,
                          slug: lecture.slug,
                        },
                      },
                    });
                  }}
                />
              );
            }}
          </Query>
        );
      }}
    </Mutation>
  );
}
