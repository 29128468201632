import React, { useState, useCallback } from 'react';
import { Callout, Intent, Button } from '@blueprintjs/core';
import { Spinner } from '@blueprintjs/core';
import { User } from '../../gql-types';
import { PageLayout } from '../layouts/PageLayout';
import { CheckoutView } from './CheckoutView';
import { ApolloClient } from 'apollo-client';
import { useCartHook } from './CartHooks';
import { Link } from 'react-router-dom';

interface Props {
  user?: User | null;
  client: ApolloClient<any>;
  localCartItems?: any[];
  onCompleted(): void;
  onCancel?(): void;
}

function formatMessage(msg?: string) {
  if (!msg) {
    return '';
  }

  return msg.replace('GraphQL error:', '').trim();
}

export function ShoppingCartDisplay(props: Props) {
  const [fail, setFail] = useState(null as any);
  const cartHook = useCartHook(Boolean(props.user), props.client);

  const { loading, data } = cartHook.items;
  const onSuccess = useCallback(() => {
    cartHook.resetCart();
    props.onCompleted();
  }, []);

  return (
    <PageLayout title="장바구니">
      {fail && (
        <Callout intent={Intent.WARNING} title="결제에러">
          {fail && fail.message && formatMessage(fail.message)}
        </Callout>
      )}
      {!Boolean(props.user) && (
        <Callout intent={Intent.NONE} title="로그인 필요">
          결제를 위해서는 로그인을 하셔야 합니다.{' '}
          <Link to="/login?redirect=/cart">로그인</Link>
        </Callout>
      )}
      {loading ? (
        <Spinner />
      ) : (
        <CheckoutView
          user={props.user}
          invoiceName="쇼핑카트"
          onFail={setFail}
          onSuccess={onSuccess}
          onCancel={props.onCancel}
          onItemRemove={cartHook.remove.onRequest}
          onItemUpdate={cartHook.update.onRequest}
          items={data}
          client={props.client}
        />
      )}
    </PageLayout>
  );
}
