import React from 'react';
import { Card } from '../../components';
import {
  Button,
  Intent,
  InputGroup,
  ControlGroup,
  FormGroup,
  Callout,
} from '@blueprintjs/core';
import { ApolloError } from 'apollo-client';
import { isValidPassword } from '../../security/password-strength';

export interface ResetPasswordInput {
  password: string;
  passwordConfirm: string;
}

interface State extends ResetPasswordInput {
  validated: boolean;
}

interface Props {
  saving?: boolean;
  called?: boolean;
  code?: string;
  error?: ApolloError;
  onSubmit(passwords: ResetPasswordInput): void;
  onLogin?(): void;
  children?: any;
}

const isStrong = isValidPassword;

export class ResetPasswordForm extends React.Component<Props, State> {
  state = {
    password: '',
    validated: false,
    passwordConfirm: '',
  };

  private handleSubmit = () => {
    const { password, passwordConfirm } = this.state;
    this.setState({
      validated: true,
    });

    const invalidPasswordConfirm = password !== passwordConfirm;
    const invalidPassword = !isStrong(password);

    if (!invalidPassword && !invalidPasswordConfirm) {
      this.props.onSubmit({
        password,
        passwordConfirm,
      });
    }
  };

  private handleChagne = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    const key = target.name as keyof State;
    const value = target.value;
    this.setState({
      [key]: value,
    } as any);
  };

  render() {
    const errorMsg = (
      <Callout intent={Intent.DANGER} title="업데이트에 실패하였습니다">
        새로운 이메일을 보내서 시도해 주세요.
      </Callout>
    );

    const { password, passwordConfirm } = this.state;

    const invalidPasswordConfirm =
      this.state.validated && password !== passwordConfirm;
    const invalidPassword = this.state.validated && !isStrong(password);

    return (
      <Card style={{ width: '100%' }}>
        {this.props.error && errorMsg}
        <ControlGroup fill>
          <FormGroup
            label="암호"
            labelFor="password"
            intent={invalidPassword ? Intent.DANGER : Intent.NONE}
            helperText={invalidPassword ? '8자 이상에 대문자 1개 이상' : ''}
          >
            <InputGroup
              style={{ width: '100%' }}
              id="password"
              name="password"
              placeholder="암호"
              type="password"
              value={this.state.password}
              onChange={this.handleChagne}
            />
          </FormGroup>
        </ControlGroup>
        <FormGroup
          label="암호확인"
          labelFor="password"
          intent={invalidPasswordConfirm ? Intent.DANGER : Intent.NONE}
          helperText={invalidPasswordConfirm ? '패스워드가 다릅니다' : ''}
        >
          <InputGroup
            style={{ width: '100%' }}
            id="passwordConfirm"
            name="passwordConfirm"
            placeholder="암호확인"
            type="password"
            value={this.state.passwordConfirm}
            onChange={this.handleChagne}
          />
        </FormGroup>
        <Button
          id="register"
          loading={this.props.saving}
          disabled={this.props.called}
          intent={Intent.PRIMARY}
          fill
          onClick={this.handleSubmit}
        >
          리셋
        </Button>
        {this.props.called && !this.props.error && (
          <Button
            style={{ marginTop: '1em' }}
            id="move-to-login"
            intent={Intent.PRIMARY}
            fill
            onClick={this.props.onLogin}
          >
            로그인 하기
          </Button>
        )}
      </Card>
    );
  }
}
