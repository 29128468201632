import { css } from 'emotion';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import isBefore from 'date-fns/is_before';
import React from 'react';
import { MobileChat, TV } from '../../components/icons';
import { SubscriptionLevel, UserSubscription } from '../../gql-types';

interface Props {
  subscription: UserSubscription;
}

const SubscriptionLabel = (subscription: UserSubscription): string => {
  switch (subscription.level) {
    case SubscriptionLevel.none:
      return '비회원';
    case SubscriptionLevel.basic:
      return '영상 멤버십';
    case SubscriptionLevel.advanced:
      return '컨설팅 멤버십';
    case SubscriptionLevel.course:
        return '코스 멤버십';
  }
};

const SubscriptionIcon = (subscription: UserSubscription) => {
  switch (subscription.level) {
    case SubscriptionLevel.none:
      return null;
    case SubscriptionLevel.basic:
    case SubscriptionLevel.course:
      return <TV />;
    case SubscriptionLevel.advanced:
      return <MobileChat />;
  }
};

function ExpirationLabel(expiresAt: Date | number | string) {
  const date = parse(expiresAt);
  return `${format(date, 'YYYY-MM-DD')} ${date.toLocaleTimeString()}`;
}

export function MembershipView(props: Props) {
  const { expiresAt } = props.subscription;
  const isExpired = isBefore(expiresAt, new Date());

  return (
    <div
      className={css({
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '3em 1fr 1fr 1fr',
        gridColumnGap: '0.5em',
      })}
    >
      <div style={{ width: '2em', height: '2em', }} >
        {SubscriptionIcon(props.subscription)}
      </div>
      <div>{SubscriptionLabel(props.subscription)}</div>
      <div style={{ color: isExpired ? 'red' : undefined }}>
        {ExpirationLabel(expiresAt)} {isExpired && '(만료)'}
      </div>
      {props.subscription.level === SubscriptionLevel.advanced && (
        <a href="http://pf.kakao.com/_gxlNkd">
          담당트레이너와 카카오톡 연결하기
        </a>
      )}
    </div>
  );
}
