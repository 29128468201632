import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Button, Intent } from '@blueprintjs/core';

const UPDATE_PROFILE_MUTATION = gql`
  mutation UpdateProfile($name: String, $gender: Gender, $birthday: String) {
    updateProfile(name: $name, gender: $gender, birthday: $birthday) {
      id
      email
      firstName
      lastName
      gender
      birthday
    }
  }
`;

interface Props {
  profile: {
    name: string;
    gender: string;
    birthday: string;
  };
  onCompleted(): void;
}

export class UpdateProfileButton extends React.Component<Props> {
  render() {
    const { profile } = this.props;
    return (
      <Mutation
        mutation={UPDATE_PROFILE_MUTATION}
        variables={profile}
        onCompleted={this.props.onCompleted}
      >
        {(update, obj) => {
          return (
            <Button
              id="updateProfile"
              name="updateProfile"
              loading={obj.loading}
              disabled={obj.loading}
              intent={Intent.SUCCESS}
              onClick={() => update()}
            >
              저장하기
            </Button>
          );
        }}
      </Mutation>
    );
  }
}
