import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { css } from 'emotion';
import {
  CreditCard,
  Bank,
  Paypal,
  OnlineTransfer,
} from '../../components/icons';
import { ChooserGrid, ChooserButton } from '../../components';
import { Spinner } from '@blueprintjs/core';

interface Props {
  method: string;
  onChange(method: string): void;
}

interface PaymentItem {
  id: string;
  label: string;
  helperText?: string;
  icon: any;
}

const FETCH_BENEPIA_PARAMS = gql`
  query {
    benepiaParams {
      sitecode
      sitename
      userid
      benefit_id
    }
  }
`;

const items: PaymentItem[] = [
  {
    id: 'card',
    label: '신용카드',
    helperText: 'BC 및 BC 계열 카드는 최장 3개월 까지 할부가 적용됩니다.',
    icon: <CreditCard />,
  },
  {
    id: 'trans',
    label: '실시간 계좌이체',
    icon: <Bank />,
  },
  {
    id: 'paypal',
    label: '페이팔',
    icon: <Paypal />,
  },
  // {
  //   id: 'phone',
  //   label: '휴대폰 결제',
  //   icon: <MobileCheckout />,
  // },
  // {
  //   id: 'vbank',
  //   label: '무통장 입금',
  //   icon: <OnlineTransfer />,
  // },
];

export function PaymentMethodChooser(props: Props) {
  return (
    <Query query={FETCH_BENEPIA_PARAMS}>
      {result => {
        if (result.loading) {
          return <Spinner />;
        }

        const hasParams = Boolean(result.data && result.data.benepiaParams);
        const options = hasParams
          ? items.concat({
              id: 'point',
              label: '베네피아 포인트 및 복합결제',
              icon: <OnlineTransfer />,
            })
          : items;

        return (
          <ChooserGrid size={2}>
            {options.map(item => {
              return (
                <ChooserButton
                  key={item.id}
                  active={props.method === item.id}
                  onClick={() => props.onChange(item.id)}
                >
                  <div
                    style={{
                      marginBottom: '1em',
                    }}
                  >
                    <i
                      style={{
                        display: 'block',
                        width: '2em',
                        height: '2em',
                        margin: '0 auto',
                      }}
                    >
                      {item.icon}
                    </i>
                  </div>
                  <div>{item.label}</div>
                  {item.helperText && (
                    <div
                      className={css({
                        fontSize: '0.8em',
                        color: 'grey',
                      })}
                    >
                      {item.helperText}
                    </div>
                  )}
                </ChooserButton>
              );
            })}
          </ChooserGrid>
        );
      }}
    </Query>
  );
}
