import React, { useCallback } from 'react';
import { Button, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import {
  IGenericItem,
  SelectProps,
  GenericSelectBase,
  GenericItemView,
} from './GenericSelect';

interface StaticSelectProps extends SelectProps {
  items: IGenericItem[];
  placeholder?: any;
}

export function StaticGenericSelect(props: StaticSelectProps) {
  const { value, items } = props;
  const currentValue = items.find(item => item.id === value);
  const handleItemSelect = useCallback(
    (item: IGenericItem) => props.onSelect(item.id),
    [items],
  );

  return (
    <GenericSelectBase
      items={items}
      filterable={false}
      disabled={props.disabled}
      activeItem={currentValue}
      itemRenderer={GenericItemView}
      onItemSelect={handleItemSelect}
    >
      <Button
        disabled={props.disabled}
        intent={Intent.NONE}
        text={currentValue ? currentValue.label : props.placeholder}
        rightIcon={IconNames.CARET_DOWN}
      />
    </GenericSelectBase>
  );
}
