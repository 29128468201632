import React from 'react';
import { css } from 'emotion';
import { fromEvent, merge, Subscription } from 'rxjs';
import Hls, { errorData } from 'hls.js';
import { throttleTime, filter, first } from 'rxjs/operators';
import { SecurityText } from './SecurityText';
import { Lecture } from '../../gql-types';

interface Props {
  src?: string;
  type?: string;
  lecture: Lecture;
  timeToStart?: number;
  poster?: string | null;
  userId?: string;
  userNickname?: string | null;
  onReady?: () => void;
  onTimeUpdate?: (time: number) => void;
  onInvalidAccess?(): void;
  onEnd?: () => void;
}

interface State {
  playing: boolean;
  completed: boolean;
}

const currentTimeInSec = (player: HTMLVideoElement) =>
  Math.floor(player.currentTime);

function isInvalidAccess(data: errorData) {
  return (
    data.type === 'networkError' &&
    data.details === 'keyLoadError' &&
    data.response &&
    data.response.code === 403
  );
}

export class HlsVideoPlayer extends React.Component<Props, State> {
  video: HTMLVideoElement | null = null;
  hls: Hls | null = null;
  events: Subscription[] = [];
  state = {
    playing: false,
    completed: false,
  };

  componentDidMount() {
    if (!this.video) {
      return;
    }

    const video = this.video;
    const timeupdates = fromEvent(video, 'timeupdate')
      .pipe(throttleTime(10000))
      .pipe(filter(() => currentTimeInSec(video) !== 0));
    const play = fromEvent(video, 'play');
    const pause = fromEvent(video, 'pause');
    const seeked = fromEvent(video, 'seeked');
    const ended = fromEvent(video, 'ended');
    const updates = merge(timeupdates, seeked, ended);
    const sub = updates.subscribe(this.handleTimeupdate);
    const playSub = play.subscribe(this.handlePlay);
    const endSub = merge(pause, ended).subscribe(this.handleStop);
    const { timeToStart } = this.props;
    const duration$ = fromEvent(video, 'durationchange').pipe(first());
    const durationSub = duration$.subscribe(() => {
      if (
        timeToStart &&
        !Number.isNaN(video.duration) &&
        video.duration - timeToStart > 120
      ) {
        video.currentTime = timeToStart;
      }
    });

    this.events.push(sub);
    this.events.push(playSub);
    this.events.push(endSub);
    this.events.push(durationSub);

    if (Hls.isSupported()) {
      try {
        const hls = new Hls({});
        this.hls = hls;
        hls.loadSource(this.props.src || '');
        hls.attachMedia(this.video);
        hls.on(Hls.Events.MANIFEST_PARSED, this.focus);
        hls.on('hlsError', this.handleError);
      } catch (err) {
        console.error('err', err);
        // TODO:: prompt user to login
        // TODO: display error screen as unsupported
      }
    } else {
      // TODO:: display error screen as unsupported browser
      // console.log('no');
    }
  }

  componentWillUnmount() {
    this.events.forEach(e => {
      e.unsubscribe();
    });
    this.events = [];
    if (this.hls) {
      this.hls.off(Hls.Events.MANIFEST_PARSED, this.focus);
      this.hls.off('hlsError', this.handleError);
      this.hls.destroy();
    }
  }

  private focus = () => {
    if (this.video) {
      this.video.focus();
    }
  };

  private handleTimeupdate = () => {
    if (this.video) {
      const currentTime = currentTimeInSec(this.video);
      const { duration } = this.props.lecture;
      if (this.props.onTimeUpdate) {
        this.props.onTimeUpdate(currentTime);
      }

      if (duration - currentTime <= 120) {
        this.setState({ completed: true });
      }
    }
  };

  private handleClick = () => {
    if (this.video) {
      this.video.focus();
    }
  };

  private handlePlay = () => {
    this.setState({ playing: true });
  };

  private handleStop = () => {
    this.setState({ playing: false });
  };

  private preventDefault = (e: React.SyntheticEvent<any>) => e.preventDefault();

  private handleError = (err: any, data: errorData) => {
    if (isInvalidAccess(data) && this.props.onInvalidAccess) {
      this.props.onInvalidAccess();
    }
  };

  render() {
    return (
      <div
        className={css({
          display: 'flex',
          position: 'relative',
          width: '100%',
          overflow: 'hidden',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'black',
        })}
        onClick={this.handleClick}
      >
        <video
          className={css({
            width: 'auto',
            maxWidth: '100%',
            backgroundColor: 'black',
            display: 'block',
            minHeight: '80vh',
          })}
          controls={true}
          onContextMenu={this.preventDefault}
          ref={video => (this.video = video)}
        >
          <source src={this.props.src} type={this.props.type} />
        </video>
        <SecurityText
          playing={this.state.playing}
          userId={this.props.userId}
          userNickname={this.props.userNickname}
        />
        {/* {this.state.completed && (
          <LectureCompleteScreen
            onBackToHome={() => {}}
            onClose={() => this.setState({ completed: false })}
          />
        )} */}
      </div>
    );
  }
}
