import { Button, Intent } from '@blueprintjs/core';
import { css } from 'emotion';
import React from 'react';
import styled from 'react-emotion';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { CardStyles, StarPoint } from '../../components';
import { Dumbbell } from '../../components/icons/Dumbbell';
import { ResistanceBand } from '../../components/icons/ResistanceBand';
import { YogaMat } from '../../components/icons/YogaMat';
import { Course } from '../../gql-types';
import { WithCurrentUser, ICurrentUserProps } from '../users/CurrentUserQuery';

interface Props extends RouteComponentProps<any>, ICurrentUserProps {
  item: Course;
  onClickDetail?: () => void;
}

const StyleCard = css({
  width: '90vw',
  '@media (min-width: 800px)': {
    width: 'calc(32vw - 1em)',
  },
});

const ContainerStyle = css(CardStyles.container, CardStyles.shadow, StyleCard);
const MediaContainerStyel = css(CardStyles.media, CardStyles.mediaContainer);
const ActionFooter = styled('footer')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

const FlexCentered = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const MetaHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '1em',
});

function CourseCard(props: Props) {
  const { item } = props;
  const imageUrl = item.imageUrl;

  return (
    <div className={ContainerStyle} style={{ minWidth: 250 }}>
      <div
        className={MediaContainerStyel}
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
      <div className={CardStyles.content} style={{ backgroundColor: 'white' }}>
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
          })}
        >
          <h3>{item.name}</h3>
          {item.logoUrl && (
            <img
              className={css({
                maxHeight: 30,
                height: '100%',
                marginLeft: '0.5em',
              })}
              src={item.logoUrl}
            />
          )}
        </div>
        <MetaHeader>
          <FlexCentered>
            <div
              style={{
                marginRight: '1em',
              }}
            >
              난이도
            </div>
            <div>
              <StarPoint value={item.difficulty} max={6} color="black" />
            </div>
          </FlexCentered>
          <FlexCentered>
            <YogaMat
              style={{
                width: '1.5em',
                height: '1.5em',
                fill: 'transparent',
                stroke: 'black',
                strokeWidth: 2,
                marginRight: '0.5em',
              }}
            />
            <ResistanceBand
              style={{
                width: '1.5em',
                height: '2em',
                marginRight: '0.5em',
              }}
            />
            <Dumbbell
              style={{
                width: '1.5em',
                height: '1.5em',
              }}
            />
          </FlexCentered>
        </MetaHeader>
        <ActionFooter>
          <Button
            intent={Intent.PRIMARY}
            onClick={() =>
              props.history.push(`/courses/${item.slug}/description`)
            }
          >
            자세히 보기
          </Button>
          {props.user && (
            <Button
              onClick={() => props.history.push(`/courses/${item.slug}`)}
              intent={Intent.SUCCESS}
            >
              프로그램 리스트
            </Button>
          )}
        </ActionFooter>
      </div>
    </div>
  );
}

export default withRouter(WithCurrentUser(CourseCard));
