import {
  Button,
  ControlGroup,
  FormGroup,
  Intent,
  Classes,
  Switch,
  InputGroup,
} from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { Card, KakaoButton, FacebookButton } from '../../components';
import React, { FormEventHandler } from 'react';

interface Props {
  loading?: boolean;
  onSubmit?(cred: Credential): void;
}

export interface Credential {
  email: string;
  password: string;
  passwordConfirm: string;
}

interface State {
  creds: {
    [key: string]: string;
  };
  agreeTos: boolean;
  valid: boolean;
}

class Signup extends React.Component<Props, State> {
  state = {
    creds: {
      email: '',
      password: '',
      passwordConfirm: '',
      nickname: '',
      name: '',
      referral: '',
    },
    agreeTos: false,
    valid: false,
  };

  private handleSubmit: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    if (this.props.onSubmit) {
      const { creds } = this.state;
      this.props.onSubmit(creds);
    }
  };

  private handleCredsChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const { creds } = this.state;
    this.setState({
      creds: {
        ...creds,
        [name]: value,
      },
    });
  };

  private handleAgreeTos = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const checked = e.currentTarget.checked;
    this.setState({ agreeTos: checked });
  };

  render() {
    const { creds } = this.state;
    return (
      <Card>
        <form onSubmit={this.handleSubmit}>
          <ControlGroup fill>
            <FormGroup label="이메일" labelFor="email">
              <InputGroup
                style={{ width: '100%' }}
                id="email"
                name="email"
                placeholder="이메일을 입력하세요"
                type="email"
                value={creds.email}
                onChange={this.handleCredsChange}
              />
            </FormGroup>
          </ControlGroup>
          <ControlGroup fill>
            <FormGroup label="암호" labelFor="password">
              <InputGroup
                style={{ width: '100%' }}
                id="password"
                name="password"
                placeholder="암호"
                type="password"
                value={creds.password}
                onChange={this.handleCredsChange}
              />
            </FormGroup>
          </ControlGroup>
          <FormGroup label="암호확인" labelFor="password">
            <InputGroup
              style={{ width: '100%' }}
              id="passwordConfirm"
              name="passwordConfirm"
              placeholder="암호확인"
              type="password"
              value={creds.passwordConfirm}
              onChange={this.handleCredsChange}
            />
          </FormGroup>
          <FormGroup label="닉네임" labelFor="nickname">
            <InputGroup
              style={{ width: '100%' }}
              id="nickname"
              name="nickname"
              placeholder="유저아이디"
              value={creds.nickname}
              onChange={this.handleCredsChange}
            />
          </FormGroup>
          <FormGroup label="이름" labelFor="name">
            <InputGroup
              style={{ width: '100%' }}
              id="name"
              name="name"
              placeholder="이름"
              value={creds.name}
              onChange={this.handleCredsChange}
            />
          </FormGroup>
          <FormGroup label="가입경로" labelFor="referral">
            <InputGroup
              style={{ width: '100%' }}
              id="referral"
              name="referral"
              placeholder="가입경로 (선택입력)"
              value={creds.referral}
              onChange={this.handleCredsChange}
            />
          </FormGroup>
          <label
            style={{
              fontSize: '0.8em',
              wordBreak: 'keep-all',
            }}
          >
            서비스를 이용함으로써 귀하는 본 약관에 동의하게 되므로 본 약관을
            주의 깊게 읽어보시기 바랍니다.
          </label>
          <div
            style={{
              marginBottom: '1em',
            }}
          >
            <Link to="/tos">이용약관 및 개인정보방침</Link>
            <Switch
              label="위 이용약관을 읽었으며, 내용에 동의합니다."
              checked={this.state.agreeTos}
              onChange={this.handleAgreeTos}
            />
          </div>
          <Button
            disabled={!this.state.agreeTos}
            id="register"
            type="submit"
            intent={Intent.PRIMARY}
            fill
          >
            회원가입
          </Button>
          <div
            style={{
              marginTop: '1em',
              marginBottom: '1em',
            }}
          >
            <FacebookButton
              disabled={!this.state.agreeTos}
              large
              fill
              message="register"
            />
          </div>
          <div>
            <KakaoButton
              disabled={!this.state.agreeTos}
              large
              fill
              message="register"
            />
          </div>
        </form>
      </Card>
    );
  }
}

export default Signup;
