import React from 'react';
import { History } from 'history';
import { Card } from '../../components';
import { DietbibleCard } from './DietbibleCard';
import { EpochCard } from './EpochCard';

interface Props {
  history: History;
}

export class StartDescription extends React.Component<Props> {
  render() {
    return (
      <div style={{ minWidth: 320 }}>
        <DietbibleCard
          logo="https://s3.ap-northeast-2.amazonaws.com/secret-diet-assets/diet-bible-start-logo.png"
          videoUrl="https://player.vimeo.com/external/247670468.hd.mp4?s=fa1278e78b15648087a58294747fb59964ed860d&profile_id=175"
        />
        <EpochCard />
        <Card style={{ marginTop: '1em' }}>
          <img
            style={{ width: '100%' }}
            src="https://s3.ap-northeast-2.amazonaws.com/secret-diet-assets/start-curriculum.jpg"
          />
        </Card>
      </div>
    );
  }
}
