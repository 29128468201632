import React from 'react';
import { ProgressBar, Intent } from '@blueprintjs/core';

interface Props {
  completed: number;
  total: number;
}

export function LectureCompleteBar(props: Props) {
  const { completed, total } = props;
  const progress = completed / total;
  return (
    <div>
      <div style={{ fontWeight: 800 }}>
        {completed} / {total}
      </div>
      <ProgressBar stripes={false} value={progress} intent={Intent.SUCCESS} />
    </div>
  );
}
