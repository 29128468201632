import {
  Button,
  ControlGroup,
  FormGroup,
  InputGroup,
  Intent,
  Switch,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Link } from 'react-router-dom';
import { Card, FacebookButton, KakaoButton } from '../../components';
import React, { FormEvent } from 'react';

const ItemSpace = {
  marginBottom: 20,
};

interface Props {
  hasError?: boolean;
  className?: string;
  loading?: boolean;
  onSubmit?: (credentials: Credentials, remember: boolean) => void;
}

interface Credentials {
  email: string;
  password: string;
}

interface State {
  credentials: Credentials;
  remember: boolean;
}

class LoginForm extends React.Component<Props, State> {
  private emailInput: HTMLInputElement | null = null;

  state = {
    credentials: {
      email: '',
      password: '',
    },
    remember: false,
  };

  componentDidMount() {
    if (this.emailInput) {
      this.emailInput.focus();
    }
  }

  private handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { credentials } = this.state;
    if (this.props.onSubmit) {
      this.props.onSubmit(credentials, this.state.remember);
    }
  };

  private handleChange = (key: keyof Credentials) => (
    e: FormEvent<HTMLInputElement>,
  ) => {
    const { credentials } = this.state;
    const value = {
      ...credentials,
      [key]: e.currentTarget.value,
    };

    this.setState({ credentials: value });
  };

  render() {
    const { email, password } = this.state.credentials;
    const { remember } = this.state;
    return (
      <Card className={this.props.className}>
        <form onSubmit={this.handleSubmit}>
          <ControlGroup style={ItemSpace} fill vertical>
            <FormGroup>
              <InputGroup
                id="email"
                type="text"
                large
                inputRef={input => (this.emailInput = input)}
                leftIcon={IconNames.USER}
                name="email"
                value={email}
                placeholder="이메일"
                onChange={this.handleChange('email')}
              />
              <InputGroup
                id="password"
                type="password"
                large
                leftIcon={IconNames.LOCK}
                name="password"
                value={password}
                placeholder="암호"
                onChange={this.handleChange('password')}
              />
            </FormGroup>
            <FormGroup>
              <Switch
                label="로그인 상태 유지"
                checked={remember}
                onChange={e =>
                  this.setState({ remember: e.currentTarget.checked })
                }
              />
            </FormGroup>
            <Button
              disabled={this.props.loading}
              id="login-submit"
              intent={Intent.PRIMARY}
              large
              type="submit"
            >
              로그인
            </Button>
          </ControlGroup>
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '2em',
            }}
          >
            <Link to="/forgot-password">패스워드 찾기</Link>
            <Link to="/signup">가입하기</Link>
          </div>
          <div
            style={{
              marginBottom: '1em',
            }}
          >
            <FacebookButton
              className="pt-fill"
              large
              fill
              message="login"
              remember={this.state.remember}
            />
          </div>
          <div>
            <KakaoButton
              className="pt-fill"
              large
              fill
              message="login"
              remember={this.state.remember}
            />
          </div>
        </form>
      </Card>
    );
  }
}

export default LoginForm;
