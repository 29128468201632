import React from 'react';
import { css } from 'emotion';

export const HomeLayoutStyle = css({
  maxWidth: 1920,
  margin: '0 auto',
});

export const HomeFontStyle = css({
  fontSize: 16,
  '@media (min-width: 1080px)': {
    fontSize: '2vw',
  },
});

export const HomeTitleMessage = (props: {
  className?: string;
  message: string;
  highlight?: boolean;
}) => {
  if (props.highlight) {
    const { message } = props;
    const firstLetter = message.charAt(0);
    const rest = message.slice(1);
    const children = [
      <span
        key="first"
        className={css({
          textDecoration: 'overline',
          textDecorationColor: 'rgb(130,207,204)',
        })}
      >
        {firstLetter}
      </span>,
      <span key="rest">{rest}</span>,
    ];

    return <div className={css(props.className)}>{children}</div>;
  }

  return <div className={css(props.className)}>{props.message}</div>;
};
