import React from 'react';
import { css } from 'emotion';
import styled from 'react-emotion';
import { AboutSectionContainer, MaxWidthSetter } from './AboutStyles';

const FirstContainer = styled('article')(
  {
    paddingTop: '5em',
    paddingBottom: '5em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  MaxWidthSetter,
);

export function AboutSecretdiet() {
  return (
    <AboutSectionContainer>
      <FirstContainer>
        <img
          className={css({
            width: '50%',
            marginBottom: '2em',
          })}
          src="https://storage.googleapis.com/secretdiet-public-images/about/secretdiet-big-logo.png"
        />
        <div
          className={css({
            fontSize: '1.2em',
            lineHeight: '2em',
          })}
        >
          <div>시크릿다이어트는 1:1 퍼스널 트레이닝 시스템을</div>
          <div>집으로 옮겨 놓은 새로운 개념의 홈트레이닝 브랜드입니다</div>
        </div>
      </FirstContainer>
    </AboutSectionContainer>
  );
}
