import React from 'react';
import omit from 'lodash/omit';
import { AddressInput } from '../../gql-types';
import { Button, Intent } from '@blueprintjs/core';
import { AddressForm } from '../users/AddressForm';
import { UpdateAddressButton } from '../users/UpdateAddressButton';

interface Props {
  isLoggedIn: boolean;
  initialAddress: AddressInput;
  missingUserAddress?: boolean;
  onChange(address: Partial<AddressInput>): void;
  onCancel(): void;
}

interface State {
  address: Partial<AddressInput>;
}

export class AddressEditView extends React.Component<Props, State> {
  state = {
    address: this.props.initialAddress,
  };

  private handleChange = (address: AddressInput) => {
    this.setState({ address });
  };

  private handleSubmit = () => {
    this.props.onChange(this.state.address);
  };

  private handleCompleted = (data: { address: Partial<AddressInput> }) => {
    this.setState({ address: omit(data.address, 'id') });
    this.props.onChange(data.address);
  };

  render() {
    const { missingUserAddress } = this.props;
    return (
      <div>
        <AddressForm
          address={this.state.address}
          onChange={this.handleChange}
        />
        {this.props.isLoggedIn && missingUserAddress ? (
          <UpdateAddressButton
            address={this.state.address}
            onCompleted={this.handleCompleted}
          />
        ) : (
          <Button intent={Intent.SUCCESS} onClick={this.handleSubmit}>
            저장하기
          </Button>
        )}
      </div>
    );
  }
}
