import React from 'react';
import { Card } from '../../components';
import { User, AddressInput, Address } from '../../gql-types';
import styled from 'react-emotion';
import { Button, Intent, Callout } from '@blueprintjs/core';
import { AddressEditView } from './AddressEditView';

interface Props {
  user?: User | null;
  address: AddressInput;
  onChange(address: AddressInput): void;
}

interface State {
  edit: boolean;
}

function isMissingAddress(address?: AddressInput | Address | null) {
  if (!address) {
    return true;
  }

  return (
    address.address1 === '' ||
    address.address1 === '' ||
    address.city === '' ||
    address.mobile === '' ||
    address.postalCode === '' ||
    address.country === ''
  );
}

const ItemLayout = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
});

const ItemFieldName = styled('label')({
  flexBasis: 'auto',
  flexGrow: 0,
  flexShrink: 1,
  minWidth: 120,
});

export class AddressView extends React.Component<Props, State> {
  state: State = {
    edit: false,
  };

  private startEditMode = () => this.setState({ edit: true });
  private stopEditMode = () => this.setState({ edit: false });

  private handleChange = (address: AddressInput) => {
    this.stopEditMode();
    this.props.onChange(address);
  };

  renderView() {
    if (this.state.edit) {
      return null;
    }

    const { address } = this.props;
    return (
      <>
        <ItemLayout>
          <ItemFieldName>주소1</ItemFieldName>
          <div>{address.address1}</div>
        </ItemLayout>
        <ItemLayout>
          <ItemFieldName>주소2</ItemFieldName>
          <div>{address.address2}</div>
        </ItemLayout>
        <ItemLayout>
          <ItemFieldName>전화</ItemFieldName>
          <div>{address.mobile}</div>
        </ItemLayout>
        <ItemLayout>
          <ItemFieldName>도시</ItemFieldName>
          <div>{address.city}</div>
        </ItemLayout>
        {address.country !== 'KOR' && (
          <ItemLayout>
            <ItemFieldName>주</ItemFieldName>
            <div>{address.state}</div>
          </ItemLayout>
        )}
        <ItemLayout>
          <ItemFieldName>우편번호</ItemFieldName>
          <div>{address.postalCode}</div>
        </ItemLayout>
      </>
    );
  }

  renderForm() {
    if (!this.state.edit) {
      return null;
    }

    const { user } = this.props;

    return (
      <AddressEditView
        isLoggedIn={Boolean(user)}
        missingUserAddress={isMissingAddress(user && user.address)}
        initialAddress={this.props.address}
        onChange={this.handleChange}
        onCancel={this.stopEditMode}
      />
    );
  }

  render() {
    const { address } = this.props;
    return (
      <Card>
        <h3>주소 정보</h3>
        {isMissingAddress(address) && (
          <Callout intent={Intent.WARNING} title="주소를 입력해 주세요">
            원활한 결제를 위해서, 주소를 입력해 주세요.
          </Callout>
        )}
        {this.renderView()}
        {this.renderForm()}
        {!this.state.edit && (
          <Button onClick={this.startEditMode}>변경하기</Button>
        )}
      </Card>
    );
  }
}
