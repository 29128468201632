import React from 'react';
import { css } from 'emotion';
import { User, UserSubscription, SubscriptionLevel } from '../../gql-types';
import { Card } from '../../components';
import { Link } from 'react-router-dom';
import { MembershipView } from './MembershipView';

interface Props {
  user: User;
}

const hasSubscription = (subscriptions: UserSubscription[]): boolean => {
  return subscriptions.length > 0;
};

export class MembershipStatusView extends React.Component<Props> {
  render() {
    const { user } = this.props;
    const subscriptions = user.subscriptions;
    return (
      <Card
        style={{
          marginBottom: '1em',
        }}
      >
        <h3>멤버십 정보</h3>
        <div>
          {hasSubscription(subscriptions)
            ? subscriptions
                .sort((a, b) => {
                  if (a.level === SubscriptionLevel.basic) {
                    return -1;
                  }

                  return 1;
                })
                .map(subscription => {
                  return (
                    <MembershipView
                      key={subscription.level}
                      subscription={subscription}
                    />
                  );
                })
            : undefined}
        </div>
        {!hasSubscription(subscriptions) && (
          <Link to="/plans">멤버십 결제하기</Link>
        )}
      </Card>
    );
  }
}
