import React from 'react';
import { History } from 'history';
import { Card } from '../../components';

interface Props {
  history: History;
}

export class AbdsDownDescription extends React.Component<Props> {
  render() {
    return (
      <div style={{ minWidth: 320 }}>
        <Card>
          <h1>뱃살 DOWN</h1>
          <p>
            누구나 고민하는 다이어트 목표 1순위, 뱃살을 집중 공략하는 운동으로
            구성되어 있습니다.
          </p>
          <p>
            골반의 불균형 때문에 복부비만이 더욱 심해진다는 사실, 알고 계시나요?
            뱃살은 복부 운동만으로 감량하기가 어렵습니다.
          </p>
          <p>
            이 프로그램은 골반을 바로 잡아줄 수 있는 코어 운동과 열량 소모가 큰
            하체 운동 및 유산소 운동으로 구성되어 있습니다.
          </p>
          <img
            style={{ width: '100%' }}
            src="https://s3.ap-northeast-2.amazonaws.com/secret-diet-assets/abds-down-curriculum.jpg"
          />
        </Card>
      </div>
    );
  }
}
