import React from 'react';
import { hydrate } from 'emotion';
import * as ReactDOM from 'react-dom';
import 'tui-editor/dist/tui-editor.css'; // editor ui
import 'tui-editor/dist/tui-editor-contents.css'; // editor content
import '../styles/main.scss';
import { ApolloClient } from 'apollo-client';
import BrowserProvider from '../views/BrowserProvider';
import SecretdietApp from '../views/SecretdietApp';
import { configureLink } from './ClientLink';
import { configureCache, getState } from './ClientCache';
import { persistCache } from 'apollo-cache-persist';

async function startBrowserApp(id: string) {
  const cache = await configureCache();
  await persistCache({
    cache,
    storage: window.localStorage as any,
  });
  cache.restore(getState());
  const link = configureLink(cache);
  const client = new ApolloClient({
    cache,
    link,
    connectToDevTools: true,
  });
  const App = (
    <BrowserProvider client={client}>
      <SecretdietApp />
    </BrowserProvider>
  );
  hydrate((window as any).__CSS_IDS || []);
  ReactDOM.render(App, document.querySelector(id));
}

startBrowserApp('#secret-app').catch(err => console.error(err));
