import React, { SVGAttributes } from 'react';

export const ResistanceBand = (props: SVGAttributes<SVGElement>) => (
  <svg viewBox="0 0 24 24" {...props}>
    <g
      fill="none"
      stroke="#303C42"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
    >
      <path d="M10.5 2.5h-7a1 1 0 1 1 0-2h7a1 1 0 1 1 0 2z" />
      <path d="M2.5 1.5h-.586a1.414 1.414 0 1 0-1 2.414l3.147 3.147a1.5 1.5 0 0 0 1.06.439H8.88a1.5 1.5 0 0 0 1.06-.44l3.147-3.146a1.414 1.414 0 0 0-1-2.414H11.5M13.5 21.5h7a1 1 0 1 1 0 2h-7a1 1 0 0 1 0-2z" />
      <path d="M21.5 22.5h.586a1.414 1.414 0 1 0 1-2.414l-3.147-3.147a1.5 1.5 0 0 0-1.06-.439H15.12a1.5 1.5 0 0 0-1.06.44l-3.147 3.146a1.414 1.414 0 0 0 1 2.414h.586M7 7.5v2.429c0 2.61 3.155 3.916 5 2.071 1.845-1.845 5-.538 5 2.071V16.5" />
    </g>
  </svg>
);
