import {
  Button,
  Callout,
  Intent,
  NonIdealState,
  Spinner,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css } from 'emotion';
import gql from 'graphql-tag';
import { History } from 'history';
import clamp from 'lodash/clamp';
import get from 'lodash/get';
import React from 'react';
import { Query, QueryResult } from 'react-apollo';
import styled from 'react-emotion';
import {
  Discount,
  Product,
  ProductShowcase,
  ProductType,
  SubscriptionLevel,
  User,
  UserSubscription,
} from '../../gql-types';
import MandatoryInfoConfirm from '../users/MandatoryInfoConfirm';
import { PricingCheckoutStage } from './PricingCheckoutStage';
import PricingChooser, { IPricingValue } from './PricingChooser';

interface Props {
  user?: User | null;
  history: History;
}

interface State {
  stage: number;
  error: any;
  pricing: IPricingValue;
}

interface QueryResponse {
  subscription: UserSubscription;
  user: User;
  showcases: ProductShowcase[];
  discounts: Discount[];
}

function formatMessage(msg?: string) {
  if (!msg) {
    return '';
  }

  return msg.replace('GraphQL error:', '').trim();
}

const query = gql`
  query {
    showcases: productShowCasesByCountry {
      alias
      product {
        id
        name
        productType
        price
        durationInMonth
        level
      }
    }
    discounts: showCaseDiscountsByCountry {
      id
      type
      filter
      filterJson
      amount
    }
  }
`;

function isProduct(product?: Product | null): product is Product {
  return Boolean(product);
}

function isProductType(type: any) {
  return (showcase?: any) => {
    return get(showcase, 'product.productType') === type;
  };
}

const isGood = (product: Product) =>
  product.productType === ProductType.physical;
const isSubscription = isProductType(ProductType.subscription);
const toProduct = (showcase: ProductShowcase) => showcase.product;
const isLevel = (level: SubscriptionLevel) => (product: Product) =>
  product.level === level;
const isVideo = isLevel(SubscriptionLevel.basic);
const isConsulting = isLevel(SubscriptionLevel.advanced);

const maxStage = 2;

const ViewFooter = styled('div')(
  {
    marginTop: '1em',
    display: 'flex',
  },
  (props: { stage: number; max: number }) => {
    const { stage, max } = props;
    if (stage === 1) {
      return {
        justifyContent: 'flex-end',
      };
    } else if (stage === max) {
      return {
        justifyContent: 'flex-start',
      };
    }

    return {
      justifyContent: 'space-between',
    };
  },
);

export class PricingCheckoutView extends React.Component<Props, State> {
  state: State = {
    stage: 1,
    error: null,
    pricing: {
      month: 1,
      level: SubscriptionLevel.basic,
      gears: false,
    },
  };

  private updateStage = (inc: number) => {
    this.setState(prevState => {
      return {
        stage: clamp(prevState.stage + inc, 1, maxStage),
      };
    });
  };

  private handleNext = () => {
    this.updateStage(1);
  };

  private handlePrev = () => {
    this.updateStage(-1);
  };

  private handleSuccess = () => {
    const { history } = this.props;
    history.push('/profile');
  };

  private handleLogin = () => {
    const { history } = this.props;
    history.push('/login');
  };

  private handleSignup = () => {
    const { history } = this.props;
    history.push('/signup');
  };

  private handleFail = (err: any) => {
    this.setState({ error: err });
  };

  private handlePricingChange = (pricing: IPricingValue) => {
    this.setState({ pricing });
  };

  render() {
    const { stage } = this.state;
    const { user } = this.props;

    if (!user) {
      return (
        <NonIdealState
          icon={IconNames.UNLOCK}
          title="회원가입을 먼저 해주세요."
          description={
            <>
              <div>
                시크릿다이어트에 더 알고 싶으시다면, 회원가입을 해주세요.
              </div>
              <div>
                <Button
                  style={{
                    marginRight: '1em',
                  }}
                  intent={Intent.PRIMARY}
                  onClick={this.handleSignup}
                >
                  회원가입
                </Button>
                <Button onClick={this.handleLogin}>로그인</Button>
              </div>
            </>
          }
        />
      );
  }
    return (
      <Query query={query}>
        {(obj: QueryResult<QueryResponse>) => {
          if (obj.loading) {
            return <Spinner />;
          }

          if (!obj.data) {
            return <div>empty</div>;
          }

          const { showcases, discounts } = obj.data;
          const { pricing } = this.state;

          const gear = showcases.map(toProduct).find(isGood);
          const subscriptions = showcases.filter(isSubscription).map(toProduct);
          const videoSubscriptions = subscriptions.filter(isVideo);
          const consultingSubscriptions = subscriptions.filter(isConsulting);
          const byMonth = (p: Product) => p.durationInMonth === pricing.month;

          const videoSubscription = videoSubscriptions.find(byMonth);
          const consultingSubscription =
            pricing.level === SubscriptionLevel.advanced
              ? consultingSubscriptions.find(byMonth)
              : undefined;

          const items: Product[] = [
            videoSubscription,
            consultingSubscription,
            pricing.gears ? gear : undefined,
          ].filter(isProduct);

          const totalPrice = items.reduce(
            (acc, product) => acc + product.price,
            0,
          );

          const invoiceItems = items.map(item => ({
            product: item,
            quantity: 1,
            discount: discounts.find(d => {
              try {
                const filter: any = JSON.parse(d.filterJson);
                return (
                  get(filter, 'level') === item.level &&
                  get(filter, 'duration') === item.durationInMonth
                );
              } catch (err) {
                return false;
              }
            }),
          }));

          return (
            <div
              className={css({
                width: '100%',
                minWidth: 320,
                maxWidth: 800,
                minHeight: 600,
              })}
            >
              <MandatoryInfoConfirm />
              <header>
                {this.state.error && (
                  <Callout intent={Intent.WARNING} title="결제에러">
                    {this.state.error &&
                      formatMessage(this.state.error.message)}
                  </Callout>
                )}
              </header>
              {stage === 1 && (
                <PricingChooser
                  value={this.state.pricing}
                  gear={gear}
                  subscriptons={subscriptions}
                  discounts={discounts}
                  onChange={this.handlePricingChange}
                  onNext={this.handleNext}
                />
              )}
              {stage === 2 && (
                <PricingCheckoutStage
                  user={user}
                  items={invoiceItems}
                  client={obj.client}
                  onSuccess={this.handleSuccess}
                  onFail={this.handleFail}
                />
              )}

              <ViewFooter stage={stage} max={maxStage}>
                {stage !== 1 && (
                  <Button id="prev" large onClick={this.handlePrev}>
                    이전으로
                  </Button>
                )}
              </ViewFooter>
            </div>
          );
        }}
      </Query>
    );
  }
}
