import { Spinner, Callout, Intent } from '@blueprintjs/core';
import { isAfter, isBefore, differenceInDays } from 'date-fns';
import gql from 'graphql-tag';
import React from 'react';
import { Query } from 'react-apollo';
import { Redirect, RouteComponentProps } from 'react-router';
import {
  Lecture,
  SubscriptionLevel,
  User,
  UserProgress,
} from '../../gql-types';
import { QueryErrorPage } from '../errors/QueryErrorPage';
import { CurrentLectureScreen } from '../lectures/CurrentLectureScreen';
import { CourseLectureListView } from '../courses/CourseLectureListView';
import { getNodes } from '../../secret-fns';
import { css } from 'emotion';
import MandatoryInfoConfirm from '../users/MandatoryInfoConfirm';
import { ApolloError } from 'apollo-client';

const CellStyle = css({
  width: '90vw',
  '@media (min-width: 800px)': {
    width: 'calc(50vw - 1em)',
  },
});

const gaps = css({
  marginTop: '2em',
  wordBreak: 'keep-all',
});

const query = gql`
  query {
    user: currentUser {
      id
      email
      created
      updated
      deleted
      subscriptions {
        __typename
        id
        level
        status
        startsAt
        expiresAt
      }
    }
    progress: currentProgress {
      lecture {
        id
        name
        slug
        description
        videoUrl
        duration
        imageUrl
        videoUrl
        effectiveAreas
        thumbnailUrl
        progress {
          lectureId
          currentTimeInSec
          completed
        }
        course {
          id
          name
          logoUrl
          imageUrl
          description
          slug
          progress {
            completed
          }
          lectureConnection {
            totalCount
            edges {
              node {
                id
                name
                slug
                effectiveAreas
                description
                courseId
                number
                duration
                unlocked
                imageUrl
                thumbnailUrl
                videoUrl
                progress {
                  lectureId
                  currentTimeInSec
                  completed
                }
              }
            }
          }
        }
      }
    }
  }
`;

function hasValidSubscription(user: User, now = new Date()): boolean {
  const subs = user.subscriptions;
  return subs.some(sub => {
    const isExpired = !isAfter(sub.expiresAt, now);
    const isStarted =
      sub.status === 'active' ||
      (sub.status === 'halted' && isBefore(sub.startsAt, now));
    return sub.level === SubscriptionLevel.basic && !isExpired && isStarted;
  });
}

interface Props extends RouteComponentProps<any> {}

function hasAuthError(error: ApolloError) {
  const errors = error.graphQLErrors || [];
  return errors.some(err => {
    const code = err.extensions && err.extensions.code;
    return code === 'UNAUTHENTICATED';
  });
}

class MyFitnessPage extends React.Component<Props> {
  private handleLectureSelect = (lecture: Lecture) => {
    this.handleLectureView(lecture.slug);
  };

  private handleLectureView = (slug: string) => {
    const { history } = this.props;
    const lectureView = `/gym/lectures/${slug}`;
    history.push(lectureView);
  };

  private handleCourseDetail = (slug: string) => {
    const { history } = this.props;
    const courseView = `/courses/${slug}`;
    history.push(courseView);
  };

  private handleExtensionRequest = () => {
    const { history } = this.props;
    history.push('/plans');
  };

  render() {
    return (
      <Query query={query} fetchPolicy="network-only">
        {obj => {
          if (obj.loading) {
            return <Spinner />;
          }

          if (obj.error) {
            if (hasAuthError(obj.error)) {
              return <Redirect to="/login" />;
            }

            return <QueryErrorPage error={obj.error} />;
          }

          const user = obj.data.user as User | null;
          const progress = obj.data.progress as UserProgress;

          if (!user) {
            // TODO:: show screen with Login required
            return <Redirect to="/login" />;
          }

          if (!hasValidSubscription(user)) {
            return <Redirect to="/plans" />;
          }

          const nodes = getNodes(progress.lecture.course.lectureConnection);
          const isNewMember =
            differenceInDays(new Date(), user.created || new Date()) <= 10;
          const aboutToExpire = user.subscriptions
            .filter(sub => sub.level === SubscriptionLevel.advanced)
            .some(subscription => {
              if (!subscription.expiresAt) {
                return false;
              }

              return differenceInDays(subscription.expiresAt, new Date()) <= 7;
            });

          const isExpertProgram = [
            'yoyox',
            'bikini',
            'leg',
            'abds-down',
            'body-up-8weeks',
            'fitness-up',
            'wedding',
          ].includes(progress.lecture.course.slug);

          return (
            <div className={CellStyle} style={{ padding: '1em' }}>
              <MandatoryInfoConfirm />
              <CurrentLectureScreen
                user={user}
                lecture={progress.lecture}
                onLectureView={this.handleLectureView}
                onCourseDetail={this.handleCourseDetail}
                onExtensionRequest={this.handleExtensionRequest}
              />
              {aboutToExpire && (
                <Callout
                  className={gaps}
                  intent={Intent.WARNING}
                  title="멤버쉽이 조만간 만기됩니다"
                >
                  <div>늦기 전에 연장하세요.</div>
                </Callout>
              )}
              {isNewMember && (
                <Callout
                  className={gaps}
                  intent={Intent.SUCCESS}
                  title="시크릿다이어트에 오신것을 환영합니다."
                >
                  <div>
                    가입일로부터 10일 간 영상 멤버십 무료 체험이 제공됩니다.
                  </div>
                  <div>스타트레벨 1-1 부터 운동을 시작해주세요.</div>
                  <div>주 6일 운동 1일 휴식을 권장합니다.</div>
                  <div>시크리터님, 파이팅!</div>
                </Callout>
              )}
              {isExpertProgram && (
                <Callout
                  className={gaps}
                  intent={Intent.WARNING}
                  title="이 프로그램은 상급자를 위한 프로그램입니다."
                >
                  <div>
                    선택하신 프로그램은 운동 상급자를 위한 고난이도
                    프로그램입니다.
                  </div>
                  <div>
                    다이어트바이블 스타트 / 베이직 / 마스터 레벨을 클리어한 후
                    이용해주세요
                  </div>
                </Callout>
              )}
              <div className={gaps}>
                <CourseLectureListView
                  items={nodes}
                  onSelect={this.handleLectureSelect}
                />
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default MyFitnessPage;
