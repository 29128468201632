import React from 'react';
import { ReviewSectionLayout } from '../community/ReviewSectionLayout';
import { BoardList } from '../community/BoardList';
import { BoardType } from '../../gql-types';
import { RouteComponentProps } from 'react-router';

interface RouteParams {
  type: BoardType;
}

interface Props extends RouteComponentProps<RouteParams> {}

interface State {
  page: number;
  limit: number;
}

export class BeforeAndAfterPage extends React.Component<Props, State> {
  state: State = {
    page: 1,
    limit: 50,
  };

  render() {
    return (
      <ReviewSectionLayout type="beforeAndAfter" history={this.props.history}>
        <BoardList
          prefix="reviews"
          type={BoardType.beforeAndAfter}
          first={20}
        />
      </ReviewSectionLayout>
    );
  }
}
